import React, {useState} from 'react'
import {SpinnerLoading} from '../../Style/styles.css'
import {BiCloudDownload} from "react-icons/all";
import clientAPI from "../../../service/ApiService";
import {API_ASSETS} from "../../../constants";
import axios from "axios";
import {toast} from "react-toastify";


function DownloadButton({asset}) {

    const initialState = {
        loading: false,
    }
    const [state, setState] = useState(initialState)

    const downloadAsset = () =>{
        setState({
            ...state,
            loading: true,
        })
        clientAPI.get(API_ASSETS + "/presigned/" + asset.id)
            .then(response => {
                axios.get(response.data.payload, {
                    responseType: "blob"
                })
                    .then(response => {
                        setState({loading:false})
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', asset.originalFilename);
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode.removeChild(link);
                    })
                    .catch(error => {
                        setState({loading:false})
                        if(error && error.response && error.response.data.statusCode.msg){
                            console.log(error);
                        }else{
                            toast.error('Error connecting with the server, check your connection or contact support');
                        }
                    })
            })
            .catch(error => {
                if(error && error.response && error.response.data.statusCode.msg){
                }else{
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })

    }

    return (
        <div>
            {state.loading && <SpinnerLoading className={"spinner"} animation="border" role="status" />}
            {!state.loading &&
                <BiCloudDownload className={"download-asset"} onClick={() => {
                    downloadAsset();
                }} />
            }
        </div>
    )
}

export default DownloadButton
