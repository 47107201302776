import {
    USER_LOGIN,
    USER_LOGOUT,
    USER_GET_ACCOUNT,
    USER_UPDATE,
    USER_GET_PROFILE,
    ANONYMOUS_USER_LOGIN,
    LAUNCH_ONBOARD_DONE,
    LAUNCH_ONBOARD
} from './UserTypes'

import Cookies from 'universal-cookie';

const cookiesRead = new Cookies();
let token     = cookiesRead.get('token');
let userId    = cookiesRead.get('user');
let name      = cookiesRead.get('name');
let email     = cookiesRead.get('email');
let surname   = cookiesRead.get('surname');
let image     = cookiesRead.get('image');
let account   = cookiesRead.get('account');
let roles     = cookiesRead.get('roles');
let launchOnboard = cookiesRead.get('launchOnboard') || false; // First time we launch onboarding

var user = {}
var jwtToken = {}

if(token && token.length>0){
    jwtToken = token;
    user = {
        "ID" : userId,
        "email" : email,
        "name" : name,
        "surname" : surname,
        "image" : image,
        "account" : account,
        "roles" : roles,
        "launchOnboard" : launchOnboard,
    }
}

export const userInitialState = {
    user: user,
    jwtToken: jwtToken,
}
const cookies = new Cookies();

const UserReducer = (state = userInitialState, action) => {    
    switch (action.type) {
        case USER_LOGIN:

            const current = new Date();
            const nextYear = new Date();

            nextYear.setFullYear(current.getFullYear() + 1);

            cookies.set('token', action.payload.JwtToken, { path: '/', expires: nextYear});
            cookies.set('user', action.payload.User.ID, { path: '/', expires: nextYear});
            cookies.set('email', action.payload.User.email, { path: '/', expires: nextYear});
            cookies.set('name', action.payload.User.name, { path: '/', expires: nextYear});
            cookies.set('surname', action.payload.User.surname, { path: '/', expires: nextYear});
            cookies.set('image', action.payload.User.image, { path: '/', expires: nextYear});
            cookies.set('roles', action.payload.User.roles, { path: '/', expires: nextYear});

            return {
                ...state,
                jwtToken: action.payload.JwtToken,
                user: {...action.payload.User, anonymous: false},
            };
        case ANONYMOUS_USER_LOGIN:

            const fakeUser = {
                ID : 0,
                email : "anonymous",
                name :  "anonymous",
                surname : "",
                image : null,
                account : null,
                anonymous: true,
            }

            cookies.set('token', action.payload.JwtToken, { path: '/' });

            return {
                ...state,
                jwtToken: action.payload.JwtToken,
                user: fakeUser,
            };
        case USER_GET_ACCOUNT:

            cookies.set('account', action.payload.name, { path: '/' });
            return {
                ...state,
                user: {...state.user, 'account' : action.payload.name},
            };
            
        case USER_UPDATE:

            cookies.set('name', action.payload.name, { path: '/' });
            cookies.set('surname', action.payload.surname, { path: '/' });
            cookies.set('account', action.payload.accountName, { path: '/' });

            return {
                ...state,
                user: {...user, 
                    'name' : action.payload.name,
                    'surname' : action.payload.surname,
                    'account' : action.payload.accountName
                },
            };
        case USER_LOGOUT:

            cookies.remove('token');
            cookies.remove('user');
            cookies.remove('email');
            cookies.remove('name');
            cookies.remove('surname');
            cookies.remove('account');
            cookies.remove('roles');

            return {
                ...state,
                jwtToken: {},
                user: {},
            };
        case USER_GET_PROFILE:

            cookies.set('email', action.payload.name, { path: '/' });
            cookies.set('name', action.payload.name, { path: '/' });
            cookies.set('surname', action.payload.surname, { path: '/' });
            cookies.set('image', action.payload.image, { path: '/' });
            cookies.set('thumb', action.payload.thumb, { path: '/' });
            cookies.set('roles', action.payload.roles, { path: '/' });

            return {
                ...state,
                user: action.payload,
            };
        case LAUNCH_ONBOARD_DONE:
            console.log("LAUNCH_ONBOARD_DONE")
            return {
                ...state,
                user: {...state.user, launchOnboard: false},
            };
        case LAUNCH_ONBOARD:
            return {
                ...state,
                user: {...state.user, launchOnboard: true},
            };
        default:
            return state;
    }
};

export default UserReducer;