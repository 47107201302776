import React, {useEffect, useState} from 'react'
import {Drawer} from "antd";
import {SpinnerLoading} from "../Style/styles.css";
import clientAPI from "../../service/ApiService";
import {API_TEMPLATE} from "../../constants";
import {toast} from "react-toastify";
import AssetTablePreview from "./AssetTablePreview";
import PreviewTable from "./PreviewTable/PreviewTable";

function TemplatePreview({template=null, closePreview}) {

    const [visible, setVisible] = useState(false)
    const initialState = {
        error: null,
        loading: false,
        templateDetail: null, //template
    }
    const [state, setState] = useState(initialState)

    useEffect(() => {
        if(template !== null){
            setVisible(true);
            getTemplate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template]);

    const onClose = () => {
        setVisible(false);
        closePreview();
    };

    const getTemplate = () => {

        setState({
            ...state,
            error: null,
            loading: true,
            templateDetail: null
        })

        clientAPI.get(API_TEMPLATE + "/" + template.id)
            .then(response => {
                let payload = response.data.payload;

                setState({
                    ...state,
                    error: null,
                    loading: false,
                    templateDetail: payload,
                })
            })
            .catch(error => {
                let msg = error?.response?.data?.statusCode?.msg
                setState({
                    ...state,
                    error: msg ?? null,
                    loading: false,
                })
                if(!msg)
                    toast.error('Error connecting with the server, check your connection or contact support');
            })
    }

    return (
        <div className="template-preview">
            <Drawer
                title={template?.name}
                placement={"bottom"}
                closable={true}
                onClose={onClose}
                visible={visible}
                key={"bottom"}
                height="100%"
                className={"template-preview-drawer"}
            >
                {state.loading && <SpinnerLoading animation="border" role="status" className={"spinner"} />}
                {  state.templateDetail && state.templateDetail.tables && state.templateDetail?.type === 'board' &&
                    state.templateDetail.tables.map((table, index) =>
                        <div key={index} className={"preview-table-container"}>

                            { table.type === "copy"  ?
                                table?.body?.length > 0 ?
                                    <div className={"copy-table-container"} dangerouslySetInnerHTML={{ __html: table.body }} />
                                    :
                                    <h4 className={"empty"}>Empty text</h4>
                                :
                                table.type === "assets" ?
                                    <AssetTablePreview table={table} files={state.templateDetail.assets}/>
                                    :
                                    <PreviewTable table={table}
                                                  r={state.templateDetail.rows}
                                                  template={state.templateDetail}
                                    />
                            }

                        </div>
                    )
                }
                { template?.type === 'table' && state.templateDetail?.type === 'table' &&
                    <div className={"preview-table-container"}>
                        <PreviewTable table={state.templateDetail.table}
                                      r={state.templateDetail.rows}
                                      template={state.templateDetail}
                        />
                    </div>
                }
            </Drawer>
        </div>
    )
}

export default TemplatePreview
