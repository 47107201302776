import React, {useContext, useEffect, useState} from 'react'
import clientAPI from "../../service/ApiService"
import {API_BOARD} from '../../constants';
import {toast} from 'react-toastify';
import {Alert, Modal} from 'antd';
import {BoardContext} from "../../state/boardStore";
import {UPDATE_VIEW_CONFIG} from "../../state/boards/BoardTypes";
import {Select} from 'antd';


function ConfigureViewsModal({table, counter, viewToOpen, changeView}) {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        visible: false,
        hasDateColumn: null,
        calendarStartColumn: null,
        calendarEndColumn: null,
        hasStatusColumn : null,
        kanbanStatusColumn: null,
    }
    const [state, setState] = useState(initialState)
    const [startDateColumnsExist, setStartDateColumnsExist] = useState(null)
    const [endDateColumnsExist, setEndDateColumnsExist] = useState(null)
    const [statusColumnExists, setStatusColumnExists] = useState(null)
    const [boardState, dispatchBoard] = useContext(BoardContext);


    useEffect(() => {
        if (counter > 0) {
            showModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter]);

    const showModal = () => {

        // if calendar is configured set the dates
        let calendarStartColumn = null;
        let calendarEndColumn = null;
        let thereIsDate = null;
        if(table.calendarStartDate){
            calendarStartColumn = table.calendarStartDate;
        }
        if(table.calendarEndDate){
            calendarEndColumn = table.calendarEndDate;
        }

        setStartDateColumnsExist(table.columns.find(col => col.id === table.calendarStartDate));
        setEndDateColumnsExist(table.columns.find(col => col.id === table.calendarEndDate));
        setStatusColumnExists(table.columns.find(col => col.id === table.kanbanStatusColumn));

        if(!calendarStartColumn || !calendarEndColumn || !startDateColumnsExist || !endDateColumnsExist){
            // Check if there is at least one date column in the table, and if not show the notAvailable message
            // eslint-disable-next-line array-callback-return
            thereIsDate = table?.columns.find(column => {
                if (column.type.type === "datetime") {
                    return true;
                }
            })

            // If there are dates try to infer which one is which one
            if(thereIsDate !== undefined){
                // eslint-disable-next-line array-callback-return
                const inferStartColumn = table?.columns.filter(column => {
                    if (column.type.type === "datetime" && column.name.toLowerCase().includes("start")) {
                        return column;
                    }
                })
                if(inferStartColumn.length > 0){
                    calendarStartColumn = inferStartColumn[0]?.id
                }

                // eslint-disable-next-line array-callback-return
                const inferEndColumn = table?.columns.filter(column => {
                    if (column.type.type === "datetime" && (column.name.toLowerCase().includes("due") || column.name.toLowerCase().includes("end") )) {
                        return column;
                    }
                })
                if(inferEndColumn.length > 0){
                    calendarEndColumn = inferEndColumn[0]?.id
                }
            }
        }else{
            thereIsDate = true;
        }

        // Kanban
        let kanbanStatusColumn = null;
        let hasStatusColumn = null;
        if(table.kanbanStatusColumn){
            kanbanStatusColumn = table.kanbanStatusColumn;
        }else {
            // eslint-disable-next-line array-callback-return
            hasStatusColumn = table?.columns.find(column => {
                if (column.type.type === "status") {
                    return true;
                }
            })
        }

        setState({
            ...initialState,
            visible: true,
            hasDateColumn: thereIsDate !== undefined,
            calendarStartColumn: calendarStartColumn,
            calendarEndColumn: calendarEndColumn,
            hasStatusColumn: hasStatusColumn !== undefined,
            kanbanStatusColumn: kanbanStatusColumn,
        });
    };

    const handleCancel = e => {
        setState({
            ...state,
            visible: false,
        });
    };

    const save = () => {

        if(!state.hasDateColumn){
            setState({
                ...state,
                visible: false,
            });
            return;
        }

        setState({
            ...state,
            error: null,
            loading: true,
            success: false,
        })

        const payload = {
            "startColumnDate" : state.calendarStartColumn,
            "endColumnDate"   : state.calendarEndColumn,
            "kanbanStatusColumn" : state.kanbanStatusColumn,
        }

        clientAPI.post(API_BOARD + "/" + boardState.board.id + "/table/" + table.id + "/configure-view", payload )
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false,
                    success: false,
                    visible: false,
                })
                handleCancel()
                dispatchBoard({type: UPDATE_VIEW_CONFIG,
                    payload: {
                    tableId:table.id,
                        calendarStartDate: state.calendarStartColumn,
                        calendarEndDate: state.calendarEndColumn,
                        kanbanStatusColumn: state.kanbanStatusColumn,
                }});
                if(viewToOpen !== null)
                    changeView(viewToOpen);
            })
            .catch(error => {
                console.log(error)
                if (error && error.response && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false,
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    };

    const {Option} = Select;

    return (
        <React.Fragment>

            <Modal
                title={"Configure view parameters"}
                visible={state.visible}
                okText={"Save"}
                className="configure-views"
                onOk={() => save()}
                onCancel={() => handleCancel()}
                confirmLoading={state.loading}
                okButtonProps={{form: 'category-editor-form', key: 'submit', htmlType: 'submit'}}
            >
                {state.error && <Alert message={state.error} type="error" closable className="notification"/>}
                <div className={"view-section calendar-section"}>
                    <h3>Calendar view</h3>
                    {state.hasDateColumn ?
                        <React.Fragment>
                        <p>To make the calendar view work, you need to select which columns are going to be used for this
                            view.</p>
                        <div className="options">
                            <div className="start-date">
                                <label htmlFor="">Start date</label>
                                <Select defaultValue={ startDateColumnsExist && state.calendarStartColumn ? state.calendarStartColumn : null}
                                        onChange={(value) => setState({...state, calendarStartColumn: value})}>
                                    {table?.columns.map(column => {
                                        if (column.type.type === "datetime") {
                                            return <Option value={column.id} key={column.id}>{column.name}</Option>;
                                        }
                                        return null;
                                    })}
                                </Select>
                            </div>
                            <div className="end-date">
                                <label htmlFor="">End date</label>
                                <Select defaultValue={endDateColumnsExist && state.calendarEndColumn ? state.calendarEndColumn : null}
                                        onChange={(value) => setState({...state, calendarEndColumn: value})}>
                                    {table?.columns.map(column => {
                                        if (column.type.type === "datetime") {
                                            return <Option value={column.id} key={column.id}>{column.name}</Option>;
                                        }
                                        return null;
                                    })}
                                </Select>
                            </div>
                        </div>
                        </React.Fragment>
                        :
                        <p className={"not-available"}>This table doesn't contain any date type column required for the date calendar.
                            If you want to make use of the calendar view, please add at least one date column in your list.</p>
                    }
                </div>
                <div className="separator"/>
                <div className={"view-section kanban-section"}>
                    <h3>Kanban view</h3>
                    {state.hasStatusColumn ?
                        <React.Fragment>
                            <p>To make the kanban view work, you need to select which column is going to be used for this
                                view.</p>
                            <div className="options">
                                <div className="kanban-status">
                                    <label htmlFor="">Status view</label>
                                    <Select defaultValue={statusColumnExists && state.kanbanStatusColumn ? state.kanbanStatusColumn : null}
                                            onChange={(value) => setState({...state, kanbanStatusColumn: value})}>
                                        {table?.columns.map(column => {
                                            if (column.type.type === "status") {
                                                return <Option value={column.id} key={column.id}>{column.name}</Option>;
                                            }
                                            return null;
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </React.Fragment>
                        :
                        <p className={"not-available"}>This table doesn't contain any status type column required for the Kanban view.
                            If you want to make use of the kanban view, please add at least one status column in your list.</p>
                    }
                </div>
            </Modal>

        </React.Fragment>
    )
}

export default ConfigureViewsModal
