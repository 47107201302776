import React, {useContext, useEffect, useState} from 'react'
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import arrayMove from 'array-move';
import clientAPI from "../../service/ApiService"
import {API_ROW_MOVE, API_URL} from '../../constants';
import {generatePath} from 'react-router';
import {toast} from 'react-toastify';
import Row from './Row';
import {SET_ROWS, SET_SUB_ROWS} from "../../state/boards/BoardTypes";
import {BoardContext} from "../../state/boardStore";
import {GENERIC_ERR_MSG} from "../../constants/constants";

function SortableRows(props) {

    const [rows, setRows] = useState([])

    const initialState = {
        movePath: API_URL + generatePath(API_ROW_MOVE, {
            board: props.boardId,
            table: props.table.id
        }),
    }
    const [state, setState] = useState(initialState)
    const dispatchBoard = useContext(BoardContext)[1];

    useEffect(() => {
        setRows(props.rows)
    }, [props.rows]);

    function onDragEnd(result) {
        
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        let items = arrayMove([...rows], result.source.index, result.destination.index);


        // New position would be the sum of prev and next positions divided by 2
        // let currentIndex = result.destination.index;
        let prevIndexPos = result.destination.index > 0 ? items[result.destination.index-1].position : 0;
        let nextIndexPos = result.destination.index < items.length-1 ? items[result.destination.index+1].position : (items[items.length-2].position)+1;
        let newPosition  = (prevIndexPos + nextIndexPos)/2;

        items[result.destination.index].position = newPosition;
        setRows(items)

        let payload = { "row" : result.draggableId, "position": newPosition}

        clientAPI.post(state.movePath, payload)
        .then(response => {
            setState({
                ...state,
                loading: false,
            })

            // dispatchBoard({type: UPDATE_ROW_POSITION, payload: {tableId: props.table.id, rowId: result.draggableId, position: newPosition}});
            if(!props.parent){
                dispatchBoard({type: SET_ROWS, payload: {tableId: props.table.id ,rows: items}});
            }else{
                dispatchBoard({type: SET_SUB_ROWS, payload: {tableId: props.table.id ,rows: items, parentRowId: props.parent}});
            }

        })
        .catch(error => {
            setState({
                ...state,
                loading: false,
            })
            toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
        })
    }

    return (
        <div className="draggable-list-rows">
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list-rows">
                    {provided => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>

                            {rows && rows.map((row, index) => (
                                <Row boardId={props.boardId}
                                     table={props.table}
                                     index={index}
                                     row={row}
                                     key={row.id}/>
                            ))}

                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

        </div>
    )
}

export default SortableRows
