import React, {useContext, useEffect, useState} from 'react'
import clientAPI from "../../../service/ApiService"
import {API_CELL, API_URL} from '../../../constants';
import {generatePath} from 'react-router';
import {BoardContext} from '../../../state/boardStore'
import EdiText from 'react-editext'
import {toast} from 'react-toastify';
import {SET_SINGLE_VALUE} from '../../../state/boards/BoardTypes';
import {GENERIC_ERR_MSG} from "../../../constants/constants";

function NumberCell(props) {

    const initialState = {
        loading: false,
        error: null,
        path: API_URL + generatePath(API_CELL, {
            board: props.boardId,
            table: props.table.id,
            row: props.row.id,
            column: props.column.id,
        }),
        entry: null,
    }

    const dispatchBoard = useContext(BoardContext)[1];
    const [state, setState] = useState(initialState)

    useEffect(() => {
        var entryAux = props.row.entries.find(function(obj){
            return obj.column === props.column.id
        })
        setState({
            ...state,
            entry: entryAux !== undefined ? entryAux.value : "",
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const updateValue = val => {

        clientAPI.post(state.path, {"value": val})
        .then(response => {
            setState({
                ...state,
                loading: false,
                entry: val,
            })
            // We try to avoid re-rendering the table as much as possible,
            // but with numbers as we have to access the recap row we are forced to rerender the whole board
            // TODO future improvement would be rerender the table and not the board.
            // dispatchBoard({type: UPDATE_BOARD, payload:response.data.payload});

            dispatchBoard({
                type: SET_SINGLE_VALUE,
                payload: {tableId: props.table.id, rowId: props.row.id, parentRowId: props.row.parent, columnId: props.column.id, value: val}
            });

        })
        .catch(error => {
            setState({
                ...state,
                loading: false,
                entry: null,
            })
            toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
        })
    }

    return (    
        <div  className="cell-entry">
            { state.entry != null &&
                <EdiText 
                    submitOnEnter
                    cancelOnEscape
                    submitOnUnfocus
                    hideIcons={true}
                    editOnViewClick={true}
                    showButtonsOnHover={false}
                    editButtonClassName="hide"
                    saveButtonClassName="hide"
                    cancelButtonClassName="hide"
                    viewContainerClassName="row-name"
                    inputProps={{
                        style: {
                            backgroundColor: '#FAFAFA',
                            width: "100%"
                        },
                    }}
                    type="number" 
                    value={state.entry} 
                    onSave={updateValue} 
                    className="edit-entry"/>
            }
        </div>
    )
}

export default NumberCell
