import React, { useState, useEffect } from 'react'
import TeamIcon from '../Common/TeamIcon';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function TeamsIcons({ teams }) {

    const initialState = {
        numPlus: 0,
        teamsToDisplay: [],
        showOverlay: false
    }
    const [state, setState] = useState(initialState)

    useEffect(() => {
        if (!teams) return
        if (teams.length > 5) {
            setState({
                ...state,
                numPlus: teams - 2,
                teamsToDisplay: teams.slice(0, 5)
            })
        } else {
            setState({
                ...state,
                teamsToDisplay: teams
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teams]);

    const colors = [
        "#43a047",
        "#ffb300",
        "#1e88e5",
        "#e53935",
        "#fb8c00",
        "#757575",
        "#5e35b1",
        "#546e7a"
    ]

    const tooltip = (props) => (
        <Tooltip id="user-teams-tooltip" {...props}>
            <div className="user-teams-inner">
                {teams && teams.length > 0 &&
                    <div>
                        <ul>
                            {teams.map((team, index) =>
                                <li key={index}>{team.name}</li>
                            )}
                        </ul>
                    </div>
                }
            </div>
        </Tooltip>
    );

    return (
        <OverlayTrigger placement={'bottom'} overlay={tooltip} showOverlay={false}>
            <div className="users-and-teams" style={{ width: (33 + ((state.teamsToDisplay.length-1) * 16)) }}>
                <div className="list" style={{ width: (33 + ((state.teamsToDisplay.length-1) * 16)) }} >
                    {state.teamsToDisplay && state.teamsToDisplay.map((team, index) =>
                        <div key={index} className="user-team-entry" style={{ left: index * 15, zIndex: 5 - index }}>
                            <TeamIcon team={team} color={colors[index]} />
                        </div>
                    )}
                </div>

            </div>
        </OverlayTrigger>
    )
}

export default TeamsIcons
