import React, {useEffect, useState} from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import clientAPI from "../../service/ApiService"
import {API_TEAMS_SEARCH} from '../../constants/index';
import {SpinnerLoading} from '../Style/styles.css'
import {toast} from 'react-toastify';
import {Alert, Empty, Input} from 'antd';
import UsersPagination from '../Users/UsersPagination'
import TeamRow from './TeamRow'
import CreateTeamModal from "./CreateTeamModal";
import TeamsSVG from '../../assets/teams-icon2.svg';

function TeamsTable({needReload, reloadAck}) {

    const initialState = {
        page: 1,
        teams: [],
        sizePerPage: 10,
        totalSize: 0,
        error: null,
        loading: false,
        firstLoad: false,
    }
    const [state, setState] = useState(initialState)

    useEffect(() => {
        getTeams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(needReload)
            getTeams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needReload]);

    useEffect(() => {
        getTeams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.page, state.sizePerPage]);

    const getTeams = (search=null) => {
        setState({
            ...state,
            error: null,
            loading: true,
        })

        clientAPI.get(API_TEAMS_SEARCH + "?page=" + state.page + "&limit=" + state.sizePerPage + (search ? "&search=" + encodeURIComponent(search) : "" ))
            .then(response => {
                let teams = response.data.payload.teams;
                if(!teams || teams.length <= 0 ){
                    setState({...state,
                        teams:[],
                        totalSize: 0,
                        page: state.page > 1 ? state.page-1 : state.page,
                        firstLoad: true,
                    })
                    return false
                }

                setState({
                    ...state,
                    error: null,
                    loading: false,
                    teams: teams,
                    totalSize: response.data.payload.pagination.total,
                    firstLoad: true,
                })
                reloadAck();
            })
            .catch(error => {
                let msg = error?.response?.data?.statusCode?.msg
                setState({
                    ...state,
                    error: msg ?? null,
                    loading: false,
                    teams: [],
                    totalSize: null,
                    firstLoad: true,
                })
                if(!msg)
                    toast.error('Error connecting with the server, check your connection or contact support');
            })
    }


    const goToPage = ((page) => {
        setState({
            ...state,
            page: page
        })
    });

    const { Search } = Input;
    const [timer, setTimer] = useState(null)


    const setSearch = (event) => {
        var searchText = event.target.value;

        if (timer != null) {
            clearTimeout(timer);
        }

        setTimer(
            setTimeout(() => {
                getTeams(searchText)
            }, 300)
        )
    }

    return (
        <div className={"teams-table-container"}>

            {state.error && <Alert message={state.error} type="error" closable className="notification" />}

            <Search placeholder="Search team"  onChange={(e) => setSearch(e)}/>
            {state.loading && <SpinnerLoading animation="border" role="status" className={"spinner"} />}
            {state.teams &&
                <div>
                    <table className="table teams-table">
                        <thead>
                            <tr>
                                <th scope="col" style={{ color:"black" }}>Name</th>
                                <th scope="col">Users</th>
                                <th scope="col">Boards</th>
                                <th scope="col" style={{ width:200 }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.teams && state.teams.length > 0 && state.teams.map((team, index) =>
                                <TeamRow key={index} team={team} refresh={getTeams} />
                            )}
                        </tbody>
                    </table>
                    <UsersPagination
                        sizePerPage={state.sizePerPage}
                        totalSize={state.totalSize}
                        page={state.page}
                        goToPage={goToPage}
                    />
                </div>
            }
            { state.firstLoad && state.teams.length <= 0 &&
                <Empty
                    image={TeamsSVG}
                    imageStyle={{
                        height: 80,
                    }}
                    description={
                        <span>
                            Start by creating your first team
                        </span>
                    }
                >
                    <CreateTeamModal reload={()=>getTeams()} />
                </Empty>
            }
        </div>
    )
}

export default TeamsTable
