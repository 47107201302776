import React, {useContext, useEffect, useState} from 'react'
import { Tabs } from 'antd';
import CompanyInfo from "./CompanyInfo";
import AdminUsers from "./AdminUsers";
import DeleteAccount from "./DeleteAccount";
import {Context} from "../../state/store";
import {API_GET_ACCOUNT} from "../../constants";
import clientAPI from "../../service/ApiService";
import {toast} from "react-toastify";

function AdminPage() {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        company: null,
        admins: null,
    }

    const [state, setState] = useState(initialState)
    const [userState] = useContext(Context);
    const { TabPane } = Tabs;

    function callback(key) {}

    useEffect(() => {
        getCompanyInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getCompanyInfo = () => {

        clientAPI.get(API_GET_ACCOUNT)
            .then(response => {

                let company = response.data.payload;
                var options = [];
                company.admins.map(function (user) {
                    options.push({value: user.ID, label: user.email});
                    return user;
                })

                setState({
                    ...state,
                    company: company,
                    error: null,
                    loading: false,
                    admins: options
                })

            })
            .catch(error => {

                if (error && error.response && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false,
                        success: false
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    }

    return (
        <div className="admin-page-container page-inner">

            { userState.user.account &&
                <h3>{userState.user.account}</h3>
            }

            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Company Info" key="1">
                    <CompanyInfo company={state.company} />
                </TabPane>
                <TabPane tab="Manage admin users" key="2">
                    <AdminUsers admins={state.admins} />
                </TabPane>
                <TabPane tab="Delete account" key="3">
                    <DeleteAccount/>
                </TabPane>
            </Tabs>

        </div>
    )
}

export default AdminPage
