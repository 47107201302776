import React, {useState} from 'react'
import {API_TEMPLATE} from '../../constants/index';
import {SpinnerLoading} from '../Style/styles.css'
import clientAPI from "../../service/ApiService"
import { toast } from 'react-toastify';
import {IoIosClose} from "react-icons/io";
import {Tooltip} from "antd";

function DeleteButton({template, refresh}) {

    const initialState = {
        loading: false,
    }
    const [state, setState] = useState(initialState)

    const deleteTemplate = () =>{
        setState({
            ...state,
            loading: true,
        })
        clientAPI.delete(API_TEMPLATE + "/" + template.id)
            .then(response => {
                setState({
                    ...state,
                    loading: false,
                })
                refresh()
            })
            .catch(error => {
                if(error && error.response && error.response.data.statusCode.msg){
                    setState({
                        ...state,
                        loading: false,
                    })
                }else{
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    }

    return (
        <div>
            {state.loading && <SpinnerLoading className={"spinner"} animation="border" role="status" />}
            {!state.loading &&

            <Tooltip placement="bottom" mouseEnterDelay={0.6} title={"Delete template"}>
                <IoIosClose className={"delete-template"} onClick={() => {
                    if (window.confirm('Are you sure you want to delete this template?'))
                        deleteTemplate()}
                } />
            </Tooltip>
            }
        </div>
    )
}

export default DeleteButton