import React, {createContext, useReducer} from "react";
import UsersReducer, {usersInitialState} from './users/UsersReducer'

//We will need to eventually combine them
const UsersStore = ({children}) => {
    const [state, dispatch] = useReducer(UsersReducer, usersInitialState);
    
    return (
        <UsersContext.Provider value={[state, dispatch]}>
            {children}
        </UsersContext.Provider>
    )
};

export const UsersContext = createContext(usersInitialState);
export default UsersStore;