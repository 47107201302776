import React, {useContext, useEffect, useState} from 'react'
import {StarFilled, StarOutlined} from '@ant-design/icons';
import clientAPI from "../../service/ApiService"
import {API_BOARD} from '../../constants/index'
import {toast} from 'react-toastify';
import {STAR_BOARD, UNSTAR_BOARD} from "../../state/boards/BoardTypes";
import {BoardsContext} from '../../state/boardsStore'
import {BoardContext} from "../../state/boardStore";
import {GENERIC_ERR_MSG} from "../../constants/constants";

function StarBoardButton({board}) {

    const [favorite, setFavorite] = useState(false)
    const dispatchBoards = useContext(BoardsContext)[1];
    const dispatchBoard= useContext(BoardContext)[1];

    useEffect(() => {
        if(board == null) return;
        setFavorite( (board.userpreference && board.userpreference.favorite) ? board.userpreference.favorite : false)
    }, [board]);

    const apiSetFavorite = (favorite) =>{
        setFavorite(favorite);
        clientAPI.post(API_BOARD + "/" + board.id + "/favorite/" + (favorite ? "1" : "0") )
        .then(response => {
            if(favorite){
                dispatchBoard({type: STAR_BOARD});
                dispatchBoards({type: STAR_BOARD, payload: {boardId: board.id}});
            }else{
                dispatchBoard({type: UNSTAR_BOARD});
                dispatchBoards({type: UNSTAR_BOARD, payload: {boardId: board.id}});
            }

            // dispatchBoards({type: NEED_RELOAD});
        })
        .catch(error => {
            setFavorite(!favorite);
            toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
        })
    }

    return (
        <div className="favorite">
            { favorite ?
                   <StarFilled  className="unfavorite-cta" onClick={() => apiSetFavorite(false)} />
                :  <StarOutlined onClick={() => apiSetFavorite(true)} />
            }

        </div>
    )
}

export default StarBoardButton
