import {
    GET_BOARDS,
    CREATE_BOARD,
    DELETE_BOARD,
    EDIT_BOARD,
    NEED_RELOAD,
    STAR_BOARD,
    UNSTAR_BOARD,
    SET_SOCKET,
    SET_WIDTH,
    OPEN_CREATE_BOARD_MODAL
} from './BoardTypes'

export const boardsInitialState = {
    boards: [],
    reload: 0,
    socket: null,
    width: 0,
    createBoardsModal: 0,
    modalMode: null,
    board: null,
}

const sortBoards = (boards) => {

    let elements = boards.sort(function(a, b) {

        if(a?.userpreference?.favorite && b?.userpreference?.favorite === false){
            return -1;
        }else if(a?.userpreference?.favorite === false && b?.userpreference?.favorite){
            return 1;
        }else{
            if(a?.updatedAt > b?.updatedAt){
                return -1;
            }else{
                return 1;
            }
        }
    });

    return elements;
}

const BoardsReducer = (state = boardsInitialState, action) => {    
    switch (action.type) {
        case GET_BOARDS:          

            // All except the current one that you don't have to replace


            return {
                ...state,
                boards: action.payload !== null ? action.payload : [],
            };
        case CREATE_BOARD:

            let boards = [...state.boards];            
            boards.push(action.payload);   
                     
            return {
                ...state,
                boards: boards,
            };
        case DELETE_BOARD:
        
            return {
                ...state,
                boards: [...state.boards].filter(function (board) {
                    return board.id !== action.payload;
                }),
            };
        case EDIT_BOARD:
    
            return {
                ...state,
                boards: [...state.boards].map(function (board) {
                     if(board.id === action.payload.id){
                         board.name = action.payload.name;
                     }
                     return board
                }),
            };
        case NEED_RELOAD:

            return {
                ...state,
                reload: state.reload + 1
            };
        case STAR_BOARD:

            let elements = [...state.boards];
            elements.forEach(function(item,i){
                if(item.id === action.payload.boardId){
                    if(!item.userpreference)
                        item.userpreference = {};
                    item.userpreference.favorite = true;
                }
            });

            return {
                ...state,
                boards: sortBoards(elements),
            };
        case UNSTAR_BOARD:

            let elements2 = [...state.boards];
            elements2.forEach(function(item,i){
                if(item.id === action.payload.boardId){
                    item.userpreference.favorite = false;
                }
            });

            return {
                ...state,
                boards: sortBoards(elements2),
            };
        case SET_SOCKET:
            return {
                ...state,
                socket: new WebSocket(action.payload)
            };
        case SET_WIDTH:
            return {
                ...state,
                width: action.payload
            };
        case OPEN_CREATE_BOARD_MODAL:

            return {
                ...state,
                createBoardsModal: state.createBoardsModal + 1,
                board: action.payload.board ?? null,
                template: action.payload.template ?? null,
            };
        default:
            return state;
    }
};

export default BoardsReducer;