import React from 'react';
import AutomaticSearchBoard from "./AutomationSearchBoard";
import AutomationSelectTable from "./AutomationSelectTable";
import AutomationRowFields from "./AutomationRowFields";

function AutomationCreateRow({board, selectedBoard, table, selectedTable, rowName, selectedName}) {

    return (
        <div>
            { board === null &&
            <div className={"board-selection"}>
                <p>Select board</p>
                <AutomaticSearchBoard
                    selectedBoard={(board) => selectedBoard(board)} />
            </div>
            }
            { board !== null && table === null &&
            <div className={""}>
                <div>
                    <p>Select Table</p>
                    <AutomationSelectTable
                        boardId={board.id}
                        selectedTable={(table) => selectedTable(table)} />
                </div>
            </div>
            }

            { board !== null && table !== null &&
            <div className={"create-row-container"}>
                <AutomationRowFields
                    board={board}
                    table={table}
                    selectedName={(name) => selectedName(name)}/>
            </div>
            }

        </div>
    );

}

export default AutomationCreateRow