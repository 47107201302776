export const API_URL       = process.env.REACT_APP_API_URL
export const API_CONTACTUS = API_URL + "/contactus"
export const API_LOGIN     = API_URL + "/login"
export const API_GOOGLE_LOGIN = API_URL + "/google/login"
export const API_FACEBOOK_LOGIN = API_URL + "/facebook/login"
export const API_REGISTER  = API_URL + "/signup"
export const API_CONFIRM   = API_URL + "/signup/confirm"
export const API_FORGET    = API_URL + "/forget"
export const API_RESET_PWD = API_URL + "/forget/reset"
export const API_USER         = API_URL + "/user"
export const API_SAVE_PROFILE = API_URL + "/user/profile"
export const API_GET_PROFILE = API_URL + "/user/profile"
export const API_CHANGE_PASS  = API_URL + "/user/password"
export const API_USER_PICTURE = API_URL + "/user/picture"
export const API_USER_EMAIL   = API_URL + "/user/email"
export const API_CHANGE_EMAIL = API_URL + "/change-email"

export const API_GET_ACCOUNT = API_URL + "/account"
export const API_SET_ACCOUNT = API_URL + "/account"
export const API_USERS       = API_URL + "/account/users"
export const API_ALL_USERS   = API_URL + "/account/all-users"
export const API_DASHBOARD   = API_URL + "/account/dashboard"

export const API_TEAMS        = API_URL + "/team"
export const API_TEAMS_SEARCH = API_URL + "/team/search"
export const API_TEAMS_ALL    = API_URL + "/team/all"
export const API_TEAMS_EDIT   = "/team/:team/edit"
export const API_TEAMS_ID     = "/team/:team"

export const API_SEARCH        = API_URL + "/search"

export const API_INVITE        = API_URL + "/user/invite"
export const API_UNINVITE      = API_URL + "/account/uninvite"
export const API_ACCEPT_INVITE = API_URL + "/account/accept"

export const API_BOARD       = API_URL + "/board"
export const API_BOARD_ID    = API_URL + "/board/:board"
export const API_BOARD_MOVE  = API_URL + "/board/move"
export const API_TABLE       = "/board/:board/table"
export const API_TABLE_MOVE  = "/board/:board/table/move"
export const API_TABLE_ID    = "/board/:board/table/:table"

export const API_TEMPLATE       = API_URL + "/template"

export const API_COLUMN       = "/board/:board/table/:table/column"
export const API_COLUMN_MOVE  = "/board/:board/table/:table/column/move"
export const API_COLUMN_ID    = "/board/:board/table/:table/column/:column"

export const API_ROW       = "/board/:board/table/:table/row"
export const API_ROW_MOVE  = "/board/:board/table/:table/row/move"
export const API_ROW_ID    = "/board/:board/table/:table/row/:row"

export const API_CELL          = "/board/:board/table/:table/column/:column/row/:row/entry"
export const API_AUTOMATIONS   = "/board/:board/table/:table/automation"
export const API_AUTOMATIONS_ID = "/board/:board/table/:table/automation/:automation"

export const API_GET_MESSAGES     = "/board/:board/table/:table/row/:row/message"
export const API_GET_MESSAGES_ID  = "/board/:board/table/:table/row/:row/message/:message"

export const API_ASSETS       = API_URL + "/asset"

export const API_GET_LOGS          = "/log/:board"

export const API_GET_NOTIFICATIONS = API_URL + "/notifications"

export const SHARE_LINK             = API_URL + "/shared-link"
export const SHARE_LINK_BOARD_ID    = API_URL + "/shared-link/:board"

export const API_WS_CONNECT    = process.env.REACT_APP_API_WS_CONNECT