import React, {useContext, useEffect, useState} from 'react'
import {Alert, Modal} from 'antd';
import {BoardContext} from "../../../state/boardStore";
import Messages from "./Messages";
import TextCell from "../../Boards/Cells/TextCell";
import DateTimeCell from "../../Boards/Cells/DateTimeCell";
import PeopleCell from "../../Boards/Cells/PeopleCell/PeopleCell";
import StatusCell from "../../Boards/Cells/StatusCell";
import NumberCell from "../../Boards/Cells/NumberCell";
import EdiText from "react-editext";
import clientAPI from "../../../service/ApiService";
import {toast} from "react-toastify";
import {API_ROW_ID, API_URL} from "../../../constants";
import {generatePath} from 'react-router';
import {CHANGE_ROW_NAME} from "../../../state/boards/BoardTypes";
import RowModalSubtasks from "./RowModalSubtasks";

function RowModal({table, row, counter}) {

    const initialState = {
        error: null,
        loading: false,
        success: false,
    }
    const [state, setState] = useState(initialState)
    const [boardState, dispatchBoard] = useContext(BoardContext);

    useEffect(() => {
        if (counter > 0) {
            showModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter]);

    const showModal = () => {

        setState({
            ...initialState,
            visible: true,
        });
    };

    const handleCancel = e => {
        setState({
            ...state,
            visible: false,
        });
    };

    const save = () => {

    };

    const editRowName = val => {

        const path = API_URL + generatePath(API_ROW_ID, {
            board: boardState.board.id,
            table: table.id,
            row: row.id
        });

        clientAPI.put(path, {"name": val})
            .then(response => {
                setState({
                    ...state,
                    loading: false,
                })
                dispatchBoard({type: CHANGE_ROW_NAME, payload:{tableId:table.id, rowId:row.id , name:val}});
            })
            .catch(error => {
                console.log(error)
                if(error && error.response && error.response.data.statusCode.msg){
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false,
                    })
                }else{
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    }

    return (
        <React.Fragment>

            <Modal
                title={"Event"}
                visible={state.visible}
                okText={"Save"}
                className="row-modal"
                onOk={() => save()}
                onCancel={() => handleCancel()}
                footer={null}
            >
                {row &&
                <React.Fragment>
                    {state.error && <Alert message={state.error} type="error" closable className="notification"/>}
                    <div className={"title-section"}>
                        <h3>
                            <EdiText
                            submitOnEnter
                            cancelOnEscape
                            submitOnUnfocus
                            hideIcons={true}
                            editOnViewClick={true}
                            showButtonsOnHover={false}
                            editButtonClassName="hide"
                            saveButtonClassName="hide"
                            cancelButtonClassName="hide"
                            viewContainerClassName="row-name"
                            inputProps={{
                                style: {
                                    backgroundColor: '#FAFAFA',
                                },
                            }}
                            type="text" value={row.name} onSave={editRowName} className="edit-row-name"/>
                        </h3>
                        <p>in <strong>{table.name}</strong> on board <strong>{boardState.board.name}</strong></p>
                    </div>
                    <div className="row-modal-body">
                        <div className="main-section">

                            <div className={"subtask-container"}>
                                <h4>Subtasks</h4>
                                <RowModalSubtasks boardId={boardState.board.id} table={table} row={row} />
                            </div>

                            <div className="text-cells">
                                { table?.columns?.map((column, index) =>
                                    <React.Fragment key={index}>
                                        {column.type.type === 'text' &&
                                        <div className={"text-cell"}>
                                            <p className={"label-text-cell"}>{column.name}</p>
                                            <TextCell boardId={boardState.board.id} table={table} row={row} column={column} />
                                        </div>
                                        }
                                    </React.Fragment>
                                )}
                            </div>

                            <div className="activity">
                                <h4>Activity</h4>
                                <div className="activity-body">
                                    <Messages row={row} table={table} />
                                </div>
                            </div>

                        </div>
                        <div className="right-options">

                            <div className="people-cells">
                                { table?.columns?.map((column, index) =>
                                    <React.Fragment key={index}>
                                        {column.type.type === 'people' &&
                                        <div className={"people-cell"}>
                                            <p className={"label-date-cell"}>{column.name}</p>
                                            <PeopleCell boardId={boardState.board.id} table={table} row={row} column={column} />
                                        </div>
                                        }
                                    </React.Fragment>
                                )}
                            </div>

                            <div className="status-cells">
                                { table?.columns?.map((column, index) =>
                                    <React.Fragment key={index}>
                                        {column.type.type === 'status' &&
                                        <div className={"state-cell"}>
                                            <p className={"label-date-cell"}>{column.name}</p>
                                            <StatusCell boardId={boardState.board.id} table={table} row={row} column={column} />
                                        </div>
                                        }
                                    </React.Fragment>
                                )}
                            </div>

                            <div className="date-cells">
                                { table?.columns?.map((column, index) =>
                                    <React.Fragment key={index}>
                                        {column.type.type === 'datetime' &&
                                        <div className={"date-cell"}>
                                            <p className={"label-date-cell"}>{column.name}</p>
                                            <DateTimeCell boardId={boardState.board.id} table={table} row={row} column={column} />
                                        </div>
                                        }
                                    </React.Fragment>
                                )}
                            </div>

                            <div className="date-cells">
                                { table?.columns?.map((column, index) =>
                                    <React.Fragment key={index}>
                                        {column.type.type === 'number' &&
                                        <div className={"date-cell"}>
                                            <p className={"label-date-cell"}>{column.name}</p>
                                            <NumberCell boardId={boardState.board.id} table={table} row={row} column={column} />
                                        </div>
                                        }
                                    </React.Fragment>
                                )}
                            </div>

                        </div>
                    </div>

                </React.Fragment>
                }
            </Modal>

        </React.Fragment>
    )
}

export default RowModal
