export const CLEANUP       = 'CLEANUP'
export const GET_BOARDS    = 'GET_BOARDS'
export const CREATE_BOARD  = 'CREATE_BOARD'
export const DELETE_BOARD  = 'DELETE_BOARD'
export const EDIT_BOARD    = 'EDIT_BOARD'
export const UPDATE_BOARD  = 'UPDATE_BOARD'
export const RELOAD_BOARD  = 'RELOAD_BOARD'
export const NEED_RELOAD   = 'NEED_RELOAD'
export const UPDATE_ASSETS  = 'UPDATE_ASSETS'
export const STAR_BOARD     = 'STAR_BOARD'
export const UNSTAR_BOARD   = 'UNSTAR_BOARD'
export const SET_SOCKET     = 'SET_SOCKET'
export const SET_WIDTH      = 'SET_WIDTH'
export const ON_DRAG_START  = 'ON_DRAG_START'
export const ON_DRAG_END    = 'ON_DRAG_END'
export const OPEN_CREATE_BOARD_MODAL      = 'OPEN_CREATE_BOARD_MODAL'
export const OPEN_MESSAGES_DRAWER   = 'OPEN_MESSAGES_DRAWER'
export const CLOSE_MESSAGES_DRAWER  = 'CLOSE_MESSAGES_DRAWER'
export const OPEN_LOGS_DRAWER       = 'OPEN_LOGS_DRAWER'
export const CLOSE_LOGS_DRAWER      = 'CLOSE_LOGS_DRAWER'
export const SELECT_ROW             = 'SELECT_ROW'
export const DESELECT_ROW           = 'DESELECT_ROW'
export const DESELECT_ALL_ROWS      = 'DESELECT_ALL_ROWS'
export const DELETED_SOME_ROWS      = 'DELETED_SOME_ROWS'
export const DUPLICATED_SOME_ROWS   = 'DUPLICATED_SOME_ROWS'
export const UPDATE_ROWS            = 'UPDATE_ROWS'
export const ADD_COLUMN_AND_ROWS    = 'ADD_COLUMN_AND_ROWS'
export const DELETE_ENTRY           = 'DELETE_ENTRY'

export const UPDATE_BOARD_NAME      = 'UPDATE_BOARD_NAME'
export const UPDATE_BOARD_DESC      = 'UPDATE_BOARD_DESC'

export const CREATE_TABLE           = 'CREATE_TABLE'
export const TABLE_CHANGE_NAME      = 'TABLE_CHANGE_NAME'
export const DELETE_TABLE           = 'DELETE_TABLE'
export const CHANGE_ACTION_WIDTH    = 'CHANGE_ACTION_WIDTH'
export const SET_TABLES             = 'SET_TABLES'
export const TABLE_COMPLETE         = 'TABLE_COMPLETE'

export const SORT_COLUMN            = 'SORT_COLUMN'
export const DELETE_COLUMN          = 'DELETE_COLUMN'
export const ADD_COLUMN             = 'ADD_COLUMN'
export const CHANGE_COLUMN_NAME     = 'CHANGE_COLUMN_NAME'
export const CHANGE_COLUMN_WIDTH    = 'CHANGE_COLUMN_WIDTH'
export const UPDATE_KANBAN_STATUS_ORDER = 'UPDATE_KANBAN_STATUS_ORDER'
export const UPDATE_KANBAN_ROW_ORDER = 'UPDATE_KANBAN_ROW_ORDER'
export const CHANGED_STATUS_NAME_METADATA = 'CHANGED_STATUS_NAME_METADATA'

export const UPDATE_ROW_POSITION    = 'UPDATE_ROW_POSITION'
export const ADD_ROW                = 'ADD_ROW'
export const ADD_SUB_ROW            = 'ADD_SUB_ROW'
export const ADD_ROWS               = 'ADD_ROWS'
export const DELETE_ROW             = 'DELETE_ROW'
export const CHANGE_ROW_NAME        = 'CHANGE_ROW_NAME'
export const SET_ROWS               = 'SET_ROWS'
export const SET_SUB_ROWS           = 'SET_SUB_ROWS'
export const SET_SINGLE_VALUE       = 'SET_SINGLE_VALUE'

export const SET_VIEW               = 'SET_VIEW'
export const UPDATE_VIEW_CONFIG     = 'UPDATE_VIEW_CONFIG'

export const RICH_TEXT_SET_BODY     = 'RICH_TEXT_SET_BODY'
export const RICH_TEXT_SET_CONTENT     = 'RICH_TEXT_SET_CONTENT'
