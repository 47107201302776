import {
    GET_ALL_USERS,
    ADD_USER
  } from './UsersTypes'

// We use this users as a cache to avoid having the same users repeated over and over.
export const usersInitialState = {
    users: [],
}

const UsersReducer = (state = usersInitialState, action) => {    
    switch (action.type) {
        case GET_ALL_USERS:

            const users = action.payload.filter(user => {
                if(user.ID === "5f951de010eb5f0751030fe6"){
                    return false;
                }
                return true
            });

            return {
                ...state,
                users: users,
            };
        case ADD_USER:
            return {
                ...state,
                users: state.users.concat(action.payload.user),
            };
        default:
            return state;
    }
};

export default UsersReducer;