import React, {useContext, useEffect, useState} from 'react'
import {Button, Dropdown, Menu} from "antd";
import clientAPI from "../../../service/ApiService";
import { toast } from 'react-toastify';
import {GENERIC_ERR_MSG} from "../../../constants/constants";
import {DELETE_TABLE, SET_WIDTH} from "../../../state/boards/BoardTypes";
import {API_TABLE_ID, API_URL} from "../../../constants";
import {BoardContext} from "../../../state/boardStore";
import {generatePath} from 'react-router';
import ReactQuill from "react-quill";

function CopyView({table, setEditMode}) {

    const [showActions, setShowActions] = useState(false)
    const [loading, setLoading] = useState(false)
    const [boardState, dispatchBoard] = useContext(BoardContext);

    const deleteTable = () => {
        setLoading(true);

        const path = API_URL + generatePath(API_TABLE_ID, {
                board: boardState.board.id,
                table: table.id
            });

        clientAPI.delete(path)
            .then(response => {
                setLoading(false);
                dispatchBoard({type: DELETE_TABLE, payload: {tableId: table.id}});
            })
            .catch(error => {
                setLoading(false);
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    const menu = (
        <Menu className={"table-menu"} inlineIndent={0} >
            <Menu.Item onClick={() => setEditMode(true) }>Edit</Menu.Item>
            <Menu.Item onClick={() => {
                if (window.confirm('Are you sure you want to delete this text block?')) deleteTable()
            }}>Delete</Menu.Item>
        </Menu>
    );

    const getTableContent = (table) => {
        if(table.quillContent && table.quillContent.length > 0){
            try {
                return JSON.parse(table.quillContent, true);
            } catch(e) {
                toast.error("Error reading the rich text");
            }

        }else if(table.body && table.body.length > 0){
            return table.body;
        }else{
            return "";
        }
    }

    return (
        <div className={"copy-view"}
             onMouseEnter={() => setShowActions(true)}
             onMouseLeave={() => setShowActions(false)}
        >
            {table.body?.length > 0 || table.quillContent?.length > 0 ?
                // <div dangerouslySetInnerHTML={{ __html: table.body }} />
                <ReactQuill
                    theme={"bubble"}
                    readOnly={true}
                    value={getTableContent(table)}
                />
            :
                <h4 className={"empty"} onClick={()=>setEditMode(true)}>Empty text</h4>
            }
            { showActions &&
                <div className="actions desktop">
                    <Button
                        className={""}
                        onClick={()=>setEditMode(true)}
                    >Edit</Button>
                    <Button
                        danger
                        loading={loading}
                        className={""}
                        onClick={() => {
                            if (window.confirm('Are you sure you want to delete this text block?')) deleteTable()
                        }}
                    >Delete</Button>
                </div>
            }
            <div className="actions mobile">
                <Dropdown className={"dropdown-mobile"} overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()} href="/#">
                        <span className="threedots"/>
                    </a>
                </Dropdown>
            </div>
        </div>
    )
}

export default CopyView