import React, {useEffect, useState} from 'react'
import {Drawer} from 'antd';
import AssetIcon from "./AssetIcon";
import EdiText from "react-editext";
import clientAPI from "../../service/ApiService";
import {API_ASSETS, API_TABLE_ID, API_URL} from "../../constants";
import {toast} from "react-toastify";
import {humanFileSize} from "./Utils";
import {formatDate} from "../../service/DateService";
import axios from 'axios'
import {generatePath} from 'react-router';
import {GENERIC_ERR_MSG} from "../../constants/constants";
import {Link} from "react-router-dom";

function AssetDrawer({ visible, close, asset, changeAssetName }) {

    const initialState = {
        boardId: null,
        boardName: null,
        tableId: null,
        tableName: null,
    }
    const [state, setState] = useState(initialState)

    useEffect(() => {
        if(asset?.boardId && asset?.tableId){
            getTableAndBoard();
        }else{
            setState(initialState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asset]);

    const getTableAndBoard = val => {
        const path = API_URL + generatePath(API_TABLE_ID, {
            board: asset.boardId,
            table: asset.tableId,
        });
        clientAPI.get(path)
            .then(response => {
                const data = response.data.payload;
                setState({
                    boardId: data.boardId,
                    boardName: data.board,
                    tableId: data.tableId,
                    tableName: data.table,
                })
            })
            .catch(error => {
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    const setMetadata = (value) => {
        const payload = {"name": value, "description": asset?.description}
        clientAPI.post(API_ASSETS + "/metadata/" + asset.id, payload)
            .then(response => {
                changeAssetName(asset, value);
            })
            .catch(error => {
                console.log(error);
                if(error && error.response && error.response.data.statusCode.msg){
                    console.log(error);
                }else{
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    }

    const download = () => {
        clientAPI.get(API_ASSETS + "/presigned/" + asset.id)
            .then(response => {
                axios.get(response.data.payload, {
                    responseType: "blob"
                })
                    .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', asset.originalFilename);
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode.removeChild(link);
                    })
                    .catch(error => {
                        if(error && error.response && error.response.data.statusCode.msg){
                        }else{
                            toast.error('Error connecting with the server, check your connection or contact support');
                        }
                    })
            })
            .catch(error => {
                if(error && error.response && error.response.data.statusCode.msg){
                }else{
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    }

    return (
        <div className="asset-panel">
            <Drawer
                title=""
                placement="right"
                className="asset-drawer"
                closable={true}
                onClose={ () => close() }
                visible={visible}
                width="500px"
            >
            { asset &&
                <div>
                    <div className="asset-preview">
                        <AssetIcon asset={asset} />
                    </div>
                    <EdiText
                        submitOnEnter
                        cancelOnEscape
                        submitOnUnfocus
                        hideIcons={true}
                        editOnViewClick={true}
                        showButtonsOnHover={false}
                        editButtonClassName="hide"
                        saveButtonClassName="hide"
                        cancelButtonClassName="hide"
                        viewContainerClassName="asset-name"
                        inputProps={{
                            style: {
                                backgroundColor: '#FAFAFA',
                                width: "100%"
                            },
                        }}
                        type="text" value={asset.name && asset.name.length > 0 ? asset.name : asset.originalFilename }
                        onSave={setMetadata}
                        className="edit-entry"/>
                    <div className="metadata">
                        <div className="entry">
                            <p>Original filename</p>
                            <p className={"value"}>{asset.originalFilename}</p>
                        </div>
                        <div className="entry">
                            <p>Created at</p>
                            <p className={"value"}>{formatDate(asset.createdAt)}</p>
                        </div>
                        {asset.fileSizeInBytes &&
                            <div className="entry">
                                <p>Size</p>
                                <p className={"value"}>{humanFileSize(asset.fileSizeInBytes)}</p>
                            </div>
                        }
                        {asset.mimetype &&
                            <div className="entry">
                                <p>Mimetype</p>
                                <p className={"value"}>{asset.mimetype}</p>
                            </div>
                        }
                        {asset.width &&
                            <div className="entry">
                                <p>Width</p>
                                <p className={"value"}>{asset.width}</p>
                            </div>
                        }
                        {asset.height &&
                            <div className="entry">
                                <p>Height</p>
                                <p className={"value"}>{asset.height}</p>
                            </div>
                        }
                        {asset.codecName &&
                            <div className="entry">
                                <p>Codec</p>
                                <p className={"value"}>{asset.codecName}</p>
                            </div>
                        }
                        {asset.bitRate &&
                        <div className="entry">
                            <p>Bit rate</p>
                            <p className={"value"}>{asset.bitRate}</p>
                        </div>
                        }
                        {asset.aspectRatio &&
                        <div className="entry">
                            <p>Aspect ratio</p>
                            <p className={"value"}>{asset.aspectRatio}</p>
                        </div>
                        }
                        {asset.durationInSeconds &&
                        <div className="entry">
                            <p>Duration</p>
                            <p className={"value"}>{asset.durationInSeconds.toFixed(1)} seconds</p>
                        </div>
                        }
                        {state.boardName &&
                            <div className="entry">
                                <p>Board</p>
                                <p className={"value"}>
                                    <Link to={"/boards/" + state.boardId}>{state.boardName}</Link>
                                </p>
                            </div>
                        }
                        { state.tableName &&
                            <div className="entry">
                                <p>Table</p>
                                <p className={"value"}>{state.tableName}</p>
                            </div>
                        }
                        {asset.uri &&
                            <button className={"ant-btn"} onClick={() => download()}>Download</button>
                        }
                    </div>
                </div>
            }
            </Drawer>
        </div >
    )
}

export default AssetDrawer
