import React, {useContext, useState} from 'react'
import BoardsTable from './BoardsTable'
import {Button} from "antd";
import {OPEN_CREATE_BOARD_MODAL} from "../../state/boards/BoardTypes";
import {BoardsContext} from "../../state/boardsStore";

function CreateBoardPage() {

    const [needReload, setNeedReload] = useState(false)
    const dispatchBoards = useContext(BoardsContext)[0];

    const reloadAck = () => {
        setNeedReload(false)
    }

    return (
        <div className="main-container boards-list-page">
            <h3>Boards</h3>
            <div className="actions">
                <Button type="primary"
                        onClick={() => dispatchBoards({type: OPEN_CREATE_BOARD_MODAL,
                            payload: {board: null}})}
                >
                    Create board
                </Button>
            </div>
            <BoardsTable needReload={needReload} reloadAck={() => reloadAck()} />
        </div>
    )
}

export default CreateBoardPage
