import React, {useContext, useEffect, useState} from 'react'
import clientAPI from "../../service/ApiService"
import {API_DASHBOARD} from '../../constants';
import {toast} from 'react-toastify';
import BoardsTable from '../BoardsPage/BoardsTable'
import {humanFileSize} from "../AssetsPage/Utils";
import {Button, Tooltip} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import {OPEN_CREATE_BOARD_MODAL} from "../../state/boards/BoardTypes";
import {BoardsContext} from "../../state/boardsStore";

function DashboardPage() {

    useEffect(() => {
        getDashboard();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialState = {
        users: null,
        boards: null,
        pending: null,
        diskUsage: null,
        diskAvailable: null,
        error: null,
        loading: false,
    }


    const [state, setState] = useState(initialState)
    const dispatchBoards = useContext(BoardsContext)[1];

    const getDashboard = () => {

        setState({
            ...state,
            error: null,
            loading: true,
        })

        clientAPI.get(API_DASHBOARD)
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false,
                    users: response.data.payload.users,
                    boards: response.data.payload.boards,
                    pending: response.data.payload.pending,
                    diskUsage: response.data.payload.diskUsage,
                    diskAvailable: response.data.payload.diskAvailable,
                })
            })
            .catch(error => {
                console.log(error)
                let msg = error?.response?.data?.statusCode?.msg
                setState({
                    ...state,
                    error: msg ?? null,
                    loading: false,
                    users: null,
                    boards: null,
                    pending: null,
                })
                if(!msg)
                    toast.error('Error connecting with the server, check your connection or contact support');
            })
    }

    const [needReload, setNeedReload] = useState(false);
    const reloadAck = () => {
        setNeedReload(false)
    }

    return (
        <div className={"page-inner"}>

            <div className="main-container dashboard-page">
                <div className="metrics">
                    <div className="pending">
                        <p className="value">{state.boards ?? 0}</p>
                        <p className="metric">Boards</p>
                    </div>
                    <div className="users">
                        <p className="value">{state.users ?? 0 }</p>
                        <p className="metric">Users</p>
                    </div>
                    <div className="pending">
                        <p className="value"> { state.pending ?? 0 }</p>
                        <p className="metric">Pending Invites</p>
                    </div>
                    <div className="disk">
                        <div className="value">
                            {state?.diskAvailable &&
                                <div className={"disk-wrapper"}>
                                    <div className="percentage">
                                        {(100 * state?.diskUsage/state?.diskAvailable).toFixed(1)}%
                                        <div className={"values"}>
                                            <span className={"top"}>{humanFileSize(state?.diskUsage, true)}</span>
                                            <span className={"bottom"}>/{humanFileSize(state?.diskAvailable, true)}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <p className="metric">Disk Usage
                            <Tooltip placement="bottom" title="Disk Usage is the sum of the assets as templates, in boards and in the files section. Only files are counted, if you need extra space, contact support at info@kaiseki.io.">
                                <InfoCircleOutlined/>
                            </Tooltip>
                        </p>
                    </div>
                </div>
                <div className="actions">
                    <Button type="primary"
                            onClick={() => dispatchBoards({type: OPEN_CREATE_BOARD_MODAL,
                                payload: {board: null}})}
                    >
                        Create board
                    </Button>
                </div>
                <BoardsTable needReload={needReload} reloadAck={() => reloadAck()} />
            </div>
        </div>
    )
}

export default DashboardPage
