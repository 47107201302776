import React, {useState, useEffect} from 'react'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

function SelectColumnsField({columns, selectColumns}) {

    const initialState = {
        values: columns && columns.length > 0 ? columns.map(function (col) {
            return {value: col.id, label: (col.name + " - " +  col.type.type.charAt(0).toUpperCase() + col.type.type.slice(1))}
        }) : [],
    }

    useEffect(() => {
        setState({
            ...state,
            values: columns && columns.length > 0 ? columns.map(function (col) {
                return {value: col.id, label: (col.name + " - " +  col.type.type.charAt(0).toUpperCase() + col.type.type.slice(1))}
            }) : [],
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    const [state, setState] = useState(initialState)
    const animatedComponents = makeAnimated();

    return (
        <div>
            <Select
                options={state.values}
                name="users"
                isMulti
                components={animatedComponents}
                closeMenuOnSelect={false}
                defaultOptions={true}
                className="columns-multi-select"
                classNamePrefix="columns-select"
                // defaultValue={state.values}
                placeholder="Select columns"
                rules={{required: false}}
                onChange={(values)=>{
                    selectColumns(values.map(o=>o.value))
                }}
            />
        </div>
    )
}

export default SelectColumnsField
