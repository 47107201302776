import React from 'react'
import AssetView from "./AssetView";

function TableComponent() {

    return (
        <div className={`asset-table-type`}>
            <div className={"asset-component"}>
                <AssetView/>
            </div>
        </div>
    )
}

export default TableComponent