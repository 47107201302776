import React, {useContext, useMemo, useRef, useState} from 'react'
import {BoardContext} from '../../../state/boardStore'
import 'react-quill/dist/quill.snow.css';
import {Button} from "antd";
import {API_ASSETS, API_TABLE_ID, API_URL} from "../../../constants";
import clientAPI from "../../../service/ApiService";
import {GENERIC_ERR_MSG} from "../../../constants/constants";
import {generatePath} from 'react-router';
import {toast} from "react-toastify";
import {RICH_TEXT_SET_CONTENT} from "../../../state/boards/BoardTypes";
import ReactQuill from "react-quill";

function QuillCopyEditor({table, setEditMode}) {

    const getTableContent = () => {
        if(table.quillContent && table.quillContent.length > 0){
            return JSON.parse(table.quillContent, true);
        }else if(table.body && table.body.length > 0){
            return table.body;
        }else{
            return "";
        }
    }

    const initialState = {
        editMode: false,
    }
    const [state, setState] = useState(initialState)
    const [quillContent, setQuillContent] = useState(getTableContent())
    const [loading, setLoading] = useState(false)
    const [boardState, dispatchBoard] = useContext(BoardContext);

    const SaveCopy = () => {
        setLoading(true);

        let path= API_URL + generatePath(API_TABLE_ID, {
            board: boardState.board.id,
            table: table.id,
        })

        clientAPI.post(path+"/copy", { quillContent : JSON.stringify(quillContent)})
            .then(response => {
                setLoading(false);
                setEditMode(false);
                dispatchBoard({type: RICH_TEXT_SET_CONTENT, payload: {tableId: table.id, quillContent: JSON.stringify(quillContent) } });
            })
            .catch(error => {
                setState({
                    ...state,
                    loading: false,
                    error: error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG
                })
                setLoading(false)
            })
    }

    // function MyCustomUploadAdapterPlugin(editor) {
    //     editor.plugins.get( 'FileRepository' ).createUploadAdapter = (loader) => {
    //         return new MyUploadAdapter(loader, boardState.board.id, table.id)
    //     }
    // }
    const quillRef = useRef();
    function handleChange(content, delta, source, editor) {
        setQuillContent(editor.getContents());
    }

    const imageHandler = (e) => {
        const editor = quillRef.current.getEditor();
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            if (/^image\//.test(file.type)) {
                console.log(file);
                // const formData = new FormData();
                // formData.append("image", file);
                // const res = await ImageUpload(formData); // upload data into server or aws or cloudinary
                // const url = res?.data?.url;

                const formData = new FormData();
                formData.append("asset", file);
                formData.append("boardId", boardState.board.id);
                formData.append("tableId", table.id);

                let response = await clientAPI.post(API_ASSETS, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                console.log(response)

                editor.insertEmbed(editor.getSelection(), "image", response.data.payload.thumb);
            } else {
                toast.error('Error connecting with the server, check your connection or contact support');
            }
        };
    }
    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', "strike"],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                    { 'indent': '-1' }, { 'indent': '+1' }],
                ['image', "link",],
                [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }]
            ],
            handlers: {
                image: imageHandler
            }
        },
    }), [])

    return (
        <div className={"copy-editor"}>
            <ReactQuill
                ref={quillRef}
                theme="snow"
                value={quillContent}
                modules={modules}
                onChange={handleChange}
            />
            <div className="actions">
                <Button
                    className={"cancel-cta"}
                    onClick={()=>setEditMode(false)}
                >Cancel</Button>
                <Button
                    className={"save-cta"}
                    type="primary"
                    loading={loading}
                    onClick={()=>SaveCopy()}
                >Save</Button>
            </div>
        </div>
    )
}




// class MyUploadAdapter {
//     constructor(loader, boardId, tableId) {
//         this.loader   = loader;
//         this.boardId  = boardId;
//         this.tableId  = tableId;
//     }
//
//     upload() {
//
//         return this.loader.file
//             .then(result => {
//
//                 const formData = new FormData();
//                 formData.append("asset", result);
//                 formData.append("boardId", this.boardId);
//                 formData.append("tableId", this.tableId);
//
//                 return clientAPI.post(API_ASSETS, formData, {
//                     headers: {
//                         'Content-Type': 'multipart/form-data'
//                     }
//                 })
//                     .then(response => {
//                         return ({
//                             default: response.data.payload.thumb
//                         });
//                     })
//                     .catch(error => {
//                         toast.error('Error uploading the image, please contact support');
//                     })
//
//             })
//
//     }
//
// }

export default QuillCopyEditor