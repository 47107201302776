import React, {useContext, useState} from 'react'
import {toast} from 'react-toastify';
import {IoIosClose} from "react-icons/io";
import {SHARE_LINK} from "../../../constants";
import clientAPI from "../../../service/ApiService";
import {SpinnerLoading} from "../../Style/styles.css";
import {GENERIC_ERR_MSG} from "../../../constants/constants";
import {BoardContext} from "../../../state/boardStore";
import {Tooltip} from "antd";

function DeleteButton({link, refresh}) {

    const initialState = {
        loading: false,
    }
    const [state, setState] = useState(initialState)
    const [boardState] = useContext(BoardContext);

    const deleteLink = () =>{
        setState({
            ...state,
            loading: true,
        })
        clientAPI.delete(SHARE_LINK + "/" + boardState.board.id + "/" + link.id)
            .then(response => {
                setState({
                    ...state,
                    loading: false,
                })
                refresh()
            })
            .catch(error => {
                toast.error(GENERIC_ERR_MSG);
            })
    }

    return (
        <React.Fragment>
            {state.loading && <SpinnerLoading className={"spinner"} animation="border" role="status" />}
            {!state.loading &&
            <Tooltip mouseEnterDelay={0.5} placement="bottom" title="Delete link">
                <IoIosClose className={"delete-link"} onClick={() => {
                    if (window.confirm('Are you sure you wish to delete this link?'))
                        deleteLink();
                }} />
            </Tooltip>
            }
        </React.Fragment>
    )
}

export default DeleteButton
