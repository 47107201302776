import React, {useCallback, useEffect, useState} from 'react';
import {Alert} from 'antd';
import {useDropzone} from "react-dropzone";
import clientAPI from "../../../service/ApiService";
import {API_ASSETS} from "../../../constants";
import {toast} from "react-toastify";
import AssetRow from "./AssetRow";
import AssetDrawer from "../../AssetsPage/AssetDrawer";
import axios from 'axios';

function AssetTable({open, table, boardId, showUpload}){

    const initialState = {
        visible: false,
        error: null,
        drawerVisible: false,
        selectedAsset: null,
    }
    const [state, setState] = useState(initialState);
    const [filesAdded, setFilesAdded] = useState(false);
    const [files, setFiles] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const cancelTokenSource = axios.CancelToken.source();

    useEffect(() => {
        if(open === 0) return;
        setState({
            ...state,
            visible: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        getAssetsByTable()

        return function cleanup() {
            cancelTokenSource.cancel("Cancelling in cleanup");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(table?.refresh == null  || table?.refresh === 0){
            return;
        }
        getAssetsByTable();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table?.refresh]);

    const refresh = () =>{
        getAssetsByTable();
    }

    const getAssetsByTable = (search=null) => {
        setState({
            ...state,
            error: null,
            loading: true,
        })

        clientAPI.get(API_ASSETS + "?page=1&limit=9999&boardId=" + boardId + "&tableId=" + table.id,
            {cancelToken:cancelTokenSource.token})
            .then(response => {
                let assets = response.data.payload.assets;
                if(!assets || assets.length <= 0 ){
                    setFiles([]);
                    return false
                }

                let assetFiles = [];
                assets.map(a => { // Asset files is an asset encapsulated in the file object that has a status
                    const obj = {};
                    obj.status = "done";
                    obj.asset = a
                    assetFiles.push(obj);
                    return a
                })

                setState({
                    ...state,
                    error: null,
                    loading: false
                })

                setFiles(assetFiles);
            })
            .catch(error => {
                if(error.hasOwnProperty("message") && error.message === "Cancelling in cleanup"){
                    return
                }
                let msg = error?.response?.data?.statusCode?.msg
                setState({
                    ...state,
                    error: msg ?? null,
                    loading: false,
                })
                setFiles([]);
                if(!msg)
                    toast.error('Error connecting with the server, check your connection or contact support');

            })
    }

    const onDrop = useCallback(acceptedFiles => {
        setState({
            ...state,
            visible: true
        })

        setFiles(acceptedFiles.concat(files));
        setFilesToUpload(filesToUpload.concat(acceptedFiles));
        setFilesAdded(true)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setState, state, files])

    useEffect(() => {
        if(filesAdded === false) return;

        let promises = [];
        filesToUpload.forEach((file) => {
            promises.push(uploadFile(file));
        });

        setFilesToUpload([]);
        setFilesAdded(false);

        Promise.all(promises)
            .then((results) => {
                setFilesToUpload([]);
                setFilesAdded(false);
                refresh();
            })
            .catch((e) => {
                toast.error('Error uploading assets');
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filesAdded]);

    const uploadFile = (file) => {

        const formData = new FormData();
        formData.append("asset", file);
        formData.append("boardId", boardId);
        formData.append("tableId", table.id);

        return clientAPI.post(API_ASSETS, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {

                // setState({...state,
                //     filesAdded: false,
                // })
                setFilesAdded(false);
                // setFilesToUpload([]);

                // setFiles(files.map(a => {
                //     if(file.name === a.name){
                //         a.status = "done";
                //         a.asset = response.data.payload
                //     }
                //     return a;
                // }))

            })
            .catch(error => {
                if (error && error.response && error.response.data.statusCode.msg) {
                    if(error.response.data.statusCode.code === -9001){
                        setState({...state,
                            error: "Disk exceed quota, please remove some files or contact support at info@kaiseki.io"})
                        setFilesAdded(false)
                        setFiles(files.map(a => {
                            if(file.name === a.name){
                                a.status = "error";
                            }
                            return a;
                        }));
                    }else{
                        setState({...state, error: error.response.data.statusCode.msg})
                    }
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const showAssetDetail = (asset) => {
        setState({...state, drawerVisible: true, selectedAsset: asset })
    }

    const closeDrawer = () => {
        setState({...state, drawerVisible: false, selectedAsset: null})
    };

    const changeAssetName = (asset, value) => {

        setFiles(files.map(a => {
                if(asset.id === a.asset.id)
                    a.asset.name = value;
                return a;
            }));
    }


    return (
        <div className={"upload-table-wrapper"}>

            { (showUpload || files.length === 0) &&
                <div {...getRootProps()} className={"upload-dropzone"}>
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>Drop the files here ...</p> :
                            <p>Drag 'n' drop some files here, or click to select files<br/>
                                {/*<small>Click on the file name to edit it</small>*/}
                            </p>
                    }
                </div>
            }

            {state.error && <Alert message={state.error} type="error" closable className="notification"/>}
            { files && files.length > 0 &&
            <React.Fragment>
                <table className="table asset-table">
                    <tbody>
                    {files.map((file, index) =>
                        <AssetRow
                            key={index}
                            assetFile={file}
                            refresh={refresh}
                            showAssetDetail={showAssetDetail}
                        />
                    )}
                    </tbody>
                </table>
            </React.Fragment>
            }

            <AssetDrawer
                visible={state.drawerVisible}
                close={closeDrawer}
                asset={state.selectedAsset}
                changeAssetName={changeAssetName}
            />

        </div>
    );
};

export default AssetTable;
