import React from 'react';
import styled from '@emotion/styled';
import {colors} from '@atlaskit/theme';
import {borderRadius, grid} from './constants';
import {Draggable} from 'react-beautiful-dnd';
import QuoteList from './quote-list';
import EdiText from "react-editext";
import clientAPI from "../../../service/ApiService";
import {API_COLUMN_ID, API_URL} from "../../../constants";
import {generatePath} from 'react-router';
import {toast} from "react-toastify";
import AddCard from "./AddCard";
import 'simplebar/dist/simplebar.min.css';

const Container = styled.div`
  margin: ${grid}px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: left;
  justify-content: left;
  border-top-left-radius: ${borderRadius}px;
  border-top-right-radius: ${borderRadius}px;
  background-color: ${({isDragging}) =>
    isDragging ? colors.G50 : colors.N30};
  transition: background-color 0.2s ease;
    padding: 7px 11px;
    padding-bottom: 6px;
    width: 250px;

  &:hover {
    background-color: ${colors.G50};
  }
  
  .edit-status-button{
      font-size: 16px;
      margin-bottom: 0px;
      font-weight: 600;
      font-family: "Nunito Sans", sans-serif;
      min-width: 25px;
      min-height: 25px;
      
      div{
        width: 100%;
        height: 100%;
        display: block;
      }
  }
`;

function Column({title, columnId, table, kanbanColumn, boardId, quotes, index, isScrollable, isCombineEnabled, useClone, openRowModal}) {

    const editStatusName = (val) => {

        const path = API_URL + generatePath(API_COLUMN_ID, {
            board: boardId,
            table: table.id,
            column: kanbanColumn
        })

        const metadata = table.columns.find(column => {
            return column.id === kanbanColumn
        }).type.metadata.map(status => {
            if (status.id === columnId) {
                status.name = val;
            }
            return status;
        })

        var payload = {
            "type": "status",
            "metadata": metadata
        }

        clientAPI.put(path, payload)
            .then(response => {

                // dispatchBoard({type: UPDATE_BOARD, payload:response.data.payload});
            })
            .catch(error => {
                toast.error('Error Changing the column name');
            })
    }


    return (
        <Draggable draggableId={columnId} index={index}>
            {(provided, snapshot) => (
                <Container ref={provided.innerRef} {...provided.draggableProps}>
                    <Header {...provided.dragHandleProps} isDragging={snapshot.isDragging}>
                        <EdiText
                            submitOnEnter
                            cancelOnEscape
                            submitOnUnfocus
                            hideIcons={true}
                            editOnViewClick={columnId !== 'unassigned'}
                            showButtonsOnHover={false}
                            editButtonClassName="hide"
                            saveButtonClassName="hide"
                            cancelButtonClassName="hide"
                            value={title}
                            onSave={editStatusName}
                            className="edit-status-button"/>
                    </Header>
                    <QuoteList
                        key={columnId}
                        columnId={columnId}
                        table={table}
                        listId={title}
                        listType="QUOTE"
                        style={{
                            backgroundColor: snapshot.isDragging ? colors.G50 : null,
                        }}
                        quotes={quotes}
                        internalScroll={isScrollable}
                        isCombineEnabled={isCombineEnabled}
                        useClone={useClone}
                        openRowModal={openRowModal}
                    />
                    <AddCard quotesLength={quotes.length} table={table} boardId={boardId} columnId={columnId}
                             kanbanColumn={kanbanColumn}/>
                </Container>
            )}
        </Draggable>
    );
}

export default Column