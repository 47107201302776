import React, {useContext, useEffect, useState} from 'react'
import clientAPI from "../../service/ApiService"
import {API_ROW_ID, API_URL} from '../../constants';
import {generatePath} from 'react-router';
import {CHANGE_ROW_NAME, DESELECT_ROW, OPEN_MESSAGES_DRAWER, SELECT_ROW} from '../../state/boards/BoardTypes';
import {BoardContext} from '../../state/boardStore'
import {Badge, Checkbox} from 'antd';
import EdiText from 'react-editext'
import TextCell from "./Cells/TextCell";
import StatusCell from './Cells/StatusCell';
import {toast} from 'react-toastify';
import DateTimeCell from './Cells/DateTimeCell';
import NumberCell from './Cells/NumberCell';
import PeopleCell from './Cells/PeopleCell/PeopleCell';
import {MessageOutlined} from '@ant-design/icons';
import {Draggable} from "react-beautiful-dnd";
import SubTasks  from "./SubTasks"
import {DEFAULT_ACTION_WIDTH, DEFAULT_CELL_WIDTH, GENERIC_ERR_MSG} from "../../constants/constants";
import { FiList } from "react-icons/fi";

function Row(props) {

    const initialState = {
        loading: false,
        error: null,
        boardId: props.boardId,
        path: API_URL + generatePath(API_ROW_ID, {
            board: props.boardId,
            table: props.table.id,
            row: props.row.id
        }),
        hover: false,
        selected: false,
        showSubTasks: false,
    }

    const [boardState, dispatchBoard] = useContext(BoardContext);
    const [state, setState] = useState(initialState)

    const editRowName = val => {
        clientAPI.put(state.path, {"name": val})
            .then(response => {
                setState({
                    ...state,
                    loading: false,
                })

                dispatchBoard({
                    type: CHANGE_ROW_NAME,
                    payload: {tableId: props.table.id, rowId: props.row.id, parentRowId:props.row.parent, name: val}
                });
            })
            .catch(error => {
                setState({
                    ...state,
                    loading: false,
                })
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    //This was commented for some reason
    useEffect(() => {
        if(boardState.selectedTable !== props.table.id && state.selected){
            setState({...state, selected: false})
        }
    }, [boardState.selectedTable, props.table.id, state]);

    // const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    //     <a href="" ref={ref}onClick={e => {
    //         e.preventDefault();
    //         onClick(e);
    //       }}
    //     >
    //       {children}
    //       <button className="edit-column-action"><IoIosArrowDropdown/></button>
    //     </a>
    //   ));
    //
    // const DragHandle = sortableHandle(() => <GiHamburgerMenu className="row-drag-handle" />);

    // Messages drawer
    const openDrawer = () => {
        dispatchBoard({type: OPEN_MESSAGES_DRAWER, payload: {row: props.row, table: props.table}});
    }

    const selectRow = (e) => {
        if (e.target.checked) {
            setState({...state, selected: true})
            dispatchBoard({type: SELECT_ROW, payload: {table: props.table.id, row: props.row.id}});
        } else {
            setState({...state, selected: false})
            dispatchBoard({type: DESELECT_ROW, payload: {row: props.row.id}});
        }
    }

    return (
        <div>
            {props.row &&
            <Draggable draggableId={props.row.id} index={props.index}>
                {provided => (
                    <React.Fragment>
                        <div className={`${state.selected ? "row-entry selected" : "row-entry"} ${state.showSubTasks ? "show-subtask" : ""} `}
                             ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <div className="row-name-container"
                                 style={{width: ((props.table.actionWidth) ? props.table.actionWidth : DEFAULT_ACTION_WIDTH)}}
                                 onMouseEnter={() => setState({...state, hover: true})}
                                 onMouseLeave={() => setState({...state, hover: false})}
                            >
                                {/* { state.hover &&
                                <DragHandle/>
                            } */}
                                <EdiText
                                    submitOnEnter
                                    cancelOnEscape
                                    submitOnUnfocus
                                    hideIcons={true}
                                    editOnViewClick={true}
                                    showButtonsOnHover={false}
                                    editButtonClassName="hide"
                                    saveButtonClassName="hide"
                                    cancelButtonClassName="hide"
                                    viewContainerClassName="row-name"
                                    inputProps={{
                                        style: {
                                            backgroundColor: '#FAFAFA',
                                            // width: DEFAULT_CELL_WIDTH
                                        },
                                    }}
                                    type="text" value={props.row.name} onSave={editRowName} className="edit-row-name"/>

                                {!props.row?.parent &&
                                    <button onClick={() => {
                                        setState({...state, showSubTasks: !state.showSubTasks})
                                    }}
                                        className={`subtask-button ${state.showSubTasks ? "unfold" : ""}`}>
                                        <FiList/>
                                        { ((!props.row.rows && props.row.numSubrows > 0) ||
                                            (props.row?.rows?.length > 0)
                                        ) &&
                                            <span className="num-subtasks"/>
                                        }
                                    </button>
                                }

                                <MessageOutlined onClick={() => openDrawer()} className="message-button"/>
                                {props.row.numMessages > 0 &&
                                <Badge dot>
                                    <p></p>
                                </Badge>
                                }
                                {/*<Dropdown className="edit-row-dropdown">*/}
                                {/*    <Dropdown.Toggle as={CustomToggle} />*/}
                                {/*    <Dropdown.Menu size="sm" title="">*/}
                                {/*        <Dropdown.Item onClick={() => { if (window.confirm('Are you sure you wish to delete this entry?')) deleteRow() } }>Delete</Dropdown.Item>*/}
                                {/*    </Dropdown.Menu>*/}
                                {/*</Dropdown>*/}
                                <div className="edit-row-select">
                                    <Checkbox onChange={selectRow} checked={state.selected}/>
                                </div>
                            </div>

                            <div className="cells-container">
                                {props.table && props.table.columns.map((column, index) =>
                                    <div key={column.id} className={"cell-width"}
                                         style={{width: ((column.width) ? column.width : DEFAULT_CELL_WIDTH)}}>
                                        {column.type.type === "text" &&
                                        <TextCell boardId={boardState.board.id} table={props.table} row={props.row}
                                                  column={column}/>}
                                        {column.type.type === "status" &&
                                        <StatusCell boardId={boardState.board.id} table={props.table} row={props.row}
                                                    column={column}/>}
                                        {column.type.type === "datetime" &&
                                        <DateTimeCell boardId={boardState.board.id} table={props.table} row={props.row}
                                                      column={column}/>}
                                        {column.type.type === "number" &&
                                        <NumberCell boardId={boardState.board.id} table={props.table} row={props.row}
                                                    column={column}/>}
                                        {column.type.type === "people" &&
                                        <PeopleCell boardId={boardState.board.id} table={props.table} row={props.row}
                                                    column={column}/>}
                                    </div>
                                )}
                            </div>
                        </div>
                        {state.showSubTasks &&
                            <SubTasks boardId={boardState.board.id} table={props.table} row={props.row}/>
                        }
                    </React.Fragment>
                )}
            </Draggable>
            }
        </div>
    )
}

export default Row
