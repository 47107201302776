import React, {useEffect, useState} from 'react'
import clientAPI from "../../service/ApiService"
import {API_ASSETS} from '../../constants/index';
import {SpinnerLoading} from '../Style/styles.css'
import {toast} from 'react-toastify';
import {Alert, Button, Empty, Input} from 'antd';
import UsersPagination from '../Users/UsersPagination'
import AssetRow from "./AssetRow";
import AssetDrawer from "./AssetDrawer";
import AssetUploadModal from "./AssetUploadModal";
import {FileDrop} from 'react-file-drop';
import EmptyIconSVG from '../../assets/empty-icon.svg';

function AssetsTable({ needReload, reloadAck, reload }) {

    const initialState = {
        page: 1,
        assets: [],
        sizePerPage: 20,
        totalSize: 0,
        error: null,
        loading: false,
        assetsSelected: [],
        deleting: false,
        downloading: false,
        searchText: "",
        deleteDone: 0,
        drawerVisible: false,
        selectedAsset: null,
        openUploadModal: 0,
        firstLoad: false,
    }
    const [state, setState] = useState(initialState)

    useEffect(() => {
        getAssets()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.page, state.sizePerPage]);

    useEffect(() => {
        if (needReload)
            getAssets()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needReload]);


    const refresh = () => {
        getAssets()
    }

    useEffect(() => {
        if(state.deleteDone > 0){
            getAssets();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.deleteDone]);

    const getAssets = (search=null) => {
        setState({
            ...state,
            error: null,
            loading: true,
        })

        clientAPI.get(API_ASSETS + "?page=" + state.page + "&limit=" + state.sizePerPage + (search ? "&search=" + encodeURIComponent(search) : "" ))
            .then(response => {
                let assets = response.data.payload.assets;
                if(!assets || assets.length <= 0 ){
                    setState({...state,
                        assets:[],
                        totalSize: 0,
                        page: state.page > 1 ? state.page-1 : state.page,
                        firstLoad: true,
                    })
                    return false
                }

                setState({
                    ...state,
                    error: null,
                    loading: false,
                    assets: assets,
                    totalSize: response.data.payload.pagination.total,
                    firstLoad: true,
                })
            })
            .catch(error => {

                let msg = error?.response?.data?.statusCode?.msg
                setState({
                    ...state,
                    error: msg ?? null,
                    loading: false,
                    assets: [],
                    totalSize: null,
                    firstLoad: true,
                })
                if(!msg)
                    toast.error('Error connecting with the server, check your connection or contact support');

            })
    }

    const goToPage = ((page) => {
        setState({
            ...state,
            page: page
        })
    });

    const { Search } = Input;
    const [timer, setTimer] = useState(null)


    const setSearch = (event) => {
        var searchText = event.target.value;

        if (timer != null) {
            clearTimeout(timer);
        }
        setState({
            ...state,
            searchText: searchText
        })

        setTimer(
            setTimeout(() => {
                getAssets(searchText)
            }, 300)
        )
    }

    const selectAsset = (asset) => {
        setState({
            ...state,
            assetsSelected: state.assetsSelected.concat(asset.id),
        })
    }

    const deselectAsset = (asset) => {
        var aux = state.assetsSelected;
        var index = aux.indexOf(asset.id);
        if (index !== -1) {
            aux.splice(index, 1);
        }

        setState({
            ...state,
            assetsSelected: aux,
        })
    }

    const deleteSelectedAssets = () => {

        setState({
            ...state,
            deleting: true,
        })

        let promises = [];
        state.assetsSelected.map((assetId) => promises.push(deleteAsset(assetId)))
        Promise.all(promises)
            .then((results) => {
                setState({
                    ...state,
                    deleting: false,
                    assetsSelected: [],
                    deleteDone: state.deleteDone + 1,
                })
            })
            .catch((e) => {
                toast.error('Error loading rows');
            });
    }

    const deleteAsset = (assetId) => {

        return clientAPI.delete(API_ASSETS + "/" + assetId)
            .then(response => {

            })
            .catch(error => {
                if(error && error.response && error.response.data.statusCode.msg){
                }else{
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    }

    const showAssetDetail = (asset) => {
        setState({...state, drawerVisible: true, selectedAsset: asset })
    }

    const closeDrawer = () => {
        setState({...state, drawerVisible: false, selectedAsset: null})
    };

    const changeAssetName = (asset, value) => {
        setState({...state, assets: state.assets.map(a => {
            if(asset.id === a.id)
               a.name = value;
            return a;
        })})
    }

    const downloadSelectedAssets = () => {
        setState({...state, downloading: true})
        clientAPI.post(API_ASSETS + "/download",  state.assetsSelected, {responseType: "blob"})
            .then((response) => {
                setState({...state, downloading: false})

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `assets.zip`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);

            })
            .catch(error => {
                toast.error('Error downloading assets');
                setState({...state, downloading: false})
            })
    }

    return (
        <div className={"assets-table-container"}>
            {state.error && <Alert message={state.error} type="error" closable className="notification" />}
            <div className="assets-actions">
                <Button type="primary" onClick={() => setState({...state, openUploadModal: state.openUploadModal + 1})}>Upload</Button>

                <div className="right-actions">
                { state.assetsSelected.length > 0 &&
                <React.Fragment>
                    <Button onClick={() => setState({...state, assetsSelected: [], deleting: false})}>Deselect all</Button>
                    <Button type="primary" onClick={() => downloadSelectedAssets()} loading={state.downloading}>
                        { state.downloading ? "Downloading " : "Download " }
                            {state.assetsSelected.length} elements
                    </Button>
                    <Button type="primary" danger onClick={() => deleteSelectedAssets()} loading={state.deleting}>
                        Delete {state.assetsSelected.length} elements
                    </Button>
                </React.Fragment>
                }
                </div>
            </div>
            <Search placeholder="Search by name or description"  onChange={(e) => setSearch(e)}/>
            {state.loading && <SpinnerLoading animation="border" role="status" className={"spinner"} />}
            {state.assets &&
                <div className={"assets-table-wrapper"}>
                    <table className="table asset-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th scope="col">Name</th>
                                <th scope="col">Type</th>
                                <th scope="col">Size</th>
                                <th scope="col">Uploaded at</th>
                                <th scope="col">Uploaded by</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.assets.map((asset, index) =>
                                <AssetRow key={index}
                                          asset={asset}
                                          selected={state.assetsSelected.includes(asset.id)}
                                          selectAsset={selectAsset}
                                          deselectAsset={deselectAsset}
                                          showAssetDetail={showAssetDetail}
                                />
                            )}
                        </tbody>
                    </table>
                    <UsersPagination
                        sizePerPage={state.sizePerPage}
                        totalSize={state.totalSize}
                        page={state.page}
                        goToPage={goToPage}
                    />
                </div>
            }
            { state.firstLoad && state.assets.length <= 0 &&
                <Empty
                    image={EmptyIconSVG}
                    imageStyle={{
                        height: 80,
                    }}
                    description={
                        <span>
                            Start by uploading your first asset
                        </span>
                    }
                >
                    <Button type="primary" onClick={() => setState({...state, openUploadModal: state.openUploadModal + 1})}>Upload</Button>
                </Empty>
            }


            <AssetDrawer
                visible={state.drawerVisible}
                close={closeDrawer}
                asset={state.selectedAsset}
                changeAssetName={changeAssetName}
            />

            <AssetUploadModal
                open={state.openUploadModal}
                refresh={refresh}
            />

            <FileDrop
                onFrameDragEnter={(event) => setState({...state, openUploadModal: state.openUploadModal + 1})}
             />

        </div >
    )
}

export default AssetsTable
