import React, {useState, useEffect, useContext} from 'react'
import {Drawer} from 'antd';
import {SpinnerLoading} from '../Style/styles.css'
import {BoardContext} from '../../state/boardStore'
import {CLOSE_LOGS_DRAWER} from '../../state/boards/BoardTypes';
import {API_URL, API_GET_LOGS} from '../../constants';
import {LogUpdateBoard,
    LogUpdBoardDescription,
    LogDeleteBoard,
    LogCreateTable,
    LogUpdateTable,
    LogDeleteTable,
    LogMovedTables,
    LogDuplicatedTable,
    LogCreateColumn,
    LogUpdateColumn,
    LogDeleteColumn,
    LogMovedColumns,
    LogCreateRow,
    LogUpdateRow,
    LogDeleteRow,
    LogMovedRows,
    LogEntry,
    LogCreateMessage,
    LogDuplicatedColumn,
    LogTransferTable
} from '../../constants/constants';
import {generatePath} from 'react-router';
import {toast} from 'react-toastify';
import clientAPI from "../../service/ApiService"
import {formatDate} from "../../service/DateService";

function LogDrawer() {

    const initialState = {
        loading: false,
        error: null,
        end: false,
        loadingPage: false,
        initialRequest: false,
    }

    const limit = 20;
    const [boardState, dispatchBoard] = useContext(BoardContext);
    const [state, setState] = useState(initialState);
    const [visible, setVisible] = useState(boardState.logsDrawerVisible);
    const [logs, setLogs] = useState([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        setVisible(boardState.logsDrawerVisible)

        if (boardState.logsDrawerVisible) {
            setPage(1);
            setLogs([]);
            setState({...state, initialRequest: true, end: false});
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boardState.logsDrawerVisible]);

    useEffect(() => {
        if(state.initialRequest){
            requestLogs();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.initialRequest]);

    useEffect(() => {
        if(boardState.board && page > 1)
            requestLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const onClose = () => {
        dispatchBoard({type: CLOSE_LOGS_DRAWER});
    };

    const requestLogs = () => {

        if(state.loadingPage || state.end || !boardState){
            return false;
        }

        let path = API_URL + generatePath(API_GET_LOGS, {
            board: boardState.board.id,
        })

        setState({
            ...state,
            loadingPage: true,
        })

        clientAPI.get(path + "?page=" + page + "&limit=" + limit)
            .then(response => {

                let logsObj = response.data.payload.logs;

                if(!logsObj || logsObj.length === 0){
                    setState({
                        ...state,
                        loading: false,
                        end: true,
                        loadingPage: false,
                        initialRequest: false
                    })
                }else{
                    setState({
                        ...state,
                        loading: false,
                        loadingPage: false,
                        initialRequest: false
                    })
                    setLogs(logsObj ? logs.concat(logsObj) : [])
                }

            })
            .catch(error => {
                if (error && error.response && error.response.data && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false,
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    }

    const handleScroll = (e) => {

        if(state.loadingPage || state.end){
            return false;
        }

        const target = e.target;
        let difference = target.scrollHeight - target.scrollTop;
        if( difference === target.clientHeight){
            setPage(page + 1);
        }
    }

    return (
        <div>
            <Drawer
                title={"Logs"}
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
                width="300px"
                onScroll={(e) => handleScroll(e)}
            >

                {logs && logs.map((log, index) =>
                    <div className="log-card" key={index}>
                        <div className="log">
                            <p className="card-date"><strong>{log.createdByUser.name}</strong> on {formatDate(log.createdAt)}</p>
                        </div>

                        <div className="message-card-content">
                            {(() => {
                                switch (log.Type) {
                                    case LogUpdateBoard:
                                        return <p>Updated board</p>;
                                    case LogUpdBoardDescription:
                                        return <p>Updated board description</p>;
                                    case LogDeleteBoard:
                                        return <p>Deleted the board</p>;
                                    case LogCreateTable:
                                        return <p>Created a table</p>;
                                    case LogUpdateTable:
                                        return <p>Updated the table name or users</p>;
                                    case LogDeleteTable:
                                        return <p>Deleted a table</p>;
                                    case LogDuplicatedTable:
                                        return <p>Duplicated a table</p>;
                                    case LogTransferTable:
                                        return <p>Transferred a table</p>;
                                    case LogMovedTables:
                                        return <p>Moved a table</p>;
                                    case LogCreateColumn:
                                        return <p>Created a column</p>;
                                    case LogUpdateColumn:
                                        return <p>Updated a column</p>;
                                    case LogDeleteColumn:
                                        return <p>Deleted a column</p>;
                                    case LogMovedColumns:
                                        return <p>Moved the columns</p>;
                                    case LogDuplicatedColumn:
                                        return <p>Duplicated a column</p>;
                                    case LogCreateRow:
                                        return <p>Created a row</p>;
                                    case LogUpdateRow:
                                        return <p>Updated a row name</p>;
                                    case LogDeleteRow:
                                        return <p>Deleted a row</p>;
                                    case LogMovedRows:
                                        return <p>Moved rows</p>;
                                    case LogEntry:
                                        return <p>Added/modified a value</p>;
                                    case LogCreateMessage:
                                        return <p>Write a message in a row</p>;
                                    default:
                                        return null;
                                }
                            })()}
                        </div>
                    </div>
                )}

                {!logs && !state.loading &&
                    <p>There are no logs for this row yet.</p>
                }

                {state.loading &&
                <SpinnerLoading className="logs-drawer-spinner" animation="border" role="status" />}
            </Drawer>
        </div>
    )
}

export default LogDrawer
