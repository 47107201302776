import React, {useEffect, useState} from 'react';
import {Button, Modal} from 'antd';
import SharedLinksTable from "./SharedLinksTable";
import CreateLink from "./CreateLink";

function ShareModal({open, boardId}){

    const initialState = {
        visible: false,
        error: null,
        creating: false,
        refresh: 0,
    }
    const [state, setState] = useState(initialState)

    useEffect(() => {
        if(open === 0) return;
        setState({
            ...state,
            visible: true,
            creating: false,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleCancel = e => {
        setState({
            ...state,
            visible: false,
        });
    };

    const handleOk = e => {
        setState({
            ...state,
            visible: false,
        });
    };

    const refresh = () => {
        setState({
            ...state,
            refresh: state.refresh + 1,
            creating: false,
        })
    }

    return (
        <div>
            {state.visible &&
            <Modal
                title="Share"
                visible={state.visible}
                okText="Done"
                className="link-upload-modal"
                onOk={() => handleOk()}
                onCancel={() => handleCancel()}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{form: 'search-editor-form', key: 'submit', htmlType: 'submit'}}
            >
                <p>This feature allows to generate links to share this board <strong>public to anyone</strong> outside kaiseki.io<br/>
                    If you want to share this board with somebody on your team, please edit the board and add your teammate there instead.</p>
                <Button type="primary" onClick={() => setState({...state, creating: !state.creating})}>
                    { state.creating ? "Close" : "Create Link" }
                </Button>
                { state.creating &&
                    <CreateLink boardId={boardId} refresh={refresh} />
                }
                <SharedLinksTable boardId={boardId} refresh={state.refresh} />
            </Modal>
            }

        </div>
    );
};

export default ShareModal;
