import React, {useState, useContext, useEffect, useRef} from 'react'
import { API_URL, API_CELL } from '../../../../constants';
import { generatePath } from 'react-router';
import { Dropdown } from 'antd';
import { UsersContext } from '../../../../state/usersStore';
import PeopleButton from './PeopleButton';
import PeopleDropdown from './PeopleDropdown';
import clientAPI from "../../../../service/ApiService";
import {ADD_USER} from "../../../../state/users/UsersTypes";

function PeopleCell(props) {

    const initialState = {
        loading: false,
        error: null,
        table: props.table,
        boardId: props.boardId,
        path: API_URL + generatePath(API_CELL, {
            board: props.boardId,
            table: props.table.id,
            row: props.row.id,
            column: props.column.id,
        }),
        entry: null,
        users: [],
    }

    const [usersState, dispatchUsers] = useContext(UsersContext);
    const [state, setState] = useState(initialState)
    const [visible, setVisible] = useState(false)
    const [rowEntries, setRowEntries] = useState(null)

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setVisible(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useEffect(() => {
        if(!usersState || !usersState || usersState.users.length <= 0) return

        let entries = props.row.entries.find(function(obj){
            return obj.column === props.column.id
        });

        setRowEntries(entries);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, usersState]);

    useEffect(() => {
        computeEntry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, rowEntries, usersState]);

    const computeEntry = () => {
        if(!usersState || !usersState || usersState.users.length <= 0) return

        var users = [];
        if(rowEntries){
            users = rowEntries.value.map((userId) => {
                const user = usersState.users.find(o => o.ID === userId)
                if(user === undefined){
                    clientAPI.get(API_URL + "/user/" + userId)
                        .then(response => {
                            const user = response.data.payload;
                            dispatchUsers({
                                type: ADD_USER,
                                payload: {user: user}
                            });
                        })
                        .catch(error => {

                            console.log("Error getting user");
                        })
                }
                return user
            })
        }

        setState({
            ...state,
            entry: (rowEntries !== undefined && rowEntries != null) ? rowEntries.value : "",
            users: users,
        })
    }

    const closeDropdown = () => {
        setVisible(false);
    }

    const updateCell = (values) => {
        setRowEntries({column: props.column.id, value: values})
    }

    const changeVisibility = (e) => {
        setVisible(!visible)
    };

    const menu = (
        <div className={"people-dropdown"} ref={wrapperRef}>
            <PeopleDropdown
                users={state.users}
                path={state.path}
                table={props.table}
                row={props.row}
                column={props.column}
                visible={visible}
                closeDropdown={closeDropdown}
                updateCell={updateCell} />
        </div>
    );

    return (
        <div className="cell-entry people-cell">
            {state.entry != null &&
            <Dropdown overlay={menu}
                      trigger={["click"]}
                      arrow={true}
                      placement="bottomCenter"
                      visible={visible}
                      animation={false}
                      onClick={(e)=>changeVisibility(e)}
            >
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()} href="/#">
                    <button
                        id="dropdown-basic"
                        className="status-toggle"
                        style={{backgroundColor: state.entry.color}}>
                            <PeopleButton
                                users={state.users}
                                path={state.path}
                                table={props.table}
                                row={props.row}
                                column={props.column}
                                updateCell={updateCell} />
                    </button>
                </a>
            </Dropdown>
            }
        </div>
    )
}

export default PeopleCell
