import React, {useEffect, useState} from 'react'
import LandingDrawer from "./LandingDrawer";
import {Alert, Button, Collapse, Input} from 'antd';
import {HashLink as Link} from 'react-router-hash-link';
import poster from 'assets/basic_poster.jpg';
import AppleLogo from 'assets/apple-logo.png';
import createVideoMp4 from 'assets/create_row.mp4';
import BasicKaisekiMp4 from 'assets/basic.mp4';
import createVideoWebm from 'assets/create_row.webm';
import {useHistory} from "react-router-dom";
import Fade from 'react-reveal/Fade';
import {ImPlay} from "react-icons/im";
import {ReactComponent as Singapore} from 'assets/singapore.svg';
import {ReactComponent as Logo} from 'assets/kaiseki-logo.svg';
import {ReactComponent as WindowsLogo} from 'assets/windows-logo.svg';
import {ReactComponent as LinuxLogo} from 'assets/linux-logo.svg';
import SubtitlesMain from 'assets/subtitles-main.vtt';
import TextLoop from "react-text-loop";
import {Form} from "react-bootstrap";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {GOOGLE_RECAPTCHA_KEY} from "../../../constants/constants";
import {Controller, useForm} from "react-hook-form";
import axios from "axios";
import {API_CONTACTUS} from "../../../constants";
import {toast} from "react-toastify";
import {DefaultPlayer as Video} from 'react-html5video';
import 'react-html5video/dist/styles.css';
import CookieConsent from "react-cookie-consent";
import {GiHamburgerMenu} from "react-icons/gi";
import {
    CloseOutlined
} from '@ant-design/icons';

function LandingPage() {

    const initialState = {
        panelVisible: false,
        panelType: null,
        success: false,
        showMenu: false,
        faqCollapsed: true,
        downloadSelected: null
    }

    const history = useHistory();

    const [state, setState] = useState(initialState)
    const {handleSubmit, control, register, errors, setValue} = useForm();
    const {TextArea} = Input;
    const { Panel } = Collapse;

    useEffect(() => {

        register({name: 'captchaToken'}, {required: true});

        if (history.location.hash === "#login") {
            setState({...state, panelVisible: true, panelType: "login"})
        } else if (history.location.hash === "#register") {
            setState({...state, panelVisible: true, panelType: "register"})
        } else if (history.location.hash === "#forget") {
            setState({...state, panelVisible: true, panelType: "forget"})
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const close = () => {
        setState({...state, panelVisible: false, panelType: null})
    };

    const goToLogin = () => {
        setState({...state, panelVisible: true, panelType: "login"})
    };

    const goToRegister = () => {
        setState({...state, panelVisible: true, panelType: "register"})
    };

    const goToForget = () => {
        setState({...state, panelVisible: true, panelType: "forget"})
    };

    const onVerifyCaptcha = (token) => {
        setValue('captchaToken', token);
    };

    const onSubmit = values => {
        setState({
            ...state,
            error: null,
            loading: true,
            success: false
        })

        axios.post(API_CONTACTUS, values)
            .then(response => {
                console.log(response);
                setState({
                    ...state,
                    error: null,
                    loading: false,
                    success: true
                })
            })
            .catch(error => {
                console.log(error)
                if (error?.response?.data?.statusCode?.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false,
                        success: false
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    };

    return (
        <div className="landing-page">

            <nav>
                <div className="landing-container">
                    <div className="mobile">
                        <Link to="/landing#home" className="mobile-logo"><Logo/></Link>
                    </div>
                    <div className={`dropdown-menu-mobile ${state.showMenu ? "show-menu" : ""}`}>
                        <div className="anchors">
                            <Link to="/landing#home" className="anchor-logo"><Logo/></Link>
                            <Link to="/landing#info" onClick={() => setState({...state, showMenu: false})}>What is this?</Link>
                            <Link to="/landing#downloads" onClick={() => setState({...state, showMenu: false})}>Downloads</Link>
                            <Link to="/landing#features" onClick={() => setState({...state, showMenu: false})}>Features</Link>
                            {/*<Link to="/landing#aboutus" onClick={() => setState({...state, showMenu: false})}>About us</Link>*/}
                            <Link to="/landing#faq" onClick={() => setState({...state, showMenu: false})}>FAQ</Link>
                            <Link to="/landing#contactus" onClick={() => setState({...state, showMenu: false})}>Contact us</Link>
                        </div>
                        <div className="actions">
                            <Button className="register-button" onClick={() => setState({
                                ...state,
                                panelVisible: true,
                                panelType: "register",
                                showMenu: false
                            })}>Register</Button>
                            <Button className="login-button"
                                    onClick={() => setState({
                                        ...state,
                                        panelVisible: true,
                                        panelType: "login",
                                        showMenu: false
                                    })}>Login</Button>
                        </div>
                    </div>
                    <button className="mobile mobile-dropdown"
                            onClick={() => setState({...state, showMenu: !state.showMenu})}>
                        <GiHamburgerMenu/>
                    </button>
                </div>
            </nav>

            <LandingDrawer
                visible={state.panelVisible}
                close={close}
                type={state.panelType}
                goToLogin={goToLogin}
                goToRegister={goToRegister}
                goToForget={goToForget}
            />

            <div className="container">
                <div id="home" className="section intro-section" style={{backgroundColor: "white"}}>
                    <div className="info">
                        <div className="content">
                            <h1>Collaboration made&nbsp;
                                <TextLoop>
                                    <span>easy</span>
                                    <span>simple</span>
                                    {/*<span>real-time</span>*/}
                                    <span>free</span>
                                </TextLoop>{" "}
                                <br/>
                                <p className="body">Kaiseki will help you and your team plan, communicate and execute
                                    projects with simple to use tools - all for <strong>free</strong>.</p>
                                {/*<Typist avgTypingDelay={80}*/}
                                {/*        cursor={{show: true, hideWhenDone: true, hideWhenDoneDelay: 0}}>*/}
                                {/*    <Typist.Delay ms={1200}/>*/}
                                {/*    Create.*/}
                                {/*    <Typist.Backspace count={8} delay={1500}/>*/}
                                {/*    Innovate.*/}
                                {/*    <Typist.Backspace count={9} delay={3000}/>*/}
                                {/*    /!*<Typist.Delay ms={3400}/>*!/*/}
                                {/*    Succeed.*/}

                                {/*</Typist>*/}
                            </h1>
                        </div>
                    </div>
                    <video playsInline={true} muted loop autoPlay={true}>
                        <source src={createVideoMp4} type="video/mp4"/>
                        <source src={createVideoWebm} type="video/webm"/>
                    </video>
                </div>
            </div>

            <section id={"downloads"}>
                <h2>Downloads</h2>
                <p>Start organizing your projects by downloading a windows installer or use it in the website.</p>
                <div className="download-options">
                    <div className="mac">
                        <a href={"https://apps.apple.com/app/id6443762623"} target={"_blank"} rel={"noopener noreferrer"}>
                            <span className={"mac-logo"}><img src={AppleLogo} alt={"Mac Logo"}/></span>
                            <p>Mac</p>
                        </a>
                    </div>
                    <div className="windows">
                        <a type="primary" className="download-windows" download={true} href={"https://apps.microsoft.com/store/detail/kaisekiio/9N88KS8619PT"}  >
                            <span className={"windows-logo"}><WindowsLogo/></span>
                            <p>Windows</p>
                        </a>
                    </div>
                    <div className="linux">
                        <Button variant=""
                                className={state.downloadSelected==="linux" && "selected"}  onClick={() => setState({
                            ...state,
                            downloadSelected: state.downloadSelected === "linux" ? null : "linux"
                        })}>
                            <span className={"linux-logo"}><LinuxLogo/></span>
                            <p>Linux</p>
                        </Button>
                    </div>
                    <div className="website">
                        <Button variant=""
                                className={state.downloadSelected === "website" && "selected"}  onClick={() => setState({
                            ...state,
                            downloadSelected: state.downloadSelected === "website" ? null : "website"
                        })}>
                            Website
                        </Button>
                    </div>
                </div>
                {/*{state.downloadSelected === "windows" &&*/}
                {/*    <div className="gray-bg windows">*/}
                {/*        <button className="close" onClick={() => setState({*/}
                {/*            ...state,*/}
                {/*            downloadSelected: null*/}
                {/*        })}>*/}
                {/*            <CloseOutlined />*/}
                {/*        </button>*/}
                {/*        <p>You can download it directly from this website, or from the Microsoft Windows Store</p>*/}
                {/*        <a type="primary" className="download-windows" download={true} href={"ms-windows-store://pdp/?ProductId=9N88KS8619PT"}>*/}
                {/*            Windows Store*/}
                {/*        </a>*/}
                {/*        <a type="primary" className="download-windows" download={true} href={"https://production-kaiseki.sgp1.digitaloceanspaces.com/installers/kaiseki.io.exe"}>*/}
                {/*            Download*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*}*/}
                {state.downloadSelected === "linux" &&
                    <div className="gray-bg linux">
                        <button className="close" onClick={() => setState({
                            ...state,
                            downloadSelected: null
                        })}>
                            <CloseOutlined />
                        </button>
                        <p>For now, we only support Ubuntu/Debian distributions. To install it:</p>
                        <pre className="steps">
                            curl -sS https://production-kaiseki.sgp1.digitaloceanspaces.com/linux/Kaiseki.io_1.0.1_amd64.deb --output kaiseki.deb<br/>
                            sudo apt -y install libsecret-1-dev libjsoncpp-dev<br/>
                            sudo dpkg -i kaiseki.deb<br/>
                            rm kaiseki.deb <br/>
                        </pre>
                    </div>
                }

                {state.downloadSelected === "website" &&
                    <div className="gray-bg website">
                        <button className="close" onClick={() => setState({
                            ...state,
                            downloadSelected: null
                        })}>
                            <CloseOutlined />
                        </button>
                        <p>You can use this current website, or try our new beta version</p>
                        <a type="primary" className="existing-website" onClick={() => setState({
                                    ...state,
                                    panelVisible: true,
                                    panelType: "login",
                                    showMenu: false
                                })}
                        >
                            Login
                        </a>
                        <a type="primary" className="website-flutter"  href={"https://beta.kaiseki.io"}>
                            Experience the beta site!
                        </a>
                    </div>
                }

            </section>

            <section id={"info-section"}>
                <div id="info" className="section info-intro">
                    <Fade bottom>
                        <h2>Resource management <span>well done</span></h2>
                        <p className="subtitle">We made this all for you - taking inspiration from our own experiences and
                            current tools, to give you a simple-to-use platform for managing projects. </p>
                    </Fade>
                </div>

                <div id="interactive" className="section interactive-section">
                    {/*<img src={screenshot} alt="Screenshot"/>*/}
                    <div className="video-container">
                        <div className="info">
                            <ImPlay />
                            <p>Quick tour</p>
                            <div className="subtitle">Learn the basics by clicking play down below</div>
                        </div>
                        <Video loop muted
                               controls={['PlayPause', 'Seek', 'Time']}
                               poster={poster}
                               onCanPlayThrough={() => {
                                   // Do stuff
                               }}>
                            <source src={BasicKaisekiMp4} type="video/webm"/>
                            <track label="English" kind="subtitles" srcLang="en" src={SubtitlesMain} default/>
                        </Video>
                    </div>
                </div>
            </section>

            <div id="features" className="section features-section" style={{backgroundColor: "white"}}>
                <Fade bottom>
                    <h2>Main Features</h2>
                    {/*<p className="subtitle"></p>*/}

                    <div className="feature-section" id="organize">
                        <h3>Organize</h3>
                        <div className="features">
                            <div className="feature">
                                {/*<FaPencilRuler style={{width: 31, marginLeft: 14}}/>*/}
                                <h4>Tailor made</h4>
                                <p>One board can have multiple customizable tables to cater to what you need</p>
                            </div>
                            <div className="feature">
                                {/*<IoMdShuffle style={{width: 37, marginLeft: 7}}/>*/}
                                <h4>Shuffle</h4>
                                <p>Move, sort and rearrange tables, rows and columns</p>
                            </div>
                            <div className="feature">
                                {/*<Padlock style={{width: 37, marginLeft: 7}}/>*/}
                                <h4>Manage access</h4>
                                <p>Create unlimited <strong>public</strong> and <strong>private</strong> boards - manage access of boards to relevant
                                    users and teams</p>
                            </div>
                            <div className="feature">
                                {/*<SlidersOutlined style={{width: 37, marginLeft: 7}}/>*/}
                                <h4>Custom types</h4>
                                <p>Customize your boards and tasks with different column types such as status, date,
                                    person and many more</p>
                            </div>
                            <div className="feature">
                                {/*<FilesIcon style={{width: 37, marginLeft: 7}}/>*/}
                                <h4>Templates</h4>
                                <p>Create your own board and table templates to supercharge your projects</p>
                            </div>
                            <div className="feature">
                                {/*<FilesIcon style={{width: 37, marginLeft: 7}}/>*/}
                                <h4>Files</h4>
                                <p>Up to <strong>500 MB</strong> to organize your projects by attaching files to your boards, and share them with your colleagues</p>
                            </div>
                        </div>
                    </div>

                    <div className="feature-section" id="collaborate">
                        <h3>Collaborate</h3>
                        <div className="features">
                            <div className="feature">
                                {/*<WiLightning style={{width: 60}}/>*/}
                                <h4>Real Time</h4>
                                <p>Collaborate in real time with your team, see the changes made right after you do
                                    it</p>
                            </div>
                            <div className="feature">
                                {/*<AiOutlineTeam style={{width: 40}}/>*/}
                                <h4>Teams</h4>
                                <p>Organize your projects by grouping it in categories and arranging it by teams</p>
                            </div>
                            <div className="feature">
                                {/*<IoMdNotificationsOutline style={{width: 40}}/>*/}
                                <h4>Notifications</h4>
                                <p>Stay up to date with your projects with real time notifications </p>
                            </div>
                            {/*<div className="feature">*/}
                            {/*    <IoMdNotificationsOutline style={{ width: 40 }} />*/}
                            {/*    <h4>Chat</h4>*/}
                            {/*    <p>Chat function allows communication with your teammates in real time for quick updates </p>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    <div className="feature-section" id="practical">
                        <h3>Hands-on</h3>
                        <div className="features">
                            <div className="feature">
                                {/*<FaList style={{width: 32, marginLeft: 14}}/>*/}
                                <h4>Actions in bulk</h4>
                                <p>Many rows at the same time</p>
                            </div>
                            <div className="feature">
                                {/*<Bulk style={{width: 36, marginLeft: 14}}/>*/}
                                <h4>Views</h4>
                                <p>Toggle between Table, kanban and calendar view</p>
                            </div>
                            <div className="feature">
                                {/*<GoGitCompare style={{width: 36, marginLeft: 14}}/>*/}
                                <h4>Automatic actions</h4>
                                <p>For those recurrent tasks</p>
                            </div>
                            <div className="feature">
                                {/*<StarFilled style={{width: 36, marginLeft: 14}}/>*/}
                                <h4>Favorites</h4>
                                <p>Highlight important boards as favorites</p>
                            </div>
                            <div className="feature">
                                {/*<FaTable style={{width: 36, marginLeft: 14}}/>*/}
                                <h4>Sheet integration</h4>
                                <p>Import and export to Excel format</p>
                            </div>
                            <div className="feature">
                                {/*<Free style={{width: 44, marginLeft: 8}}/>*/}
                                <h4>Cost</h4>
                                <p>Completely <strong>free</strong></p>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>

            {/*<div id="aboutus" className="section aboutus-section" style={{backgroundColor: "white"}}>*/}

            {/*    <Fade bottom>*/}
            {/*        <h2>Who we are</h2>*/}
            {/*        <p className="subtitle">The brains and hands behind Kaiseki.io</p>*/}
            {/*    </Fade>*/}

            {/*    <div className="img-container">*/}
            {/*        /!*<img src={whoWeAre} alt="Who we are"/>*!/*/}
            {/*        /!*<Parallax bgImage={whoWeAre} strength={500}>*!/*/}
            {/*        /!*</Parallax>*!/*/}

            {/*        <Parallax*/}
            {/*            bgImage={whoWeAre}*/}
            {/*            bgImageAlt="Who we are"*/}
            {/*            strength={300}*/}
            {/*            disabled={size.width < 768}*/}
            {/*        >*/}
            {/*            <img src={Arrow1} className={"arrow sarah"} alt="Arrow 1"/>*/}
            {/*            <img src={Arrow1} className={"arrow joan"} alt="Arrow 1"/>*/}
            {/*            /!*<Joan />*!/*/}
            {/*            <div className="pinfo sarah-info">*/}
            {/*                <h4>Sarah</h4>*/}
            {/*                <p>Advertising native who loves bullet points and lists</p>*/}
            {/*                <a href="https://www.linkedin.com/in/sarahmarigomen" target="_blank" rel="noopener noreferrer">*/}
            {/*                    <Linkedin/>*/}
            {/*                </a>*/}
            {/*            </div>*/}

            {/*            <div className="pinfo joan-info">*/}
            {/*                <h4>Joan</h4>*/}
            {/*                <p>Software engineer by day, platform founder by night.</p>*/}
            {/*                <a href="https://sg.linkedin.com/in/joan-ilari-civit-855ab9ba" target="_blank" rel="noopener noreferrer">*/}
            {/*                    <Linkedin/>*/}
            {/*                </a>*/}
            {/*            </div>*/}
            {/*            <div className="about-us-height" style={{height: '700px'}}/>*/}
            {/*        </Parallax>*/}

            {/*    </div>*/}

            {/*</div>*/}

            <div id="faq" style={{backgroundColor: "white"}} className="section">
                <h2>FAQ</h2>
                <Collapse className={"faqs"} accordion={false}>
                    {/*<Panel header="What is kaiseki.io?" key="1">*/}
                    {/*    <p>Kaiseki.io is a platform that will help you and your team plan, communicate and execute projects using different workspaces in real-time, all for free.</p>*/}
                    {/*</Panel>*/}
                    <Panel header="Is kaiseki.io really free, or are you just pulling our leg?" key="2">
                        <p>Have you checked for a pricing section? Have you seen anything at all related to any dollar amount? Good! We don't accept payments, so, good for you. If you want to help with the platform, please write a nice review at <a
                            href="https://alternativeto.net/software/kaiseki-io/about/" target={"_blank"} rel="noopener noreferrer">alternativeto.net</a> or to <a
                            href="https://www.capterra.com/p/231324/kaisekiio/" target={"_blank"} rel="noopener noreferrer">Capterra.com</a> and recommend us there. :)</p>
                    </Panel>
                    <Panel header="And yet another platform to organize projects? How is this different from Asana or Monday.com?" key="3">
                        <p>Yes indeed, the idea behind it is the same, but we wanted to highlight about our flexibility and (non-existent) pricing.
                            Meanwhile we were using these other platforms, we realized there was a need of having tables inside a board with not one, but many tables, rich text and assets in order to create a page that will give all the possible context to your teammates. Having one single source of information without having to go looking at other pages/platforms, we thought could be a game changer. </p>
                    </Panel>
                    <Panel header="Why are you doing this?" key="4">
                        <p>Being frank, it all started as our stay-at-home project during the pandemic, we were interested in having a common project with technologies that we wanted to learn and the idea of kaiseki.io came after some frustration using the other project management tools. Since we thought we have crafted a nice platform, we wanted to share it with the world.</p>
                    </Panel>
                    <Panel header="What can I do with kaiseki.io?" key="5">
                        <p>We have tried to do in the most dead simple way - a project management system where you could combine rich text, many tables and assets in one board. We suggest you to read the features section, but in short, you can create tables, kanban, calendar, rich text, asset management system, teams, templates, automatic actions, have an admin panel, dashboards and all in real time.</p>
                    </Panel>
                    <Panel header="How do you sustain this?" key="6">
                        <p>The only expenses that we have is our sanity and our servers, and so far, we can afford both.</p>
                    </Panel>
                    { !state.faqCollapsed &&
                        <React.Fragment>
                            <Panel header="Is kaiseki.io secure?" key="7">
                                <p>It uses the industry standards so you can be safe on this side.</p>
                            </Panel>
                            <Panel header="Are you going to sell our personal data?" key="8">
                                <p>No, we will never sell/give your data to anyone/a company/anybody else in particular.</p>
                            </Panel>
                            <Panel header="Can I propose or suggest new features?" key="9">
                                <p>Sure, we would love to hear your suggestions in order to improve kaiseki.io. We will release a roadmap of features soon where you can see what is being developed.</p>
                            </Panel>
                            <Panel header="Where are kaiseki.io servers located?" key="10">
                                <p>Servers are located in Singapore, depending if we have much traffic from any other regions, we will consider to have servers there as well, so it will speed up the connection.</p>
                            </Panel>
                            <Panel header="Do you offer any support?" key="11">
                                <p>Yes, but you have to note that we have our 8-hour day jobs too and we will not be able to reply immediately. However, you can expect responses on the same day.</p>
                            </Panel>
                            <Panel header="What are kaiseki.io next steps?" key="12">
                                <p>We are currently working on native apps for iOS, Android and desktop apps for OSX, Windows and Linux.</p>
                            </Panel>
                        </React.Fragment>
                    }
                </Collapse>
                { state.faqCollapsed &&
                    <Button className={"faq-view-more"} onClick={()=>setState({...state, faqCollapsed: false})} >View more</Button>
                }
            </div>

            <div id="contactus" className="section contactus-section" style={{backgroundColor: "white"}}>

                <div className="centered1140">

                    <div className="left">
                        <h2>Contact us</h2>
                        <p className="subtitle">Feel free to reach out if you have any questions, feature suggestions,
                            bugs to report or just want to say hi.<br/><br/>
                            If web forms is not your thing, drop me a note at <a href={"mailto:info@kaiseki.io"}
                                                                                 target={"_blank"} rel="noopener noreferrer">info@kaiseki.io</a>
                        </p>
                    </div>
                    <div className="right">
                        {state.success && <Alert message="Thanks for reaching out, I will get in touch soon." closable
                                                 className="notification" type="success"/>}

                        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                            <Form.Group controlId="formBasicName">
                                <Form.Label>Name</Form.Label>
                                <Controller
                                    as={Input}
                                    name='name'
                                    control={control}
                                    placeholder="John Doe"
                                    rules={{required: true}}
                                />
                                {errors.name && (
                                    <span className="alert-danger">You must specify a name</span>
                                )}
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Controller
                                    as={Input}
                                    name='email'
                                    control={control}
                                    placeholder="john.doe@gmail.com"
                                    rules={{
                                        pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                        required: true,
                                    }}
                                />

                                {errors.email && (
                                    <span className="alert-danger">Invalid email address</span>
                                )}
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Message</Form.Label>
                                <Controller
                                    as={TextArea}
                                    name='message'
                                    control={control}
                                    placeholder="Write text here"
                                    rules={{
                                        required: true,
                                    }}
                                />

                                {errors.message && (
                                    <span className="alert-danger">Message is mandatory</span>
                                )}
                            </Form.Group>

                            <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_KEY}>
                                <GoogleReCaptcha onVerify={token => onVerifyCaptcha(token)}/>
                            </GoogleReCaptchaProvider>

                            <Button variant="primary" type="primary" htmlType="submit" className="login-form-button"
                                    loading={state.loading}>
                                Send Message
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
            <div id="proud" className="section proud-section">
                <p>PROUDLY MADE IN SINGAPORE&nbsp;&nbsp;|&nbsp;&nbsp;<Singapore/></p>
            </div>

            <CookieConsent
                location="bottom"
                buttonText="Got it"
                cookieName="consentCookieName"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
            >
                This website uses cookies to enhance the user experience.{" "}
            </CookieConsent>

        </div>
    )
}

export default LandingPage
