import React, {useContext, useState} from 'react'
import {Form} from 'react-bootstrap';
import {Alert, Button, Input} from 'antd';
import {Controller, useForm} from "react-hook-form";
import axios from 'axios'
import {Context} from '../../../state/store'
import {LAUNCH_ONBOARD, USER_GET_ACCOUNT, USER_LOGIN} from '../../../state/user/UserTypes'
import {API_FACEBOOK_LOGIN, API_GET_ACCOUNT, API_GOOGLE_LOGIN, API_LOGIN} from '../../../constants/index';
import {FACEBOOK_APP_ID, GOOGLE_CLIENT_ID} from '../../../constants/constants';
import {useHistory} from "react-router-dom";
import clientAPI from "../../../service/ApiService"
import {toast} from 'react-toastify';
import google_logo from "../../../assets/google-btn.svg";
import facebook_logo from "assets/facebook_logo.webp";
import {useGoogleLogin} from "react-use-googlelogin";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'


function LoginPanel({goToRegister, goToLogin, goToForget}) {

    const dispatch = useContext(Context)[1];

    const initialState = {
        error: null,
        loading: false,
    }
    const history = useHistory();

    const { signIn } = useGoogleLogin({
        clientId: GOOGLE_CLIENT_ID,
        scope: "profile email",
        persist: false,
    })

    const [state, setState] = useState(initialState)
    const { handleSubmit, control, errors } = useForm();
    const onSubmit = values => {
        setState({
            ...state,
            error: null,
            loading: true
        })

        axios.post(API_LOGIN, values)
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false
                })
                dispatch({ type: USER_LOGIN, payload: response.data.payload });
                if(!response.data.payload.User.lastLogin){ // first time we launch onboarding
                    dispatch({ type: LAUNCH_ONBOARD})
                }
                getAccount()
            })
            .catch(error => {
                console.log(error)
                setState({
                    ...state,
                    error: error?.response?.data?.statusCode?.msg ?? 'Error connecting with the server, check your connection or contact support',
                    loading: false,
                    success: false
                })
            })
    };

    const getAccount = values => {
        setState({
            ...state,
            error: null,
            loading: true
        })

        clientAPI.get(API_GET_ACCOUNT, values)
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false
                })
                dispatch({ type: USER_GET_ACCOUNT, payload: response.data.payload });

                history.push("/");
            })
            .catch(error => {
                if (error && error.response && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    };


    const googleSignIn = async () => {
        const googleUser = await signIn()
        if(googleUser && googleUser.hasOwnProperty("accessToken")){
            let googleToken = googleUser.tokenObj.id_token;
            axios.post(API_GOOGLE_LOGIN, {"token" : googleToken})
                .then(response => {
                    console.log(response);
                    setState({
                        ...state,
                        error: null,
                        loading: false
                    })
                    dispatch({ type: USER_LOGIN, payload: response.data.payload });
                    getAccount()
                })
                .catch(error => {
                    console.log(error);
                    if (error?.response?.data?.statusCode?.msg) {
                        setState({
                            ...state,
                            error: error.response.data.statusCode.msg,
                            loading: false
                        })
                    } else {
                        toast.error('Error connecting with the server, check your connection or contact support');
                    }
                })
        }
    }

    const responseFacebook = (response) => {
        axios.post(API_FACEBOOK_LOGIN, {"token" : response.accessToken})
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false
                })
                dispatch({ type: USER_LOGIN, payload: response.data.payload });
                getAccount()
            })
            .catch(error => {
                if (error?.response?.data?.statusCode?.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    }

    return (
        <div className={"login-panel"}>
            <p className="other-actions">
                <button onClick={() => goToRegister()}><h5>Sign Up</h5></button>
                <button onClick={() => goToForget()} style={{ marginLeft: "10px" }}><h5>Forget password</h5></button>
            </p>
            {state.error && <Alert message={state.error} type="error" closable className="notification" />}
            <h3>Log in</h3>
            <div className="options"><p>or <button onClick={()=>goToRegister()} className="blue-link">create an account</button></p></div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Controller
                        as={Input}
                        name='email'
                        control={control}
                        placeholder="Email"
                        rules={{
                            pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                            required: true,
                        }}
                    />

                    {errors.email && (
                        <span className="alert-danger">Invalid email address</span>
                    )}
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Controller
                        as={Input.Password}
                        name='password'
                        control={control}
                        placeholder="Password"
                        rules={{
                            minLength: 5,
                            required: true,
                        }}
                    />
                    {errors.email && (
                        <span className="alert-danger">Invalid password, password must have at least 5 characters</span>
                    )}
                </Form.Group>
                <Button variant="primary" type="primary" htmlType="submit" className="login-form-button" loading={state.loading}>
                    Login
                </Button>
                <button onClick={()=>goToForget()} className="blue-link forget-link">Forget password?</button>

                <p className={"or"}>or</p>

                <button onClick={() => googleSignIn()} type="button" className={"btn google-signin"}>
                    <img src={google_logo} alt="Google button"/>
                    Login with Google
                </button>

                <FacebookLogin
                    appId={FACEBOOK_APP_ID}
                    autoLoad={false}
                    fields="id,name,email,first_name,last_name,gender,birthday,location{location{name, country_code}}, picture.type(large)"
                    callback={(data) => responseFacebook(data)}
                    type="button"
                    render={renderProps => (
                        <button type="button" className={"btn facebook-signin"} onClick={renderProps.onClick}>
                            <img src={facebook_logo} alt="Facebook button"/>
                            Login with Facebook
                        </button>
                    )}
                />

            </Form>
        </div>
    )
}

export default LoginPanel
