import React, {useEffect, useRef, useState} from 'react'
import {Dropdown} from 'antd';
import PeopleButton from './PeopleButton';
import PeopleDropdown from './PeopleDropdown';

function PeopleCell({table, row, column, setRowValue}) {

    const initialState = {
        loading: false,
        error: null,
        entry: null,
        users: [],
        allUsers: [
            {
                ID: "1",
                name: "Bob",
                surname: "Smith",
                thumb: "https://production-kaiseki.sgp1.cdn.digitaloceanspaces.com/onboarding/user1.jpeg",
                image: "https://production-kaiseki.sgp1.cdn.digitaloceanspaces.com/onboarding/user1.jpeg",
                createdAt: "2020-09-15T16:15:43.309Z",
                enabled: true,
            },
            {
                ID: "2",
                name: "Matt",
                surname: "Jobs",
                thumb: "https://production-kaiseki.sgp1.cdn.digitaloceanspaces.com/onboarding/user2.jpeg",
                image: "https://production-kaiseki.sgp1.cdn.digitaloceanspaces.com/onboarding/user2.jpeg",
                createdAt: "2020-09-15T16:15:43.309Z",
                enabled: true,
            }
        ],
    }

    const [state, setState] = useState(initialState)
    const [visible, setVisible] = useState(false)
    const [rowEntries, setRowEntries] = useState(null)

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setVisible(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useEffect(() => {
        let entries = row.entries.find(function(obj){
            return obj.column === column.id
        });
        setRowEntries(entries);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        computeEntry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowEntries]);

    const computeEntry = () => {
        let users = [];
        if(rowEntries){
            users = rowEntries.value.map((userId) => {
                const user = state.allUsers.find(o => o.ID === userId)
                if(user === undefined){

                }
                return user
            })
        }

        setState({
            ...state,
            entry: (rowEntries !== undefined && rowEntries != null) ? rowEntries.value : "",
            users: users,
        })
    }

    const closeDropdown = () => {
        setVisible(false);
    }

    const updateCell = (values) => {
        setRowEntries({column: column.id, value: values})
        // setRowValue({column: column.id, value: values})

        setRowValue({
            row   : row,
            column: column,
            value : values,
        })
    }

    const changeVisibility = (e) => {
        setVisible(!visible)
    };

    const menu = (
        <div className={"people-dropdown"} ref={wrapperRef}>
            <PeopleDropdown
                table={table}
                row={row}
                column={column}
                setVisible={setVisible}
                visible={visible}
                allUsers={state.allUsers}
                users={state.users}
                closeDropdown={closeDropdown}
                updateCell={updateCell} />
        </div>
    );

    return (
        <div className="cell-entry people-cell">
            {state.entry != null &&
            <Dropdown overlay={menu}
                      trigger={["click"]}
                      arrow={true}
                      placement="bottomCenter"
                      visible={visible}
                      animation={false}
                      onClick={(e)=>changeVisibility(e)}
            >
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()} href="/#">
                    <button
                        id="dropdown-basic"
                        className="status-toggle"
                        style={{backgroundColor: state.entry.color}}>
                            <PeopleButton
                                users={state?.users}
                                table={table}
                                row={row}
                                column={column}
                                updateCell={updateCell} />
                    </button>
                </a>
            </Dropdown>
            }
        </div>
    )
}

export default PeopleCell
