import React, {useEffect, useState} from 'react'
import clientAPI from "../../../service/ApiService"
import {API_BOARD} from '../../../constants';
import {toast} from 'react-toastify';
import {Alert, Input} from 'antd';
import {SpinnerLoading} from "../../Style/styles.css";
import UsersPagination from "../../Users/UsersPagination";
import BoardIcon from "../../BoardsPage/BoardIcon";


function AutomaticSearchBoard({selectedBoard}) {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        page: 1,
        boards: [],
        sizePerPage: 10,
        totalSize: 0,
    }
    const [state, setState] = useState(initialState)
    useEffect(() => {
        getBoards()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.page, state.sizePerPage]);


    const getBoards = (search = null) => {
        setState({
            ...state,
            error: null,
            loading: true,
        })

        clientAPI.get(API_BOARD + "?page=" + state.page + "&limit=" + state.sizePerPage + (search ? "&search=" + encodeURIComponent(search) : ""))
            .then(response => {
                let boards = response.data.payload.boards;

                if (!boards || boards.length <= 0) {
                    setState({...state, boards: [], totalSize: 0, page: state.page > 1 ? state.page - 1 : state.page})
                    return false
                }

                // boards = boards.filter(o => o.id !== boardId)

                setState({
                    ...state,
                    error: null,
                    loading: false,
                    boards: boards,
                    totalSize: response.data.payload.pagination.total,
                })

            })
            .catch(error => {

                if (error && error.response && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false,
                        data: []
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }

            })
    }

    const goToPage = ((page) => {
        setState({
            ...state,
            page: page
        })
    });

    const {Search} = Input;
    const [timer, setTimer] = useState(null)


    const setSearch = (event) => {
        var searchText = event.target.value;

        if (timer != null) {
            clearTimeout(timer);
        }

        setTimer(
            setTimeout(() => {
                getBoards(searchText)
            }, 300)
        )
    }

    return (
        <div className={"search-board-component"}>

            {state.error && <Alert message={state.error} type="error" closable className="notification"/>}

            <div className="search-actions">
                <Search placeholder="Search board by name" onChange={(e) => setSearch(e)}/>
            </div>

            {state.loading && <SpinnerLoading animation="border" role="status"/>}
            {state.boards &&
            <div>
                <table className="table select-board-table">
                    <thead>
                    <tr>
                        <th scope="col" style={{color: "black"}}>Name</th>
                    </tr>
                    </thead>
                    <tbody>
                    {state.boards.map((board, index) =>
                        <tr className="board-row" key={index} onClick={() => selectedBoard(board)}>
                            <td className="board-name-row">
                                <BoardIcon board={board} color={board.color}/>
                                <div className="vertical-div">
                                    <div className="d-flex">
                                        <div className="name">{board.name}</div>
                                    </div>
                                    <div className="visibility">{board.visibility}</div>
                                </div>

                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
                <UsersPagination
                    sizePerPage={state.sizePerPage}
                    totalSize={state.totalSize}
                    page={state.page}
                    goToPage={goToPage}
                />
            </div>
            }

        </div>
    )
}

export default AutomaticSearchBoard
