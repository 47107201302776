import React, {useEffect, useState} from 'react'
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import {API_USERS} from '../../constants/index';
import clientAPI from "../../service/ApiService"
import {Controller} from "react-hook-form";
import {toast} from 'react-toastify';
import {Form} from 'react-bootstrap';

function SelectUsersField({ title, control, errors, defaultUsers }) {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        loadingUsers: false,
        defaultValues: defaultUsers && defaultUsers.length > 0 ? defaultUsers.map(function(user){ return {value: user.ID, label: (user.name +  " " + user.surname)}} ) : [],
    }

    useEffect(() => {
        setState({...state, 
            defaultValues: defaultUsers && defaultUsers.length > 0 ? defaultUsers.map(function(user){ return {value: user.ID, label: (user.name +  " " + user.surname)}} ) : [],
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultUsers]);

    const [state, setState] = useState(initialState)
    const animatedComponents = makeAnimated();

    const promiseOptions = inputValue =>
        new Promise(resolve => {
            setState({
                ...state,
                error: null,
                loadingUsers: true,
            })

            clientAPI.get(API_USERS + "?page=1&limit=20&search=" + inputValue)
                .then(response => {

                    setState({
                        ...state,
                        error: null,
                        loadingUsers: false
                    })

                    let users = response.data.payload.users;
                    var options = [];
                    if(!users || users.length === 0){
                        resolve(options);
                        return
                    }
                    users.map(function (user) {
                        options.push({ value: user.ID, label: (user.name +  " " + user.surname) });
                        return user;
                    })

                    resolve(options);
                })
                .catch(error => {
                    setState({
                        ...state,
                        loadingUsers: false,
                    })
                    if (error && error.response && error.response.data.statusCode.msg) {

                    } else {
                        toast.error('Error connecting with the server, check your connection or contact support');
                    }

                })
        });

    return (
        <div>
            <Form.Group controlId="formBasicUsers">
                <Form.Label>{title}</Form.Label>

                <Controller
                    name="users"
                    as={AsyncSelect}
                    isMulti
                    components={animatedComponents}
                    isLoading={state.loadingUsers}
                    loadOptions={promiseOptions}
                    control={control}
                    closeMenuOnSelect={false}
                    defaultOptions={true}
                    className="users-multi-select"
                    classNamePrefix="users-select"
                    defaultValue={state.defaultValues}
                    rules={{ required: false }}
                />

                {errors.users && (
                    <span className="alert-danger">Invalid users</span>
                )}
            </Form.Group>
        </div>
    )
}

export default SelectUsersField
