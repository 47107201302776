import React, {useState, useEffect, useContext} from 'react'
import {Form} from 'react-bootstrap';
import {Controller, useForm} from "react-hook-form";
import {API_SAVE_PROFILE, API_GET_PROFILE} from '../../constants/index';
import clientAPI from "../../service/ApiService"
import {Context} from '../../state/store'
import {toast} from 'react-toastify';
import UploadPicture from './UploadPicture';
import {Button, Input, Alert} from 'antd';
import { USER_GET_PROFILE } from '../../state/user/UserTypes'
import ChangePasswordModal from './ChangePasswordModal'
import ChangeEmailModal from './ChangeEmailModal'

function MyProfilePage() {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        user: null,
        passwordSuccess: false,
        emailSuccess: false,
    }

    const [userState, dispatch] = useContext(Context);
    const [state, setState] = useState(initialState)
    const {handleSubmit, control, watch, errors, setValue} = useForm({
        defaultValues: {
            name: userState.user.name,
            email: userState.user.email,
            surname: userState.user.surname,
        }
    });
    const watchShowName    = watch("name", userState.user.name);
    const watchShowSurname = watch("surname", userState.user.surname);
    const watchShowTitle   = watch("title", "");


    useEffect(() => {
        getUserProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(!state.user) return;

        setValue('name', state.user.name, { shouldValidate: true })
        setValue('surname', state.user.surname, { shouldValidate: true })
        setValue('title', state.user.title, { shouldValidate: true })
        setValue('phone', state.user.phone, { shouldValidate: true })
        setValue('email', state.user.email, { shouldValidate: true })
        setValue('address', state.user.address, { shouldValidate: true })
        setValue('city', state.user.city, { shouldValidate: true })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user]);


    const getUserProfile = () => {

        clientAPI.get(API_GET_PROFILE)
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false,
                })

                setState({...state, user: response.data.payload})
                dispatch({ type: USER_GET_PROFILE, payload: response.data.payload });
            })
            .catch(error => {
                console.log(error)
                if (error && error.response && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false,
                        success: false
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    }

    const onSubmit = values => {
        setState({
            ...state,
            error: null,
            loading: true,
            success: false
        })

        clientAPI.put(API_SAVE_PROFILE, values)
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false,
                    success: true,
                })

            })
            .catch(error => {
                console.log(error)
                setState({
                    ...state,
                    error: error?.response?.data?.statusCode?.msg ?? 'Error connecting with the server, check your connection or contact support',
                    loading: false,
                    success: false
                })
            })
    };

    const changePasswordSuccess = () => {
        setState({
            ...state,
            passwordSuccess: true
        })
    };

    const changeEmailSuccess = () => {
        setState({
            ...state,
            emailSuccess: true
        })
    };

    return (
        <div className="edit-profile-page-container page-inner">
            <div className="edit-profile-page">

                <div className="image-container">

                    { state.user &&
                    <React.Fragment>
                        <div className="image-container1">
                            <UploadPicture user={state.user} />
                            <div className="name-surname">
                                <p className={"name"}>{watchShowName}</p>&nbsp;<p className={"surname"}>{watchShowSurname}</p>
                                <p className={"title"}>{watchShowTitle}</p>
                            </div>
                        </div>

                        <div className="image-container2">
                            <div className="metrics">
                                <div className="metric">
                                    <div className="value">{ state.user.boardsIds.length ?? "" }</div>
                                    <div className="name">Boards</div>
                                </div>
                                <div className="separator" />
                                <div className="metric">
                                    <div className="value">{ state.user.teamIds.length ?? "" }</div>
                                    <div className="name">Teams</div>
                                </div>
                            </div>
                            <div className="actions">
                                <ChangePasswordModal changePasswordSuccess={changePasswordSuccess} />
                                <ChangeEmailModal changeEmailSuccess={changeEmailSuccess} />
                            </div>
                        </div>

                    </React.Fragment>
                    }
                </div>
                <div className="info-container">
                    <h3>Basic information</h3>
                    {state.error && <Alert message={state.error} type="error" closable className="notification"/>}
                    {state.success &&  <Alert message={"Changes have been saved."} type="info" closable className="notification"/>}
                    {state.passwordSuccess && <Alert message="Password changed." closable className="notification" type="success" />}
                    {state.emailSuccess && <Alert message="We have send an email to the new address to confirm it. Please check your inbox." closable className="notification" type="success" />}

                    <div className="separator"></div>

                    <Form onSubmit={handleSubmit(onSubmit)}>

                        <div className="inline-fields">
                            <Form.Group controlId="formBasicName">
                                <Form.Label>Name</Form.Label>
                                <Controller
                                    as={Input}
                                    name='name'
                                    control={control}
                                    placeholder="Name"
                                    rules={{required: true}}
                                />
                                {errors.name && (
                                    <span className="alert-danger">You must specify a name</span>
                                )}
                                <p className="alert-danger">{errors.name && errors.name.message}</p>
                            </Form.Group>

                            <Form.Group controlId="formBasicSurname">
                                <Form.Label>Surname</Form.Label>
                                <Controller
                                    as={Input}
                                    name='surname'
                                    control={control}
                                    placeholder="Surname"
                                    rules={{required: true}}
                                />
                                {errors.surname && (
                                    <span className="alert-danger">You must specify a surname</span>
                                )}
                            </Form.Group>
                        </div>

                        <Form.Group controlId="formBasicTitle">
                            <Form.Label>Title</Form.Label>

                            <Controller
                                as={Input}
                                name='title'
                                control={control}
                                value={state.user ? state.user.title : ""}
                                placeholder="Title"
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>

                            <Controller
                                as={Input}
                                name='email'
                                control={control}
                                placeholder="Email"
                                disabled={true}
                                value={userState.user.email}
                                // rules={{
                                //     pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                // }}
                            />

                            {errors.email && (
                                <span className="alert-danger">Invalid email address</span>
                            )}

                        </Form.Group>

                        <Form.Group controlId="formBasicTitle">
                            <Form.Label>Phone</Form.Label>

                            <Controller
                                as={Input}
                                name='phone'
                                control={control}
                                placeholder="+1 83748956"
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicTitle">
                            <Form.Label>Address</Form.Label>

                            <Controller
                                as={Input}
                                name='address'
                                control={control}
                                placeholder="Address"
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicTitle">
                            <Form.Label>City</Form.Label>

                            <Controller
                                as={Input}
                                name='city'
                                control={control}
                                placeholder="City"
                            />
                        </Form.Group>

                        <Button variant="primary" type="primary" htmlType="submit" className="submit"
                                loading={state.loading}>
                            Save Changes
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default MyProfilePage
