import React, {useState} from 'react';
import clientAPI from "../../service/ApiService";
import {API_USERS, API_GET_ACCOUNT} from "../../constants";
import {toast} from "react-toastify";
import {Alert, Button} from "antd";
import {Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import makeAnimated from "react-select/animated/dist/react-select.esm";

function AdminUsers({admins}) {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        company: null,
        admins: null,
        loadingUsers: false,
        adminsError: false,
    }

    const [state, setState] = useState(initialState)
    const animatedComponents = makeAnimated();
    const {handleSubmit, control, errors} = useForm({});

    const promiseOptions = inputValue =>
        new Promise(resolve => {
            setState({
                ...state,
                error: null,
                loadingUsers: true,
            })

            clientAPI.get(API_USERS + "?page=1&limit=20&search=" + inputValue)
                .then(response => {
                    let users = response.data.payload.users;
                    var options = [];
                    users.map(function (user) {
                        options.push({value: user.ID, label: user.email});
                        return user;
                    })

                    setState({
                        ...state,
                        error: null,
                        loadingUsers: false
                    })
                    resolve(options);
                })
                .catch(error => {
                    if (error && error.response && error.response.data.statusCode.msg) {
                        setState({
                            ...state,
                            loadingUsers: false,
                        })
                    } else {
                        toast.error('Error connecting with the server, check your connection or contact support');
                    }

                })
        });

    const onSubmit = values => {

        if (values.admins == null || values.admins.length <= 0) {
            setState({
                ...state,
                adminsError: true
            })
            return false;
        }

        let payload = {
            ...values,
            admins: values.admins.map(function (user) {
                return user.value
            })
        }

        setState({
            ...state,
            error: null,
            loading: true,
            success: false,
        })

        clientAPI.put(API_GET_ACCOUNT + "/admin/admin-users", payload)
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false,
                    success: true,
                    adminsError: false
                })
            })
            .catch(error => {
                if (error && error.response && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false,
                        success: false,
                        adminsError: false
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    };

    return (
        <div className="info-container">
            {state.success &&
            <Alert style={{marginBottom: 12}} message={"Changes have been saved."} type="info" closable
                   className="notification"/>
            }

            <div className="admin-users">

                <div className="inline-fields">
                    <h5>Admin users</h5>
                </div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {state.adminsError === true &&
                    <Alert style={{marginBottom: 12}} message={"At least one admin required."} type="error" closable
                           className="notification"/>
                    }

                    {admins &&

                    <Form.Group controlId="formBasicUsers">
                        <Form.Label>Manage admin users</Form.Label>

                        <Controller
                            name="admins"
                            defaultValue={admins}
                            as={AsyncSelect}
                            isMulti
                            components={animatedComponents}
                            isLoading={state.loadingUsers}
                            loadOptions={promiseOptions}
                            control={control}
                            closeMenuOnSelect={false}
                            defaultOptions={true}
                            className="users-multi-select"
                            classNamePrefix="users-select"
                            rules={{required: false}}
                        />

                        {errors.users && (
                            <span className="alert-danger">Invalid users</span>
                        )}
                    </Form.Group>

                    }
                    <div className="actions">
                        <Button variant="primary" type="primary" htmlType="submit" className="submit"
                                loading={state.loading}>
                            Save Changes
                        </Button>
                    </div>

                </Form>
            </div>
        </div>
    );
}

export default AdminUsers;