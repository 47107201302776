import React, {useState, useEffect} from 'react'
import { Button} from 'antd';
import { API_UNINVITE } from '../../constants/index';
import {SpinnerLoading} from '../Style/styles.css'
import clientAPI from "../../service/ApiService"
import { toast } from 'react-toastify';

function UninviteButton(props) {

    const initialState = {
        loading: false,
        email: props.row.email,
    }
    const [state, setState] = useState(initialState)

    useEffect(() => {
        setState({
            ...state,
            email: props.row.email,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);
    
    const uninviteUser = () =>{
        setState({
            ...state,
            loading: true,
        })
        clientAPI.post(API_UNINVITE, {"email": state.email})
        .then(response => {
            setState({
                ...state,
                loading: false,
            })
            props.refresh()
        })
        .catch(error => {
            if(error && error.response && error.response.data.statusCode.msg){
                setState({
                    ...state,
                    loading: false,
                })
            }else{
                toast.error('Error connecting with the server, check your connection or contact support');
            }
        })
    }

    return (
        <div>
            {state.loading && <SpinnerLoading animation="border" role="status"></SpinnerLoading>}
            {!props.row.enabled && <Button danger variant="danger" onClick={uninviteUser}>Uninvite</Button>}
        </div>
    )
}

export default UninviteButton
