import React, {useContext, useState, useEffect} from 'react'
import {BoardsContext} from "../../../state/boardsStore";
import clientAPI from "../../../service/ApiService";
import {toast} from "react-toastify";
import {API_USER} from "../../../constants";
import DeleteButton from "./DeleteButton";
import CopyLinkButton from "./CopyLinkButton";
import {formatDate} from "../../../service/DateService";

function SharedLinkRow({ index, link, refresh }) {

    const [boardsState] = useContext(BoardsContext);
    const [user, setUser] = useState(null);

    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUser = () => {
        clientAPI.get(API_USER + "/" + link.userId)
            .then(response => {
                setUser(response.data.payload)
            })
            .catch(error => {
                toast.error('Error connecting with the server, check your connection or contact support');
            })
    }

    return (
        <tr className="link-row">
            <React.Fragment>
                <td className="user">
                    {index + 1}
                </td>
                <td className="align">
                    {link && link.public && <p>No</p>}
                    {link && !link.public && <p style={{color: "#1890ff"}}>Protected</p>}
                </td>
                {boardsState?.width > 768 &&
                <React.Fragment>
                    <td className="user">
                        {user &&
                        user.name + " " + user.surname
                        }
                    </td>
                    <td className="align">
                        {link &&
                        formatDate(link.createdAt)
                        }
                    </td>
                </React.Fragment>
                }
                <td className="align d-flex">
                    <CopyLinkButton link={link}/>
                    <DeleteButton link={link} refresh={refresh}/>
                </td>
            </React.Fragment>
        </tr>
    )
}

export default SharedLinkRow
