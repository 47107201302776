import React, {useContext, useState} from 'react'
import {Dropdown, Menu} from 'antd';
import clientAPI from "../../service/ApiService"
import {API_COLUMN_ID, API_URL} from '../../constants';
import {generatePath} from 'react-router';
import EdiText from 'react-editext'
import {IoIosArrowDropdown} from "react-icons/io";
import {toast} from 'react-toastify';
import {sortableHandle} from 'react-sortable-hoc';
import {GiHamburgerMenu} from "react-icons/gi";
import {ResizableBox} from 'react-resizable';
import {BoardContext} from "../../state/boardStore";
import {
    ADD_COLUMN_AND_ROWS,
    CHANGE_COLUMN_NAME,
    CHANGE_COLUMN_WIDTH,
    DELETE_COLUMN
} from "../../state/boards/BoardTypes";
import {DEFAULT_CELL_WIDTH, GENERIC_ERR_MSG, MIN_CELL_WIDTH} from "../../constants/constants";

function ColumnHeader(props) {

    const initialState = {
        loading: false,
        error: null,
        table: props.table.id,
        boardId: props.boardId,
        path: API_URL + generatePath(API_COLUMN_ID, {
            board: props.boardId,
            table: props.table.id,
            column: props.column.id
        })
    }

    const [state, setState] = useState(initialState)
    const dispatchBoard = useContext(BoardContext)[1];

    const editColumnName = val => {
        clientAPI.put(state.path, {"name": val})
            .then(response => {
                setState({
                    ...state,
                    loading: false,
                })
                dispatchBoard({type: CHANGE_COLUMN_NAME, payload: {tableId: props.table.id ,columnId: props.column.id, name: val}});
            })
            .catch(error => {
                setState({
                    ...state,
                    loading: false,
                })
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    const deleteColumn = val => {

        clientAPI.delete(state.path)
            .then(response => {
                setState({
                    ...state,
                    loading: false,
                })
                dispatchBoard({type: DELETE_COLUMN, payload: {tableId: props.table.id ,columnId: props.column.id}});
            })
            .catch(error => {
                setState({
                    ...state,
                    loading: false,
                })
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    const duplicateColumn = () => {
        clientAPI.post(state.path + "/duplicate")
            .then(response => {
                // props.addColumn(response.data.payload)
                // We are forced to refresh the board as we also have to update the rows values
                // console.log(response);
                dispatchBoard({type: ADD_COLUMN_AND_ROWS,
                    payload:{
                        tableId: props.table.id,
                        column:  response.data.payload.column,
                        rows:    response.data.payload.rows,
                    }
                });
            })
            .catch(error => {
                setState({
                    ...state,
                    loading: false,
                })
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    const DragHandle = sortableHandle(() => <GiHamburgerMenu className="column-drag-handler" />);

    // Resize columns
    const onResize = (event, {element, size, handle}) => {

        clientAPI.put(state.path, {"width": size.width})
            .then(response => {
                setState({
                    ...state,
                    loading: false,
                })
                dispatchBoard({type: CHANGE_COLUMN_WIDTH, payload: {tableId: props.table.id ,columnId: props.column.id, width: size.width}});
            })
            .catch(error => {
                setState({
                    ...state,
                    loading: false,
                })
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    };

    const menu = (
        <Menu className={"edit-column-dropdown"} inlineIndent={0} >
            <Menu.Item onClick={() => duplicateColumn()}>Duplicate</Menu.Item>
            <Menu.Divider/>
            <Menu.Item className={"delete-column"} onClick={() => {
                if (window.confirm('Are you sure you wish to delete this column?')) deleteColumn()
            }}>Delete</Menu.Item>
        </Menu>
    );

    return (
        <ResizableBox className="box resizable-column-header"
                      height={45}
                      width={(props.column.width) ? props.column.width : DEFAULT_CELL_WIDTH}
                      minConstraints={[MIN_CELL_WIDTH, 45]}
                      maxConstraints={[650, 45]}
                      axis="x"
                      resizeHandles={['e']}
                      onResizeStop={onResize}>

            <div className={`column-header column-type-${props.column.type.type}`}>
                {/* <div style={{ width: "30px" }}></div> */}
                <DragHandle />
                <div>
                    <EdiText
                        submitOnEnter
                        cancelOnEscape
                        submitOnUnfocus
                        hideIcons={true}
                        editOnViewClick={true}
                        showButtonsOnHover={false}
                        editButtonClassName="hide"
                        saveButtonClassName="hide"
                        cancelButtonClassName="hide"
                        viewContainerClassName="column-name"
                        inputProps={{
                            style: {
                                backgroundColor: '#FAFAFA',
                                width: DEFAULT_CELL_WIDTH
                            },
                        }}
                        type="text"
                        value={props.column.name}
                        onSave={editColumnName}
                        className="edit-column-name"/>
                </div>

                <Dropdown overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()} href="/#">
                        <button className="edit-column-action"><IoIosArrowDropdown/></button>
                    </a>
                </Dropdown>

            </div>

        </ResizableBox>
    )
}

export default ColumnHeader
