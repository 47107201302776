import React, {useEffect, useState} from 'react'

function PreviewStatusCell({row, column}) {

    const initialState = {
        entry: null,
    }

    const [state, setState] = useState(initialState)

    useEffect(() => {

        var rowEntry = row.entries.find(function(obj){
            return obj.column === column.id
        })

        var entryAux = column.type.metadata.find(function(obj){
            let v = "";
            if(rowEntry && rowEntry.hasOwnProperty("value")){
                v = rowEntry.value;
            }
            return obj.id === v
        })

        setState({
            ...state,
            entry: entryAux !== undefined ? entryAux : "",
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="cell-entry status-cell">
            { state.entry != null &&
                <p style={{backgroundColor:state.entry.color }}>{state.entry.name}</p>
            }
        </div>
    )
}

export default PreviewStatusCell
