import React, {useEffect, useState} from 'react';
import {Form} from "react-bootstrap";
import {Alert, Button, Input} from "antd";
import {Controller, useForm} from "react-hook-form";
import clientAPI from "../../service/ApiService";
import {API_GET_ACCOUNT} from "../../constants";

function CompanyInfo({company}) {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        passwordSuccess: false,
        emailSuccess: false,
        loadingUsers: false,
    }

    const [state, setState] = useState(initialState)
    const {handleSubmit, control, errors, setValue} = useForm({});

    useEffect(() => {
        if (!company) return;

        setValue('name', company.name, {shouldValidate: true})
        setValue('email', company.email, {shouldValidate: true})
        setValue('domain', company.domain, {shouldValidate: false})
        setValue('address', company.address, {shouldValidate: false})
        setValue('city', company.city, {shouldValidate: false})
        setValue('country', company.country, {shouldValidate: false})
        setValue('postalCode', company.postalCode, {shouldValidate: false})
        setValue('phone', company.phone, {shouldValidate: false})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);

    const onSubmit = values => {

        setState({
            ...state,
            error: null,
            loading: true,
            success: false,
        })

        clientAPI.put(API_GET_ACCOUNT + "/admin", values)
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false,
                    success: true,
                    adminsError: false
                })
            })
            .catch(error => {
                console.log(error)
                setState({
                    ...state,
                    error: error?.response?.data?.statusCode?.msg ?? 'Error connecting with the server, check your connection or contact support',
                    loading: false,
                    success: false
                })
            })
    };

    return (
        <div className="info-container">
            <Form onSubmit={handleSubmit(onSubmit)}>

                {state.success &&
                <Alert style={{marginBottom: 12}} message={"Changes have been saved."} type="info" closable
                       className="notification"/>
                }

                <div className="company-info">
                    <h5>Company information</h5>
                    {state.error &&
                    <Alert message={state.error} type="error" closable className="notification"/>}

                    <div className="inline-fields">
                        <Form.Group controlId="formBasicName">
                            <Form.Label>Name</Form.Label>
                            <Controller
                                as={Input}
                                name='name'
                                control={control}
                                placeholder="Name"
                                rules={{required: true}}
                            />
                            {errors.name && (
                                <span className="alert-danger">You must specify a name</span>
                            )}
                            <p className="alert-danger">{errors.name && errors.name.message}</p>
                        </Form.Group>

                        <Form.Group controlId="formBasicTitle">
                            <Form.Label>Email address</Form.Label>

                            <Controller
                                as={Input}
                                name='email'
                                control={control}
                                placeholder="Email"
                                rules={{
                                    pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                    required: true,
                                }}
                            />

                            {errors.email && (
                                <span className="alert-danger">Invalid email address</span>
                            )}
                        </Form.Group>
                    </div>

                    <div className="inline-fields">
                        <Form.Group controlId="formBasicTitle">
                            <Form.Label>Domain</Form.Label>

                            <Controller
                                as={Input}
                                name='domain'
                                control={control}
                                placeholder="Domain"
                            />
                        </Form.Group>

                    </div>

                    <div className="separator"></div>

                    <Form.Group controlId="formBasicTitle" className={"address"}>
                        <Form.Label>Address</Form.Label>

                        <Controller
                            as={Input}
                            name='address'
                            control={control}
                            placeholder="Address"
                        />
                    </Form.Group>

                    <div className="inline-fields">
                        <Form.Group controlId="formBasicTitle">
                            <Form.Label>City</Form.Label>

                            <Controller
                                as={Input}
                                name='city'
                                control={control}
                                placeholder="City"
                            />
                            <p className="alert-danger">{errors.city && errors.city.message}</p>
                        </Form.Group>

                        <Form.Group controlId="formBasicTitle">
                            <Form.Label>Country</Form.Label>

                            <Controller
                                as={Input}
                                name='country'
                                control={control}
                                placeholder="Country"
                            />
                            <p className="alert-danger">{errors.country && errors.country.message}</p>
                        </Form.Group>
                    </div>

                    <div className="inline-fields">
                        <Form.Group controlId="formBasicTitle">
                            <Form.Label>Postal Code</Form.Label>

                            <Controller
                                as={Input}
                                name='postalCode'
                                control={control}
                                placeholder="Postal Code"
                            />
                            <p className="alert-danger">{errors.postalCode && errors.postalCode.message}</p>
                        </Form.Group>

                        <Form.Group controlId="formBasicTitle">
                            <Form.Label>Phone</Form.Label>

                            <Controller
                                as={Input}
                                name='phone'
                                control={control}
                                placeholder="Phone"
                            />
                            <p className="alert-danger">{errors.phone && errors.phone.message}</p>
                        </Form.Group>
                    </div>

                    <div className="actions">
                        <Button variant="primary" type="primary" htmlType="submit" className="submit"
                                loading={state.loading}>
                            Save Changes
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
}

export default CompanyInfo;