import React, {useState} from 'react';
import {Alert, Button, Dropdown, Menu} from "antd";
import SelectUsersField from "./SelectUsersField";
import SelectColumnsField from "./SelectColumnsField";
import clientAPI from "../../../service/ApiService";
import {generatePath} from 'react-router';
import {toast} from "react-toastify";
import {API_TABLE_ID, API_URL} from "../../../constants";
import AutomationCreateRow from "./AutomationCreateRow";

function AutomationNew({board, table, setView}) {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        trigger: null,
        action: null,
        allColumnsSelected: true,
        selectedColumns: [],
        selectedUsers: [],
        notifyAllUsers: true,
        board: null,
        table: null,
        rowName: null,
    }
    const [state, setState] = useState(initialState)

    // Triggers
    const menuTrigger = (
        <Menu>
            <Menu.Item key="0" onClick={() => setState({...state, trigger: "setValue"})}>a value changes</Menu.Item>
            <Menu.Item key="1" onClick={() => setState({...state, trigger: "createdRow"})}>a new row is added</Menu.Item>
        </Menu>
    );

    const columnTrigger = (
        <Menu>
            <Menu.Item key="0" onClick={() => setState({...state, allColumnsSelected: true})}>any column</Menu.Item>
            <Menu.Item key="1" onClick={() => setState({...state, allColumnsSelected: false})}>selected columns</Menu.Item>
        </Menu>
    );

    // Actions
    const menuActions = (
        <Menu>
            <Menu.Item key="0"
                       onClick={() => setState({...state, action: "notification", board: null, table: null, rowName:null})}>
                notify via email</Menu.Item>
            <Menu.Item key="1" onClick={() => setState({...state, action: "createRow"})}>create row</Menu.Item>
        </Menu>
    );

    const menuNotifications = (
        <Menu>
            <Menu.Item key="0" onClick={() => setState({...state, notifyAllUsers: true})}>all users from this board</Menu.Item>
            <Menu.Item key="1" onClick={() => setState({...state, notifyAllUsers: false})}>users</Menu.Item>
        </Menu>
    );


    // Save
    const save = () => {

        setState({
            ...state,
            error: null,
            loading: true,
            success: false,
        })

        let path = API_URL + generatePath(API_TABLE_ID, {
            board: board.id,
            table: table.id,
        });

        let values = {
            "type"    : state.trigger,
            "columns" : (state.allColumnsSelected ? null : state.selectedColumns),
            "actions" : [
                {
                    "type"     : state.action,
                    "users"    : (state.notifyAllUsers ? null : state.selectedUsers),
                    "message"  : "",
                    "rowName"  : state.rowName,
                    "rowBoard" : state.board?.id,
                    "rowTable" : state.table?.id,
                    "entries"  : null,
                }
            ],
            "enabled" : true,
        }

        clientAPI.post(path + "/automation", values)
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false,
                    success: false,
                })
                setView("list")
            })
            .catch(error => {
                if (error && error.response && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false,
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    };

    return (
        <div className={"new-automation"}>
            {state.error && <Alert message={state.error} type="error" closable className="notification" />}
            <div className="when">
                <p>When&nbsp;</p>
                <Dropdown overlay={menuTrigger} trigger={['click']}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()} href="/#">
                        {(() => {
                            switch (state.trigger) {
                                case "setValue":
                                    return "a value changes";
                                case "createdRow":
                                    return "a new row is added";
                                default:
                                    return "select"
                            }
                        })()}
                    </a>
                </Dropdown>
                {state?.trigger === "setValue" &&
                <React.Fragment>
                    <p className={"on"}>&nbsp;on&nbsp;</p>
                    <Dropdown overlay={columnTrigger} trigger={['click']}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()} href="/#">
                            {state.allColumnsSelected ? "any column" : "selected columns"}
                        </a>
                    </Dropdown>
                </React.Fragment>
                }
            </div>

            {state?.trigger === "setValue" && state?.allColumnsSelected === false &&
            <div className="column-selection">
                <SelectColumnsField
                    columns={table?.columns}
                    selectColumns={(columns) => setState({...state, selectedColumns: columns})}/>
            </div>
            }

            {state?.trigger &&
            <div className={"action"}>
                <p>do&nbsp;</p>
                <Dropdown overlay={menuActions} trigger={['click']}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()} href="/#">
                        {(() => {
                            switch (state.action) {
                                case "notification":
                                    return "notify via email";
                                case "createRow":
                                    return "create a new row";
                                default:
                                    return "select"
                            }
                        })()}
                    </a>
                </Dropdown>

                {state.trigger && state.action === "notification" &&
                <React.Fragment>
                    <p>&nbsp;to&nbsp;</p>
                    <Dropdown overlay={menuNotifications} trigger={['click']}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()} href="/#">
                            {state.notifyAllUsers ? "all users from this board" : "users"}
                        </a>
                    </Dropdown>

                </React.Fragment>
                }
                {state.board &&
                <React.Fragment>
                    <p>&nbsp;to board&nbsp;</p>
                    <span className={"blue"}>{state.board.name}</span>
                    {state.table &&
                    <React.Fragment>
                        <p>&nbsp;and table&nbsp;</p>
                        <span className={"blue"}>{state.table.name}</span>
                    </React.Fragment>
                    }
                </React.Fragment>
                }
            </div>
            }

            {/*Select users*/}
            {state?.action === "notification" && state?.notifyAllUsers === false &&
            <div className="users-selection">
                <SelectUsersField
                    defaultUsers={board.users}
                    selectUsers={(users) => setState({...state, selectedUsers: users})}
                />
            </div>
            }

            {/*Create row*/}
            {state.trigger && state.action === "createRow" &&
            <div className={"create-row"}>
                <AutomationCreateRow
                    board={state.board}
                    selectedBoard={(board) => setState({...state, board: board})}
                    table={state.table}
                    selectedTable={(table) => setState({...state, table: table})}
                    rowName={state.rowName}
                    selectedName={(name) => setState({...state, rowName: name})}
                />
            </div>
            }

            <div className="actions-container">
                <Button onClick={()=>setView("list")} className={"back"}>Back</Button>
                <Button variant="primary"
                        disabled={!state.trigger || !state.action}
                        loading={state.loading}
                        type="primary"
                        onClick={()=>save()}
                        className={"save"}>Save
                </Button>
            </div>

        </div>
    );
}

export default AutomationNew;