import React from 'react'

function CopyView({table, setEditMode, body}) {

    return (
        <div className={"copy-view"}>
            {body?.length > 0 ?
                <div dangerouslySetInnerHTML={{ __html: body }} />
            :
                <h4 className={"empty"} onClick={()=>setEditMode(true)}>Empty text</h4>
            }
        </div>
    )
}

export default CopyView