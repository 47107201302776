import styled from 'styled-components';
import {Spinner} from 'react-bootstrap';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

export const Box = styled.div`
    position: relative;
    padding: 1rem;
    border: .2rem solid #ececec;
    border-radius: 8px;
    margin-right: 0;
    margin-left: 0;
    color: #212529;
    min-width: 400px;
    background-color: white;
`;

// A new component based on Button, but with some override styles
export const SpinnerLoading = styled(Spinner)`
  color: black;
  float:right;
`;

