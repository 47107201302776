import React, {useEffect, useState} from 'react'
import clientAPI from "../../../service/ApiService"
import {API_TEMPLATE} from '../../../constants/index';
import {SpinnerLoading} from '../../Style/styles.css'
import {toast} from 'react-toastify';
import {Alert, Input, Radio} from 'antd';
import UsersPagination from '../../Users/UsersPagination'
import TemplateRow from "./TemplateRow";

function TableSearchTemplate({ ttype, selectTemplate }) {

    const initialState = {
        page: 1,
        templates: [],
        sizePerPage: 10,
        totalSize: 0,
        error: null,
        loading: false,
        searchText: "",
        search: null,
        refreshSearch: 0,
        visibility: "public",
    }
    const [state, setState] = useState(initialState)
    const [searchText, setSearchText] = useState("")

    useEffect(() => {
        getTemplates(searchText);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.page, state.sizePerPage, state.visibility]);

    const getTemplates = (search=null) => {

        setState({
            ...state,
            error: null,
            loading: true,
            searchText: search,
        })

        clientAPI.get(API_TEMPLATE + "?visibility="+ state.visibility + "&type=" + ttype + "&page=" + state.page + "&limit=" + state.sizePerPage + (search ? "&search=" + encodeURIComponent(search) : "" ))
            .then(response => {
                let templates = response.data.payload.templates;

                if(!templates || templates.length <= 0 ){
                    setState({...state,
                        templates:[],
                        totalSize: 0,
                        page: state.page > 1 ? state.page-1 : state.page,
                        searchText: search,
                    })
                    return false
                }

                setState({
                    ...state,
                    error: null,
                    loading: false,
                    templates: templates,
                    totalSize: response.data.payload.pagination.total,
                    searchText: search,
                })

            })
            .catch(error => {

                let msg = error?.response?.data?.statusCode?.msg
                setState({
                    ...state,
                    error: msg ?? null,
                    loading: false,
                    users: null,
                    templates: null,
                    pending: null,
                })
                if(!msg)
                    toast.error('Error connecting with the server, check your connection or contact support');

            })
    }

    const goToPage = ((page) => {
        setState({
            ...state,
            page: page
        })
    });

    const [timer, setTimer] = useState(null)


    const setSearch = (event) => {
        var st = event.target.value;
        setSearchText(st)
        if (timer != null) {
            clearTimeout(timer);
        }

        setTimer(
            setTimeout(() => {
                getTemplates(st)
            }, 300)
        )
    }

    const changeVisibility = (value) => {
        setState({
            ...state,
            visibility: value,
        })
    }

    return (
        <div className={"templates-table-container"}>
            {state.error && <Alert message={state.error} type="error" closable className="notification" />}
            <p>Select template</p>
            <div className="search-actions">
                <Input.Group compact className={"filters-bar"}>
                    <Input style={{ flexGrow: 2 }}
                           placeholder="Search by name"
                           value={searchText}
                           className={"search-input"}
                           onChange={(e) => setSearch(e)} />

                    {/*<div className="filter-group">*/}

                        <div className="filter-wrapper">
                            {/*<p>Owned by</p>*/}
                            <Radio.Group style={{marginLeft:'10px'}} value={state.visibility} onChange={(e) => changeVisibility(e.target.value)}>
                                <Radio.Button value="public">Kaiseki.io</Radio.Button>
                                <Radio.Button value="account">Account</Radio.Button>
                                <Radio.Button value="private">Me</Radio.Button>
                            </Radio.Group>
                        </div>
                    {/*</div>*/}

                </Input.Group>

            </div>

            {state.loading && <SpinnerLoading animation="border" role="status" className={"spinner"} />}
            {state.templates &&
            <div>
                <table className="table template-table">
                    <thead>
                    <tr>
                        <th scope="col" style={{ color:"black" }}>Name</th>
                        <th scope="col">Format</th>
                        <th scope="col">Owner</th>
                    </tr>
                    </thead>
                    <tbody>
                    {state.templates.map((template, index) =>
                        <TemplateRow key={index}
                                     template={template}
                                     visibility={state.visibility}
                                     refresh={getTemplates}
                                     onClick={()=>selectTemplate(template)}
                        />
                    )}
                    </tbody>
                </table>
                <UsersPagination
                    sizePerPage={state.sizePerPage}
                    totalSize={state.totalSize}
                    page={state.page}
                    goToPage={goToPage}
                />
            </div>
            }

        </div >
    )
}

export default TableSearchTemplate
