import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../../state/store";
import {LAUNCH_ONBOARD_DONE} from "../../state/user/UserTypes";
import Intro from "./Pages/Intro";
import Page1 from "./Pages/Page1";
import Page2 from "./Pages/Page2";
import EndPage from "./Pages/EndPage";

function OnboardingPage(){

    const [page, setPage] = useState(0)
    const [state, setState] = useState({
        boardName: "board name",
        table: null,
        copy: null,
        rows: [],
    })
    const dispatch = useContext(Context)[1];

    const prev = () => {
        setPage(page - 1);
    }

    const next = () => {
        setPage(page + 1);
    }

    useEffect(()=>{
        if(state.table != null){
            setPage(page + 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    const done = (boardName, table, copy, rows) => {
        setState({...state, boardName:boardName, copy: copy, table: table, rows: rows});
    }

    return (
        <div className={"onboarding-page"}>
            {(() => {
                switch (page) {
                    case 0:
                        return <Intro next={() => next()}/>;
                    case 1:
                        return <Page1 next={() => next()} prev={() => prev()} />;
                    case 2:
                        return <Page2 done={(boardName, table, body, rows) => done(boardName, table, body, rows)} prev={() => prev()} />;
                    case 3:
                        return <EndPage boardName={state.boardName} table={state.table} copy={state.copy} rows={state.rows} />;
                    default:
                        return <div>Default</div>;
                }
            })()}

            <button className={"skip"}
                    onClick={() => {
                        if (window.confirm('Are you sure you want to skip the tutorial? You can always come back later on your profile.'))
                            dispatch({ type: LAUNCH_ONBOARD_DONE})
                    }}
            >
                Skip tutorial
            </button>
        </div>
    );
};

export default OnboardingPage;