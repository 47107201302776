import React from 'react'
import RegisterPanel from '../Register/RegisterPanel'
import LoginPanel from '../Login/LoginPanel'
import ForgetPanel from '../Forget/ForgetPanel'
import { Drawer } from 'antd';

function LandingDrawer({ visible, close, type, goToRegister, goToLogin, goToForget }) {

    return (
        <div className="landing-panel">
            <Drawer
                title=""
                placement="right"
                className="landing-drawer"
                closable={true}
                onClose={ () => close() }
                visible={visible}
                width="500px"
            >
                <div className="center">
                    { type && type === "register" &&
                       <RegisterPanel goToLogin={goToLogin} goToRegister={goToRegister} goToForget={goToForget}/>
                    }

                    { type && type === "login" &&
                       <LoginPanel goToLogin={goToLogin} goToRegister={goToRegister} goToForget={goToForget}/>
                    }

                    { type && type === "forget" &&
                       <ForgetPanel goToLogin={goToLogin} goToRegister={goToRegister} goToForget={goToForget}/>
                    }
                </div>
            </Drawer>
        </div >
    )
}

export default LandingDrawer
