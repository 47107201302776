import React from 'react'

const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function TemplateRow({ template, refresh, visibility, onClick }) {
    return (
        <tr className="template-row" onClick={onClick}>
            <td className="template-name-row">
                {template.name}
            </td>
            <td>
                {capitalize(template.type)}
            </td>
            <td>
                {template.visibility === "public" && "Kaiseki.io"}
                {template.visibility === "account" && "Account"}
                {template.visibility === "private" && "Me"}
            </td>
        </tr>
    )
}

export default TemplateRow
