import React, {useState, useContext} from 'react'
import {Dropdown, Menu} from 'antd';
import clientAPI from "../../service/ApiService"
import {API_COLUMN, API_URL} from '../../constants';
import {generatePath} from 'react-router';
import {FiPlusCircle} from "react-icons/fi";
import {AiOutlineCalculator, AiOutlineUnorderedList} from "react-icons/ai";
import {GoTextSize} from "react-icons/go";
import {MdDateRange, MdPersonOutline} from "react-icons/md";
import {toast} from 'react-toastify';
import {ADD_COLUMN} from "../../state/boards/BoardTypes";
import {BoardContext} from "../../state/boardStore";
import {GENERIC_ERR_MSG} from "../../constants/constants";

function AddColumn(props) {

    const initialState = {
        loading: false,
        error: null,
        table: props.table,
        boardId: props.boardId,
        path: API_URL + generatePath(API_COLUMN, {
            board: props.boardId,
            table: props.table.id
        })
    }

    const dispatchBoard = useContext(BoardContext)[1];
    const [state, setState] = useState(initialState)
    const addColumn = val => {
        var payload;
        if(val === "status"){
            payload = {
                "name" : "Status",
                "type" : "status",
                "metadata" : [
                    {
                        name: "Done",
                        color: "#43a047",
                        position: 1,
                    }, 
                    {
                        name: "Working on it",
                        color: "#ffb300",
                        position: 2,
                    }, 
                    {
                        name: "Pending",
                        color: "#1e88e5",
                        position: 3,
                    }, 
                    {
                        name: "Stuck",
                        color: "#e53935",
                        position: 4,
                    }, 
                    {
                        name: "",
                        color: "#fb8c00",
                        position: 5,
                    }, 
                    {
                        name: "",
                        color: "#757575",
                        position: 6,
                    }, 
                    {
                        name: "",
                        color: "#5e35b1",
                        position: 7,
                    }, 
                    {
                        name: "",
                        color: "#546e7a",
                        position: 8,
                    }
                ]
            }
        }else if(val === "text"){
            payload = {
                "name" : "Text",
                "type" : "text"
            }
        }else if(val === "datetime"){
            payload = {
                "name" : "Datetime",
                "type" : "datetime"
            }
        }else if(val === "number"){
            payload = {
                "name" : "Number",
                "type" : "number"
            }
        }else if(val === "people"){
            payload = {
                "name" : "Owner",
                "type" : "people"
            }
        }

        clientAPI.post(state.path, payload)
        .then(response => {
            setState({
                ...state,
                loading: false,
            })
            // dispatchBoard({type: UPDATE_BOARD, payload:response.data.payload});
            dispatchBoard({type: ADD_COLUMN, payload: {tableId: props.table.id ,column: response.data.payload}});
        })
        .catch(error => {
            setState({
                ...state,
                loading: false,
            })
            toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
        })
    }

    const menu = (
        <Menu className={"add-row-container"} inlineIndent={0} >
            <Menu.Item onClick={() => addColumn("status")}><AiOutlineUnorderedList/>Status</Menu.Item>
            <Menu.Item onClick={() => addColumn("text")}><GoTextSize/>Text</Menu.Item>
            <Menu.Item onClick={() => addColumn("datetime")}><MdDateRange/>Date</Menu.Item>
            <Menu.Item onClick={() => addColumn("number")}><AiOutlineCalculator/>Number</Menu.Item>
            <Menu.Item onClick={() => addColumn("people")}><MdPersonOutline/>People</Menu.Item>
        </Menu>

    );

    return (    
        <div className="add-row-container">
            <Dropdown overlay={menu} trigger={['click']}>
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()} href="/#">
                    <button className="add-column-action"><FiPlusCircle/></button>
                </a>
            </Dropdown>
        </div>
    )
}

export default AddColumn
