import React from 'react';

const NotFoundPage = () => {
    return (
        <div className={"not-found-page"}>
            <div className="centered">
                <h1>Page not found</h1>
                <p>The page that you are looking doesn't exist or cannot be found, please ensure that the url is correct and try again</p>
            </div>
        </div>
    );
};

export default NotFoundPage;
