import React, {useState} from 'react'
import {API_ASSETS} from '../../constants/index';
import {SpinnerLoading} from '../Style/styles.css'
import clientAPI from "../../service/ApiService"
import { toast } from 'react-toastify';
import {IoIosClose} from "react-icons/io";

function DeleteButton({asset, refresh}) {

    const initialState = {
        loading: false,
    }
    const [state, setState] = useState(initialState)

    const deleteAsset = () =>{
        setState({
            ...state,
            loading: true,
        })
        clientAPI.delete(API_ASSETS + "/" + asset.id)
        .then(response => {
            setState({
                ...state,
                loading: false,
            })
            refresh()
        })
        .catch(error => {
            if(error && error.response && error.response.data.statusCode.msg){
                setState({
                    ...state,
                    loading: false,
                })
            }else{
                toast.error('Error connecting with the server, check your connection or contact support');
            }
        })
    }

    return (
        <div>
            {state.loading && <SpinnerLoading className={"spinner"} animation="border" role="status" />}
            {!state.loading &&
                <IoIosClose className={"delete-asset"} onClick={() => {
                    if (window.confirm('Are you sure you want to delete this asset?'))
                        deleteAsset();
            }} />
            }
        </div>
    )
}

export default DeleteButton
