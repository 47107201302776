import React, {useState, useRef, useEffect, useContext} from 'react';
import {
    PlusOutlined
} from '@ant-design/icons';
import {Button} from "antd";
import {IoMdClose} from "react-icons/io";
import clientAPI from "../../../service/ApiService";
import {ADD_ROW, SET_SINGLE_VALUE} from "../../../state/boards/BoardTypes";
import {toast} from "react-toastify";
import {API_ROW, API_URL} from "../../../constants";
import { generatePath } from 'react-router';
import {BoardContext} from "../../../state/boardStore";

function AddCard({columnId, table, kanbanColumn, boardId, quotesLength}) {

    const [show, setShowField] = useState(false)
    const [value, setValue] = useState("")
    const [loading, setLoading] = useState(false)
    const [boardState, dispatchBoard] = useContext(BoardContext);

    const open = () => {
        setShowField(true);
    }

    const close = () => {
        setShowField(false);
        setValue("");
    }

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    close()
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const save = () => {

        setLoading(true)

        const path = API_URL + generatePath(API_ROW, {
            board: boardId,
            table: table.id
        })

        clientAPI.post(path, {"name" : value})
            .then(response => {
                setShowField(false);
                setValue("");
                setLoading(false);

                const row = response.data.payload;
                row.kanbanPosition = quotesLength + 1;

                dispatchBoard({type: ADD_ROW, payload: {tableId: table.id, row: row}});

                if(columnId !== "unassigned"){

                    // Set the value
                    dispatchBoard({
                        type: SET_SINGLE_VALUE,
                        payload: {tableId: table.id,
                            rowId: row.id,
                            columnId: table.kanbanStatusColumn,
                            value: columnId}
                    });

                    clientAPI.post(API_URL + "/board/" + boardState.board.id +
                        "/table/" + table.id + "/column/" + table.kanbanStatusColumn + "/row/" +  row.id +
                        "/entry" , {"value": columnId})
                        .then(response => {

                        })
                        .catch(error => {
                            setLoading(false)
                            toast.error('Error connecting with the server, check your connection or contact support');
                        })

                    // Set the position
                    clientAPI.post(API_URL + "/board/" + boardState.board.id +
                        "/table/" + table.id + "/kanban/position", [{row: row.id, position:row.kanbanPosition}] )
                        .then(response => {

                        })
                        .catch(error => {
                            toast.error('Error set the positions');
                        })
                }

            })
            .catch(error => {
                setLoading(false)
                toast.error('Error connecting with the server, check your connection or contact support');
            })
    }

    return (
        <div ref={wrapperRef} className={"add-card"}>
            { !show &&
               <button className={"add-button"} onClick={() => open()}><PlusOutlined /> Add another element</button>
            }
            { show &&
                <div>
                    <textarea autoFocus
                              placeholder={"Enter a title for this card"}
                              value={value}
                              name=""
                              id=""
                              cols="30"
                              rows="3"
                              onChange={e => setValue(e.target.value)} />
                    <div className="actions">
                        <Button loading={loading} type="primary" onClick={() => save()} className="cta-create-table">Save</Button>
                        <button className={"close-button"} onClick={() => close()}><IoMdClose /></button>
                    </div>
                </div>
            }
        </div>
    );
}

export default AddCard;