import React, { useState} from 'react'
import TeamsTable from './TeamsTable';
import CreateTeamModal from './CreateTeamModal';

function TeamsPage() {

    const [needReload, setNeedReload] = useState(false)
    const reload = () => {
        setNeedReload(true)
        // console.log("need reload")
    }
    const reloadAck = () => {
        setNeedReload(false)
        // console.log("reload ack")
    }

    return (
        <div className="main-container teams-page page-inner">
            <h3>Teams</h3>
            <div className="actions">
                <CreateTeamModal reload={reload} />
            </div>
            <TeamsTable needReload={needReload} reloadAck={() => reloadAck()} />
        </div>
    )
}

export default TeamsPage
