import React, {useState, useRef, useEffect} from 'react'
import {Form} from 'react-bootstrap';
import {Button, Input} from "antd";
import {Controller, useForm} from "react-hook-form";

function AddRow({addRow}) {

    const initialState = {
        loading: false,
        error: null,
    }

    useEffect(()=>{
        inputRef.current.focus({
            cursor: 'start',
        })
    },[]);

    const inputRef = useRef(null);
    const [state] = useState(initialState)
    const { handleSubmit, control, reset} = useForm();

    const submit = (value,e) => {
        addRow(value);
        reset();
    }

    return (
        <div id={"add-row-id"}>
            <Form inline onSubmit={handleSubmit(submit)} className="add-row-action">
                <Form.Group controlId="formBasic" className={"add-row-action-input"}>
                    <Controller
                        as={<Input autoFocus={true} ref={inputRef} />}
                        name='name'
                        control={control}
                        autoComplete= "off"
                        placeholder="Row name"
                        rules={{required: true}}
                    />
                </Form.Group>
                <Button type="primary" htmlType="submit"  loading={state.loading}>
                    Add
                </Button>
            </Form>
        </div>
    )
}

export default AddRow
