import React, {createContext, useReducer} from "react";
import ConnectedUsersReducer, {connectedUsersInitialState} from './connectedUsers/ConnectedUsersReducer'

//We will need to eventually combine them
const ConnectedUsersStore = ({children}) => {
    const [state, dispatch] = useReducer(ConnectedUsersReducer, connectedUsersInitialState);
    
    return (
        <ConnectedUsersContext.Provider value={[state, dispatch]}>
            {children}
        </ConnectedUsersContext.Provider>
    )
};

export const ConnectedUsersContext = createContext(connectedUsersInitialState);
export default ConnectedUsersStore;