import React, {useState} from 'react'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Button} from "antd";

function CopyEditor({table, setEditMode, body, setBody, done}) {

    const [data, setData] = useState(body ?? "")
    const [loading, setLoading] = useState(false)
    const [ckeditor, setCKEditor] = useState(null)

    const SaveCopy = () => {
        setLoading(true);

        ckeditor.model.change(writer => {
            const e = writer.createElement('caption'); //create the caption element
            writer.insertElement('paragraph', e); //put the paragraph inside
        });
        done(data);
        setEditMode(false);
    }

    return (
        <div className={"copy-editor"}>
            <CKEditor
                editor={ ClassicEditor }
                className="message-editor"
                config={ {
                    // extraPlugins: [ MyCustomUploadAdapterPlugin ],
                    toolbar: ['heading', '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList', '|',
                        'indent',
                        'outdent', '|',
                        'insertTable',
                        // 'imageUpload',
                        // 'imageStyle:full',
                        // 'imageStyle:side',
                        // 'imageStyle:alignLeft',
                        // 'imageStyle:alignCenter',
                        // 'imageStyle:alignRight',
                    ],
                    image: {
                        // Configure the available styles.
                        styles: [
                            'alignLeft', 'alignCenter', 'alignRight'
                        ],
                    },
                    heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
                        ]
                    }
                } }
                onInit={ editor => {
                    setCKEditor(editor)
                    editor.data.set(body ?? "");
                    editor.editing.view.focus();
                    editor.model.change( writer => {
                        writer.setSelection( writer.createPositionAt( editor.model.document.getRoot(), 'end' ) );
                    } );
                } }
                onChange={ ( event, editor ) => {
                    setData(editor.getData())
                } }
            />
            <div className="actions">
                {/*<Button*/}
                {/*    className={"cancel-cta"}*/}
                {/*    onClick={()=>setEditMode(false)}*/}
                {/*>Cancel</Button>*/}
                <Button
                    className={"save-cta"}
                    type="primary"
                    loading={loading}
                    onClick={()=>SaveCopy()}
                >Save</Button>
            </div>
        </div>
    )
}


// class MyUploadAdapter {
//     constructor(loader, boardId, tableId) {
//         this.loader   = loader;
//         this.boardId  = boardId;
//         this.tableId  = tableId;
//     }
//
//     upload() {
//
//         return this.loader.file
//             .then(result => {
//
//                 const formData = new FormData();
//                 formData.append("asset", result);
//                 formData.append("boardId", this.boardId);
//                 formData.append("tableId", this.tableId);
//
//                 return clientAPI.post(API_ASSETS, formData, {
//                     headers: {
//                         'Content-Type': 'multipart/form-data'
//                     }
//                 })
//                     .then(response => {
//                         return ({
//                             default: response.data.payload.thumb
//                         });
//                     })
//                     .catch(error => {
//                         toast.error('Error uploading the image, please contact support');
//                     })
//
//             })
//
//     }
//
// }

export default CopyEditor