import React, {useContext, useEffect, useState} from 'react'
import Table from './Table'
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import arrayMove from 'array-move';
import clientAPI from "../../service/ApiService"
import {API_TABLE_MOVE, API_URL} from '../../constants';
import {generatePath} from 'react-router';
import {toast} from 'react-toastify';
import {SET_TABLES} from "../../state/boards/BoardTypes";
import {BoardContext} from "../../state/boardStore";
import {GENERIC_ERR_MSG} from "../../constants/constants";
import CopyComponent from "./Copy/CopyComponent";
import TableComponent from "./Asset/AssetComponent";

function SortableTables({board, setLoading, startDragging}) {

    const [tables, setTables] = useState([])
    const [isDragging, setIsDragging] = useState(false)
    const dispatchBoard = useContext(BoardContext)[1];

    useEffect(() => {

        // Problem here is that for some reason we need an incremental id for each table that has
        // to be constant, we are using for now position

        setTables(board.tables)
    }, [board.tables]);

    useEffect(() => {
        if(startDragging === 0){return;}
        setIsDragging(true);
    }, [startDragging]);

    function onDragStart() {
        setIsDragging(true);
    }

    function onDragEnd(result) {
        if (!result.destination) {
            setIsDragging(false);
            return;
        }

        if (result.destination.index === result.source.index) {
            setIsDragging(false);
            return;
        }

        let items = arrayMove([...tables], result.source.index, result.destination.index);
        let payload = { "tables" : items.map( o => o.id ) }
        dispatchBoard({type: SET_TABLES, payload: {tables: items}});
        // setIsDragging(false);
        setTables(items)
        const movePath = API_URL + generatePath(API_TABLE_MOVE, {
            board: board.id,
        });
        clientAPI.post(movePath, payload)
            .then(response => {

            })
            .catch(error => {
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })

    }

    return (
        <div>
            { isDragging &&
                <p>Drag and drop the tables from the handler on the left. <span className={"cancel-reorder"} onClick={() => setIsDragging(false)}>Click here when you are done.</span></p>
            }
            <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={()=>onDragStart()}>
                <Droppable droppableId="list">
                    {provided => (
                        <div ref={provided.innerRef} {...provided.droppableProps}
                             className={"droppable-tables"}>
                            {board.id && tables && tables.map((table, index) =>
                                <React.Fragment key={table.id}>
                                    { table.type === "copy"  ?
                                            <CopyComponent
                                                key={index}
                                                table={table}
                                                index={index}
                                                isDragging={isDragging}
                                            />
                                        :
                                        table.type === "assets" ?
                                            <TableComponent
                                                key={index}
                                                table={table}
                                                index={index}
                                                isDragging={isDragging}
                                            />
                                        :
                                            <Table table={table}
                                                   boardId={board.id}
                                                   index={index}
                                                   key={index}
                                                   setLoading={setLoading}
                                                   provided={provided}
                                                   isDragging={isDragging}
                                            />
                                    }
                                </React.Fragment>
                            )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

        </div>
    )
}

export default SortableTables