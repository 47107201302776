import React, {useEffect, useState} from 'react'
import EdiText from 'react-editext'

function TextCell(props) {

    const initialState = {
        loading: false,
        error: null,
        entry: null,
    }

    const [state, setState] = useState(initialState)

    useEffect(() => {
        var entryAux = props.row.entries.find(function(obj){
            return obj.column === props.column.id
        })
        setState({
            ...state,
            entry: entryAux !== undefined ? entryAux.value : "",
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const updateValue = val => {
        console.log("Update value")
    }

    return (
        <div  className="cell-entry">
            { state.entry != null &&
            <EdiText
                submitOnEnter
                cancelOnEscape
                submitOnUnfocus
                hideIcons={true}
                editOnViewClick={true}
                showButtonsOnHover={false}
                editButtonClassName="hide"
                saveButtonClassName="hide"
                cancelButtonClassName="hide"
                viewContainerClassName="row-name"
                inputProps={{
                    style: {
                        backgroundColor: '#FAFAFA',
                        width: "100%"
                    },
                }}
                type="text" value={state.entry} onSave={updateValue} className="edit-entry"/>
            }
        </div>
    )
}

export default TextCell
