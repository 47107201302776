import React, {useContext, useEffect, useRef} from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import { Context } from './state/store';
import ConfirmPage from './components/Public/Confirm/ConfirmPage';
import ChangeEmailPage from './components/Public/ChangeEmail/ChangeEmailPage';
import LandingPage from './components/Public/Landing/LandingPage';
import ResetPasswordPage from './components/Public/Forget/ResetPasswordPage';
import AcceptInvitePage from './components/Public/AcceptInvite/AcceptInvitePage';
import BasePage from './components/Base/BasePage';
import ShareLinkPage from "./components/Public/ShareLink/ShareLinkPage";
import {SET_WIDTH} from "./state/boards/BoardTypes";
import {useResize} from "./hooks/useResize";
import {BoardsContext} from "./state/boardsStore";

function AppRouter() {

    const appLayoutRef = useRef()
    const {width} = useResize(appLayoutRef)
    const dispatchBoards = useContext(BoardsContext)[1];

    useEffect(() => {
        if(width===0)return
        dispatchBoards({type: SET_WIDTH, payload: width});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    const [userState] = useContext(Context);
    const IsAuthenticated = () => {
        return userState.jwtToken && userState.jwtToken.length >= 0
    }

    return (
        <div ref={appLayoutRef}>
            <Router>
                <Switch>
                    <Route exact path="/landing" component={LandingPage} />
                    <Route exact path="/confirm/:token" component={ConfirmPage} />
                    <Route exact path="/change-email/:token" component={ChangeEmailPage} />
                    <Route exact path="/reset/:token" component={ResetPasswordPage} />
                    <Route exact path="/invite/:token" component={AcceptInvitePage} />
                    {/*<Route exact path="/share/:board/:token" component={ShareLinkPage} />*/}
                    {/* Anonymous request with token */}
                    <Route exact path="/boards/:board/:token" component={ShareLinkPage} />
                    <Route path="/">
                        {!IsAuthenticated() ? <Redirect to="/landing"/> : <Route path="/" component={BasePage} /> }
                    </Route>
                </Switch>
            </Router>
        </div>
    )
}

export default AppRouter



