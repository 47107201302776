import React, {createContext, useReducer} from "react";
import BoardReducer, {boardInitialState} from './boards/BoardReducer'

//We will need to eventually combine them
const BoardStore = ({children}) => {
    const [state, dispatch] = useReducer(BoardReducer, boardInitialState);
    
    return (
        <BoardContext.Provider value={[state, dispatch]}>
            {children}
        </BoardContext.Provider>
    )
};

export const BoardContext = createContext(boardInitialState);
export default BoardStore;