import React, {useContext, useState} from 'react'
import clientAPI from "../../service/ApiService"
import {API_ROW, API_URL} from '../../constants';
import {generatePath} from 'react-router';
import {BoardContext} from '../../state/boardStore'
import {Form} from 'react-bootstrap';
import {Button, Input} from "antd";
import {Controller, useForm} from "react-hook-form";
import {SpinnerLoading} from '../Style/styles.css'
import {toast} from 'react-toastify';
import {ADD_ROW, ADD_SUB_ROW} from "../../state/boards/BoardTypes";
import {GENERIC_ERR_MSG} from "../../constants/constants";

function AddRow(props) {

    const initialState = {
        loading: false,
        error: null,
        table: props.table,
        boardId: props.boardId,
        path: API_URL + generatePath(API_ROW, {
            board: props.boardId,
            table: props.table.id
        })
    }

    const dispatchBoard = useContext(BoardContext)[1];
    const [state, setState] = useState(initialState)

    const { handleSubmit, control, reset} = useForm();

    const addRow = (value,e) => {

        const payload = {...value, "parent": props.parentRowId,}

        clientAPI.post(state.path, payload)
        .then(response => {
            setState({
                ...state,
                loading: false,
                error: false,
            })

            if(!props.parentRowId){
                dispatchBoard({type: ADD_ROW, payload: {tableId: props.table.id ,row: response.data.payload}});
            }else{
                dispatchBoard({type: ADD_SUB_ROW, payload: {tableId: props.table.id, rowParentId: props.parentRowId ,row: response.data.payload}});
            }

            e.target.reset();
            reset({
                name:'',
            })
        })
        .catch(error => {
            setState({
                ...state,
                loading: false,
            })
            toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
        })
    }

    return (    
        <div>
            { state.loading && <SpinnerLoading animation="border" role="status" />}
            {/*{ state.error && <Alert variant={"danger"}>{state.error}</Alert>}*/}
            <Form inline onSubmit={handleSubmit(addRow)} className="add-row-action">
                <Form.Group controlId="formBasicEmail" className={"add-row-action-input"}>
                    <Controller
                        as={Input}
                        name='name'
                        control={control}
                        autoComplete= "off"
                        placeholder="Row name"
                        rules={{required: true}}
                    />
                </Form.Group>
                <Button type="primary" htmlType="submit"  loading={state.loading}>
                    Add
                </Button>
            </Form>
        </div>
    )
}

export default AddRow
