import React, { useState, useEffect } from 'react'
import { API_URL, API_TEAMS_ID } from '../../constants/index';
import clientAPI from "../../service/ApiService"
import { toast } from 'react-toastify';
import { generatePath } from 'react-router';
import { Tooltip } from 'antd';
import {
    DeleteTwoTone
} from '@ant-design/icons';

function DeleteTeam({ team, refresh }) {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        path: API_URL + generatePath(API_TEAMS_ID, {
            team: team.id,
        }),
    }

    useEffect(() => {
        setState({
            ...state,
            path: API_URL + generatePath(API_TEAMS_ID, {
                team: team.id,
            }),
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team]);

    const [state, setState] = useState(initialState)
    const deleteTeam = () => {

        setState({
            ...state,
            error: null,
            loading: true
        })

        clientAPI.delete(state.path)
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false,
                    success: true,
                })
                // toast.info('Team deleted');
                refresh();
            })
            .catch(error => {
                if (error && error.response && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    };

    return (
        <Tooltip placement="bottom" title={"Delete team"}>
            <DeleteTwoTone
                className={"delete-team-button"}
                twoToneColor="#ff4d4f"
                style={{ marginLeft: "10px" }}
                onClick={() => { if (window.confirm('Are you sure you want to delete this team?')) deleteTeam() } } />
        </Tooltip>
    )
}

export default DeleteTeam
