import React, {useContext, useEffect, useState} from 'react'
import {Link, Route, Switch, useHistory, useLocation} from "react-router-dom";
import MyProfilePage from '../MyProfile/MyProfilePage'
import AdminPage from '../AdminPage/AdminPage'
import DashboardPage from '../Dashboard/DashboardPage';
import UsersPage from '../Users/UsersPage';
import BoardsPage from '../Boards/BoardsPage';
import clientAPI from "../../service/ApiService"
import {API_BOARD, API_GET_ACCOUNT, API_WS_CONNECT} from "../../constants"
import {USER_GET_ACCOUNT, USER_LOGOUT} from '../../state/user/UserTypes'
import {Context} from '../../state/store';
import {LinkContainer} from 'react-router-bootstrap';
import {Nav, Navbar} from 'react-bootstrap';
import {Dropdown, Layout, Menu} from 'antd';
import {BoardsContext} from '../../state/boardsStore'
import {toast} from 'react-toastify';
import {
    ADD_COLUMN_AND_ROWS,
    ADD_ROW,
    CHANGE_ROW_NAME,
    DELETED_SOME_ROWS,
    DUPLICATED_SOME_ROWS,
    GET_BOARDS,
    OPEN_CREATE_BOARD_MODAL,
    SET_SINGLE_VALUE,
    SET_SOCKET,
    UPDATE_ASSETS,
    UPDATE_BOARD,
    UPDATE_KANBAN_ROW_ORDER,
    UPDATE_ROW_POSITION
} from '../../state/boards/BoardTypes';
import Icon, {
    BarsOutlined,
    CaretDownOutlined,
    FileOutlined,
    HomeOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    PlusCircleOutlined,
    StarFilled,
    TeamOutlined,
    UserOutlined
} from '@ant-design/icons';
import TeamsPage from '../Teams/TeamsPage';
import CustomIcon from "./CustomIcon";
import NotificationsModal from "../Common/NotificationsModal";
import NotFoundPage from "../OtherPages/NotFoundPage";
import CreateBoard from "../Boards/Modals/CreateBoard";
import {BoardContext} from "../../state/boardStore";
import {UPDATE_CONNECTED_USERS} from "../../state/connectedUsers/ConnectedUsersTypes";
import {ConnectedUsersContext} from "../../state/connectedUsersStore";
import {GiHamburgerMenu, HiOutlineTemplate} from "react-icons/all";
import {ReactComponent as Logo} from 'assets/logo-inverse.svg';
import AssetsPage from "../AssetsPage/AssetsPage";
import TemplatesPage from "../Templates/TemplatesPage";
import OnboardingPage from "../Onboarding/OnboardingPage";

function BasePage() {

    const { Content, Sider } = Layout;
    const [userState, dispatch] = useContext(Context);
    const [boardsState, dispatchBoards] = useContext(BoardsContext);
    const location = useLocation();
    const dispatchBoard = useContext(BoardContext)[1];

    const [width, setWidth] = useState(boardsState.width)
    useEffect(() => {
        setWidth(boardsState.width)
        setCollapsed(boardsState.width<768)
    }, [boardsState.width]);

    const [collapsed, setCollapsed] = useState(boardsState.width<768)
    const [state, setState] = useState({
        notificationsCount : 0,
        ticket: null,
    })

    useEffect(() => {
        getBoards();
        getAccount();
        getTicket()

        // dispatch({ type: LAUNCH_ONBOARD});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getBoards();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boardsState.reload]);

    const getAccount = () =>{
        clientAPI.get(API_GET_ACCOUNT)
        .then(response => {
            dispatch({ type: USER_GET_ACCOUNT, payload: response.data.payload });
        })
        .catch(error => {
            console.log("Error loading user profile");
        })
    };

    const getBoards = () =>{

        setState({
            ...state,
            error:null,
            loading: true,
        })

        clientAPI.get(API_BOARD)
        .then(response => {
            setState({
                ...state,
                error:null,
                loading: false,
            })

            let boards = response.data.payload.boards;

            dispatchBoards({type: GET_BOARDS, payload: boards});
        })
        .catch(error => {
            console.log(error)
            let msg = error?.response?.data?.statusCode?.msg
            setState({
                ...state,
                error: msg ?? null,
                loading: false,
                users: null,
                boards: null,
                pending: null,
            })
            if(!msg)
                toast.error('Error connecting with the server, check your connection or contact support');
        })
    }

    const toggle = () => {
        setCollapsed(!collapsed)
    };

    const history = useHistory();
    const logout = () => {
        dispatch({ type: USER_LOGOUT });
        history.push("/");
    };


    // // Sort boards
    // const SortableBoard = SortableElement(({ board, index, ...props }) =>
    //     // <Menu.Item key={board.id} {...props}  className="board-menu-item" icon={ <Icon component={() => (<FaRegCircle style={{color: "red"}}/>)} />}>
    //     <Menu.Item key={index} {...props} index={index} className="board-menu-item" icon={ <CheckCircleTwoTone/> }>
    //         {board.name}
    //         <Link to={"/boards/" + board.id} />
    //     </Menu.Item>
    // );

    // const BoardList = SortableContainer(({ items, ...props }) =>
    //     <div>
    //         {items.map((board, index) =>
    //             // <SortableBoard key={index} {...props} index={index} board={board} />

    //             <Menu.Item key={index} {...props} index={index} className="board-menu-item" icon={ <CheckCircleTwoTone/> }>
    //                 {board.name}
    //                 <Link to={"/boards/" + board.id} />
    //             </Menu.Item>

    //         )}
    //     </div>
    // );

    // const onSortEnd = ({ oldIndex, newIndex }) => {
    //     let items = arrayMove([...state.boards], oldIndex, newIndex);
    //     let payload = { "boards": items.map(o => o.id) }
    //
    //     setState({
    //         ...state,
    //         boards: items
    //     })
    //
    //     clientAPI.post(API_BOARD_MOVE, payload)
    //         .then(response => {
    //             setState({
    //                 ...state,
    //                 loading: false,
    //                 boards: items
    //             })
    //
    //             // dispatchBoard({type: UPDATE_BOARD, payload: newBoard.board});
    //         })
    //         .catch(error => {
    //             if (error && error.response && error.response.data.statusCode.msg) {
    //                 setState({
    //                     ...state,
    //                     error: error.response.data.statusCode.msg,
    //                     loading: false,
    //                 })
    //             } else {
    //                 toast.error('Error connecting with the server, check your connection or contact support');
    //             }
    //         })
    // };

    const [wsInterval, setWSInterval] = useState(null);
    const dispatchConnectedUsers = useContext(ConnectedUsersContext)[1];

    const getTicket = () => {
        clientAPI.get(API_BOARD + "/ticket")
            .then(response => {
                setState({
                    ...state,
                    ticket: response.data.payload.token,
                })
            })
            .catch(error => {
                if (error && error.response && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        ticket: false,
                        error: error.response.data.statusCode.msg,
                    })
                }
            })
    }

    useEffect(() => {
        if (!state.ticket) return;

        const webSocketUrl = `${API_WS_CONNECT}?ticket=${state.ticket}`;
        dispatchBoards({type: SET_SOCKET, payload:webSocketUrl});

        setState((prevState) => ({
            ...prevState,
            ticket: false,
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.ticket]);

    useEffect(() => {
        if (boardsState.socket == null) return;
        connect();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boardsState.socket && boardsState.socket.readyState]);

    const connect = () => {
        boardsState.socket.onmessage = (messageEvent) => {
            const msg = JSON.parse(messageEvent.data);
            switch (msg.type) {
                case "boards":
                    // console.log("Received boards from ws");
                    // dispatchBoards({type: GET_BOARDS, payload: msg.boards});
                    getBoards();
                    break;
                case "board":
                    // console.log("Received board from ws");
                    dispatchBoard({type: UPDATE_BOARD, payload: msg.board});
                    break;
                case "assets":
                    dispatchBoard({type: UPDATE_ASSETS, payload: msg});
                    break;
                case "row":
                    // console.log("Received board from ws");
                    switch (msg.action) {
                        case "updateRowName":
                            dispatchBoard({type: CHANGE_ROW_NAME, payload:{tableId:msg.table, rowId:msg.row.id , name:msg.value}});
                            break;
                        case "deleteRow":
                            dispatchBoard({type: DELETED_SOME_ROWS, payload:{tableId:msg.table, rows:msg.valueIds}});
                            break;
                        case "duplicateRows":
                            dispatchBoard({type: DUPLICATED_SOME_ROWS, payload:{tableId:msg.table, rows:msg.rows}});
                            break;
                        case "addEntry":
                            dispatchBoard({
                                type: SET_SINGLE_VALUE,
                                payload: {tableId: msg.table, rowId: msg.rowId, columnId: msg.columnId, value: msg.entry.value}
                            });
                            break;
                        case "addRow":
                            dispatchBoard({type: ADD_ROW, payload: {tableId: msg.table, row: msg.row}});
                            break;
                        case "moveRow":
                            dispatchBoard({type: UPDATE_ROW_POSITION, payload: {tableId: msg.table, row: msg.row}});
                            break;
                        case "moveKanbanRow":

                            var p = [];
                            msg.kanbanPositions.map((entry) => {return p[entry.row] = entry.position});
                            dispatchBoard({
                                type: UPDATE_KANBAN_ROW_ORDER,
                                payload: {tableId: msg.table, positions: p}
                            });
                            break;
                        case "duplicateCol":
                            dispatchBoard({type: ADD_COLUMN_AND_ROWS,
                                payload:{
                                    tableId: msg.table,
                                    column:  msg.column,
                                    rows:    msg.rows,
                                }
                            });
                            break;
                        default:
                            break;
                    }
                    // dispatchBoard({type: UPDATE_BOARD, payload: msg.board});
                    break;
                case "users":
                    // console.log("Received users from ws");
                    dispatchConnectedUsers({
                        type: UPDATE_CONNECTED_USERS, payload: msg?.users?.filter(user => {
                            return user.ID !== userState.user.ID
                        })
                    });
                    break;
                case "notification":
                    setState({
                        ...state,
                        notificationsCount:state.notificationsCount+1
                    })

                    break;
                default:
                    console.log(msg)
            }
        };

        boardsState.socket.onopen = () => {
            // console.log("on open");

            clearInterval(wsInterval);
            setWSInterval(null);

            subscribeWS()
        }

        boardsState.socket.onclose = () => {
            // console.log("on close");

            setState((prevState) => ({
                ...prevState,
                connected: false
            }));

            if (wsInterval != null) return;
            setWSInterval(setInterval(() => {
                console.log("requesting ticket ")
                getTicket()
            }, 6000));
        }

        return function cleanup() {
            boardsState.socket.close();
            console.log('cleanup');
        };
    }

    // Here we are going to subscribe to receive events for a particular board
    useEffect(() => {
        subscribeWS();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const subscribeWS = () => {
        if (!boardsState.boards || boardsState.boards.length <= 0) return;
        const boardId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
        if (boardId.length !== 24) return
        setState((prevState) => ({
            ...prevState,
            currentBoard: boardId,
            connected: true,
        }));
        if (!boardsState.socket || boardsState.socket.readyState !== WebSocket.OPEN) return;
        boardsState.socket.send(JSON.stringify({"endpoint": "subscribeBoard", "data": boardId}));
        // console.log("subscribed");
    }

    const menu = (
        <Menu className={"add-row-container"} inlineIndent={0} >
            { userState.user && userState.user.roles && userState.user.roles.length > 0 && userState.user.roles.includes('ROLE_ADMIN') &&
                <Menu.Item>
                    <Link to="/admin">
                        Admin
                    </Link>
                </Menu.Item>
            }
            <Menu.Item>
                <Link to="/my-profile">
                    My Profile
                </Link>
            </Menu.Item>

            <Menu.Divider/>
            <Menu.Item onClick={logout}>Logout</Menu.Item>
        </Menu>
    );

    return (
        <div className="app-layout">
            {userState.user.launchOnboard ?
                <OnboardingPage/>
                :
                <Layout>
                    <React.Fragment>
                        <Sider trigger={null}
                               collapsible
                               collapsed={collapsed}
                               breakpoint="md"
                               collapsedWidth={width > 768 ? "80" : "0"}
                        >
                            <div className="logo-container">
                                {!collapsed &&
                                <LinkContainer to="/">
                                    <div className="logo">
                                        <Logo/>
                                    </div>
                                </LinkContainer>
                                }
                                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: "trigger",
                                    onClick: toggle,
                                })}
                            </div>
                            <Menu
                                theme="dark"
                                mode="inline"
                                className="side-menu"
                                defaultSelectedKeys={['1']}
                                selectedKeys={[location.pathname]}
                            >
                                <Menu.Item className="menu-item-entry" key="/" icon={< HomeOutlined/>}>
                                    Home
                                    <Link to="/" onClick={() => {
                                        width < 768 && setCollapsed(true)
                                    }}/>
                                </Menu.Item>
                                <Menu.Item key="/boards" className="boards-menu-entry" icon={< BarsOutlined/>}>
                                    Boards
                                    <Link to="/boards" onClick={() => {
                                        width < 768 && setCollapsed(true)
                                    }}/>
                                </Menu.Item>
                                <Menu.Item key="/templates" className="boards-menu-entry"
                                           icon={<Icon component={() => (<HiOutlineTemplate/>)}/>}>
                                    Templates
                                    <Link to="/templates" onClick={() => {
                                        width < 768 && setCollapsed(true)
                                    }}/>
                                </Menu.Item>
                                <Menu.Item key="/assets" icon={< FileOutlined/>}>
                                    Files
                                    <Link to="/assets" onClick={() => {
                                        width < 768 && setCollapsed(true)
                                    }}/>
                                </Menu.Item>
                                <Menu.Item key="/users" icon={< UserOutlined/>}>
                                    Users
                                    <Link to="/users" onClick={() => {
                                        width < 768 && setCollapsed(true)
                                    }}/>
                                </Menu.Item>
                                <Menu.Item key="/teams" icon={< TeamOutlined/>}>
                                    Teams
                                    <Link to="/teams" onClick={() => {
                                        width < 768 && setCollapsed(true)
                                    }}/>
                                </Menu.Item>
                                <Menu.Divider/>

                                {/* <Nav.Item>*/}
                                {/*    <LinkContainer to="/boards/create">*/}
                                {/*        <Nav.Link>Create Board</Nav.Link>*/}
                                {/*    </LinkContainer>*/}
                                {/*</Nav.Item>*/}
                                <Menu.Item onClick={() => dispatchBoards({
                                    type: OPEN_CREATE_BOARD_MODAL,
                                    payload: {board: null}
                                })}
                                           key="none" className={"create-board-side-button"}
                                           icon={<PlusCircleOutlined/>}>
                                    <span>
                                        Create Board
                                    </span>
                                </Menu.Item>
                                {boardsState.boards &&
                                boardsState.boards.map((board, index) =>
                                    <Menu.Item key={"/boards/" + board.id} index={board.id} className="board-menu-item"
                                               icon={(board.userpreference && board.userpreference.favorite === true) ?
                                                   <StarFilled style={{color: board.color ? board.color : "#40a9ff"}}/>
                                                   : <CustomIcon
                                                       style={{color: board.color ? board.color : "#40a9ff"}}/>}>
                                        {board.name}
                                        <Link to={"/boards/" + board.id} onClick={() => {
                                            width < 768 && setCollapsed(true)
                                        }}/>
                                    </Menu.Item>
                                )
                                }

                            </Menu>
                        </Sider>

                        <Layout className="site-layout">
                            <Navbar className="nav-user-dropdown">

                                <div className="mobile-toggle-sidebar">
                                    <button onClick={() => toggle()}>
                                        <GiHamburgerMenu/>
                                    </button>
                                </div>

                                <Nav>
                                    <NotificationsModal updateNotifications={state.notificationsCount}/>
                                    <Dropdown overlay={menu} trigger={['click']}>
                                        <a className="user-dropdown" onClick={e => e.preventDefault()} href="/#">
                                            {userState.user.name}
                                            <CaretDownOutlined/>
                                        </a>
                                    </Dropdown>
                                </Nav>
                            </Navbar>

                            <Content className="site-layout-background page-content">
                                <Switch>
                                    <Route exact path="/my-profile" component={MyProfilePage}/>
                                    <Route exact path="/admin" component={AdminPage}/>
                                    <Route path="/users">
                                        <UsersPage/>
                                    </Route>
                                    <Route path="/teams">
                                        <TeamsPage/>
                                    </Route>
                                    <Route path="/assets">
                                        <AssetsPage/>
                                    </Route>
                                    <Route path="/boards">
                                        <BoardsPage getBoards={getBoards}/>
                                    </Route>
                                    <Route path="/templates">
                                        <TemplatesPage/>
                                    </Route>
                                    <Route exact path="/" component={DashboardPage}/>
                                    <Route component={NotFoundPage}/>
                                </Switch>
                            </Content>
                        </Layout>
                    </React.Fragment>
                </Layout>
            }
            <CreateBoard />
        </div>
    )
}


export default BasePage
