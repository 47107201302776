import React, {useContext, useState} from 'react'
import {Button, Dropdown, Menu} from "antd";
import clientAPI from "../../../service/ApiService";
import { toast } from 'react-toastify';
import {DEFAULT_CELL_WIDTH, GENERIC_ERR_MSG} from "../../../constants/constants";
import {DELETE_TABLE, TABLE_CHANGE_NAME} from "../../../state/boards/BoardTypes";
import {API_TABLE_ID, API_URL} from "../../../constants";
import {BoardContext} from "../../../state/boardStore";
import {generatePath} from 'react-router';
import EdiText from "react-editext";
import AssetTable from "./AssetTable";
import {BoardsContext} from "../../../state/boardsStore";

function AssetView({table}) {

    const [showActions, setShowActions] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showUpload, setShowUpload] = useState(false)
    const [boardState, dispatchBoard] = useContext(BoardContext);
    const [boardsState] = useContext(BoardsContext);

    const deleteTable = () => {
        setLoading(true);

        const path = API_URL + generatePath(API_TABLE_ID, {
            board: boardState.board.id,
            table: table.id
        });

        clientAPI.delete(path)
            .then(response => {
                setLoading(false);
                dispatchBoard({type: DELETE_TABLE, payload: {tableId: table.id}});
            })
            .catch(error => {
                setLoading(false);
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    const menu = (
        <Menu className={"table-menu"} inlineIndent={0} >
            <Menu.Item onClick={() => {
                setShowUpload(!showUpload)
            }}>Toggle Upload</Menu.Item>
            <Menu.Item onClick={() => {
                if (window.confirm('Are you sure you want to delete this text block?')) deleteTable()
            }}>Delete</Menu.Item>
        </Menu>
    );


    const editTableTitle = val => {

        const path = API_URL + generatePath(API_TABLE_ID, {
            board: boardState.board.id,
            table: table.id
        });

        clientAPI.put(path, {"name": val})
            .then(response => {
                setLoading(false);
                dispatchBoard({type: TABLE_CHANGE_NAME, payload: {tableId: table.id, name: val } });
            })
            .catch(error => {
                setLoading(false);
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    return (
        <div className={"asset-view"}
             onMouseEnter={() => setShowActions(true)}
             onMouseLeave={() => setShowActions(false)}
        >
            <EdiText
                submitOnEnter
                cancelOnEscape
                submitOnUnfocus
                hideIcons={true}
                editOnViewClick={true}
                showButtonsOnHover={false}
                editButtonClassName="hide"
                saveButtonClassName="hide"
                cancelButtonClassName="hide"
                viewContainerClassName="asset-title"
                inputProps={{
                    style: {
                        backgroundColor: '#FAFAFA',
                        width: DEFAULT_CELL_WIDTH
                    },
                }}
                type="text"
                value={table.name?.length > 0 ? table.name : "Empty title"}
                onSave={editTableTitle}
                className="edit-assets"/>

            <AssetTable
                open={true}
                table={table}
                boardId= {boardState.board.id}
                showUpload={showUpload}
            />

            {(showActions && boardsState?.width > 768 ) &&
                <div className="actions desktop">
                    <Button
                        className={"show-upload"}
                        onClick={() => {setShowUpload(!showUpload)}}
                    >
                        { showUpload ? "Hide upload" : "Upload"}
                    </Button>
                    <Button
                        danger
                        loading={loading}
                        className={""}
                        onClick={() => {
                            if (window.confirm('Are you sure you wish to delete this text block?')) deleteTable()
                        }}
                    >Delete</Button>
                </div>
            }
            <div className="actions mobile">
                <Dropdown className={"dropdown-mobile"} overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()} href="/#">
                        <span className="threedots"/>
                    </a>
                </Dropdown>
            </div>
        </div>
    )
}

export default AssetView