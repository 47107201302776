import React, {useCallback, useEffect, useState} from 'react';
import {Alert} from 'antd';
import {useDropzone} from "react-dropzone";
import AssetRow from "./AssetRow";

function AssetTable({open, showUpload}){

    const initialState = {
        visible: false,
        error: null,
        drawerVisible: false,
        selectedAsset: null,
    }
    const [state, setState] = useState(initialState);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        if(open === 0) return;
        setState({
            ...state,
            visible: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        getAssetsByTable()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAssetsByTable = (search=null) => {
        setFiles([
            {
                account: "1",
                createdAt: "2021-06-26T05:22:51.918Z",
                fileSizeInBytes: 51085,
                filename: "creative_inspiration.jpg",
                filetype: "image",
                height: 300,
                mimetype: "image/jpeg",
                name: "Creative inspiration",
                originalFilename: "creative_inspiration.jpg",
                permission: "private",
                status: "uploaded",
                tableId: 3,
                thumb: "https://production-kaiseki.sgp1.cdn.digitaloceanspaces.com/onboarding/creative1.jpg",
                updatedAt: "2021-06-26T05:22:51.918Z",
                uri: "https://production-kaiseki.sgp1.cdn.digitaloceanspaces.com/onboarding/creative1.jpg"
            },
            {
                account: "2",
                createdAt: "2021-04-26T05:22:51.918Z",
                fileSizeInBytes: 101085,
                filename: "client_brief.pdf",
                filetype: "document",
                height: 300,
                mimetype: "application/pdf",
                name: "Client brief",
                originalFilename: "client_brief.pdf",
                permission: "private",
                status: "uploaded",
                tableId: 3,
                thumb: null,
                updatedAt: "2021-06-26T05:22:51.918Z",
                uri: "https://production-kaiseki.sgp1.cdn.digitaloceanspaces.com/onboarding/client_brief.pdf"
            }
        ])
    }

    const onDrop = useCallback(acceptedFiles => {
        console.log("Drop")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setState, state, files])


    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})


    return (
        <div className={"upload-table-wrapper"}>

            { (showUpload || files.length === 0) &&
                <div {...getRootProps()} className={"upload-dropzone"}>
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>Drop the files here ...</p> :
                            <p>Drag 'n' drop some files here, or click to select files<br/>
                            </p>
                    }
                </div>
            }

            {state.error && <Alert message={state.error} type="error" closable className="notification"/>}
            { files && files.length > 0 &&
            <React.Fragment>
                <table className="table asset-table">
                    <tbody>
                    {files.map((file, index) =>
                        <AssetRow
                            key={index}
                            asset={file}
                        />
                    )}
                    </tbody>
                </table>
            </React.Fragment>
            }
        </div>
    );
};

export default AssetTable;
