import React, {useContext, useEffect, useState, useRef} from 'react'
import clientAPI from "../../../service/ApiService"
import {API_CELL, API_COLUMN_ID, API_URL} from '../../../constants';
import {generatePath} from 'react-router';
import {BoardContext} from '../../../state/boardStore'
import {Dropdown} from 'antd';
import {toast} from 'react-toastify';
import {CHANGED_STATUS_NAME_METADATA, SET_SINGLE_VALUE} from "../../../state/boards/BoardTypes";
import {GENERIC_ERR_MSG} from "../../../constants/constants";


function StatusCell(props) {

    const initialState = {
        loading: false,
        error: null,
        table: props.table,
        boardId: props.boardId,
        editing: false,
        path: API_URL + generatePath(API_CELL, {
            board: props.boardId,
            table: props.table.id,
            row: props.row.id,
            column: props.column.id,
        }),
        metadata : props.column.type.metadata,
        form : JSON.parse(JSON.stringify(props.column.type.metadata)),
        entry: null,
        updateColumnPath: API_URL + generatePath(API_COLUMN_ID, {
            board: props.boardId,
            table: props.table.id,
            column: props.column.id
        }),
    }

    const dispatchBoard = useContext(BoardContext)[1];
    const [state, setState] = useState(initialState)
    const [visible, setVisible] = useState(false)

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    useEffect(() => {
        computeEntry()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, state.metadata]);


    const computeEntry = () =>{
        var column = props.row.entries.find(function(obj){
            return obj.column === props.column.id
        })

        if(column !== undefined){
            var entry = props.column.type.metadata.find(function(obj){
                return obj.id === column.value
            })
        }
        
        setState({
            ...state,
            metadata : props.column.type.metadata,
            entry: entry !== undefined ? entry : "",
        })
    }

    const setStatus = val => {

        clientAPI.post(state.path, {"value": val})
        .then(response => {
            var entry = props.column.type.metadata.find(function(obj){
                return obj.id === val
            })

            setState({
                ...state,
                loading: false,
                entry : entry,
            })
            setVisible(false)
            dispatchBoard({
                type: SET_SINGLE_VALUE,
                payload: {tableId: props.table.id, rowId: props.row.id, parentRowId: props.row.parent, columnId: props.column.id, value: val}
            });

        })
        .catch(error => {
            setState({
                ...state,
                loading: false,
                entry: null,
            })
            toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
        })
    }

    const editStatus = () => { 
        setState({
            ...state,
            editing: !state.editing,
        })
    }

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setVisible(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const setName = (value, meta) => {
        setState({
            ...state,
            form: state.form.map(function(obj){
                if (meta.id === obj.id ){
                    obj.name = value
                }
                return obj;
            })
        })     
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          saveMetadata();
        }
      }

    const saveMetadata = () => { 

        var payload = {
            "type" : "status",
            "metadata" : state.form
        }

        clientAPI.put(state.updateColumnPath, payload)
        .then(response => {

            setState({
                ...state,
                loading: false,
                error: false,
                editing: false,
                metadata: state.form,
                form: state.form
            })

            dispatchBoard({type: CHANGED_STATUS_NAME_METADATA, payload:{
                    tableId: props.table.id,
                    columnId: props.column.id,
                    metadata: state.form,
            }});
        })
        .catch(error => {
            if(error && error.response && error.response.data.statusCode.msg){
                setState({
                    ...state,
                    error: error.response.data.statusCode.msg,
                    loading: false,
                })
            }else{
                toast.error('Error connecting with the server, check your connection or contact support');
            }
        })
    }

    const menu = (
        <div className={"status-dropdown"} ref={wrapperRef}>
            { !state.editing &&
            <div className="status-options">
                { props.column && props.column.type && props.column.type && state.metadata.map((status, index) =>
                    <div className="status" style={{backgroundColor: status.color }} key={status.id} onClick={() => setStatus(status.id)} >
                        {status.name}
                    </div>
                )}
            </div>
            }
            { state.editing &&
            <div className="status-edit">
                { props.column && props.column.type && props.column.type && state.form.map((status, index) =>
                    <div key={status.id} className="status-edit-input">
                        <div className="band" style={{backgroundColor: status.color }} />
                        <input type="text" value={status.name} onChange={(e) => setName(e.target.value, status)} onKeyDown={handleKeyDown}/>
                    </div>
                )}
            </div>
            }

            { !state.editing &&
            <div className="edit-status">
                <button onClick={() => editStatus()}>Edit status</button>
            </div>
            }

            { state.editing &&
            <div className="edit-status">
                <button onClick={() => saveMetadata()}>Save</button>
            </div>
            }
        </div>
    );

    const changeVisibility = (e) => {
        setVisible(!visible)
    };

    return (    
        <div  className="cell-entry status-cell">
            {state.entry != null &&
                <Dropdown overlay={menu}
                          trigger={["click"]}
                          arrow={true}
                          placement="bottomCenter"
                          visible={visible}
                          animation={false}
                          onClick={(e)=>changeVisibility(e)}
                >
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()} href="/#">
                        <button
                            id="dropdown-basic"
                            className="status-toggle"
                            style={{backgroundColor: state.entry.color}}>
                            {state.entry.name}
                        </button>
                    </a>
                </Dropdown>
            }
        </div>
    )
}

export default StatusCell
