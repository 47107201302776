import React, {useEffect, useState} from 'react'
import {DEFAULT_CELL_WIDTH} from "../../../constants/constants";
import PreviewTextCell from "./PreviewTextCell";
import PreviewDateCell from "./PreviewDateCell";
import PreviewStatusCell from "./PreviewStatusCell";
import PreviewPeopleCell from "./PreviewPeopleCell";
import {Alert} from "antd";

const numberFakeRows = 5;

var rand = function() {
    return Math.random().toString(36).substr(2);
};

var token = function() {
    return rand() + rand();
};

var rowNamesSet = [
    "Clarify pitch brief requirements",
    "Creative brief development",
    "Creative development",
    "Finalize scope of work",
    "Submit pitch document",
    "Develop content calendar",
    "Finalize calendar with client",
    "Content development",
    "Public content",
];

var rowTextSet = [
    "Working on it",
    "Pending feedback",
    "To discuss",
    "Revising",
    "Check with John"
];

const randomDate = () => {
    return new Date(+(new Date()) - Math.floor(Math.random()*10000000000));
}

const generateFakeEntries = (row, table) =>{

    let entries = [];
    let i = 0;
    // eslint-disable-next-line array-callback-return
    table.columns.map(column => {
        let entry = {};
        if(column.type.type === "number" || column.type.type === "text"){
            entry = {
                column: column.id,
                value: column.type.type === "number"
                    ? Math.floor(Math.random() * 100) + 1 + ""
                    : rowTextSet[i%rowTextSet.length],
            }
            entries.push(entry);
            i++;
        }else if(column.type.type === "datetime"){

            const d = randomDate();
            d.setHours(0);
            d.setMinutes(0);
            let isoDateTime = new Date(d.getTime() - (d.getTimezoneOffset() * 60000)).toISOString();

            entry = {
                column: column.id,
                value: isoDateTime,
            }
            entries.push(entry);
        }else if(column.type.type === "status"){
            entry = {
                column: column.id,
                value: column.type.metadata[Math.floor(Math.random() * 4)].id,
            }
            entries.push(entry);
        }
    })

    return entries;
}


function PreviewTable({table, r, template}) {

    const [rows, setRows] = useState([])
    const [isFakeRows, setIsFakeRows] = useState(false)

    useEffect(() => {

        if(r === null){
            setRows([])
            if(template.visibility === 'public'){
                setIsFakeRows(true);
                generateFakeRows();
            }
            return;
        }

        var f = r.filter(x=>x.table === table.id);
        setRows(f);

        if(template.visibility === 'public' && f.length <= 0){
            setIsFakeRows(true);
            generateFakeRows();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [r]);

    const generateFakeRows = () => {
        let fakeRows = [];
        for (let i = 0; i < numberFakeRows; i++) {
            let row = {
                id: token(),
                table: table.id,
                name: rowNamesSet[i%rowNamesSet.length],
                numMessages: 0,
                numSubrows: null,
                position: i,
                kanbanPosition: 0,
                entries:[],
            };
            row.entries =  generateFakeEntries(row, table);
            fakeRows.push(row);
        }

        setRows(fakeRows);
    }

    return (
        <div className={"table-wrapper"}>
            {isFakeRows &&
                <Alert message="Displaying some data as example, this data won't appear when the template is used" type="info" className={"fake-data-alert"} />
            }
            <div className="table-header">
                <div className="resizable-column-header">
                    <h4>{table.name?.length > 0 ? table.name : "Empty title"}</h4>
                </div>
                {table && table.columns && table.columns.map((column, index) =>
                    <div className={`column-header column-type-${column.type.type}`} key={column.id}>
                        {column.name}
                    </div>
                )}
            </div>

            <div className={"list-rows"}>
                {rows && rows.map((row, index) => (
                    <div className="row-entry" key={index}>
                        <div className={"row-name-container"}>
                            {row.name}
                        </div>
                        <div className="cells-container">
                            {table && table.columns && table.columns.map((column, index) =>
                                <div key={column.id} className={"cell-width"}
                                     style={{width: ((column.width) ? column.width : DEFAULT_CELL_WIDTH)}}>
                                    {column.type.type === "text" &&
                                    <PreviewTextCell
                                        row={row}
                                        column={column}/>
                                    }
                                    {column.type.type === "datetime" &&
                                    <PreviewDateCell
                                        row={row}
                                        column={column}/>
                                    }
                                    {column.type.type === "number" &&
                                    <PreviewTextCell
                                        row={row}
                                        column={column}/>
                                    }
                                    {column.type.type === "status" &&
                                    <PreviewStatusCell
                                        row={row}
                                        column={column}/>
                                    }
                                    {column.type.type === "people" &&
                                    <PreviewPeopleCell
                                        row={row}
                                        column={column}/>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PreviewTable