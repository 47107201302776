import React, {useState, useEffect, useContext} from 'react'
import clientAPI from "../../service/ApiService"
import {API_BOARD, API_TEAMS} from '../../constants/index';
import { SpinnerLoading } from '../Style/styles.css'
import { toast } from 'react-toastify';
import {Alert, Button, Input, Select, Empty} from 'antd';
import BoardRow from './BoardRow'
import UsersPagination from '../Users/UsersPagination'
import SearchCategorySelect from "../Common/SearchCategoriesSelect";
import SearchGrid from "./SearchGrid";
import SearchModal from "./SearchModal";
import {OPEN_CREATE_BOARD_MODAL} from "../../state/boards/BoardTypes";
import {BoardsContext} from "../../state/boardsStore";
import EmptyIconSVG from '../../assets/empty-icon.svg';

function BoardsTable({ needReload, reloadAck }) {

    const initialState = {
        page: 1,
        boards: [],
        sizePerPage: 10,
        totalSize: 0,
        error: null,
        loading: false,
        firstLoad: false,
        categories: [],
        searchText: "",
        saveModalVisible: 0,
        search: null,
        refreshSearch: 0,
    }
    const [state, setState] = useState(initialState)
    const [categoriesSelect, setCategoriesSelect] = useState([])
    const dispatchBoards = useContext(BoardsContext)[1];

    const initialTeams = {
        teams: [],
        error: null,
        loading: false,
        selectedTeam : "-1",
    }
    const [teams, setTeams] = useState(initialTeams)
    const [searchText, setSearchText] = useState("")

    useEffect(() => {
        getBoards();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.page, state.sizePerPage, teams.selectedTeam]);

    useEffect(() => {
        if (needReload)
            getBoards();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needReload]);

    useEffect(() => {
        getTeams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBoards = (search=null, categories =[]) => {

        setState({
            ...state,
            error: null,
            loading: true,
            searchText: search,
            categories: categories,
        })

        var teamsString = "";
        if(teams.selectedTeam !== null && teams.selectedTeam !== "-1" && teams.selectedTeam !== undefined){
            teamsString = "&team=" + teams.selectedTeam;
        }
        let categoriesString = "";
        if(categories && categories?.length > 0){
            categoriesString = "&categories=" + categories.join("|");
        }

        clientAPI.get(API_BOARD + "?page=" + state.page + "&limit=" + state.sizePerPage + (search ? "&search=" + encodeURIComponent(search) : "" ) + categoriesString + teamsString)
            .then(response => {
                let boards = response.data.payload.boards;

                if(!boards || boards.length <= 0 ){
                    setState({...state,
                        boards:[],
                        totalSize: 0,
                        page: state.page > 1 ? state.page-1 : state.page,
                        searchText: search,
                        categories: categories,
                        firstLoad: true,
                    })
                    return false
                }

                setState({
                    ...state,
                    error: null,
                    loading: false,
                    boards: boards,
                    totalSize: response.data.payload.pagination.total,
                    searchText: search,
                    categories: categories,
                    firstLoad: true,
                })

                reloadAck();
            })
            .catch(error => {

                let msg = error?.response?.data?.statusCode?.msg
                setState({
                    ...state,
                    error: msg ?? null,
                    loading: false,
                    users: null,
                    boards: null,
                    pending: null,
                })
                if(!msg)
                    toast.error('Error connecting with the server, check your connection or contact support');

            })
    }

    const goToPage = ((page) => {
        setState({
            ...state,
            page: page
        })
    });

    const [timer, setTimer] = useState(null)

    const setSearch = (event) => {
        var st = event.target.value;
        setSearchText(st)
        if (timer != null) {
            clearTimeout(timer);
        }

        setTimer(
            setTimeout(() => {
                getBoards(st, state.categories)
            }, 300)
        )
    }

    // Teams
    const { Option } = Select;
    const handleTeamChange = (team) => {
        setTeams({
            ...teams,
            selectedTeam : team,
        })
    }
    const getTeams = (search=null) => {
        setTeams({
            ...teams,
            error: null,
            loading: true,
        })

        clientAPI.get(API_TEAMS)
            .then(response => {
                let teamsPayload = response.data.payload;
                setTeams({
                    ...teams,
                    error: null,
                    loading: false,
                    teams: teamsPayload,
                })
            })
            .catch(error => {

                if (error && error.response && error.response.data.statusCode.msg) {
                    setTeams({
                        ...teams,
                        error: error.response.data.statusCode.msg,
                        loading: false,
                        data: []
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }

            })
    }

    const selectCategory = (selectEvents) => {

        let categories = [];
        if(selectEvents?.length > 0){
             categories = selectEvents.map((event) => event.value);
        }

        getBoards(state.searchText, categories)
    }

    const openSearchModal = (search) => {
        setState({
            ...state,
            saveModalVisible: state.saveModalVisible + 1,
            search: search,
        })
    }

    const refreshSearch = () => {
        setState({
            ...state,
            refreshSearch: state.refreshSearch + 1,
        })
    }

    const doSearchFromSearchObj = (search) =>{
        setSearchText(search.text);
        setTeams({
            ...teams,
            selectedTeam : search.team?.id ?? "-1",
        })
        // console.log(state);
        // console.log(search);
        // selectCategory(state.categories.map(category => {return {value:category, label:category}}));
        getBoards(search.text, search.categories);
    }

    return (
        <div className={"boards-table-container"}>
            {state.error && <Alert message={state.error} type="error" closable className="notification" />}

            <SearchGrid openSearchModal={openSearchModal} refresh={state.refreshSearch} doSearch={doSearchFromSearchObj}/>

            <div className="search-actions">

                { teams.teams && teams.teams.length > 0 &&
                    <Select defaultValue="-1"
                            onChange={handleTeamChange}
                            value={teams.selectedTeam}
                    >
                        <Option key={-1} value="-1">Select team</Option>
                        { teams.teams.map((team, index) =>
                            <Option key={index} value={team.id}>{team.name}</Option>
                        )}
                    </Select>
                }

                <Input.Group compact className={"filters-bar"}>
                    <Input style={{ width: '50%' }}
                           placeholder="Search by name"
                           value={searchText}
                           onChange={(e) => setSearch(e)} />
                    <SearchCategorySelect
                        selectCategory={selectCategory}
                        setCategoriesSelect={setCategoriesSelect}
                        categoriesSelected={state.categories}
                    />
                    <Button className={"save-search"} onClick={() => openSearchModal(null)}>Save search</Button>
                </Input.Group>

            </div>

            {state.loading && <SpinnerLoading animation="border" role="status" className={"spinner"} />}
            {state.boards &&
                <div>
                    <table className="table board-table">
                        <thead>
                            <tr>
                                <th scope="col" style={{ color:"black" }}>Name</th>
                                <th scope="col">Categories</th>
                                <th scope="col">Users</th>
                                <th scope="col">Teams</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.boards.map((board, index) =>
                                <BoardRow key={index} board={board} refresh={getBoards} />
                            )}
                        </tbody>
                    </table>
                    <UsersPagination 
                        sizePerPage={state.sizePerPage} 
                        totalSize={state.totalSize} 
                        page={state.page}  
                        goToPage={goToPage}
                    />
                </div>
            }
            { state.firstLoad && state.boards.length <= 0 &&
                <Empty
                    image={EmptyIconSVG}
                    imageStyle={{
                        height: 80,
                    }}
                    description={
                        <span>
                            Start by creating your first board
                        </span>
                    }
                    >
                    <Button type="primary"
                            onClick={() => dispatchBoards({type: OPEN_CREATE_BOARD_MODAL, payload: {board: null}})}
                    >
                        Create board
                    </Button>
                </Empty>
            }
            <SearchModal search={state.search}
                         open={state.saveModalVisible}
                         searchText={state.searchText}
                         currentTeam={teams.selectedTeam}
                         refreshSearch={refreshSearch}
                         teams={teams.teams}
                         categoriesSelect={categoriesSelect}
                         currentCategoriesOnSearch={state.categories}
            />
        </div >
    )
}

export default BoardsTable
