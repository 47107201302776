import {
    UPDATE_CONNECTED_USERS
  } from './ConnectedUsersTypes'


export const connectedUsersInitialState = {
    users: [],
}

const ConnectedUsersReducer = (state = connectedUsersInitialState, action) => {    
    switch (action.type) {
        case UPDATE_CONNECTED_USERS:            
            // console.log(action.payload);
            return {
                ...state,
                users: action.payload,
            };
        default:
            return state;
    }
};

export default ConnectedUsersReducer;