import React, {useState} from 'react'
import {Form} from 'react-bootstrap';
import {Controller, useForm} from "react-hook-form";
import {API_TEAMS, API_USERS} from '../../constants/index';
import clientAPI from "../../service/ApiService"
import {toast} from 'react-toastify';
import {Alert, Button, Input, Modal} from 'antd';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';

function CreateTeamModal({ reload }) {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        visible: false,
        loadingUsers: false,
    }

    const showModal = () => {
        setState({
            visible: true,
        });
    };

    const handleOk = e => {
        triggerValidation()
            .then((result) => {
                if (result) {
                    handleSubmit(onSubmit)();
                }
            })
    };

    const handleCancel = e => {
        // console.log(e);
        setState({
            visible: false,
        });
        reset();
    };

    const [state, setState] = useState(initialState)
    const { handleSubmit, control, errors, triggerValidation, reset } = useForm();
    const onSubmit = values => {

        if(!values.users || values.users.length <= 0){
            toast.info("Please select at least one user for that team.")
            return;
        }

        let payload = {
            name : values.name,
            users : values.users.map(function (user) {
                return user.value
            })
        }

        setState({
            ...state,
            error: null,
            loading: true
        })

        clientAPI.post(API_TEAMS, payload)
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false,
                    success: true,
                    visible: false,
                })
                reset();
                reload();
            })
            .catch(error => {
                console.log(error)
                setState({
                    ...state,
                    error: error?.response?.data?.statusCode?.msg ?? 'Error connecting with the server, check your connection or contact support',
                    loading: false,
                    success: false
                })
            })
    };

    const animatedComponents = makeAnimated();
    const promiseOptions = inputValue =>
        new Promise(resolve => {
            setState({
                ...state,
                error: null,
                loadingUsers: true,
            })
    
            clientAPI.get(API_USERS + "?page=1&limit=20&search=" + inputValue)
                .then(response => {
                    let users = response.data.payload.users;
                    var options = [];
                    users.map(function (user) {
                        options.push({ value: user.ID, label: (user.name +  " " + user.surname) });
                        return user;
                    })
    
                    setState({
                        ...state,
                        error: null,
                        loadingUsers: false
                    })
                    resolve(options);
                })
                .catch(error => {
                    if (error && error.response && error.response.data.statusCode.msg) {
                        setState({
                            ...state,
                            loadingUsers: false,
                        })
                    } else {
                        toast.error('Error connecting with the server, check your connection or contact support');
                    }
    
                })
        });

    return (
        <div>

            <Button type="primary" onClick={() => showModal()}>
                Create team
            </Button>

            <Modal
                title="Create team"
                visible={state.visible}
                okText="Create"
                className="create-team-modal"
                onOk={() => handleOk()}
                onCancel={() => handleCancel()}
                okButtonProps={{ form: 'category-editor-form', key: 'submit', htmlType: 'submit' }}
            >
                {state.error && <Alert message={state.error} type="error" closable className="notification" />}
                {state.success && <Alert message="Team created." closable className="notification" type="success" />}

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group controlId="formBasicName">
                        <Form.Label>Enter the name of the team</Form.Label>
                        <Controller
                            as={Input}
                            name='name'
                            control={control}
                            placeholder="Name"
                            rules={{
                                required: true,
                            }}
                        />

                        {errors.name && (
                            <span className="alert-danger">Invalid name</span>
                        )}
                    </Form.Group>

                    <Form.Group controlId="formBasicUsers">
                        <Form.Label>Add users to the team</Form.Label>

                        <Controller
                            name="users"
                            as={AsyncSelect}
                            isMulti
                            components={animatedComponents}
                            isLoading={state.loadingUsers}
                            loadOptions={promiseOptions}
                            control={control}
                            closeMenuOnSelect={false}
                            defaultOptions={true}
                            className="users-multi-select"
                            classNamePrefix="users-select"
                            rules={{ required: false }}
                        />

                        {errors.users && (
                            <span className="alert-danger">Invalid users</span>
                        )}
                    </Form.Group>

                    {/* <Button variant="primary" type="primary" htmlType="submit" className="login-form-button" loading={state.loading}>
                        Invite
                    </Button> */}
                </Form>
            </Modal>

        </div>
    )
}

export default CreateTeamModal
