import React, {useState, useEffect, useRef} from 'react'
import {Form} from 'react-bootstrap';
import {Button, Input, Alert, Checkbox} from 'antd';
import {useForm, Controller} from "react-hook-form";
import axios from 'axios'
import {API_REGISTER} from '../../../constants/index';
import {GOOGLE_RECAPTCHA_KEY, GOOGLE_CLIENT_ID, FACEBOOK_APP_ID} from '../../../constants/constants';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import google_logo from "assets/google-btn.svg";
import facebook_logo from "assets/facebook-logo.svg";
import { useGoogleLogin } from 'react-use-googlelogin'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import TermsAndConditionsModal from "../Landing/TermsAndConditionsModal";

function RegisterPanel({goToRegister, goToLogin, goToForget}) {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        optionSelected: false,
        termsAndConditionsModal: 0,
    }

    const { signIn } = useGoogleLogin({
        clientId: GOOGLE_CLIENT_ID,
        scope: "profile email",
        persist: false,
    })

    const [state, setState] = useState(initialState)
    const {handleSubmit, control, register, errors, setValue, unregister, reset} = useForm();
    const recaptchaRef = useRef();

    useEffect(() => {
        register({name: 'captchaToken'}, {required: true});
        return function cleanup(){
            window.grecaptcha = null
            window.recaptcha = null
            unregister('captchaToken');
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const onVerifyCaptcha = (token) => {
        setValue('captchaToken', token);
    };

    const onSubmit = values => {

        setState({
            ...state,
            error: null,
            loading: true,
            success: false
        })

        axios.post(API_REGISTER, values)
            .then(response => {
                console.log(response);
                setState({
                    ...state,
                    error: null,
                    loading: false,
                    success: true
                })
            })
            .catch(error => {
                console.log(error)
                setState({
                    ...state,
                    error: error?.response?.data?.statusCode?.msg ?? 'Error connecting with the server, check your connection or contact support',
                    loading: false,
                    success: false
                })
            })
    };

    const emailRegistration = () => {
        setState({...state, optionSelected: true})
    }

    const googleRegistration = async () => {
        const googleUser = await signIn()
        if(googleUser && googleUser.hasOwnProperty("accessToken")){
            setState({...state, optionSelected: true})
            setValue('name', googleUser.profileObj.givenName)
            setValue('surname', googleUser.profileObj.familyName, {shouldValidate: true})
            setValue('email', googleUser.profileObj.email, {shouldValidate: true})
            setValue('googleId', googleUser.googleId, {shouldValidate: false})
            setValue('googleAccessToken', googleUser.tokenObj.id_token, {shouldValidate: false})
        }
    }

    const back = () => {
        setState({...state, optionSelected: false})
    }

    const responseFacebook = (response) => {

        setState({...state, optionSelected: true})
        setValue('name', response.first_name, {shouldValidate: true})
        setValue('surname', response.last_name, {shouldValidate: true})
        setValue('email', response.email, {shouldValidate: true})
        if(response?.picture?.data?.url.length > 0){
            setValue('image', response?.picture?.data?.url, {shouldValidate: false})
        }
        setValue('facebookId', response.userID, {shouldValidate: false})
        setValue('facebookToken', response.accessToken, {shouldValidate: false})

    }

    return (
        <div className={"register-panel"}>

            <p className="other-actions">
                <button onClick={() => back()}><h5>Sign Up</h5></button>
                <button onClick={() => goToLogin()}><h5>Login</h5></button>
                <button onClick={() => goToForget()} style={{marginLeft: "10px"}}><h5>Forget password</h5></button>
            </p>

            {state.error && <Alert message={state.error} type="error" closable className="notification"/>}
            {state.success &&
            <Alert message="You have been registered, please check your email to finish." className="notification"
                   type="success"/>
            }

            {state && !state.optionSelected &&
            <div className="register-options">

                <button className={"btn email-register"} onClick={() => emailRegistration()}>
                    Register with email
                </button>
                <button onClick={() => googleRegistration()} className={"btn google-register"}>
                    <img src={google_logo} alt="Google button"/>
                    Continue with Google
                </button>
                <FacebookLogin
                    appId={FACEBOOK_APP_ID}
                    autoLoad={false}
                    disableMobileRedirect={true}
                    fields="id,name,email,first_name,last_name,gender,birthday,location{location{name, country_code}}, picture.type(large)"
                    callback={(data) => responseFacebook(data)}
                    render={renderProps => (
                        <button className={"btn facebook-register"} onClick={renderProps.onClick}>
                            <img src={facebook_logo} alt="Facebook button"/>
                            Continue with Facebook
                        </button>
                    )}
                />
            </div>
            }

            <div style={{ display: (state && state.optionSelected) ? "block" : "none" }}>
                <h3>Register</h3>
                <div className="options">
                    <p>or <button onClick={() => goToLogin()} className="blue-link">login</button></p>
                </div>
                <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">

                    <Form.Group controlId="formBasicAccount">
                        <Form.Label>Company name</Form.Label>
                        <Controller
                            as={Input}
                            name='company'
                            control={control}
                            defaultValue=''
                            placeholder="Company"
                            rules={{required: true}}
                            autoComplete="new-user-account"
                        />

                        {errors.company && (
                            <span className="alert-danger">This field is required</span>
                        )}

                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Controller
                            as={Input}
                            name='email'
                            control={control}
                            placeholder="Email"
                            rules={{
                                pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                required: true,
                            }}
                        />

                        {errors.email && (
                            <span className="alert-danger">Invalid email address</span>
                        )}

                    </Form.Group>

                    <Form.Group controlId="formBasicName">
                        <Form.Label>Name</Form.Label>
                        <Controller
                            as={Input}
                            name='name'
                            control={control}
                            placeholder="Name"
                            rules={{required: true}}
                        />
                        {errors.name && (
                            <span className="alert-danger">You must specify a name</span>
                        )}
                    </Form.Group>

                    <Form.Group controlId="formBasicSurname">
                        <Form.Label>Surname</Form.Label>
                        <Controller
                            as={Input}
                            name='surname'
                            control={control}
                            placeholder="Surname"
                            rules={{required: true}}
                        />
                        {errors.surname && (
                            <span className="alert-danger">You must specify a surname</span>
                        )}
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Controller
                            as={Input.Password}
                            name='password'
                            control={control}
                            placeholder="Password"
                            rules={{
                                minLength: 5,
                                required: true,
                            }}
                        />
                        {errors.password && (
                            <span
                                className="alert-danger">Invalid password, password must have at least 5 characters</span>
                        )}
                    </Form.Group>

                    <Form.Group controlId="formBasicTC">
                        {/* TODO Gives an error but it does work */}
                        <Controller
                            as={<Checkbox/>}
                            control={control}
                            name="tc"
                            onChange={([e]) => e.target.checked}
                            defaultValue={""}
                            rules={{required: true}}
                        >
                            I accept the <a href="https://legal.kaiseki.io/privacy.html" target={"_blank"}>terms and conditions</a>
                        </Controller>

                        {errors.tc && (
                            <div className="alert-danger">
                                You must accept the terms and conditions
                            </div>
                        )}
                    </Form.Group>

                    <input
                        style={{ display: "none"}}
                        name="googleId"
                        ref={register({required: false})}
                    />

                    <input
                        style={{ display: "none"}}
                        name="googleAccessToken"
                        ref={register({required: false})}
                    />

                    <input
                        style={{ display: "none"}}
                        name="facebookId"
                        ref={register({required: false})}
                    />

                    <input
                        style={{ display: "none"}}
                        name="facebookToken"
                        ref={register({required: false})}
                    />

                    <input
                        style={{ display: "none"}}
                        name="image"
                        ref={register({required: false})}
                    />


                    <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_KEY}>
                        <GoogleReCaptcha ref={recaptchaRef} onVerify={token => onVerifyCaptcha(token)}/>
                    </GoogleReCaptchaProvider>


                    <Button variant="primary" type="primary" htmlType="submit" className="login-form-button"
                            loading={state.loading}>
                        Register
                    </Button>
                </Form>
            </div>

            <TermsAndConditionsModal open={state.termsAndConditionsModal} />

        </div>
    )
}

export default RegisterPanel
