import React from 'react'
import {Dropdown, Menu} from 'antd';
import EdiText from 'react-editext'
import {IoIosArrowDropdown} from "react-icons/io";
import {sortableHandle} from 'react-sortable-hoc';
import {GiHamburgerMenu} from "react-icons/gi";
import {ResizableBox} from 'react-resizable';
import {DEFAULT_CELL_WIDTH, MIN_CELL_WIDTH} from "../../../../constants/constants";

function ColumnHeader(props) {

    const editColumnName = val => {
        console.log("change column name")
    }

    const deleteColumn = val => {
        console.log("delete column")
    }

    const duplicateColumn = () => {
        console.log("duplicate column")
    }

    const DragHandle = sortableHandle(() => <GiHamburgerMenu className="column-drag-handler" />);

    // Resize columns
    const onResize = (event, {element, size, handle}) => {
        console.log("resize");
    };

    const menu = (
        <Menu className={"edit-column-dropdown"} inlineIndent={0} >
            <Menu.Item onClick={() => duplicateColumn()}>Duplicate</Menu.Item>
            <Menu.Divider/>
            <Menu.Item className={"delete-column"} onClick={() => {
                if (window.confirm('Are you sure you wish to delete this column?')) deleteColumn()
            }}>Delete</Menu.Item>
        </Menu>
    );

    return (
        <ResizableBox className="box resizable-column-header"
                      height={45}
                      width={(props.column.width) ? props.column.width : DEFAULT_CELL_WIDTH}
                      minConstraints={[MIN_CELL_WIDTH, 45]}
                      maxConstraints={[650, 45]}
                      axis="x"
                      resizeHandles={['e']}
                      onResizeStop={onResize}>

            <div className={`column-header column-type-${props.column.type.type}`}>
                {/* <div style={{ width: "30px" }}></div> */}
                <DragHandle />
                <div>
                    <EdiText
                        submitOnEnter
                        cancelOnEscape
                        submitOnUnfocus
                        hideIcons={true}
                        editOnViewClick={true}
                        showButtonsOnHover={false}
                        editButtonClassName="hide"
                        saveButtonClassName="hide"
                        cancelButtonClassName="hide"
                        viewContainerClassName="column-name"
                        inputProps={{
                            style: {
                                backgroundColor: '#FAFAFA',
                                width: DEFAULT_CELL_WIDTH
                            },
                        }}
                        type="text"
                        value={props.column.name}
                        onSave={editColumnName}
                        className="edit-column-name"/>
                </div>

                <Dropdown overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()} href="/#">
                        <button className="edit-column-action"><IoIosArrowDropdown/></button>
                    </a>
                </Dropdown>

            </div>

        </ResizableBox>
    )
}

export default ColumnHeader
