import React from 'react'
import AssetIcon from "./AssetIcon";
import {Checkbox} from "antd";
import {humanFileSize} from "./Utils";
import {formatDate} from "../../service/DateService";

function AssetRow({ asset, selectAsset, deselectAsset, selected, showAssetDetail }) {

    const selectRow = (e) => {
        if (e.target.checked) {
            selectAsset(asset);
        } else {
            deselectAsset(asset);
        }
    }

    return (
        <tr className="asset-row">
            <td className="edit-asset-select">
                <Checkbox onChange={selectRow} checked={selected}/>
            </td>
            <td className="asset-name-row" onClick={() => showAssetDetail(asset)}>
                <AssetIcon asset={asset} />
                <div className="name">{asset.name && asset.name.length > 0 ? asset.name : asset.originalFilename }</div>
            </td>
            <td>
                {(() => {
                    switch (asset?.filetype) {
                        case "image":
                            return "Image";
                        case "video":
                            return "Video";
                        case "audio":
                            return "Audio";
                        case "pdf":
                            return "PDF Document";
                        case "archive":
                            return "Compressed file";
                        case "document":
                            return "Document";
                        default:
                            return "-"
                    }
                })()}
            </td>
            <td className="">
                {humanFileSize(asset.fileSizeInBytes)}
            </td>
            <td className="">
                {formatDate(asset.createdAt)}
            </td>
            <td className="">
                { asset?.userobj?.name } { asset?.userobj?.surname }
            </td>
        </tr>
    )
}

export default AssetRow
