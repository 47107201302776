import React, {useContext, useEffect, useState} from 'react'
import EdiText from 'react-editext'
import AddColumn from './AddColumn';
import ColumnHeader from './ColumnHeader'
import AddRow from './AddRow';
import {ResizableBox} from 'react-resizable';
import {Dropdown, Menu} from 'antd';
import {useInView} from 'react-intersection-observer';
import axios from "axios";
import {DEFAULT_ACTION_WIDTH, MIN_ACTION_WIDTH} from "../../../../constants/constants";
import Row from "./Row";
import Xarrow from "react-xarrows";
import {BoardsContext} from "../../../../state/boardsStore";

function Table({table, boardId, page, setPage, addColumn, setRowsTable}) {

    const initialState = {
        error: null,
    }

    const [state, setState] = useState(initialState)
    const [rows, setRows]   = useState([
        {
            entries: [{
                column: "2",
                value : "1"
            },{
                column: "1",
                value : ["1","2"]
            }],
            id: "1",
            kanbanPosition: 1,
            name: "Task 1",
            numMessages: 0,
            numSubrows: null,
            position: 1,
            table: "1"
        },
        {
            entries: [{
                column: "2",
                value : "3"
            },{
                column: "1",
                value : ["1"]
            }],
            id: "2",
            kanbanPosition: 1,
            name: "Task 2",
            numMessages: 0,
            numSubrows: null,
            position: 1,
            table: "1"
        }
    ])
    const [ref] = useInView({});
    const [view, setView]   = useState(null)
    const [boardsState] = useContext(BoardsContext);
    const cancelTokenSource = axios.CancelToken.source();

    useEffect(() => {
        setView("board");

        const startDateColumnsExist = table.columns.find(col => col.id === table.calendarStartDate)
        const endDateColumnsExist   = table.columns.find(col => col.id === table.calendarEndDate)
        const statusColumnsExist    = table.columns.find(col => col.id === table.kanbanStatusColumn)

        if((view === "calendar" && (!startDateColumnsExist || !endDateColumnsExist)) ||
            (view === "kanban" && !statusColumnsExist )
        ){
            setView("board");
            changeView("board");
        }

        return function cleanup() {
            cancelTokenSource.cancel("Cancelling in cleanup");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const editTableTitle = val => {
        console.log(val);
    }

    const setRowValue = (value) => {
        var isNew = true;

        rows.map(function(row){
            if(value.row.id === row.id){
                // eslint-disable-next-line array-callback-return
                row.entries.find(entry => {
                    if(entry.column === value.column.id){
                        isNew = false;
                    }
                })
            }
            return row;
        })

        if(isNew){ // new entry
            setRows(rows.map(r=>{
                if(r.id === value.row.id){
                    r.entries = r.entries.concat({
                        column: value.column.id,
                        value: value.value
                    });
                }
                return r;
            }));
        }else{ // update
            let rs = rows.map(r=>{
                if(r.id === value.row.id){
                    r.entries.map(e=>{
                        if(e.column === value.column.id){
                            e.value = value.value;
                        }
                        return e;
                    })
                }
                return r;
            })
            setRows(rs);
        }

        if(page === 3 && value.column.type.type === "status"){
            setPage(page+1);
        }else if(page === 4 && value.column.type.type === "people"){
            setPage(page+1);
        }
    }

    const addRow = (value) => {

        let row = {
            entries: [],
            id: (rows.length + 1) + "",
            kanbanPosition: 1,
            name: value.name,
            numMessages: 0,
            numSubrows: null,
            position: (rows.length + 2),
            table: "1"
        }
        setRows(rows.concat(row));
        setRowsTable(rows.concat(row));
        if(page === 2){
            setPage(page+1);
        }
    }

    const changeView = (v) => {

        const startDateColumnsExist = table.columns.find(col => col.id === table.calendarStartDate)
        const endDateColumnsExist = table.columns.find(col => col.id === table.calendarEndDate)
        const statusColumnsExist    = table.columns.find(col => col.id === table.kanbanStatusColumn)

        if(v === "calendar" && (!table.calendarStartDate || !table.calendarEndDate || !startDateColumnsExist || !endDateColumnsExist)){
            setState({
                ...state,
                openConfigureViewsModal: state.openConfigureViewsModal+1,
                viewToOpen: "calendar"
            })

            return
        }else if(v === "kanban" && (!table.kanbanStatusColumn || !statusColumnsExist)){
            setState({
                ...state,
                openConfigureViewsModal: state.openConfigureViewsModal+1,
                viewToOpen: "kanban"
            })

            return
        }

        setView(v);

    }

    const { SubMenu } = Menu;
    const menu = (
        <Menu className={"table-menu"} inlineIndent={0} >
            <SubMenu title="View" className={"table-sub-menu"}>
                <Menu.Item disabled={"disabled"} className={view === "board" ? "submenu-selected" : "" }>List</Menu.Item>
                <Menu.Item disabled={"disabled"} className={view === "calendar" ? "submenu-selected" : "" }>Calendar</Menu.Item>
                <Menu.Item disabled={"disabled"} className={view === "kanban" ? "submenu-selected" : "" }>Kanban</Menu.Item>
                <Menu.Divider/>
                <Menu.Item disabled={"disabled"}>Configure</Menu.Item>
            </SubMenu>
            <Menu.Item disabled={"disabled"}>Automatic actions</Menu.Item>
            <Menu.Item disabled={"disabled"}>Duplicate</Menu.Item>
            <Menu.Item disabled={"disabled"}>Save as template</Menu.Item>
            <Menu.Item disabled={"disabled"}>Move to a board</Menu.Item>
            <Menu.Divider/>
            <Menu.Item disabled={"disabled"} className={"delete-table"}>Delete</Menu.Item>
        </Menu>
    );

    return (
        <div className={`table-type`} style={{ paddingBottom: (boardsState.width < 768 && (page === 3 || page === 4) ? "130px" : "0px") }} >
            <div className={"table-wrapper"}>
                <div className="table-header">

                    <ResizableBox className="box resizable-column-header"
                                  height={45}
                                  width={((table.actionWidth) ? table.actionWidth : DEFAULT_ACTION_WIDTH)}
                                  minConstraints={[MIN_ACTION_WIDTH, 45]}
                                  maxConstraints={[650, 45]}
                                  axis="x"
                                  resizeHandles={['e']}>

                        <div className="table-actions">
                            <span className="name">
                                {table &&
                                <h4><EdiText
                                    submitOnEnter
                                    cancelOnEscape
                                    submitOnUnfocus
                                    hideIcons={true}
                                    editOnViewClick={true}
                                    showButtonsOnHover={false}
                                    editButtonClassName="hide"
                                    saveButtonClassName="hide"
                                    cancelButtonClassName="hide"
                                    viewContainerClassName="title-table"
                                    inputProps={{
                                        style: {
                                            backgroundColor: '#FAFAFA',
                                            width: 160
                                        },
                                    }}
                                    type="text"
                                    value={table.name}
                                    onSave={editTableTitle}
                                    className="edit-title"/>
                                </h4>
                                }
                            </span>
                            <Dropdown overlay={menu} trigger={['click']}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()} href="/#">
                                    <span className="threedots"/>
                                </a>
                            </Dropdown>
                        </div>
                    </ResizableBox>


                    {(() => {
                        switch (view) {
                            // case "calendar":
                            //     return "";
                            // case "kanban":
                            //     return "";
                            default:
                                return <React.Fragment>
                                    <div className="columns-wrapper">
                                        {table.columns && table.columns.map((column, index) =>
                                            <ColumnHeader table={table}
                                                          boardId={boardId}
                                                          column={column}
                                                          key={column.id}
                                            />
                                        )}
                                    </div>

                                    <AddColumn table={table} newColumn={addColumn} page={page}/>

                                    {page === 5 &&
                                    <div className="add-column-assign">
                                        <Xarrow
                                            start="add-column-assign"
                                            end="add-row-container"
                                            color={"rgba(40,40,40,0.25)"}
                                            strokeWidth={1}
                                            startAnchor={"bottom"}
                                            endAnchor={{ position: 'top',  offset: { rightness: -3 } }}
                                            curveness={0.5}
                                            animateDrawing={page===5}
                                        />
                                        <div id={"add-column-assign"}>
                                            <p>
                                                Create a new date column type by click on the + and selecting date. Try adding some dates as well
                                            </p>
                                        </div>
                                    </div>
                                    }

                                </React.Fragment>
                        }})()}

                </div>

                {(() => {
                    switch (view) {
                        // case "calendar":
                        //     return <CalendarContainer table={table} boardId={boardState.board.id}/>;
                        // case "kanban":
                        //     return <div className={"kanban-view"}>
                        //         <Kanban
                        //             table={table}
                        //             // containerHeight={"200px"}
                        //         />
                        //     </div>
                        default:
                            return <React.Fragment>
                                <div className="rows-wrapper" id={"rows-wrapper"}>
                                    {rows?.map((row, index) => (
                                        <Row index={index}
                                             table={table}
                                             row={row}
                                             key={row.id}
                                             setRowValue={setRowValue}
                                        />
                                    ))}
                                </div>

                                {(page === 3 || page === 4) &&
                                <div className="create-row-info">
                                    <Xarrow
                                        start="highlight-assign"
                                        end="rows-wrapper"
                                        color={"rgba(40,40,40,0.25)"}
                                        strokeWidth={1}
                                        startAnchor={"top"}
                                        endAnchor={page === 3 ? { position: 'bottom', offset: { rightness: boardsState.width > 768 ? 180 : 250 } } :
                                            {position: 'bottom', offset: { rightness: boardsState.width > 768 ? 0 : 50 } }
                                        }
                                        curveness={0.5}
                                        animateDrawing={page===3}
                                    />
                                    <div id={"highlight-assign"}>
                                        {page === 3 &&
                                            <p>
                                                Assign a status by clicking on the cell and selecting from the dropdown
                                            </p>
                                        }
                                        {page === 4 &&
                                            <p>
                                                Assign a people to the task by clicking on the cell and selecting from the dropdown
                                            </p>
                                        }
                                    </div>
                                </div>
                                }

                                {/*/!* For now only used for the numbers *!/*/}
                                {/*{table && table?.rows?.length > 0 && boardState.board &&*/}
                                {/*<RecapRow boardId={boardState.board.id} table={table} />*/}
                                {/*}*/}

                                {table &&
                                <div ref={ref}>
                                    <div className="table-footer">
                                        <AddRow table={table} addRow={addRow} />

                                        {page === 2 &&
                                            <div className="create-row-info">
                                                <Xarrow
                                                    start="highlight-create-row"
                                                    end="add-row-id"
                                                    color={"rgba(40,40,40,0.25)"}
                                                    strokeWidth={1}
                                                    startAnchor={"top"}
                                                    endAnchor={"bottom"}
                                                    curveness={0.5}
                                                    animateDrawing={page===2}
                                                />
                                                <div id={"highlight-create-row"}>
                                                    <p>
                                                        Start creating a task<br/>by giving it a name<br/>and clicking on add
                                                    </p>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                                }
                            </React.Fragment>
                    }})()}
            </div>
        </div>
    )
}

export default Table
