import React, {useState, useEffect, useContext} from 'react'
import {Drawer} from 'antd';
import AutomationsList from "./AutomationsList";
import AutomationNew from "./AutomationNew";
import {BoardsContext} from '../../../state/boardsStore'

function AutomationDrawer({board, table, open}) {

    const [view, setView] = useState("list");
    const [visible, setVisible] = useState(false);
    const [boardsState] = useContext(BoardsContext);

    useEffect(() => {
        if(open <= 0) return;
        setVisible(true)
    }, [open]);

    const onClose = () => {
        setVisible(false)
    };


    return (
        <div className={"automations-drawer"}>
            <Drawer
                title={"Automatic actions for " + table.name}
                placement="right"
                closable={true}
                onClose={onClose}
                visible={visible}
                width={boardsState?.width < 768 ? "100%" : "50%"}
            >
                {(() => {
                    switch (view) {
                        case "list":
                            return <AutomationsList table={table} board={board} setView={setView}/>;
                        case "new":
                            return <AutomationNew table={table} board={board} setView={setView}/>
                        default:
                            return ""
                    }})()}
            </Drawer>
        </div>
    )
}

export default AutomationDrawer
