import React, {useContext} from 'react'
import {ConnectedUsersContext} from '../../state/connectedUsersStore'
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

function ConnectedUsers() {

    const [connectedUsers] = useContext(ConnectedUsersContext);

    // useEffect(() => {
    //     console.log("connected users");
    //     console.log(connectedUsers);
    // }, [connectedUsers]);

    const tooltip = (props) => (
        <Tooltip id="connected-users-tooltip" {...props}>
            <div className="connected-users-inner">
                <ul>
                    {connectedUsers.users && connectedUsers.users.map((user, index) =>
                        <li key={index}>{user.name} {user.surname}</li>
                    )}
                </ul>
            </div>
        </Tooltip>
    );

    return (
        <OverlayTrigger placement={'bottom'} overlay={tooltip} showOverlay={false}>
            <div className="connected-users">
                {connectedUsers.users && connectedUsers.users.length > 0 &&
                    <div>
                        <p><span>{connectedUsers.users.length}</span> online</p>
                    </div>
                }
            </div>
        </OverlayTrigger >
    )
}

export default ConnectedUsers
