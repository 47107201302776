import React, {useContext, useEffect, useState} from 'react'
import clientAPI from "../../service/ApiService"
import {API_BOARD, API_COLUMN_MOVE, API_ROW, API_TABLE_ID, API_URL} from '../../constants';
import EdiText from 'react-editext'
import {generatePath} from 'react-router';
import AddColumn from './AddColumn';
import {BoardContext} from '../../state/boardStore'
import ColumnHeader from './ColumnHeader'
import AddRow from './AddRow';
import {toast} from 'react-toastify';
import RecapRow from './RecapRow';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {GiHamburgerMenu} from "react-icons/gi";
import {Draggable} from "react-beautiful-dnd";
import SortableRows from "./SortableRows";
import {ResizableBox} from 'react-resizable';
import {DEFAULT_ACTION_WIDTH, GENERIC_ERR_MSG, MIN_ACTION_WIDTH} from "../../constants/constants";
import DuplicateTable from "./DuplicateTable";
import TransferTableModal from "./TransferTableModal";
import {
    ADD_ROWS,
    CHANGE_ACTION_WIDTH,
    DELETE_TABLE,
    SET_ROWS,
    SET_VIEW,
    SORT_COLUMN,
    TABLE_CHANGE_NAME,
    TABLE_COMPLETE
} from "../../state/boards/BoardTypes";
import {Dropdown, Menu} from 'antd';
import CalendarContainer from "./Calendar/CalendarContainer";
import ConfigureViewsModal from "./ConfigureViewsModal";
import Kanban from "./Kanban/Kanban";
import {SpinnerLoading} from "../Style/styles.css";
import {useInView} from 'react-intersection-observer';
import AutomationDrawer from "./Automations/AutomationDrawer";
import SaveTemplateTableModal from "./Template/SaveTemplateTableModal";
import axios from "axios";


// Sort columns
const SortableColumn = SortableElement(({table, column, boardId}) =>
        <ColumnHeader table={table}
                      boardId={boardId}
                      column={column}
        />
);
const ColumnList = SortableContainer(({table, boardId}) =>
    <div className="columns-wrapper">
        {table.columns && table.columns.map((column, index) =>
            <SortableColumn index={index} column={column} table={table} boardId={boardId} key={index}/>
        )}
    </div>
);

function Table({table, boardId, index, setLoading, isDragging}) {

    const initialState = {
        error: null,
        path: API_URL + generatePath(API_TABLE_ID, {
            board: boardId,
            table: table.id
        }),
        moveColumnPath: API_URL + generatePath(API_COLUMN_MOVE, {
            board: boardId,
            table: table.id
        }),
        openDuplicateModal:0,
        openTransferModal:0,
        openConfigureViewsModal:0,
        openAutomationsDrawer:0,
        templateModal:0,
        viewToOpen: null,
        pagination: null,
        tableLoading: false,
        initialLoadDone: false,
        requestedAll: false,
    }

    const [boardState, dispatchBoard] = useContext(BoardContext);
    const [state, setState] = useState(initialState)
    const [page, setPage]   = useState(1)
    const [ref, inView] = useInView({});
    const [view, setView]   = useState(null)
    const cancelTokenSource = axios.CancelToken.source();

    useEffect(() => {
        setView(boardState?.board?.userpreference?.tableViews[table.id] ?? "board");

        const startDateColumnsExist = table.columns.find(col => col.id === table.calendarStartDate)
        const endDateColumnsExist   = table.columns.find(col => col.id === table.calendarEndDate)
        const statusColumnsExist    = table.columns.find(col => col.id === table.kanbanStatusColumn)

        if((view === "calendar" && (!startDateColumnsExist || !endDateColumnsExist)) ||
           (view === "kanban" && !statusColumnsExist )
        ){
            setView("board");
            changeView("board");
        }

        if(view === "kanban" || view === "calendar"){
            loadAllRows();
        }else{
            getRows(page)
                .then((results) => {
                    setPage(page+1);
                })
                .catch((e) => {
                    toast.error('Error loading rows');
                });
        }

        return function cleanup() {
            cancelTokenSource.cancel("Cancelling in cleanup");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRows = (p) => {

        // console.log("requesting page " + p);
        if(state.requestedAll){
            console.log("already requested all");
            return
        }

        setState({
            ...state,
            tableLoading: true,
        })

        let path = API_URL + generatePath(API_ROW, {
            board: boardId,
            table: table.id
        });

        return clientAPI.get(path + "?page=" + p, {cancelToken:cancelTokenSource.token})
            .then(response => {
                let {rows, pagination} = response.data.payload;
                setLoading(false);
                setState({
                    ...state,
                    tableLoading: false,
                    pagination: pagination,
                    initialLoadDone: true,
                    requestedAll: !rows
                })

                if(p === 1){
                    dispatchBoard({type: SET_ROWS, payload: {tableId: table.id ,rows: rows}});
                }else if(rows && rows.length > 0){
                    dispatchBoard({type: ADD_ROWS, payload: {tableId: table.id ,rows: rows}});
                }

                if(p === table.numPages){
                    dispatchBoard({type: TABLE_COMPLETE, payload: {tableId: table.id}});
                }

                Promise.resolve(response);
            })
            .catch(error => {
                if(error.hasOwnProperty("message") && error.message === "Cancelling in cleanup"){
                    return
                }
                setLoading(false);
                setState({
                    ...state,
                    page: 1,
                    tableLoading: false,
                    pagination: null,
                    initialLoadDone: true,
                })
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    useEffect(() => {
        if(inView && state.initialLoadDone && !state.tableLoading && !state.requestedAll && page <= table?.numPages){
            getRows(page)
                .then((results) => {
                    setPage(page+1);
                })
                .catch((e) => {
                    toast.error('Error loading rows');
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView]);

    const loadAllRows = () => {

        if(state.requestedAll) return;

        let promises = [];
        for (let i = page; i <= table.numPages; i++) {
            promises.push(getRows(i));
        }

        Promise.all(promises)
            .then((results) => {
                setPage(table.numPages + 1);
                setState({
                    ...state,
                    requestedAll: true
                })
            })
            .catch((e) => {
                toast.error('Error loading rows');
            });

    }

    // Actions
    const editTableTitle = val => {
        clientAPI.put(state.path, {"name": val})
            .then(response => {
                setLoading(false);
                dispatchBoard({type: TABLE_CHANGE_NAME, payload: {tableId: table.id, name: val } });
            })
            .catch(error => {
                setLoading(false);
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    const deleteTable = () => {
        setLoading(true);
        clientAPI.delete(state.path)
            .then(response => {
                setLoading(false);
                dispatchBoard({type: DELETE_TABLE, payload: {tableId: table.id}});
            })
            .catch(error => {
                setLoading(false);
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }



    const onSortColumnEnd = ({oldIndex, newIndex}) => {
        let items = arrayMove([...table.columns], oldIndex, newIndex);
        let payload = {"columns": items.map(o => o.id)}

        dispatchBoard({type: SORT_COLUMN, payload: {tableId: table.id ,columns: items}});
        clientAPI.post(state.moveColumnPath, payload)
            .then(response => {
                setLoading(false);
                dispatchBoard({type: SORT_COLUMN, payload: {tableId: table.id ,columns: items}});
            })
            .catch(error => {
                setState({
                    ...state,
                    loading: false,
                })
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    };

    // Resize row header
    const onResize = (event, {element, size, handle}) => {
        clientAPI.put(state.path, {"width": size.width})
            .then(response => {
                setLoading(false);
                dispatchBoard({type: CHANGE_ACTION_WIDTH, payload:{tableId: table.id, width: size.width}});
            })
            .catch(error => {
                if (error && error.response && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                    })
                    setLoading(false);
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    };

    const changeView = (v) => {

        const startDateColumnsExist = table.columns.find(col => col.id === table.calendarStartDate)
        const endDateColumnsExist = table.columns.find(col => col.id === table.calendarEndDate)
        const statusColumnsExist    = table.columns.find(col => col.id === table.kanbanStatusColumn)

        if(v === "calendar" && (!table.calendarStartDate || !table.calendarEndDate || !startDateColumnsExist || !endDateColumnsExist)){
            setState({
                ...state,
                openConfigureViewsModal: state.openConfigureViewsModal+1,
                viewToOpen: "calendar"
            })

            return
        }else if(v === "kanban" && (!table.kanbanStatusColumn || !statusColumnsExist)){
            setState({
                ...state,
                openConfigureViewsModal: state.openConfigureViewsModal+1,
                viewToOpen: "kanban"
            })

            return
        }

        dispatchBoard({type: SET_VIEW, payload: {tableId: table.id, view: v}});
        const payload = {
            "view" : v,
        }

        if(v === "calendar" || v === "kanban"){
            loadAllRows();
        }
        setView(v);

        clientAPI.post(API_BOARD + "/" + boardState.board.id + "/table/" + table.id + "/view", payload )
            .then(response => {

            })
            .catch(error => {
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    const { SubMenu } = Menu;
    const menu = (
        <Menu className={"table-menu"} inlineIndent={0} >
            <SubMenu title="View" className={"table-sub-menu"}>
                <Menu.Item className={view === "board" ? "submenu-selected" : "" } onClick={() => {changeView("board")}}>List</Menu.Item>
                <Menu.Item className={view === "calendar" ? "submenu-selected" : "" } onClick={() => {changeView("calendar")}}>Calendar</Menu.Item>
                <Menu.Item className={view === "kanban" ? "submenu-selected" : "" } onClick={() => {changeView("kanban")}}>Kanban</Menu.Item>
                <Menu.Divider/>
                <Menu.Item onClick={() => setState({...state, openConfigureViewsModal: state.openConfigureViewsModal+1})}>Configure</Menu.Item>
            </SubMenu>
            <Menu.Item onClick={() => setState({...state, openAutomationsDrawer: state.openAutomationsDrawer+1})}>Automatic actions</Menu.Item>
            <Menu.Item onClick={() => {setState({...state, openDuplicateModal:state.openDuplicateModal+1})}}>Duplicate</Menu.Item>
            <Menu.Item onClick={() => {setState({...state, templateModal:state.templateModal+1})}}>Save as template</Menu.Item>
            <Menu.Item onClick={() => {setState({...state, openTransferModal:state.openTransferModal+1})}}>Move to a board</Menu.Item>
            <Menu.Divider/>
            <Menu.Item className={"delete-table"} onClick={() => {
                if (window.confirm('Are you sure you wish to delete this table?')) deleteTable()
            }}>Delete</Menu.Item>
        </Menu>
    );

    return (
        <div className={`table-type ${isDragging ? "dragging-table" : ""}`}>
            {table &&
            <Draggable draggableId={table.id}
                       index={index}
                       >
                {provided => (

                    <div className={boardState.selectedTable &&
                                boardState.selectedTable === table.id
                        ? "table-wrapper selected-table" : "table-wrapper"}
                         ref={provided.innerRef}
                         {...provided.draggableProps}
                    >

                        <div {...provided.dragHandleProps}>
                            <GiHamburgerMenu className="table-drag-handle"/>
                        </div>

                        {/*{state.error && <Alert variant={"danger"}>{state.error}</Alert>}*/}
                        <div className="table-header">

                            <ResizableBox className="box resizable-column-header"
                                          height={45}
                                          width={((table.actionWidth) ? table.actionWidth : DEFAULT_ACTION_WIDTH)}
                                          minConstraints={[MIN_ACTION_WIDTH, 45]}
                                          maxConstraints={[650, 45]}
                                          axis="x"
                                          resizeHandles={['e']}
                                          onResizeStop={onResize}>

                                <div className="table-actions">
                                    <span className="name">
                                        {table &&
                                        <h4><EdiText
                                            submitOnEnter
                                            cancelOnEscape
                                            submitOnUnfocus
                                            hideIcons={true}
                                            editOnViewClick={true}
                                            showButtonsOnHover={false}
                                            editButtonClassName="hide"
                                            saveButtonClassName="hide"
                                            cancelButtonClassName="hide"
                                            viewContainerClassName="title-table"
                                            inputProps={{
                                                style: {
                                                    backgroundColor: '#FAFAFA',
                                                    width: 160
                                                },
                                            }}
                                            type="text"
                                            value={table.name}
                                            onSave={editTableTitle}
                                            className="edit-title"/>
                                        </h4>
                                        }
                                    </span>
                                    <Dropdown overlay={menu} trigger={['click']}>
                                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()} href="/#">
                                            <span className="threedots"/>
                                        </a>
                                    </Dropdown>
                                </div>
                            </ResizableBox>


                            {(() => {
                                switch (view) {
                                    case "calendar":
                                        return "";
                                    case "kanban":
                                        return "";
                                    default:
                                        return <React.Fragment>
                                            {table && table.columns &&
                                                <ColumnList table={table} boardId={boardId} useDragHandle onSortEnd={onSortColumnEnd} axis='x'/>
                                            }

                                            {boardState.board && table &&
                                            <AddColumn table={table} boardId={boardState.board.id} />
                                            }
                                        </React.Fragment>
                            }})()}

                        </div>

                        {(() => {
                            switch (view) {
                                case "calendar":
                                    return <CalendarContainer table={table} boardId={boardState.board.id}/>;
                                case "kanban":
                                    return <div className={"kanban-view"}>
                                                <Kanban
                                                  table={table}
                                                  // containerHeight={"200px"}
                                                />
                                              </div>
                                    // return <Kanban table={table} boardId={boardState.board.id} />
                                default:
                                    return <React.Fragment>
                                        <SortableRows rows={table.rows}
                                                      boardId={boardState.board.id}
                                                      table={table}/>
                                        {/* For now only used for the numbers */}
                                        {table && table?.rows?.length > 0 && boardState.board &&
                                            <RecapRow boardId={boardState.board.id} table={table} />
                                        }

                                        { state.tableLoading &&
                                            <div className="table-loading">
                                                <SpinnerLoading animation="border" role="status"/><p>Loading rows</p>
                                            </div>
                                        }

                                        {table &&
                                            <div ref={ref}>
                                                <div className="table-footer">
                                                    <AddRow boardId={boardState.board.id} table={table} />
                                                </div>
                                            </div>
                                        }
                                    </React.Fragment>
                            }})()}
                        {provided.placeholder}
                    </div>
                )}
            </Draggable>
            }
            <DuplicateTable boardId={boardState.board.id}
                            table={table}
                            counter={state.openDuplicateModal} />

            <TransferTableModal boardId={boardState.board.id}
                                table={table}
                                counter={state.openTransferModal} />

            <ConfigureViewsModal table={table}
                                 counter={state.openConfigureViewsModal}
                                 changeView={changeView}
                                 viewToOpen={state.viewToOpen}
            />
            <AutomationDrawer board={boardState.board}
                              table={table}
                              open={state.openAutomationsDrawer}
            />

            <SaveTemplateTableModal boardId={boardState.board.id}
                                tableId={table.id}
                                open={state.templateModal} />

        </div>
    )
}

export default Table
