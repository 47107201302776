import React from 'react';
import TableOnboarding from "../../../assets/onboarding-table.svg";
import RichTextOnboarding from "../../../assets/onboarding-rich-text.svg";
import AssetsOnboarding from "../../../assets/onboarding-assets.svg";

function Page1({next, prev}){

    return (
        <div className={"page page1"}>
            {/*<div className="triangle-topleft"></div>*/}
            <div className="wrapper">
                <h1>The board</h1>
                <p>Everything in kaiseki.io revolves around<br/> the concept of a <strong>board</strong>.</p>
                <p>A board is your empty canvas where<br/> you can add several tables, rich text or files.</p>
                <p>We are going to create one board with some data to see how it works.</p>
                <div className="buttons">
                    <button className={"prev"} onClick={() => prev()}>Prev</button>
                    <button className={"next"} onClick={() => next()}>Next</button>
                </div>
            </div>

            <div className="board-container">
                <h2>Board</h2>
                <div className="board-wrapper">
                    <div id="table-onboarding">
                        <h3>Tables</h3>
                        <img src={TableOnboarding} alt="Table"/>
                    </div>
                    <div id="rich-content-onboarding">
                        <h3>Rich text</h3>
                        <img src={RichTextOnboarding} alt="Rich text"/>
                    </div>
                    <div id="assets-onboarding">
                        <h3>Files</h3>
                        <img src={AssetsOnboarding} alt="Assets"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page1;