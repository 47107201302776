import React, {useContext} from 'react';
import {Button} from "antd";
import clientAPI from "../../service/ApiService";
import {API_GET_ACCOUNT} from "../../constants";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import {USER_LOGOUT} from "../../state/user/UserTypes";
import { Context } from '../../state/store';

function DeleteAccount() {

    const history = useHistory();
    const dispatch = useContext(Context)[1];

    const deleteAccount = values => {

        clientAPI.delete(API_GET_ACCOUNT + "/admin")
            .then(response => {
                dispatch({ type: USER_LOGOUT });
                history.push("/");
            })
            .catch(error => {
                // if (error && error.response && error.response.data.statusCode.msg) {
                //     toast.error('Error connecting with the server, check your connection or contact support');
                // } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                // }
            })
    };

    return (
        <div className={"delete-account-page"}>
            <div className="inline-fields">
                <h5>Delete account</h5>
            </div>
            <p>If you are considering deleting your account with us, we're sorry to see you go. You can delete your account by going to your account settings and selecting the "Delete Account" option.</p>

<p>We value all of our users and appreciate the time you've spent with us. If there is anything we can do to improve your experience, please let us know.</p>

<p>Please note that account deletion is permanent, and all of your data, including your saved content and account information, will be deleted. There is no way to retrieve it once the process is complete. So, we urge you to be absolutely sure before proceeding with account deletion. </p>

            <div className="actions">
                <Button type="primary" danger onClick={() => {
                    if (window.confirm('Are you sure you wish to delete this account? This process is not reversible, all the users in your account will receive an email.')) deleteAccount()
                }}>Delete Account</Button>
            </div>
        </div>
    );
}

export default DeleteAccount;