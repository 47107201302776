import React, {useState} from 'react'
import {Alert, Form} from 'react-bootstrap';
import {useForm} from "react-hook-form";
import {SHARE_LINK} from '../../../constants/index';
import {Button, Switch} from "antd";
import clientAPI from "../../../service/ApiService";

function CreateLink({boardId, refresh}) {

    const initialState = {
        error: null,
        loading: false,
        showPass: true,
    }

    const [state, setState] = useState(initialState)
    const { handleSubmit, register, errors } = useForm();

    const createLink = (values) => {

        setState({
            ...state,
            loading: true,
        })

        clientAPI.post(SHARE_LINK + "/" + boardId, values)
            .then(response => {
                setState({
                    ...state,
                    error:null,
                    loading: false,
                })
                refresh();
            })
            .catch(error => {
                setState({
                    ...state,
                    error: "Invalid password",
                    loading: false,
                })
            })
    }

    const onChange = (value) => {
        setState({...state, showPass: value})
    }

    return (
        <div className={"create-link"}>
            {state.error && <Alert variant={"danger"}>{state.error}</Alert>}
            <div className="d-flex">
                <Switch defaultChecked onChange={onChange} />
                <Form.Label>Password</Form.Label>
            </div>
            <Form onSubmit={handleSubmit(createLink)}>
                { state.showPass &&
                    <Form.Group controlId="formBasicPassword">
                        <input
                            name="password"
                            className="ant-input"
                            placeholder="Password"
                            type="password"
                            ref={register({
                                required: "You must specify a password",
                                minLength: {
                                    value: 3,
                                    message: "Password must have at least 3 characters"
                                }
                            })}
                        />
                        <p className="alert-danger">{errors.password && errors.password.message}</p>
                    </Form.Group>
                }
                <Button variant="primary" type="submit" htmlType='submit' loading={state.loading}>
                    Create link
                </Button>
            </Form>
        </div>
    )
}

export default CreateLink
