import React, {useContext, useState} from 'react';
import {Button, Input, Row} from 'antd';
import CreateTableModal from "../Components/CreateTableModal";
import Xarrow from "react-xarrows";
import Table from "../Components/Table/Table";
import CopyComponent from "../Components/Copy/CopyComponent";
import AssetComponent from "../Components/Asset/AssetComponent";
import {BoardsContext} from "../../../state/boardsStore";

function Page2({done, prev}){

    const [boardName, setBoardName] = useState("")
    const [page, setPage] = useState(0)
    const [openCreateTableModal, setOpenCreateTableModal] = useState(0)
    const [copyComponentBody, setCopyComponentBody] = useState("")
    const [rows, setRows] = useState([]);
    const [boardsState] = useContext(BoardsContext);
    const [table, setTable] = useState({
        name: "Table name",
        actionWidth: 200,
        columns: [{
            id: "1",
            name: "Responsible",
            position: 1,
            type: {type: "people"},
            width: 0
        },{
            id: "2",
            name : "Status",
            type: {
                type: "status",
                metadata: [
                    {
                        id: "1",
                        name: "Done",
                        color: "#43a047",
                        position: 1,
                    },
                    {
                        id: "2",
                        name: "Working on it",
                        color: "#ffb300",
                        position: 2,
                    },
                    {
                        id: "3",
                        name: "Pending",
                        color: "#1e88e5",
                        position: 3,
                    },
                    {
                        id: "4",
                        name: "Stuck",
                        color: "#e53935",
                        position: 4,
                    },
                    {
                        id: "5",
                        name: "",
                        color: "#fb8c00",
                        position: 5,
                    },
                    {
                        id: "6",
                        name: "",
                        color: "#757575",
                        position: 6,
                    },
                    {
                        id: "7",
                        name: "",
                        color: "#5e35b1",
                        position: 7,
                    },
                    {
                        id: "8",
                        name: "",
                        color: "#546e7a",
                        position: 8,
                    }
                ]
            }
        }],
    });

    // Put only for debug
    // useEffect(()=>{
    //     setPage(0);
    // }, [])

    const previous = () => {
        if(page === 0){
            prev();
        }else{
            setPage(page-1)
        }
    }

    const tablenameChanged = (name) => {
        setTable({
            ...table,
            name: name,
        })
        setPage(page+1)
    }

    const addColumn = (column) => {
        let columns = table.columns.concat(column);
        setTable({...table, columns: columns})
        setPage(page+1)
    }

    const nextPage = () => {
        setPage(page+1)
        if(page === 9){
            done(boardName,table, copyComponentBody, rows);
        }
    }

    const setPageAndBody = (body) => {
        setCopyComponentBody(body);
        setPage(page+1)
    }

    return (
        <div className={"page page2"}>
            <div className="left" style={{width: page>1?"32%":"40%"}}>
                <div className="wrapper">
                    {page === 0 &&
                        <React.Fragment>
                            <h1>Let's start!</h1>
                            <p>Start creating a board by giving it a name</p>
                            <Input placeholder="Board name" value={boardName}
                                   onChange={(e) => setBoardName(e.target.value)}/>
                        </React.Fragment>
                    }
                    {page === 1 &&
                        <React.Fragment>
                            <h1>First board</h1>
                            <p>Now we have our first board created. We can see the name of the board and the action buttons to create the different components: tables, rich texts and files.</p>
                            <br/>
                            <p>Let's create our first table by clicking on the table button and giving it a name.</p>
                        </React.Fragment>
                    }
                    {page === 2 &&
                        <React.Fragment>
                            <h1>Your first table</h1>
                            <p>We have created our first table <strong>{table?.name}</strong> and we have added some example data.<br/> Let's create your first task.</p>
                        </React.Fragment>
                    }
                    {page === 3 &&
                        <React.Fragment>
                            <h1>Your first table</h1>
                            <p>Great! Now let's assign a status to the new created task.</p>
                        </React.Fragment>
                    }
                    {page === 4 &&
                    <React.Fragment>
                        <h1>Assign people</h1>
                        <p>Is time to assign who is going to do the task. This users are generated by us, but here you will have all the users registered in your account.</p>
                    </React.Fragment>
                    }
                    {page === 5 &&
                    <React.Fragment>
                        <h1>Add a due date</h1>
                        <p>
                            Add a new column type. You can add as many columns as you want per table, different types, sort them and rename them.
                            { boardsState.width < 768 && <p>Scroll to the right, and click on the blue plus button on the top right of the table, then select date.</p>}
                        </p>
                    </React.Fragment>
                    }
                    {page === 6 &&
                    <React.Fragment>
                        <h1>We got the basics of the table covered</h1>
                        <p>Let's try to add now a rich text component to give some context to the board</p>
                    </React.Fragment>
                    }
                    {page === 7 &&
                    <React.Fragment>
                        <h1>Rich text component</h1>
                        <p>Now let's add some dummy text and let's save.</p>
                    </React.Fragment>
                    }
                    {page === 8 &&
                    <React.Fragment>
                        <h1>Files component</h1>
                        <p>Great, now let's add a files component to add files to the board.</p>
                    </React.Fragment>
                    }
                    {page === 9 &&
                    <React.Fragment>
                        <h1>Files component</h1>
                        <p>This last component allows you to upload any file to this board, an example of files attached can be the creatives files, or the project brief.
                            <br/>
                            <br/>
                            You can add the files by dragging and dropping or clicking on the same box.
                        </p>
                    </React.Fragment>
                    }

                    <div className="buttons">
                        <button className={"prev"} onClick={() => previous()}>Previous</button>
                        {page === 0 &&
                            <button className={"next"} onClick={() => setPage(page+1)}>Next</button>
                        }
                        {page === 9 &&
                            <button className={"next done"} onClick={() => nextPage()}>Continue</button>
                        }
                    </div>
                </div>
            </div>
            <div className="right">
                <div className="board-element">
                    <div className="board">
                        <h1>{boardName}</h1>

                        {page >= 2 &&
                            <Table table={table} page={page} setPage={setPage} addColumn={addColumn} setRowsTable={setRows}/>
                        }
                        {page >= 7 &&
                            <CopyComponent page={page} setPageAndBody={setPageAndBody}/>
                        }
                        {page >= 9 &&
                            <AssetComponent/>
                        }

                    </div>
                    {boardName.length > 0 &&
                        <div className="add-elements-container">
                            <Row>
                                <p>Add</p>
                                { page === 1 ?
                                    <div className={"first-table"} id={"starting-table"}>
                                        <Button onClick={() => setOpenCreateTableModal(openCreateTableModal+1)}
                                                variant="outline-dark"
                                                type="primary"
                                                shape="round"
                                                className={"clickable starting-table-button"}
                                                size={"default"}
                                        >
                                            Table
                                        </Button>
                                        <div className="arrow-info">
                                            <Xarrow
                                                start="highlight-table"
                                                end="starting-table"
                                                color={"rgba(40,40,40,0.25)"}
                                                strokeWidth={1}
                                                startAnchor={"top"}
                                                endAnchor={"bottom"}
                                                curveness={0}
                                                animateDrawing={page===1}
                                            />
                                            <div className="highlight-table" id={"highlight-table"}>
                                                <p>
                                                    Click here <br/>to create a table
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <Button variant="outline-dark"
                                            type="primary"
                                            shape="round"
                                            size={"default"}
                                    >
                                        Table
                                    </Button>
                                }



                                { page === 6 ?
                                    <div className={"first-table"} id={"rich-text-cta"}>
                                        <Button onClick={() => nextPage()}
                                                variant="outline-dark"
                                                type="primary"
                                                shape="round"
                                                className={"clickable rich-text-cta-button"}
                                                size={"default"}
                                        >
                                            Rich Text
                                        </Button>
                                        <div className="arrow-info">
                                            <Xarrow
                                                start="highlight-richtext"
                                                end="rich-text-cta"
                                                color={"rgba(40,40,40,0.25)"}
                                                strokeWidth={1}
                                                startAnchor={"top"}
                                                endAnchor={"bottom"}
                                                curveness={0}
                                                animateDrawing={page===6}
                                            />
                                            <div className="highlight-richtext" id={"highlight-richtext"}>
                                                <p>
                                                    Click here to create<br/> a rich text component
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <Button variant="outline-dark"
                                            type="primary"
                                            shape="round"
                                            size={"default"}
                                    >
                                        Rich Text
                                    </Button>
                                }


                                { page === 8 ?
                                    <div className={"first-table"} id={"assets-cta"}>
                                        <Button onClick={() => nextPage()}
                                                variant="outline-dark"
                                                type="primary"
                                                shape="round"
                                                className={"clickable assets-cta-button"}
                                                size={"default"}
                                        >
                                            Assets
                                        </Button>
                                        <div className="arrow-info">
                                            <Xarrow
                                                start="highlight-assets"
                                                end="assets-cta"
                                                color={"rgba(40,40,40,0.25)"}
                                                strokeWidth={1}
                                                startAnchor={"top"}
                                                endAnchor={"bottom"}
                                                curveness={0}
                                                animateDrawing={page===6}
                                            />
                                            <div className="highlight-assets" id={"highlight-assets"}>
                                                <p>
                                                    Click here to create<br/> a files component
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <Button variant="outline-dark"
                                            type="primary"
                                            shape="round"
                                            size={"default"}
                                    >
                                        Assets
                                    </Button>
                                }
                            </Row>
                        </div>
                    }
                </div>
            </div>
            <CreateTableModal open={openCreateTableModal} setTableName={tablenameChanged} />
        </div>
    );
};

export default Page2;