import React from 'react'
import {Form} from "react-bootstrap";
import {Input} from "antd";

function AutomationRowFields({selectedName}) {

    return (
        <div className={"select-table"}>
            <Form.Group controlId="formBasicName">
                <Form.Label>Row name</Form.Label>
                <Input
                    onBlur={(e) => selectedName(e.target.value)}
                    placeholder="Row name" />
            </Form.Group>

            {/*<div className="people-cells">*/}
            {/*    { table?.columns?.map((column, index) =>*/}
            {/*        <React.Fragment key={index}>*/}
            {/*            {column.type.type === 'people' &&*/}
            {/*            <div className={"people-cell"}>*/}
            {/*                <p className={"label-date-cell"}>{column.name}</p>*/}
            {/*                <PeopleCell boardId={board.id} table={table} row={row} column={column} />*/}
            {/*            </div>*/}
            {/*            }*/}
            {/*        </React.Fragment>*/}
            {/*    )}*/}
            {/*</div>*/}

            {/*<div className="status-cells">*/}
            {/*    { table?.columns?.map((column, index) =>*/}
            {/*        <React.Fragment key={index}>*/}
            {/*            {column.type.type === 'status' &&*/}
            {/*            <div className={"state-cell"}>*/}
            {/*                <p className={"label-date-cell"}>{column.name}</p>*/}
            {/*                <StatusCell boardId={board.id} table={table} row={row} column={column} />*/}
            {/*            </div>*/}
            {/*            }*/}
            {/*        </React.Fragment>*/}
            {/*    )}*/}
            {/*</div>*/}

            {/*<div className="date-cells">*/}
            {/*    { table?.columns?.map((column, index) =>*/}
            {/*        <React.Fragment key={index}>*/}
            {/*            {column.type.type === 'datetime' &&*/}
            {/*            <div className={"date-cell"}>*/}
            {/*                <p className={"label-date-cell"}>{column.name}</p>*/}
            {/*                <DateTimeCell boardId={board.id} table={table} row={row} column={column} />*/}
            {/*            </div>*/}
            {/*            }*/}
            {/*        </React.Fragment>*/}
            {/*    )}*/}
            {/*</div>*/}

            {/*<div className="date-cells">*/}
            {/*    { table?.columns?.map((column, index) =>*/}
            {/*        <React.Fragment key={index}>*/}
            {/*            {column.type.type === 'number' &&*/}
            {/*            <div className={"date-cell"}>*/}
            {/*                <p className={"label-date-cell"}>{column.name}</p>*/}
            {/*                <NumberCell boardId={board.id} table={table} row={row} column={column} />*/}
            {/*            </div>*/}
            {/*            }*/}
            {/*        </React.Fragment>*/}
            {/*    )}*/}
            {/*</div>*/}

        </div>
    )
}

export default AutomationRowFields
