export const DEFAULT_CELL_WIDTH     = 160
export const MIN_CELL_WIDTH     = 130
export const DEFAULT_ACTION_WIDTH   = 300
export const MIN_ACTION_WIDTH   = 200

export const GOOGLE_RECAPTCHA_KEY = "6LcoTcYZAAAAAN4gJMuFc8VWd2wXcKtpyCh4OfJa"
export const GOOGLE_CLIENT_ID = '382841794137-3o1s27b75uuk5g54s7gdqkbpl37vupl1.apps.googleusercontent.com'
export const FACEBOOK_APP_ID = '309780703456059'
export const GENERIC_ERR_MSG = 'Error connecting with the server, check your connection or contact support'


export const LogRegister		 		 = 1
export const LogRegisterConfirm  	     = 2
export const LogForgetRequest    	     = 3
export const LogForgetReset      	     = 4
export const LogLogin	        	     = 5

export const LogUserProfile			     = 1001
export const LogPublicProfile		     = 1002
export const LogSetProfile			     = 1003
export const LogChangePwd			     = 1004
export const LogChangeEmail			     = 1005
export const LogChangeEmailConfirm       = 1006
export const LogSetUserPicture	         = 1007

export const LogCreateTeam			     = 2001
export const LogGetTeams				 = 2002
export const LogSearchTeams			     = 2003
export const LogGetMyTeams			     = 2004
export const LogEditTeam				 = 2005
export const LogDeleteTeam			     = 2006

export const LogGetDashboard			 = 3001
export const LogGetAccount			     = 3002
export const LogDeleteAccount		     = 3003
export const LogUpdateAccount		     = 3004
export const LogGetAccountUsers		     = 3005
export const LogGetAllUsers			     = 3006
export const LogInviteUser			     = 3007
export const LogAcceptedInvite		     = 3008
export const LogUninviteUser			 = 3009

export const LogGetBoards			     = 4001
export const LogUpdateBoard			     = 4002
export const LogUpdBoardDescription      = 4003
export const LogDeleteBoard		         = 4004
export const LogGetAllBoards		     = 4005
export const LogGetBoard			     = 4006
export const LogFavBoard			     = 4007

export const LogCreateTable		         = 5001
export const LogUpdateTable		         = 5002
export const LogDeleteTable		         = 5003
export const LogMovedTables		         = 5004
export const LogDuplicatedTable		     = 5005
export const LogTransferTable		     = 5006

export const LogCreateColumn		     = 6001
export const LogUpdateColumn		     = 6002
export const LogDeleteColumn		     = 6003
export const LogMovedColumns		     = 6004
export const LogDuplicatedColumn	     = 6005

export const LogCreateRow		         = 7001
export const LogUpdateRow		         = 7002
export const LogDeleteRow		         = 7003
export const LogMovedRows		         = 7004

export const LogEntry				     = 8001

export const LogGetMessages			     = 9001
export const LogCreateMessage		     = 9002
export const LogUpdateMessage		     = 9003

export const LogGetLogs			         = 10001

export const WelcomeNotification     		= 1
export const UserAddedToTeam		 		= 2
export const UserRemovedFromTeam		 	= 3
export const UserInvitedToBoard 		 	= 4
export const UserRemovedFromBoard		 	= 5