import React from 'react'
import {formatDate} from "../../../../service/DateService";
import {humanFileSize} from "../../../AssetsPage/Utils";
import AssetIcon from "../../../AssetsPage/AssetIcon";

function AssetRow({ asset }) {

    return (
        <tr className="asset-row">
            <td className="asset-name-row">

                <AssetIcon asset={asset}/>
                {asset &&
                    <div className="name">
                        {asset?.name && asset?.name.length > 0 ? asset?.name : asset?.originalFilename}
                    </div>
                }
            </td>
                <React.Fragment>
                    <td className={"align"}>
                        {asset &&
                        (() => {
                            switch (asset?.filetype) {
                                case "image":
                                    return "Image";
                                case "video":
                                    return "Video";
                                case "audio":
                                    return "Audio";
                                case "pdf":
                                    return "PDF Document";
                                case "archive":
                                    return "Compressed file";
                                case "document":
                                    return "Document";
                                default:
                                    return "-"
                            }
                        })()
                        }
                    </td>

                    <td className="align">
                        {asset &&
                            humanFileSize(asset.fileSizeInBytes, true)
                        }
                    </td>
                    <td className="align">
                        {asset &&
                            formatDate(asset.createdAt)
                        }
                    </td>
                </React.Fragment>
        </tr>
    )
}

export default AssetRow
