import React, {useState} from 'react'
import EdiText from 'react-editext'
import TextCell from "./Cells/TextCell";
import StatusCell from './Cells/StatusCell';
import DateTimeCell from './Cells/DateTimeCell';
import PeopleCell from './Cells/PeopleCell/PeopleCell';

import {DEFAULT_ACTION_WIDTH, DEFAULT_CELL_WIDTH} from "../../../../constants/constants";


function Row({row, table, setRowValue}) {

    const initialState = {
        loading: false,
        error: null,
        hover: false,
        selected: false,
        showSubTasks: false,
    }

    const [state, setState] = useState(initialState)

    const editRowName = val => {
        console.log(val)
    }

    return (
        <div className={"row-line"}>
            {row &&
                <React.Fragment>
                    <div className={`${state.selected ? "row-entry selected" : "row-entry"} ${state.showSubTasks ? "show-subtask" : ""} `}>
                        <div className="row-name-container"
                             style={{width: ((table.actionWidth) ? table.actionWidth : DEFAULT_ACTION_WIDTH)}}
                             onMouseEnter={() => setState({...state, hover: true})}
                             onMouseLeave={() => setState({...state, hover: false})}
                        >
                            <EdiText
                                submitOnEnter
                                cancelOnEscape
                                submitOnUnfocus
                                hideIcons={true}
                                editOnViewClick={true}
                                showButtonsOnHover={false}
                                editButtonClassName="hide"
                                saveButtonClassName="hide"
                                cancelButtonClassName="hide"
                                viewContainerClassName="row-name"
                                inputProps={{
                                    style: {
                                        backgroundColor: '#FAFAFA',
                                        // width: DEFAULT_CELL_WIDTH
                                    },
                                }}
                                type="text" value={row.name} onSave={editRowName} className="edit-row-name"/>
                        </div>

                        <div className="cells-container">
                            {table && table.columns.map((column, index) =>
                                <div key={column.id} className={"cell-width"}
                                     style={{width: ((column.width) ? column.width : DEFAULT_CELL_WIDTH)}}>
                                    {column.type.type === "text" &&
                                        <TextCell
                                              table={table}
                                              row={row}
                                              column={column}/>
                                    }
                                    {column.type.type === "status" &&
                                        <StatusCell
                                                    table={table}
                                                    row={row}
                                                    column={column}
                                                    setRowValue={setRowValue}
                                        />
                                    }
                                    {column.type.type === "datetime" &&
                                        <DateTimeCell
                                                      setRowValue={setRowValue}
                                                      table={table}
                                                      row={row}
                                                      column={column}/>
                                    }
                                    {column.type.type === "people" &&
                                        <PeopleCell
                                                table={table}
                                                row={row}
                                                column={column}
                                                setRowValue={setRowValue}
                                        />
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                </React.Fragment>
            }
        </div>
    )
}

export default Row
