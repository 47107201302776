import React, {useContext} from 'react';
import {CloseCircleTwoTone, CopyTwoTone, DeleteTwoTone} from '@ant-design/icons';
import {Button} from 'antd';
import {BoardContext} from '../../state/boardStore'
import {DELETED_SOME_ROWS, DESELECT_ALL_ROWS, DUPLICATED_SOME_ROWS} from "../../state/boards/BoardTypes";
import clientAPI from "../../service/ApiService";
import {toast} from "react-toastify";
import {API_TABLE_ID, API_URL} from "../../constants";
import {generatePath} from 'react-router';
import {GENERIC_ERR_MSG} from "../../constants/constants";

const BulkActions = () => {

    const [boardState, dispatchBoard] = useContext(BoardContext);

    const deselect = () => {
        dispatchBoard({type: DESELECT_ALL_ROWS});
    }

    const deleteRows = () => {

        let payload = {"rows": boardState.selectedRows};
        let path = API_URL + generatePath(API_TABLE_ID, {
            board: boardState.board.id,
            table: boardState.selectedTable,
        });

        return clientAPI.delete(path + "/rows", { data: payload })
            .then(response => {
                dispatchBoard({type: DELETED_SOME_ROWS, payload: {tableId:boardState.selectedTable, rows: boardState.selectedRows}});
            })
            .catch(error => {
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    const duplicateRows = () => {

        let payload = {"rows": boardState.selectedRows};
        let path = API_URL + generatePath(API_TABLE_ID, {
            board: boardState.board.id,
            table: boardState.selectedTable,
        });

        return clientAPI.post(path + "/rows/duplicate", payload)
            .then(response => {
                dispatchBoard({type: DUPLICATED_SOME_ROWS, payload: {tableId:boardState.selectedTable, rows: response.data.payload}});
            })
            .catch(error => {
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    return (
        <div className={"bulk-actions-bar"}>

            <Button type="link" className="bulk-button duplicate-rows-cta" onClick={() => duplicateRows()}>
                <CopyTwoTone />
                Duplicate
            </Button>

            <Button type="link" className="bulk-button delete-rows-cta" onClick={() => deleteRows()}>
                <DeleteTwoTone />
                Delete
            </Button>

            <div className="grower" />

            <Button type="link" className="bulk-button cancel" onClick={() => deselect()}>
                <CloseCircleTwoTone />
                Deselect
            </Button>
        </div>
    );
};

export default BulkActions;
