import React, {useContext, useEffect, useState} from 'react';
import {Alert} from 'antd';
import clientAPI from "../../../service/ApiService";
import {SHARE_LINK} from "../../../constants";
import {toast} from "react-toastify";
import SharedLinkRow from "./SharedLinkRow";
import {SpinnerLoading} from "../../Style/styles.css";
import {BoardsContext} from "../../../state/boardsStore";

function SharedLinksTable({boardId, refresh}){

    const initialState = {
        error: null,
        loading: false,
    }
    const [state, setState] = useState(initialState)
    const [links, setLinks] = useState([])
    const [boardsState] = useContext(BoardsContext);

    useEffect(() => {
        getSharedLinks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(refresh === 0) return;
        request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    const request = () =>{
        getSharedLinks()
    }

    const getSharedLinks = (search=null) => {
        setState({
            ...state,
            error: null,
            loading: true,
        })

        clientAPI.get(SHARE_LINK + "/" + boardId)
            .then(response => {

                setState({
                    ...state,
                    error: null,
                    loading: false
                })

                let links = response.data.payload;
                if(!links || links.length <= 0 ){
                    setLinks([]);
                    return false
                }

                setLinks(links);
            })
            .catch(error => {

                let msg = error?.response?.data?.statusCode?.msg
                setState({
                    ...state,
                    error: msg ?? null,
                    loading: false,
                })
                setLinks([]);
                if(!msg)
                    toast.error('Error connecting with the server, check your connection or contact support');

            })
    }

    return (
        <div className={"upload-table-wrapper"}>
            {state.error && <Alert message={state.error} type="error" closable className="notification"/>}
            {state.loading && <SpinnerLoading className={"spinner"} animation="border" role="status" />}
            { links && links.length > 0 &&
            <React.Fragment>
                <table className="table asset-table">
                    <thead>
                    <tr>
                        <th scope="col" style={{color: "black"}}>Id</th>
                        <th scope="col" style={{color: "black"}}>Password</th>
                        {boardsState?.width > 768 &&
                        <React.Fragment>
                            <th scope="col" style={{color: "black"}}>Created By</th>
                            <th scope="col" style={{color: "black"}}>Created At</th>
                        </React.Fragment>
                        }
                        <th scope="col" style={{color: "black"}}>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {links.map((link, index) =>
                        <SharedLinkRow
                            key={index}
                            index={index}
                            link={link}
                            refresh={request}
                        />
                    )}
                    </tbody>
                </table>
            </React.Fragment>
            }

        </div>
    );
};

export default SharedLinksTable;
