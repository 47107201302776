import React, {useEffect, useState} from 'react'
import {DatePicker} from 'antd';
import moment from 'moment';
import {dateFormat} from "../../../../../service/DateService";

function DateTimeCell(props) {

    const initialState = {
        loading: false,
        error: null,
        table: props.table,
        boardId: props.boardId,
        date: null,
        dateString: null,
    }

    const [state, setState] = useState(initialState)

    useEffect(() => {
        var entryAux = props.row.entries.find(function(obj){
            return obj.column === props.column.id
        })
        setState({
            ...state,
            date: entryAux !== undefined ? new Date(entryAux.value): "",
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const updateValue = date => {
        if(date !== null){
            let d = new Date(date)
            d.setHours(0);
            setState({
                ...state,
                date: d,
            })
        }else{

            return;
        }

        setState({
            ...state,
            loading: false,
            date: new Date(date),
        })

        props.setRowValue({
            row   : props.row,
            column: props.column,
            value : new Date(date),
        })
    }

    return (
        <div className="cell-entry datetime-cell">
            { state.date != null &&
            <DatePicker
                value= {state.date ? moment(state.date, dateFormat) : null}
                format={dateFormat}
                onChange={date => updateValue(date)}
            />
            }
        </div>
    )
}

export default DateTimeCell
