import React, { useState, useEffect} from 'react'
import UsersTable from './UsersTable';
import InviteModal from './InviteModal';
import { API_DASHBOARD } from '../../constants';
import clientAPI from "../../service/ApiService"
import { toast } from 'react-toastify';

function UsersPage() {

    const initialState = {
        users: null,
        boards: null,
        pending: null,
        error: null,
        loading: false,
    }
    const [state, setState] = useState(initialState)
    const [needReload, setNeedReload] = useState(false)

    const reload = () => {
        setNeedReload(true)
    }
    const reloadAck = () => {
        getDashboard();
        setNeedReload(false)
    }

    useEffect(() => {
        getDashboard();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needReload]);

    const getDashboard = () =>{

        setState({
            ...state,
            error:null,
            loading: true,
        })

        clientAPI.get(API_DASHBOARD)
        .then(response => {
            setState({
                ...state,
                error:null,
                loading: false,
                users: response.data.payload.users,
                boards: response.data.payload.boards,
                pending: response.data.payload.pending,
            })
        })
        .catch(error => {
            if(error && error.response && error.response.data.statusCode.msg){
                setState({
                    ...state,
                    error: error.response.data.statusCode.msg,
                    loading: false,
                    users: null,
                    boards: null,
                })
            }else{
                toast.error('Error connecting with the server, check your connection or contact support');
            }
        })
    }

    return (
        <div className="main-container users-page page-inner">
            <h3>Users</h3>
            <div className="metrics">
                <div className="users">
                    <p className="value">{state.users ?? 0}</p>
                    <p className="metric">Users</p>
                </div>
                <div className="pending">
                    <p className="value">{state.pending ?? 0}</p>
                    <p className="metric">Pending Users</p>
                </div>
            </div>

            <div className="actions">
                <InviteModal reload={reload} />
            </div>
            <UsersTable needReload={needReload} reloadAck={() => reloadAck()} />
        </div>
    )
}

export default UsersPage
