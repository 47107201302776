import React, {useEffect, useState} from 'react'
import {Alert, Button, Form, Input, Modal} from 'antd';


function CreateTableModal({open, setTableName}) {

    const initialState = {
        error: null,
        visible: false,
    }
    const [state, setState] = useState(initialState)

    useEffect(() => {
        if(open === 0 || !open) return;
        setState({
            ...state,
            visible: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleCancel = e => {
        setState({
            ...state,
            visible: false,
        });
    };

    const handleOk = e => {
        setTableName(e.name);
        setState({
            ...state,
            visible: false,
        });
    };


    return (
            <Modal
                title="Create table"
                visible={state.visible}
                okText="Create"
                className="create-table-modal"
                centered
                onOk={() => handleOk()}
                onCancel={() => handleCancel()}
                okButtonProps={{form: 'change-table-name', key: 'submit', htmlType: 'submit'}}
                footer={[
                    <Button type={"primary"}
                            form="myForm"
                            key="submit"
                            htmlType="submit"
                    >
                        Create
                    </Button>
                ]}
            >
                {state.error && <Alert message={state.error} type="error" closable className="notification"/>}

                <Form
                    name="basic"
                    requiredMark={false}
                    layout="vertical"
                    id="myForm"
                    onFinish={handleOk}
                >
                    <Form.Item
                        label="Table name"
                        name="name"
                        rules={[{ required: true, message: 'Please input a table name' }]}
                    >
                        <Input
                            autoFocus={true}
                        />
                    </Form.Item>

                </Form>
            </Modal>
    )
}

export default CreateTableModal
