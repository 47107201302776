import React, {useState} from 'react'
import {Form} from 'react-bootstrap';
import {Alert, Button, Input} from 'antd';
import {Controller, useForm} from "react-hook-form";
import axios from 'axios'
import {API_FORGET} from '../../../constants/index';

function ForgetPanel({goToRegister, goToLogin, goToForget}) {

    const initialState = {
        error: null,
        loading: false,
        success: false
    }

    const [state, setState] = useState(initialState)
    const { handleSubmit, control, errors } = useForm();
    const onSubmit = values => {
        console.log(values);
        setState({
            ...state,
            error: null,
            loading: true
        })
                
        axios.post(API_FORGET, values)
        .then(response => {
            console.log(response);
            setState({
                ...state,
                error:null,
                loading: false,
                success: true
            })
        })
        .catch(error => {
            console.log(error)
            setState({
                ...state,
                error: error?.response?.data?.statusCode?.msg ?? 'Error connecting with the server, check your connection or contact support',
                loading: false,
                success: false
            })
        })
    };

    return (
        <div>
            <p className="other-actions">
                <button onClick={() => goToLogin()}> <h5>Login</h5></button>
                <button onClick={() => goToRegister()} style={{ marginLeft: "10px" }}> <h5>Register</h5> </button>
            </p>
            {state.error && <Alert message={state.error} type="error" closable className="notification" />}
            {state.success && <Alert message="We have send you an email with further instructions." className="notification" type="success" />}
            <h3>Forget password</h3>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Controller
                        as={Input}
                        name='email'
                        control={control}
                        placeholder="Email"
                        rules={{
                            pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                            required: true,
                        }}
                    />

                    {errors.email && (
                        <span className="alert-danger">Invalid email address</span>
                    )}
                </Form.Group>
                <Button variant="primary" type="primary" htmlType="submit" className="login-form-button" loading={state.loading}>
                    Send
                </Button>
            </Form>
        </div>
    )
}

export default ForgetPanel
