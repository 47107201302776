import React, {useEffect, useState} from 'react'
import {Alert, Button, Form} from 'react-bootstrap';
import {useForm} from "react-hook-form";
import axios from 'axios'
import {API_RESET_PWD} from '../../../constants/index';
import {Box, Container, SpinnerLoading} from '../../Style/styles.css'

function ResetPasswordPage(props) {

    const initialState = {
        error: null,
        loading: false,
        token: props.match.params.token,
        success: false,
    }
    const [state, setState] = useState(initialState)


    useEffect(() => {}, []);
    const { handleSubmit, register, errors } = useForm();
    const changePassword = (values) => {
        
        setState({
            ...state,
            error: null,
            loading: true,
        })
        
        axios.post(API_RESET_PWD, {"token":state.token, "password":values.password})
        .then(response => {
            console.log(response);
            setState({
                ...state,
                error:null,
                loading: false,
                success: true
            })
        })
        .catch(error => {
            console.log(error)
            setState({
                ...state,
                error: error?.response?.data?.statusCode?.msg ?? 'Error connecting with the server, check your connection or contact support',
                loading: false,
                success: false
            })
        })
    };

    return (
        <Container>
            <Box>
                <h3>Reset password</h3>
                { state.error && <Alert variant={"danger"}>{state.error}</Alert>}
                { state.success && <p> Your password have been reset, you can <a href="/landing#login">login</a> again</p>}
                { !state.success &&
                    <Form onSubmit={handleSubmit(changePassword)}>
                    <Form.Group controlId="formBasicPassword">
                            <Form.Label>Please enter a new password</Form.Label>
                            <input 
                                name="password" 
                                className="form-control" 
                                placeholder="Password" 
                                type="password"
                                ref={register({
                                    required: "You must specify a password",
                                    minLength: {
                                    value: 3,
                                    message: "Password must have at least 3 characters"
                                    }
                                })}
                            />
                            <p className="alert-danger">{errors.password && errors.password.message}</p>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                        {state.loading && <SpinnerLoading animation="border" role="status"></SpinnerLoading>}
                    </Form>
                 }
            </Box>
        </Container>
    )
}

export default ResetPasswordPage
