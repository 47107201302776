import React, { useState, useContext, useEffect } from 'react'
import UserIcon from '../../../Common/UserIcon';
import { FaPlusCircle, FaRegUserCircle } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import clientAPI from "../../../../service/ApiService"
import { toast } from 'react-toastify';
import {SET_SINGLE_VALUE} from "../../../../state/boards/BoardTypes";
import {BoardContext} from "../../../../state/boardStore";
import {GENERIC_ERR_MSG} from "../../../../constants/constants";

function PeopleButton(props) {

    const initialState = {
        numPlus: 0,
        usersToDisplay: []
    }
    const [state, setState] = useState(initialState)
    const [isShown, setIsShown] = useState(false);
    const [hoverDelete, setHoverDelete] = useState(false);
    const dispatchBoard = useContext(BoardContext)[1];

    useEffect(() => {

        if(props.users.length > 3){
            setState({
                ...state,
                numPlus: props.users.length - 2,
                usersToDisplay : props.users.slice(0,2)
            })
        }else{
            setState({
                ...state,
                usersToDisplay : props.users
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.users]);

    const removeAll = (event) => {
        event.preventDefault();
        event.stopPropagation();
        
        clientAPI.post(props.path, {"value": []})
        .then(response => {
            setState({
                ...state,
                usersToDisplay : []
            })
            props.updateCell([])

            dispatchBoard({
                type: SET_SINGLE_VALUE,
                payload: {tableId: props.table.id, rowId: props.row.id, parentRowId: props.row.parent, columnId: props.column.id, value: []}
            });

        })
        .catch(error => {
            toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
        })
    }
    
    return (
        <div className="people-button" onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
            { isShown && !hoverDelete && 
                <FaPlusCircle className="add-people-cta" />
            }
            { state.usersToDisplay && state.usersToDisplay.map((user, index) =>
                <UserIcon key={index} user={user} disable={false} placement={"top"} />
            )}
            { props.users && props.users.length > 3 &&
                <div className="user-initials plus-button">+{state.numPlus}</div>
            }
            { isShown && state.usersToDisplay.length > 0 &&
                <div className="remove-people-cta" onClick={(e) => removeAll(e)} onMouseEnter={() => setHoverDelete(true)} onMouseLeave={() => setHoverDelete(false)}>
                    <IoMdCloseCircle />
                </div>
            }
            { state.usersToDisplay.length === 0 &&
                <FaRegUserCircle className="no-user-icon" />
            }
        </div>
    )
}

export default PeopleButton
