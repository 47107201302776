import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { API_URL, API_TEAMS_EDIT, API_USERS } from '../../constants/index';
import clientAPI from "../../service/ApiService"
import { toast } from 'react-toastify';
import { Modal, Tooltip, Alert, Input } from 'antd';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { generatePath } from 'react-router';
import {
    EditTwoTone
} from '@ant-design/icons';

function EditTeamModal({ team, refresh }) {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        visible: false,
        loadingUsers: false,
        usersAtTeam : null,
        name: null,
        path: API_URL + generatePath(API_TEAMS_EDIT, {
            team: team.id,
        }),
    }

    useEffect(() => {
        setState({
            ...state,
            path: API_URL + generatePath(API_TEAMS_EDIT, {
                team: team.id,
            }),
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team]);

    const showModal = () => {
        setState({
            ...state,
            visible: true,
            success: false,
            error: false,
        });
    };

    const handleOk = e => {
        triggerValidation()
            .then((result) => {
                if (result) {
                    handleSubmit(onSubmit)();
                }
            })
    };

    const handleCancel = e => {
        // console.log(e);
        setState({
            ...state,
            visible: false,
        });
        reset();
    };

    const [state, setState] = useState(initialState)

    useEffect(() => {
        
        var options = [];
        team.users.map(function (user) {
            options.push({ value: user.ID, label: (user.name +  " " + user.surname) });
            return user;
        })
        setState({...state, usersAtTeam: options, name: team.name})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team, state.visible]);

    const { handleSubmit, control, errors, triggerValidation, reset } = useForm();
    const onSubmit = values => {

        values.users = values.users != null ? values.users : [] 

        if(!values.users || values.users.length <= 0){
            toast.info("Please select at least one user for that team.")
            return;
        }

        let payload = {
            name : values.name,
            users : values.users.map(function (user) {
                return user.value
            })
        }

        setState({
            ...state,
            error: null,
            loading: true
        })

        clientAPI.post(state.path, payload)
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false,
                    success: true,
                    visible: false,
                    name: values.name,
                    users: payload.users,
                })
                reset();
                // reload();
                refresh();
            })
            .catch(error => {
                if (error && error.response && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    };

    const animatedComponents = makeAnimated();
    const promiseOptions = inputValue =>
        new Promise(resolve => {
            setState({
                ...state,
                error: null,
                loadingUsers: true,
            })
    
            clientAPI.get(API_USERS + "?page=1&limit=20&search=" + inputValue)
                .then(response => {
                    let users = response.data.payload.users;
                    var options = [];
                    users.map(function (user) {
                        options.push({ value: user.ID, label: (user.name +  " " + user.surname) });
                        return user;
                    })
    
                    setState({
                        ...state,
                        error: null,
                        loadingUsers: false
                    })
                    resolve(options);
                })
                .catch(error => {
                    if (error && error.response && error.response.data.statusCode.msg) {
                        setState({
                            ...state,
                            loadingUsers: false,
                        })
                    } else {
                        toast.error('Error connecting with the server, check your connection or contact support');
                    }
    
                })
        });

    return (
        <div>
            { state.usersAtTeam &&
                <Tooltip placement="bottom" title={"Edit team"}>
                    <EditTwoTone className={"edit-team-button"} onClick={() => showModal()} />
                </Tooltip>
            }
            { state.visible && 
                <Modal
                    title="Edit team"
                    visible={state.visible}
                    okText="Save"
                    className="create-team-modal"
                    onOk={() => handleOk()}
                    onCancel={() => handleCancel()}
                    okButtonProps={{ form: 'category-editor-form', key: 'submit', htmlType: 'submit' }}
                >
                    {state.error && <Alert message={state.error} type="error" closable className="notification" />}
                    {state.success && <Alert message="Changes saved." closable className="notification" type="success" />}

                    <Form onSubmit={handleSubmit(onSubmit)}>

                        <Form.Group controlId="formBasicName">
                            <Form.Label>Team name</Form.Label>
                            <Controller
                                as={Input}
                                name='name'
                                control={control}
                                placeholder="Name"
                                defaultValue={state.name}
                                rules={{
                                    required: true,
                                }}
                            />

                            {errors.name && (
                                <span className="alert-danger">Invalid name</span>
                            )}
                        </Form.Group>


                        <Form.Group controlId="formBasicUsers">
                            <Form.Label>Manage users</Form.Label>
                            <Controller
                                name="users"
                                defaultValue={state.usersAtTeam}
                                as={AsyncSelect}
                                isMulti
                                components={animatedComponents}
                                isLoading={state.loadingUsers}
                                loadOptions={promiseOptions}
                                control={control}
                                closeMenuOnSelect={false}
                                defaultOptions={true}
                                className="users-multi-select"
                                classNamePrefix="users-select"
                                rules={{ required: false }}
                            />
                        
                            {errors.users && (
                                <span className="alert-danger">Invalid users</span>
                            )}
                        </Form.Group>

                        {/* <Button variant="primary" type="primary" htmlType="submit" className="login-form-button" loading={state.loading}>
                            Invite
                        </Button> */}
                    </Form>
                </Modal>
            }

        </div>
    )
}

export default EditTeamModal
