import React from 'react'
import {Tooltip} from 'antd';

function UserIcon({disable, user, color, placement="bottom"}) {

    const UserInfo = <span className="d-inline-block user-thumb">
            {user?.thumb ?
                <img src={user?.thumb} className="profile-image-thumb" alt="User profile"/>
                :
                <p className="user-initials" style={{ backgroundColor: color || "green" }}>
                    {user?.name.substring(0, 2)}
                </p>
            }
        </span>

    return (
        <div>
            { !disable ?
                <Tooltip placement={placement} mouseEnterDelay={0.5} title={user?.name}>
                    {UserInfo}
                </Tooltip>
                :
                UserInfo
            }

        </div>
    )
}

export default UserIcon
