import React, {useState, useEffect} from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import clientAPI from "../../service/ApiService"
import { toast } from 'react-toastify';
import {API_URL} from "../../constants";

function SearchCategorySelect({ selectCategory, setCategoriesSelect, categoriesSelected }) {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        loadingCategories: false,
        currentCategoriesSelected: null,
        categories: [],
    }

    useEffect(() => {
        loadCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // if(!categoriesSelected) return;

        setState({
            ...state,
            currentCategoriesSelected: categoriesSelected?.map(category => {return {value:category, label:category}}) ?? null
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoriesSelected]);

    const [state, setState] = useState(initialState)
    const animatedComponents = makeAnimated();

    const loadCategories = () => {

        setState({
            ...state,
            error: null,
            loadingCategories: true,
        })

        clientAPI.get(API_URL + "/categories/all")
            .then(response => {

                let categories = response.data.payload;
                var options = [];
                if(categories && categories.length > 0){
                    categories.map(function (category) {
                        options.push({ value: category, label: category });
                        return category;
                    })
                }
                setCategoriesSelect(options);
                setState({
                    ...state,
                    error: null,
                    loadingCategories: false,
                    categories: options,
                })

            })
            .catch(error => {
                setState({
                    ...state,
                    loadingCategories: false,
                })
                if (error && error.response && error.response.data.statusCode.msg) {
                    console.log(error);
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })

    };

    const customStyles = {
        control: (styles, state) => {
        return {
            ...styles,
            boxShadow: state.isFocused ? "0 0 0 2px rgba(24, 144, 255, 0.2)" : 0,
            borderColor: state.isFocused ? "#40a9ff" : "#CED4DA",
            "&:hover": {
                borderColor: state.isFocused ? "#40a9ff" : "#CED4DA"
            }
        }}
    };



    return (
        <div className={"category-select"}>
            <Select
                isMulti
                name="categories"
                className="categories-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={false}
                defaultOptions={true}
                isLoading={state.loadingCategories}
                options={state.categories}
                components={{...animatedComponents, DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                styles={customStyles}
                onChange={selectCategory}
                value={state.currentCategoriesSelected}
            />
        </div>
    )
}

export default SearchCategorySelect