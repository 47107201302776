import React from 'react'
import BoardIcon from './BoardIcon';
import {Link} from 'react-router-dom';
import StarBoardButton from '../Common/StarBoardButton'
import UserIcons from '../Common/UserIcons'
import TeamIcons from '../Common/TeamIcons'

function BoardRow({ board, refresh }) {
    return (
        <tr className="board-row">
            <td className="board-name-row">
                <BoardIcon board={board} color={board.color} />
                <div className="vertical-div">
                    <div className="d-flex">
                        <div className="name"><Link to={"/boards/" + board.id}>{board.name}</Link></div>
                        <StarBoardButton board={board} />
                    </div>
                    <div className="visibility">{board.visibility}</div>
                </div>

            </td>
            <td className="categories">
                <div className="d-flex">
                { board.categories && board.categories.length > 0 && board.categories.map((category, index) =>
                    <div className="category" key={index}>
                        <span className={"label"}>{category}</span>
                    </div>
                )}
                </div>
            </td>
            <td className="num">
                { board.users && board.users.length > 0 &&
                    <UserIcons users={board.users} />
                }
            </td>
            <td className="num">
                { board.teams && board.teams.length > 0 &&
                    <TeamIcons teams={board.teams} />
                }
            </td>
        </tr>
    )
}

export default BoardRow
