import React from 'react'
import {DEFAULT_CELL_WIDTH} from "../../constants/constants";
import {DEFAULT_ACTION_WIDTH} from "../../constants/constants";

function RecapRow(props) {
    
    const sumColumnValues = column => {

        let sum = 0;
        props.table.rows.map((row) => {
            row.entries.map((entry) => {
                if(entry.column === column.id){
                    sum += parseInt(entry.value)
                }
                return entry;
            })
            return row;
        })

        return sum;
    }

    return (    
        <div  className="row-entry">
            <div className="empty-cell"
                 style={{ width: ((props.table.actionWidth) ? props.table.actionWidth : DEFAULT_ACTION_WIDTH) }} ></div>
            <div className="cells-container">
                { props.table && props.table.columns.map((column, index) =>
                    <div key={column.id}>
                        { column.type.type === "number"
                            ?
                            <div className="sum-cell"
                                 style={{ width: ((column.width) ? column.width + 1 : DEFAULT_CELL_WIDTH + 1) }}
                            >
                                <span className="label">SUM</span>
                                <span className="value">{ sumColumnValues(column) }</span>
                            </div>
                            : <div className="empty-cell"
                                   style={{ width: ((column.width) ? column.width : DEFAULT_CELL_WIDTH)
                                   }}
                            ></div> }
                    </div>
                )}
            </div>
        </div>
    )
}

export default RecapRow
