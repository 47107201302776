import React, {useContext, useEffect, useState} from 'react';
import SortableRows from "./SortableRows";
import {BoardContext} from "../../state/boardStore";
import AddRow from "./AddRow";
import {API_ROW_ID, API_URL} from "../../constants";
import clientAPI from "../../service/ApiService";
import {SET_SUB_ROWS} from "../../state/boards/BoardTypes";
import {toast} from "react-toastify";
import {GENERIC_ERR_MSG} from "../../constants/constants";
import {generatePath} from 'react-router';
import {Spin} from 'antd';

function SubTasks({boardId, table, row}) {

    const [boardState, dispatchBoard] = useContext(BoardContext);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(row.rows?.length>0)
            return

        getRows();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getRows = () => {

        setLoading(true);
        let path = API_URL + generatePath(API_ROW_ID, {
            board: boardId,
            table: table.id,
            row:   row.id,
        });

        return clientAPI.get(path)
            .then(response => {
                let rows = response.data.payload;
                setLoading(false);
                dispatchBoard({type: SET_SUB_ROWS, payload: {tableId: table.id ,rows: rows, parentRowId: row.id}});
            })
            .catch(error => {
                setLoading(false);
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }


    return (
        <div className={"subtasks"}>
            { loading && <Spin/>}
            { !loading &&
                <React.Fragment>
                    <SortableRows rows={row.rows}
                                  boardId={boardState.board.id}
                                  table={table}
                                  parent={row.id}
                    />
                    {/*{table && table?.rows?.length > 0 && boardState.board &&*/}
                    {/*    <RecapRow boardId={boardState.board.id} table={table} />*/}
                    {/*}*/}
                </React.Fragment>
            }
            {table &&
                <div className="table-footer">
                    <AddRow boardId={boardState.board.id} table={table} parentRowId={row.id} />
                </div>
            }
        </div>
    );
}

export default SubTasks;