import React, {useContext, useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {colors} from '@atlaskit/theme';
import Column from './Column';
import reorder, {reorderQuoteMap} from './reorder';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {SET_SINGLE_VALUE, UPDATE_KANBAN_ROW_ORDER, UPDATE_KANBAN_STATUS_ORDER} from "../../../state/boards/BoardTypes";
import {BoardContext} from "../../../state/boardStore";
import clientAPI from "../../../service/ApiService";
import {API_URL} from "../../../constants";
import {toast} from "react-toastify";
import RowModal from "../../Common/RowModal/RowModal";
import {GENERIC_ERR_MSG} from "../../../constants/constants";

const Container = styled.div`
  // background-color: ${colors.B100};
  // min-height: 100vh;
  /* like display:flex but will allow bleeding over the window width */
  // min-width: 100vw;
  display: inline-flex;
`;

function Kanban({table, containerHeight, useClone, isCombineEnabled, withScrollableColumns}) {

    const initialState = {
        isCombineEnabled: false,
        columns: null,
        openRowModal: 0,
        selectedRow: null,
    };
    const [state, setState] = useState(initialState)
    const [boardState, dispatchBoard] = useContext(BoardContext);

    useEffect(() => {

        // eslint-disable-next-line array-callback-return
        const column = table?.columns.find(column => {
            if (column.id === table.kanbanStatusColumn) {
                return true;
            }
        })

        if(!column)return

        const metadata = column?.type?.metadata.sort((a, b) => (a.position > b.position) ? 1 : -1)

        if(!table.rows) return;
        const columns = metadata.map(column => {
            let entries = table.rows.filter(row => {
                // eslint-disable-next-line array-callback-return
                return row.entries.find(entry => {
                    if(entry.value === column.id){
                        return true;
                    }
                })
            })
            entries = entries.sort((a, b) => (a.kanbanPosition > b.kanbanPosition) ? 1 : -1)
            return {id: column.id, name: column.name, quotes: entries.map(entry => {return {id: entry.id, content: entry.name}})}
        })

        let noState = [];
        table.rows.map(row => {
            // eslint-disable-next-line array-callback-return
            let a = row.entries.find(entry => {
                if(entry.column === column.id){
                    return entry;
                }
            })
            if(a === undefined){
                noState.push(row);
            }
            return row
        })

        if(noState.length > 0){
            let unassigned = {id: "unassigned", name: "Unassigned", quotes: noState.map(entry => {return {id: entry.id, content: entry.name}})}
            columns.unshift(unassigned);
        }

        setState({
            ...state,
            columns: columns,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table, boardState.board]);


    const onDragEnd = (result) => {

        // dropped nowhere
        if (!result.destination) {
            return;
        }

        const source = result.source;
        const destination = result.destination;

        // did not move anywhere - can bail early
        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }

        if(destination.droppableId === 'unassigned') return;

        // reordering column
        if (result.type === 'COLUMN') {

            const ordered = reorder(
                state.columns,
                source.index,
                destination.index,
            );

            setState({
                ...state,
                columns: ordered,
            });

            const payload = {status: ordered.filter(column=> column.id !== 'unassigned').map(column => {return column.id})};
            clientAPI.post(API_URL + "/board/" + boardState.board.id +
                "/table/" + table.id + "/column/" + table.kanbanStatusColumn + "/kanban/position" , payload )
                .then(response => {
                    // setState({
                    //     ...state,
                    //     error: null,
                    //     loading: false,
                    // })
                    dispatchBoard({type: UPDATE_KANBAN_STATUS_ORDER,
                        payload:{
                            tableId:  table.id,
                            columnId: table.kanbanStatusColumn,
                            status:   ordered,
                        }
                    });
                })
                .catch(error => {
                    setState({
                        ...state,
                        loading: false,
                    })
                    toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
                })

            return;
        }

        // console.log(result);
        // console.log(source);
        // console.log(destination);

        // console.log({tableId: table.id,
        //     rowId: result.draggableId,
        //     columnId: table.kanbanStatusColumn,
        //     value: destination.droppableId});

        const data = reorderQuoteMap({
            quoteMap: state.columns,
            source,
            destination,
        });

        const sourcePayload = data.quoteMap.find((column) => {
            return column.id === source.droppableId
        }).quotes.map((quote, i) => {
            return {row: quote.id, position: i+1};
        })
        sortApi(sourcePayload);

        const destinationPayload = data.quoteMap.find((column) => {
            return column.id === destination.droppableId
        }).quotes.map((quote, i) => {
            return {row: quote.id, position: i+1};
        })
        sortApi(destinationPayload);
        setStatus(result.draggableId, destination.droppableId)

        // Let's create a map
        let positions = {};
        data.quoteMap.map(column => {
            // eslint-disable-next-line array-callback-return
            column.quotes.map((quote, i) => {
                positions[quote.id] = i+1
            })
            return column;
        })

        dispatchBoard({
            type: UPDATE_KANBAN_ROW_ORDER,
            payload: {tableId: table.id, positions: positions}
        });

        // setState({
        //     ...state,
        //     columns: data.quoteMap,
        // });
    };

    const sortApi = (payload) => {
        clientAPI.post(API_URL + "/board/" + boardState.board.id +
            "/table/" + table.id + "/kanban/position", payload )
            .then(response => {
                // setState({
                //     ...state,
                //     error: null,
                //     loading: false,
                // })
            })
            .catch(error => {
                setState({
                    ...state,
                    loading: false,
                })
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    const setStatus = (row, val) => {

        dispatchBoard({
            type: SET_SINGLE_VALUE,
            payload: {tableId: table.id,
                rowId: row,
                columnId: table.kanbanStatusColumn,
                value: val}
        });

        clientAPI.post(API_URL + "/board/" + boardState.board.id +
            "/table/" + table.id + "/column/" + table.kanbanStatusColumn + "/row/" +  row + "/entry" , {"value": val})
            .then(response => {

            })
            .catch(error => {
                if(error && error.response && error.response.data.statusCode.msg){
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false,
                    })
                }else{
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    }

    const openRowModal = (rowId) => {

        // eslint-disable-next-line array-callback-return
        let row = table.rows.find(entry => {
            if(entry.id === rowId){
                return entry;
            }
        });

        setState({
           ...state,
           selectedRow: row,
           openRowModal: state.openRowModal+1
        });
    }

    return (
        <React.Fragment>
            <DragDropContext onDragEnd={onDragEnd}>
                <React.Fragment>
                    { state?.columns &&
                    <Droppable
                        droppableId="board"
                        type="COLUMN"
                        direction="horizontal"
                        ignoreContainerClipping={containerHeight}
                        isCombineEnabled={isCombineEnabled}
                    >
                        {(provided) => (

                            <Container ref={provided.innerRef} {...provided.droppableProps}>

                                {state.columns?.map((column, index) => (
                                    <Column
                                        key={column.id}
                                        table={table}
                                        index={index}
                                        columnId={column.id}
                                        kanbanColumn={table.kanbanStatusColumn}
                                        boardId={boardState.board.id}
                                        title={column.name}
                                        quotes={column.quotes}
                                        isScrollable={withScrollableColumns}
                                        isCombineEnabled={isCombineEnabled}
                                        useClone={useClone}
                                        openRowModal={openRowModal}
                                    />
                                ))}
                                {provided.placeholder}
                            </Container>
                        )}
                    </Droppable>
                    }
                </React.Fragment>
            </DragDropContext>
            <RowModal counter={state.openRowModal} table={table} row={state.selectedRow} />
        </React.Fragment>
    );
}

export default Kanban;
