import React, {useContext, useEffect, useState} from 'react'
import {SpinnerLoading} from '../Style/styles.css'
import {Alert} from 'react-bootstrap';
import clientAPI from "../../service/ApiService"
import {API_BOARD, API_TABLE, API_URL} from '../../constants';
import {
    CLEANUP,
    CREATE_TABLE,
    DELETE_BOARD,
    DESELECT_ALL_ROWS,
    EDIT_BOARD,
    OPEN_CREATE_BOARD_MODAL,
    OPEN_LOGS_DRAWER,
    UPDATE_BOARD,
    UPDATE_BOARD_NAME
} from '../../state/boards/BoardTypes';
import {BoardContext} from '../../state/boardStore'
import {BoardsContext} from '../../state/boardsStore'
import {useHistory, useParams} from "react-router-dom";
import EdiText from 'react-editext'
import CreateTableModal from './CreateTableModal'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {generatePath} from 'react-router';
import MessageDrawer from './MessageDrawer'
import LogDrawer from './LogDrawer'
import ConnectedUsers from './ConnectedUsers'
import SortableTables from './SortableTables';
import {Button, Dropdown, Menu, Row} from 'antd';
import DuplicateBoard from './DuplicateBoard';
import BulkActions from "./RowBulkActions";
import StarBoardButton from "../Common/StarBoardButton";
import {GENERIC_ERR_MSG} from "../../constants/constants";
import ShareModal from "./Share/ShareModal";
import SaveTemplateBoardModal from "./Template/SaveTemplateBoardModal";
import UsersAndTeams from "./UsersAndTeams";
import AssetsOnboarding from 'assets/onboarding-assets.svg';
import RichTextOnboarding from 'assets/onboarding-rich-text.svg';
import TableOnboarding from 'assets/onboarding-table.svg';
import Xarrow from "react-xarrows";
import BoardNotFoundPage from "../OtherPages/BoardNotFoundPage";
import CreateRichTextModal from "./CreateRichTextModal";

function BoardPage() {

    const initialState = {
        loading: false,
        error: null,
        ctModalShow: 0,
        richTextModalShow: 0,
        openDuplicateModal: 0,
        shareModal: 0,
        templateModal: 0,
        startDragging: 0,
        notAllowed: false,
    }
    const [state, setState] = useState(initialState)
    const [boardState, dispatchBoard] = useContext(BoardContext);
    const [boardsState, dispatchBoards] = useContext(BoardsContext);
    const history = useHistory();
    const param = useParams();

    useEffect(() => {

        if(boardState == null || boardState.board === null || boardState.board.id === null || boardState.board.id.length === 0 ) return;
        if(boardState.board.id === param.id || state.loading === true) return;

        setState({
            ...state,
            loading: true,
        })
        getBoard();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param]);

    useEffect(() => {

        getBoard();

        return function cleanup() {
            if (boardsState.socket && boardsState.socket.readyState === WebSocket.OPEN) {
                boardsState.socket.send(JSON.stringify({"endpoint": "leaveBoard", "data": param.id}));
            }
            dispatchBoard({type: CLEANUP});
            if (boardState.selectedTable)
                dispatchBoard({type: DESELECT_ALL_ROWS});
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // // We maintain in sync props board and boardState.board
    // useEffect(() => {
    //     dispatchBoard({type: UPDATE_BOARD, payload: board});
    // }, [board]);

    // We can either receive the board from the websocket or from here, but doing it from here we save the time of the first request
    const getBoard = () => {

        setState({
            ...state,
            loading: true,
        })

        clientAPI.get(API_BOARD + "/" + param.id)
            .then(response => {
                setState({
                    ...state,
                    loading: false,
                })

                dispatchBoard({type: UPDATE_BOARD, payload: response.data.payload});
            })
            .catch(error => {
                if(error?.response?.data?.statusCode?.code === -7){
                    setState({
                        ...state,
                        notAllowed: true,
                    })
                }else{
                    setState({
                        ...state,
                        loading: false,
                    })
                    toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
                }
            })
    }

    const deleteBoard = () => {
        setState({
            ...state,
            loading: true,
        })
        clientAPI.delete(API_BOARD + "/" + param.id)
            .then(response => {
                setState({
                    ...state,
                    loading: false,
                })
                dispatchBoards({type: DELETE_BOARD, payload: param.id});
                // dispatchBoard({type: UPDATE_BOARD, payload: board.id});
                history.push("/boards");
            })
            .catch(error => {
                setState({
                    ...state,
                    loading: false,
                })
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    const editBoardTitle = val => {
        clientAPI.put(API_BOARD + "/" + param.id + "/description", {"name": val})
            .then(response => {
                setState({
                    ...state,
                    loading: false,
                })
                dispatchBoards({type: EDIT_BOARD, payload: {"id": param.id, "name": val}});
                dispatchBoard({type: UPDATE_BOARD_NAME, payload: val});
            })
            .catch(error => {
                setState({
                    ...state,
                    loading: false,
                })
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    const exportToExcel = () => {
        clientAPI.get(API_BOARD + "/" + param.id + "/export", {responseType: 'arraybuffer'})
            .then(response => {
                let filename = response.headers.filename ? response.headers.filename : "board.xlsx";
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                setState({
                    ...state,
                    loading: false,
                })
                toast.error("Error exporting to excel please contact support");
            })
    }

    const boardDuplicated = ((board) => {
        toast.info('Your board have been duplicated');
    });

    const setLoading = ((value) => {
        setState({
            ...state,
            loading: value,
        })
    });

    const createTable = (tableType, name) => {
        const path = API_URL + generatePath(API_TABLE, {
            board: boardState.board.id,
        });
        clientAPI.post(path, {"type": tableType, "name": name})
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false,
                    success: true,
                })
                let table = response.data.payload;
                if (tableType === "copy")
                    table.editMode = true;
                dispatchBoard({type: CREATE_TABLE, payload: {table: table}});
                // dispatchBoard({type: UPDATE_BOARD, payload:response.data.payload});
            })
            .catch(error => {
                console.log(error)
                // setState({
                //     ...state,
                //     error: error.response.data.statusCode.msg,
                //     loading: false
                // })
            })
    }

    const menu = (
        <Menu className={"table-menu"} inlineIndent={0}>

            <Menu.Item onClick={() =>
                dispatchBoards({type: OPEN_CREATE_BOARD_MODAL, payload: {modalMode: "edit", board: boardState.board}})
            }>Edit</Menu.Item>
            <Menu.Item onClick={() => {
                dispatchBoard({type: OPEN_LOGS_DRAWER, payload: {}});
            }}>View History</Menu.Item>
            <Menu.Item onClick={() => {
                setState({...state, openDuplicateModal: state.openDuplicateModal + 1})
            }}>Duplicate</Menu.Item>
            <Menu.Item onClick={() => {
                setState({...state, startDragging: state.startDragging + 1})
            }}>Reorder tables</Menu.Item>
            <Menu.Item onClick={() => {
                setState({...state, templateModal: state.templateModal + 1})
            }}>Save as template</Menu.Item>
            <Menu.Item onClick={() => exportToExcel()} download>Export to Excel</Menu.Item>
            <Menu.Item onClick={() => setState({...state, shareModal: state.shareModal + 1})}>Share</Menu.Item>

            <Menu.Divider/>
            <Menu.Item className={"delete-table"} onClick={() => {
                if (window.confirm('Are you sure you wish to delete this board?')) deleteBoard()
            }}>Delete</Menu.Item>
        </Menu>

    );

    return (
        <div className="main-container board-page">

            {state.notAllowed ?
                <BoardNotFoundPage/>
                :

                <React.Fragment>
                    {state.error && <Alert variant={"danger"}>{state.error}</Alert>}
                    <div className="actions">
                <span className="name-spinner">
                    <div className="title-desc">
                        <h3><EdiText
                            submitOnEnter
                            cancelOnEscape
                            submitOnUnfocus
                            hideIcons={true}
                            editOnViewClick={true}
                            showButtonsOnHover={false}
                            editButtonClassName="hide"
                            saveButtonClassName="hide"
                            cancelButtonClassName="hide"
                            value={boardState.board?.name}
                            onSave={editBoardTitle}
                            className="edit-button"/></h3>
                    </div>

                    {state.loading && <SpinnerLoading animation="border" role="status"/>}
                </span>
                        <div className="right-actions">
                            <Dropdown overlay={menu} trigger={['click']}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()} href="/#">
                                    <span className="threedots"/>
                                </a>
                            </Dropdown>

                            {boardState.board && boardState.board.id &&
                                <StarBoardButton board={boardState.board}/>
                            }

                            <Button className="share-button" onClick={() =>
                                dispatchBoards({
                                    type: OPEN_CREATE_BOARD_MODAL,
                                    payload: {modalMode: "edit", board: boardState.board}
                                })
                            } style={{textTransform: 'capitalize'}}>{boardState?.board?.visibility}</Button>

                            {boardState?.board &&
                                <UsersAndTeams board={boardState?.board}/>
                            }

                            <ConnectedUsers/>

                        </div>
                    </div>

                    {boardState.board &&
                        <div className="board-container">
                            <div className="tables-container">

                                {boardState.board && boardState.board.id && boardState.board.tables &&
                                    <SortableTables
                                        board={boardState.board}
                                        setLoading={setLoading}
                                        startDragging={state.startDragging}
                                    />
                                }
                                {boardState.board && boardState.board.id && boardState.board.tables.length <= 0 &&
                                    <div className={"empty-board"}>
                                        <div className="board-onboarding">
                                            <div id="table-onboarding"
                                                 onClick={() => setState({
                                                     ...state,
                                                     ctModalShow: state.ctModalShow + 1
                                                 })}
                                            >
                                                <img src={TableOnboarding} alt="Table"/>
                                                <h3>Start with a table</h3>
                                                <p>A table is the main element of a board and will help you organize
                                                    your projects by assigning resources to tasks.</p>
                                            </div>
                                            <div id="rich-content-onboarding"
                                                 onClick={() => setState({
                                                     ...state,
                                                     richTextModalShow: state.richTextModalShow + 1
                                                 })}
                                            >
                                                <img src={RichTextOnboarding} alt="Rich text"/>
                                                <h3>Give context with rich text</h3>
                                                <p>You can add images, or rich text in order to give indications, or
                                                    explain what is the purpouse of this board.</p>
                                            </div>
                                            <div id="assets-onboarding"
                                                 onClick={() => createTable("assets", "Files")}
                                            >
                                                <img src={AssetsOnboarding} alt="Assets"/>
                                                <h3>Add assets/files</h3>
                                                <p>Adding assets per board will help you to give context via the assets
                                                    related to the project or task. </p>
                                            </div>
                                        </div>
                                        <div className="arrows">
                                            <Xarrow
                                                start="table-onboarding"
                                                end="create-table-cta"
                                                color={"rgba(20,20,20,0.15)"}
                                                strokeWidth={1}
                                                startAnchor={"bottom"}
                                                endAnchor={"top"}
                                            />
                                            <Xarrow
                                                start="rich-content-onboarding"
                                                end="create-copy-cta"
                                                color={"rgba(20,20,20,0.15)"}
                                                strokeWidth={1}
                                                startAnchor={"bottom"}
                                                endAnchor={"top"}
                                            />
                                            <Xarrow
                                                start="assets-onboarding"
                                                end="create-assets-cta"
                                                color={"rgba(20,20,20,0.15)"}
                                                strokeWidth={1}
                                                startAnchor={"bottom"}
                                                endAnchor={"right"}
                                            />
                                        </div>
                                    </div>
                                }

                                <div className="add-elements-container">

                                    <Row>
                                        <p>Add</p>
                                        <Button onClick={() => setState({...state, ctModalShow: state.ctModalShow + 1})}
                                                variant="outline-dark"
                                                className="create-table-cta"
                                                id={"create-table-cta"}
                                                type="primary"
                                                shape="round"
                                                size={"default"}
                                        >
                                            Table
                                        </Button>

                                        <Button onClick={() => setState({...state, richTextModalShow: state.richTextModalShow + 1})}
                                                variant="outline-dark"
                                                className="create-table-cta"
                                                id={"create-copy-cta"}
                                                type="primary"
                                                shape="round"
                                                size={"default"}
                                        >
                                            Rich Text
                                        </Button>

                                        <Button onClick={() => createTable("assets", "Files")}
                                                variant="outline-dark"
                                                className="create-table-cta"
                                                id={"create-assets-cta"}
                                                type="primary"
                                                shape="round"
                                                size={"default"}
                                        >
                                            Assets
                                        </Button>
                                    </Row>

                                </div>
                            </div>

                            <CreateTableModal
                                open={state.ctModalShow}
                                board={boardState.board.id}
                            />

                            <CreateRichTextModal
                                open={state.richTextModalShow}
                                board={boardState.board.id}
                            />

                            <ShareModal
                                open={state.shareModal}
                                boardId={boardState.board.id}
                            />

                            <SaveTemplateBoardModal
                                open={state.templateModal}
                                boardId={boardState.board.id}
                            />

                            <div>
                                <MessageDrawer visible={boardState.drawerVisible} row={boardState.row}/>
                                <LogDrawer visible={boardState.drawerVisible} row={boardState.row}/>
                            </div>

                            <DuplicateBoard board={boardState.board} boardDuplicated={boardDuplicated}
                                            counter={state.openDuplicateModal}/>

                            {boardState.selectedTable &&
                                <BulkActions/>
                            }
                        </div>
                    }
                </React.Fragment>
            }
        </div>

    )
}

export default BoardPage;