import React from 'react'
import { IoMdClose } from "react-icons/io";
import UserIcon from "../../../../../Common/UserIcon";

function UserCard({removeUser, user}) {

    const remove = val => {
        removeUser(user);
    }

    return (
        <div className="user-card"> 
            <div className="user-card-inner">
                <UserIcon user={user} disable={true} />
                <p>{user?.name}</p>
                <div className="remove-user-cta" onClick={(e) => remove(e)}>
                    <IoMdClose />
                </div>
            </div>
        </div>
    )
}

export default UserCard
