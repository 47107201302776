import React, {useEffect, useState} from 'react'
import clientAPI from "../../service/ApiService"
import {API_USERS} from '../../constants/index';
import {SpinnerLoading} from '../Style/styles.css'
import {toast} from 'react-toastify';
import {Alert, Input} from 'antd';
import UserRow from './UserRow'
import UsersPagination from './UsersPagination'

function UsersTable({ needReload, reloadAck, reload }) {

    const initialState = {
        page: 1,
        users: [],
        sizePerPage: 10,
        totalSize: 0,
        error: null,
        loading: false
    }
    const [state, setState] = useState(initialState)

    useEffect(() => {
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.page, state.sizePerPage]);

    useEffect(() => {
        if (needReload)
            getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needReload]);

    const getUsers = (search=null) => {
        setState({
            ...state,
            error: null,
            loading: true,
        })

        clientAPI.get(API_USERS + "?page=" + state.page + "&limit=" + state.sizePerPage + (search ? "&search=" + encodeURIComponent(search) : "" ))
            .then(response => {
                let users = response.data.payload.users;
                if(!users || users.length <= 0 ){
                    setState({...state, users:[], totalSize: 0, page: state.page > 1 ? state.page-1 : state.page})
                    return false
                }

                setState({
                    ...state,
                    error: null,
                    loading: false,
                    users: users,
                    totalSize: response.data.payload.pagination.total
                })

                reloadAck();
            })
            .catch(error => {

                let msg = error?.response?.data?.statusCode?.msg
                setState({
                    ...state,
                    error: msg ?? null,
                    loading: false,
                    users: [],
                    totalSize: null
                })
                if(!msg)
                    toast.error('Error connecting with the server, check your connection or contact support');

            })
    }

    const goToPage = ((page) => {
        setState({
            ...state,
            page: page
        })
    });

    const { Search } = Input;
    const [timer, setTimer] = useState(null)


    const setSearch = (event) => {
        var searchText = event.target.value;

        if (timer != null) {
            clearTimeout(timer);
        }

        setTimer(
            setTimeout(() => {
                getUsers(searchText)
            }, 300)
        )
    }

    return (
        <div className={"users-table-container"}>
            {state.error && <Alert message={state.error} type="error" closable className="notification" />}
            <Search placeholder="Search user by name, surname or email"  onChange={(e) => setSearch(e)}/>
            {state.loading && <SpinnerLoading animation="border" role="status" className={"spinner"} />}
            {state.users &&
                <div className={"users-table-wrapper"}>
                    <table className="table user-table">
                        <thead>
                            <tr>
                                <th scope="col" style={{ color:"black" }}>Name</th>
                                <th scope="col">Type</th>
                                <th scope="col">Boards</th>
                                <th scope="col">Teams</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.users.map((user, index) =>
                                <UserRow key={index} user={user} refresh={getUsers} />
                            )}
                        </tbody>
                    </table>
                    <UsersPagination 
                        sizePerPage={state.sizePerPage} 
                        totalSize={state.totalSize} 
                        page={state.page}  
                        goToPage={goToPage}
                    />
                </div>
            }
        </div >
    )
}

export default UsersTable
