import React, {createContext, useReducer} from "react";
import BoardsReducer, {boardsInitialState} from './boards/BoardsReducer'

//We will need to eventually combine them
const BoardsStore = ({children}) => {
    const [state, dispatch] = useReducer(BoardsReducer, boardsInitialState);
    
    return (
        <BoardsContext.Provider value={[state, dispatch]}>
            {children}
        </BoardsContext.Provider>
    )
};

export const BoardsContext = createContext(boardsInitialState);
export default BoardsStore;