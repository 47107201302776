import React, {useState, useEffect} from 'react'
import {Button, Form, Alert} from 'react-bootstrap';
import {Container, Box, SpinnerLoading} from './AcceptInvitePage.css.js'
import { useForm } from "react-hook-form";
import axios from 'axios'
import { API_ACCEPT_INVITE } from '../../../constants/index';
import {Link} from 'react-router-dom';

function AcceptInvitePage(props) {

    const initialState = {
        error: null,
        loading: false,
        success: false
    }

    useEffect(() => {}, []);

    const [state, setState] = useState(initialState)
    const { handleSubmit, register, errors } = useForm();
    const onSubmit = values => {
        console.log(values);
        setState({
            ...state,
            error: null,
            loading: true,
            success: false
        })
                
        axios.post(API_ACCEPT_INVITE, {...values, "token": props.match.params.token} )
        .then(response => {
            console.log(response);
            setState({
                ...state,
                error:null,
                loading: false,
                success: true
            })
        })
        .catch(error => {
            console.log(error)
            setState({
                ...state,
                error: error?.response?.data?.statusCode?.msg ?? 'Error connecting with the server, check your connection or contact support',
                loading: false,
                success: false
            })
        })
    };

    return (
        <Container>
            <Box>
                <h3>Please fill your information</h3>
                { state.error && <Alert variant={"danger"}>{state.error}</Alert>}
                { state.success && <Alert variant={"success"}>You have been registered, please <Link to="/landing#login">login</Link>.</Alert>}
                <Form onSubmit={handleSubmit(onSubmit)}>

                    <Form.Group controlId="formBasicName">
                        <Form.Label>Name</Form.Label>
                        <input 
                            name="name" 
                            className="form-control" 
                            placeholder="Name" 
                            type="text"
                            ref={register({
                                required: "You must specify a name",
                              })}
                        />
                        <p className="alert-danger">{errors.name && errors.name.message}</p>
                    </Form.Group>

                    <Form.Group controlId="formBasicSurname">
                        <Form.Label>Surname</Form.Label>
                        <input 
                            name="surname" 
                            className="form-control" 
                            placeholder="Surname" 
                            type="text"
                            ref={register({
                                required: "You must specify a surname",
                              })}
                        />
                        <p className="alert-danger">{errors.surname && errors.surname.message}</p>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <input 
                            name="password" 
                            className="form-control" 
                            placeholder="Password" 
                            type="password"
                            ref={register({
                                required: "You must specify a password",
                                minLength: {
                                  value: 3,
                                  message: "Password must have at least 3 characters"
                                }
                              })}
                        />
                        <p className="alert-danger">{errors.password && errors.password.message}</p>
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                    {state.loading && <SpinnerLoading animation="border" role="status"></SpinnerLoading>}
                    {/* <p className="forgot-password text-right">
                        <Link to="/login">Login</Link>
                        <Link to="/forget" style={{marginLeft: "10px"}}>Forget password</Link>
                    </p> */}
                </Form>
            </Box>
        </Container>
    )
}

export default AcceptInvitePage
