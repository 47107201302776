import React, {useContext, useEffect, useState} from 'react';
import {API_AUTOMATIONS, API_AUTOMATIONS_ID, API_URL} from "../../../constants";
import clientAPI from "../../../service/ApiService";
import {toast} from "react-toastify";
import {Alert} from "react-bootstrap";
import {Button, Spin, Switch} from "antd";
import {generatePath} from 'react-router';
import moment from "moment";
import {UsersContext} from "../../../state/usersStore";
import {IoMdClose} from "react-icons/io";

function AutomationsList({board, table, setView}) {

    const initialState = {
        loading: true,
        error: null,
        view: "list"
    }

    const [usersState] = useContext(UsersContext);
    const [state, setState] = useState(initialState);
    const [automations, setAutomations] = useState(null);

    useEffect(() => {
        requestAutomations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const requestAutomations = () => {

        let path = API_URL + generatePath(API_AUTOMATIONS, {
            board: board.id,
            table: table.id,
        })

        setState({
            ...state,
            loading: true,
        })

        clientAPI.get(path)
            .then(response => {
                setState({
                    ...state,
                    loading: false,
                    error: false,
                })
                setAutomations(response.data.payload)
            })
            .catch(error => {
                if (error && error.response && error.response.data && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false,
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    }

    const formatMessage = (automation) =>{
        let msg = "When ";
        if(automation.trigger === "setValue") {
            msg += "a value is changed in "
            if(!automation.columns || automation.columns.length === 0){
                msg += "any column"
            }else{
                let columnsName = automation?.columnsObj?.map(o => o.name);
                if (columnsName === undefined){
                    return
                }
                msg += "the column/s <span class='blue'>" + columnsName.join(",") + "</span>";
            }
        }else if(automation.trigger === "createdRow"){
            msg += " a new row is added,"
        }

        let actionsMsg = [];
        automation.actions.map((action)=>{
            if(action.type === "notification"){

                if(!action.users){
                    actionsMsg.push(" it will notify via email all the users in this board")
                }else{
                    let m = " it will notify via email the users ";
                    let usersNames = action?.users.map((userId) => {
                        const user = usersState.users.find(o => o.ID === userId)
                        return user.name;
                    })
                    m += "<strong>" +usersNames.join(", ")+ "</strong>";
                    actionsMsg.push( m )
                }

            }else if(action.type === "createRow"){
                let m = " it will create a row in the table <span class='blue'>" + action.tableName+ "</span>";;
                actionsMsg.push(m)
            }
            return action;
        })
        msg += actionsMsg.join(" and ");

        return msg;
    }

    function onChange(checked, automation) {
        let updatePath = API_URL + generatePath(API_AUTOMATIONS_ID, {
            board: board.id,
            table: table.id,
            automation: automation.id,
        })

        clientAPI.post(updatePath+"/"+ (checked ? "true":"false"))
            .then(response => {
                setAutomations(automations.map(o => {
                    if(o.id === automation.id){
                        o.enabled = checked;
                    }
                    return o;
                }))
            })
            .catch(error => {
                toast.error('Error deleting automatic action');
            })
    }

    function deleteAutomation(automation) {
        let path = API_URL + generatePath(API_AUTOMATIONS_ID, {
            board: board.id,
            table: table.id,
            automation: automation.id,
        })

        clientAPI.delete(path)
            .then(response => {
                setAutomations(automations.filter(o => o.id !== automation.id))
            })
            .catch(error => {
                toast.error('Error deleting automatic action');
            })
    }

    return (
        <div className={"automations-list"}>
            {state.error && <Alert variant={"danger"}>{state.error}</Alert>}
            {automations?.map((automation, index) =>
                <div className="automation-card" key={index}>
                    <div className={"description"} dangerouslySetInnerHTML={{__html:formatMessage(automation) }} />
                    <small>
                        {moment(automation?.createdAt).format('MMMM Do YYYY HH:mm')} by {automation.createdByUser.name}
                    </small>
                    <div className="switch">
                        <Switch
                            checked={automation.enabled}
                            onChange={(checked) => onChange(checked, automation)} />
                    </div>
                    <IoMdClose className={"delete"} onClick={() => {
                        if (window.confirm('Are you sure you wish to delete this automatic action?')) deleteAutomation(automation);
                    }} />
                </div>
            )}

            {automations===null && state.loading === false &&
            <div className={"no-automations"}>
                <p>There are no automations for this table yet.</p>
            </div>
            }

            {state.loading &&
            <Spin/>
            }

            <Button type="primary" onClick={() => setView("new")}>
                Create automatic action
            </Button>
        </div>
    );
}

export default AutomationsList;