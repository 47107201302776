import React, {useEffect, useState} from 'react'

function PreviewTextCell({row, column}) {

    const initialState = {
        entry: null,
    }

    const [state, setState] = useState(initialState)

    useEffect(() => {
        var entryAux = row.entries.find(function(obj){
            return obj.column === column.id
        })

        setState({
            ...state,
            entry: entryAux !== undefined ? entryAux.value : "",
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div  className="cell-entry">
            { state.entry != null &&
                <p>{state.entry}</p>
            }
        </div>
    )
}

export default PreviewTextCell
