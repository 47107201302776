import React, {useState} from 'react'
import {Form} from 'react-bootstrap';
import {Controller, useForm} from "react-hook-form";
import {API_INVITE} from '../../constants/index';
import clientAPI from "../../service/ApiService"
import {Alert, Button, Input, Modal} from 'antd';


function InviteModal({ reload }) {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        visible: false,
    }

    const showModal = () => {
        setState({
            visible: true,
        });
    };

    const handleOk = e => {
        triggerValidation()
        .then((result) => {
            if (result) { 
                handleSubmit(onSubmit)();
            }
        })
    };

    const handleCancel = e => {
        setState({
            visible: false,
        });
        reset();
    };

    const [state, setState] = useState(initialState)
    const { handleSubmit, control, errors, triggerValidation, reset } = useForm();
    const onSubmit = values => {
        console.log(values);
        setState({
            ...state,
            error: null,
            loading: true
        })

        clientAPI.post(API_INVITE, values)
            .then(response => {
                console.log(response);
                setState({
                    ...state,
                    error: null,
                    loading: false,
                    success: true,
                    // visible: false,
                })
                reset();
                reload();
            })
            .catch(error => {
                console.log(error)
                setState({
                    ...state,
                    error: error?.response?.data?.statusCode?.msg ?? 'Error connecting with the server, check your connection or contact support',
                    loading: false,
                    success: false
                })
            })
    };

    return (
        <div>

            <Button type="primary" onClick={() => showModal()}>
                Invite user
            </Button>

            <Modal
                title="Invite user to the account"
                visible={state.visible}
                okText="Invite"
                className="invite-user-modal"
                onOk={() => handleOk()}
                onCancel={() => handleCancel()}
                okButtonProps={{ form: 'category-editor-form', key: 'submit', htmlType: 'submit' }}
            >
                {state.error && <Alert message={state.error} type="error" closable className="notification" />}
                {state.success && <Alert message="We have send an invitation to this email." closable className="notification" type="success" />}

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Enter email of the person you want to invite</Form.Label>
                        <Controller
                            as={Input}
                            name='email'
                            control={control}
                            placeholder="Email"
                            rules={{
                                pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                required: true,
                            }}
                        />

                        {errors.email && (
                            <span className="alert-danger">Invalid email address</span>
                        )}
                    </Form.Group>

                    {/* <Button variant="primary" type="primary" htmlType="submit" className="login-form-button" loading={state.loading}>
                        Invite
                    </Button> */}
                </Form>
            </Modal>

        </div>
    )
}

export default InviteModal
