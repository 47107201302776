import React from 'react'
import UserIcon from '../Common/UserIcon';
import UninviteButton from './UninviteButton'

function UserRow({ user, refresh }) {
    return (
        <tr className="user-row">
            <td className="user-name-row">
                <UserIcon user={user} color={"#1e88e5"} />
                <div className="vertical-div">
                    <div className="name">{user.name} {user.surname}</div>
                    <div className="email">{user.email}</div>
                </div>

            </td>
            <td>
                {!user.enabled ?
                    <p style={{ color:"gray" }}>Pending verification</p>
                    :
                    ( user.roles.includes("ROLE_ADMIN") ?
                        <p>Admin</p>
                        :
                        <p>Member</p>
                    )
                }
            </td>
            <td className="num">
                {user.numBoards} boards
            </td>
            <td className="num">
                {user.numTeams} teams
            </td>
            <td>
            {!user.enabled &&
                <UninviteButton row={user} refresh={refresh} />
            }
            </td>
        </tr>
    )
}

export default UserRow
