// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export default reorder;

export const reorderQuoteMap = ({quoteMap, source, destination}) => {

    const current = quoteMap.find(quote => {
        return quote.id === source.droppableId
    })

    const next = quoteMap.find(quote => {
        return quote.id === destination.droppableId
    })
    const target = current.quotes[source.index];

    // moving to same list
    if (source.droppableId === destination.droppableId) {

        const reordered = reorder(
            current.quotes,
            source.index,
            destination.index,
        );

        return {
            quoteMap: quoteMap.map(column => {
                if(column.id === current.id) column.quotes = reordered;
                return column;
            }),
        };
    }

    // moving to different list

    // remove from original
    current.quotes.splice(source.index, 1);
    // insert into next
    next.quotes.splice(destination.index, 0, target);

    // console.log(quoteMap.map(column => {
    //     if(column.id === current.id) column.quotes = current.quotes;
    //     if(column.id === next.id) column.quotes = next.quotes;
    //     return column;
    // }))

    return {
        quoteMap: quoteMap.map(column => {
            if(column.id === current.id) column.quotes = current.quotes;
            if(column.id === next.id) column.quotes = next.quotes;
            return column;
        }),
    };
};

export function moveBetween({list1, list2, source, destination}) {
    const newFirst = Array.from(list1.values);
    const newSecond = Array.from(list2.values);

    const moveFrom = source.droppableId === list1.id ? newFirst : newSecond;
    const moveTo = moveFrom === newFirst ? newSecond : newFirst;

    const [moved] = moveFrom.splice(source.index, 1);
    moveTo.splice(destination.index, 0, moved);

    return {
        list1: {
            ...list1,
            values: newFirst,
        },
        list2: {
            ...list2,
            values: newSecond,
        },
    };
}