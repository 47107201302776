import React from 'react'
import TeamIcon from '../Common/TeamIcon';
import EditTeamModal from './EditTeamModal';
import DeleteTeam from './DeleteTeam';

function TeamRow({ team, refresh }) {

    return (
        <tr className="team-row">
            <td className="team-name-row">
                <TeamIcon team={team} color={"#1e88e5"} />
                <div className="vertical-div">
                    <div className="name">{team.name} {team.surname}</div>
                </div>

            </td>
            <td className="num">
                {team.users.length} users
            </td>
            <td className="num">
                {team.boardsIds.length} boards
            </td>
            <td>
                <div className="row-actions">
                    <EditTeamModal team={team} refresh={refresh} />
                    <DeleteTeam team={team} refresh={refresh} />
                </div>
            </td>
        </tr>
    )
}

export default TeamRow
