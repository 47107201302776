import React from 'react'
import {ReactComponent as Audio} from 'assets/wave.svg';
import {ReactComponent as Document} from 'assets/document-icon.svg';
import {ReactComponent as ZipIcon} from 'assets/zip-icon.svg';
import {ReactComponent as ImageIcon} from 'assets/image-icon.svg';
import {ReactComponent as VideoIcon} from 'assets/video-icon.svg';
import {ReactComponent as PDFIcon} from 'assets/pdf-icon.svg';

function AssetIcon({asset}) {

    return (
        <div className={"asset-icon"}  style={{ maxHeight: (asset?.filetype === "image" || asset?.filetype === "video" )
            && asset.height>asset.width ? "100%" : "300px" }}>
            {(() => {
                switch (asset?.filetype) {
                    case "image":
                        return asset && asset?.thumb?.length > 0 ?
                                <img src={asset?.thumb} className="asset-image-thumb" alt={asset.name}/> :
                                <ImageIcon />
                                ;
                    case "video":
                        return asset && asset?.thumb?.length > 0 ?
                            <img src={asset?.thumb} className="asset-image-thumb" alt={asset.name}/> :
                            <VideoIcon />
                            ;
                    case "audio":
                        return <Audio />;
                    case "archive":
                        return <ZipIcon />;
                    case "pdf":
                        return <PDFIcon />;
                    case "document":
                        return <Document />;
                    default:
                        return <Document />
                }
            })()}
        </div>
    )
}

export default AssetIcon
