import React, {useState, useEffect, useContext} from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
import clientAPI from "../../../service/ApiService";
import {SET_SINGLE_VALUE} from "../../../state/boards/BoardTypes";
import {toast} from "react-toastify";
import {API_CELL, API_URL} from "../../../constants";
import { generatePath } from 'react-router';
import {BoardContext} from "../../../state/boardStore";
import RowModal from "../../Common/RowModal/RowModal";


function CalendarContainer({table, boardId}) {

    const initialState= {
        openDuplicateModal:0,
        selectedRow: null,
    }

    const [state, setState] = useState(initialState)
    const [events, setEvents] = useState({})
    const [boardState, dispatchBoard] = useContext(BoardContext);

    useEffect(() => {

        // eslint-disable-next-line array-callback-return
        const ev = table?.rows?.filter(row => {
            // eslint-disable-next-line array-callback-return
            let date = row.entries.find(entry => {
                if(entry.column === table.calendarStartDate){
                    return entry.value;
                }
            });

            if(date !== undefined){
                return true
            }
        }).map(row => {

            // eslint-disable-next-line array-callback-return
            let startDate = row.entries.find(entry => {
                if(entry.column === table.calendarStartDate){
                    return entry.value;
                }
            });

            // eslint-disable-next-line array-callback-return
            let endDate = row.entries.find(entry => {
                if(entry.column === table.calendarEndDate){
                    return entry.value;
                }
            });

            let endD = new Date(endDate?.value)
            endD.setDate(endD.getDate() + 1)

            // console.log({ id: row.id ,title: row.name, start: startDate.value, end: endD, allDay: true });
            return { id: row.id ,title: row.name, start: startDate.value, end: endD, allDay: true };
        })

        setEvents(ev)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boardState.board]);

    const handleEventClick = (arg) => {
        let rowId = arg.event.id;
        // eslint-disable-next-line array-callback-return
        let row = table.rows.find(entry => {
            if(entry.id === rowId){
                return entry;
            }
        });

        setState({...state,
            openDuplicateModal: state.openDuplicateModal+1,
            selectedRow: row
        })
    }

    const handleEventDrop = (arg) => {
        let event = arg.event;

        updateValue(event.id, event.start, table.calendarStartDate)
        if(event.end != null){
            let endD = new Date(event.end)
            endD.setDate(endD.getDate() - 1)
            updateValue(event.id, endD, table.calendarEndDate);
        }
    }

    const handleEventResize = (arg) => {
        let event = arg.event;

        updateValue(event.id, event.start, table.calendarStartDate)
        if(event.end != null){
            let endD = new Date(event.end)
            endD.setDate(endD.getDate() - 1)
            updateValue(event.id, endD, table.calendarEndDate);
        }
    }

    const updateValue = (rowId, date, column) => {
        let d = new Date(date)
        d.setHours(0);
        let isoDateTime = new Date(d.getTime() - (d.getTimezoneOffset() * 60000)).toISOString();

        let path = API_URL + generatePath(API_CELL, {
            board: boardId,
            table: table.id,
            row: rowId,
            column: column,
        });

        clientAPI.post(path, {"value": isoDateTime})
            .then(response => {

                dispatchBoard({
                    type: SET_SINGLE_VALUE,
                    payload: {tableId: table.id, rowId: rowId, columnId: column, value: isoDateTime}
                });
            })
            .catch(error => {
                toast.error('Error connecting with the server, check your connection or contact support');
            })
    }

    return (
        <div className={"calendar-container"}>

            <FullCalendar
                plugins={[ dayGridPlugin , interactionPlugin ]}
                initialView="dayGridMonth"
                events={events}
                nextDayThreshold={'00:00:00'}
                editable={true}
                eventStartEditable={true}
                eventDurationEditable={true}
                eventClick = {(args) => handleEventClick(args)}
                eventDrop={(args) => handleEventDrop(args)}
                eventResize={(args) => handleEventResize(args)}
            />

            <RowModal counter={state.openDuplicateModal} table={table} row={state.selectedRow} />

        </div>
    );
}

export default CalendarContainer;