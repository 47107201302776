import React from 'react'
import TemplatesTable from "./TemplatesTable";

function TemplatesPage() {

    return (
        <div className="main-container page-inner templates-page">
            <h3>Templates</h3>
            <TemplatesTable />
        </div>
    )
}

export default TemplatesPage
