import React, {useState} from 'react'
import {MdContentCopy} from "react-icons/all";
import {message, Tooltip} from "antd";

function CopyLinkButton({link}) {

    const initialState = {
        loading: false,
    }
    const [state] = useState(initialState)

    return (
        <React.Fragment>
            {!state.loading &&
            <Tooltip mouseEnterDelay={0.5} placement="bottom" title="Copy link">
                <MdContentCopy className={"copy-link"} onClick={() => {
                    navigator.clipboard.writeText(link.url);
                    message.info('Link copied to clipboard');
                }} />
            </Tooltip>
            }
        </React.Fragment>
    )
}

export default CopyLinkButton
