import React, {useEffect, useState} from 'react'
import AssetRow from "../Boards/Asset/AssetRow";

function AssetTablePreview({table, files}) {

    const [assets, setAssets] = useState([])

    useEffect(() => {
        if(files === null){
            setAssets([])
        }

        var f = files.filter(x=>x.tableId === table.id).map( x => { return {"asset" : x, "status" : "uploaded"} } );
        setAssets(f);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);


    return (
        <div className={"assets-table-container"}>
            <h3>{table.name?.length > 0 ? table.name : "Empty title"}</h3>
            <table className="table asset-table">
                <tbody>
                {assets.map((file, index) =>
                    <AssetRow
                        key={index}
                        assetFile={file}
                        refresh={()=>console.log("no")}
                        showAssetDetail={()=>console.log("no")}
                    />
                )}
                </tbody>
            </table>
        </div>
    )
}

export default AssetTablePreview