import React, {useState, useEffect} from 'react'
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { API_TEAMS_SEARCH } from '../../constants/index';
import clientAPI from "../../service/ApiService"
import { Controller } from "react-hook-form";
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';

function SelectTeamsField({ title, control, errors, defaultTeams }) {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        loadingTeams: false,
        defaultValues: defaultTeams && defaultTeams.length > 0 ? defaultTeams.map(function(team){ return {value: team.id, label: team.name}} ) : [],
    }

    useEffect(() => {
        setState({...state, 
            defaultValues: defaultTeams && defaultTeams.length > 0 ? defaultTeams.map(function(team){ return {value: team.id, label: team.name}} ) : [],
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultTeams]);


    const [state, setState] = useState(initialState)
    const animatedComponents = makeAnimated();

    const promiseOptions = inputValue =>
        new Promise(resolve => {
            setState({
                ...state,
                error: null,
                loadingTeams: true,
            })

            clientAPI.get(API_TEAMS_SEARCH + "?page=1&limit=20&search=" + inputValue)
                .then(response => {

                    setState({
                        ...state,
                        error: null,
                        loadingTeams: false
                    })

                    let teams = response.data.payload.teams;
                    var options = [];
                    if(!teams || teams.length === 0){
                        resolve(options);
                        return
                    }
                    teams.map(function (team) {
                        options.push({ value: team.id, label: team.name });
                        return team;
                    })

                    resolve(options);
                })
                .catch(error => {
                    setState({
                        ...state,
                        loadingTeams: false,
                    })
                    if (error && error.response && error.response.data.statusCode.msg) {

                    } else {
                        console.log(error);
                        toast.error('Error connecting with the server, check your connection or contact support');
                    }

                })
        });

    return (
        <div>
            <Form.Group controlId="formBasicTeams">
                <Form.Label>{title}</Form.Label>

                <Controller
                    name="teams"
                    as={AsyncSelect}
                    isMulti
                    components={animatedComponents}
                    isLoading={state.loadingTeams}
                    loadOptions={promiseOptions}
                    control={control}
                    closeMenuOnSelect={false}
                    defaultOptions={true}
                    className="teams-multi-select"
                    classNamePrefix="teams-select"
                    defaultValue={state.defaultValues}
                    rules={{ required: false }}
                />

                {errors.teams && (
                    <span className="alert-danger">Invalid teams</span>
                )}
            </Form.Group>
        </div>
    )
}

export default SelectTeamsField
