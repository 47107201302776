import React from 'react'

function UsersPagination({sizePerPage, totalSize, page, goToPage}) {

    return (
        <ul className="users-pagination">
            { sizePerPage && sizePerPage && Math.ceil(totalSize/sizePerPage) > 1 && 
                [...Array( Math.ceil(totalSize/sizePerPage) )].map((e, i) => 
                    <li key={i} className={i+1 === page ? "selected" : ""}>
                        <button onClick={() => goToPage(i+1)}>
                            {i+1}
                        </button>
                    </li>
                )
            }
        </ul>       
    )
}

export default UsersPagination
