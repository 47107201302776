import React from 'react'
import {Draggable} from "react-beautiful-dnd";
import {GiHamburgerMenu} from "react-icons/gi";
import AssetView from "./AssetView";

function TableComponent({table, index, isDragging}) {

    return (
        <div className={`asset-table-type ${isDragging ? "dragging-table" : ""}`}>

            <Draggable draggableId={table.id}
                       index={index}
                       >
                {provided => (

                    <div className={"table-wrapper"}
                         ref={provided.innerRef}
                         {...provided.draggableProps}
                    >

                        <div {...provided.dragHandleProps}>
                            <GiHamburgerMenu className="table-drag-handle"/>
                        </div>
                        <div className={"asset-component"}>
                            <AssetView
                                table={table}
                            />
                        </div>
                        {provided.placeholder}
                    </div>
                )
                }
            </Draggable>
        </div>
    )
}

export default TableComponent