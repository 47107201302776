import React, {useState} from 'react'
import {Form} from 'react-bootstrap';
import {Controller, useForm} from "react-hook-form";
import {API_CHANGE_PASS} from '../../constants/index';
import clientAPI from "../../service/ApiService"
import {Alert, Button, Input, Modal} from 'antd';


function ChangePasswordModal({changePasswordSuccess}) {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        visible: false,
    }

    const showModal = () => {
        setState({
            visible: true,
        });
    };

    const handleOk = e => {
        triggerValidation()
            .then((result) => {
                if (result) {
                    handleSubmit(onSubmit)();
                }
            })
    };

    const handleCancel = e => {
        setState({
            visible: false,
        });
        reset();
    };

    const [state, setState] = useState(initialState)
    const { handleSubmit, control, errors, triggerValidation, reset } = useForm();
    const onSubmit = values => {

        setState({
            ...state,
            error: null,
            loading: true,
            success: false
        })

        clientAPI.put(API_CHANGE_PASS, values)
            .then(response => {
                setState({
                    ...state,
                    error:null,
                    loading: false,
                    success: true,
                    visible: false
                })
                reset();
                changePasswordSuccess();
            })
            .catch(error => {
                console.log(error)
                setState({
                    ...state,
                    error: error?.response?.data?.statusCode?.msg ?? 'Error connecting with the server, check your connection or contact support',
                    loading: false,
                    success: false
                })
            })
    };

    return (
        <div>

            <Button className="change-password" onClick={() => showModal()}>
                Change password
            </Button>

            <Modal
                title="Change password"
                visible={state.visible}
                okText="Change"
                className="change-password-modal"
                onOk={() => handleOk()}
                onCancel={() => handleCancel()}
                okButtonProps={{ form: 'change-password-form', key: 'submit', htmlType: 'submit' }}
            >
                {state.error && <Alert message={state.error} type="error" closable className="notification" />}

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group controlId="formBasicOldPassword">
                        <Form.Label>Old Password</Form.Label>
                        <Controller
                            as={Input.Password}
                            name='oldPassword'
                            control={control}
                            placeholder="Old password"
                            rules={{
                                minLength: 5,
                                required: true,
                            }}
                        />
                        {errors.oldPassword && (
                            <span className="alert-danger">Invalid password, password must have at least 5 characters</span>
                        )}
                    </Form.Group>

                    <Form.Group controlId="formBasicOldPassword">
                        <Form.Label>New Password</Form.Label>
                        <Controller
                            as={Input.Password}
                            name='newPassword'
                            control={control}
                            placeholder="New password"
                            rules={{
                                minLength: 5,
                                required: true,
                            }}
                        />
                        {errors.newPassword && (
                            <span className="alert-danger">Invalid password, password must have at least 5 characters</span>
                        )}
                    </Form.Group>

                    {/* <Button variant="primary" type="primary" htmlType="submit" className="login-form-button" loading={state.loading}>
                        Invite
                    </Button> */}
                </Form>
            </Modal>

        </div>
    )
}

export default ChangePasswordModal
