import React, {useEffect, useState} from 'react'

function PreviewDateCell({row, column}) {

    const initialState = {
        entry: null,
    }

    const [state, setState] = useState(initialState)

    function getFormattedDate(date) {
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return month + '/' + day + '/' + year;
    }

    useEffect(() => {

        var entryAux = row.entries.find(function(obj){
            return obj.column === column.id
        })

        if(entryAux !== undefined){

            let d = new Date(entryAux.value)
            d.setHours(0);
            setState({
                ...state,
                entry: getFormattedDate(d),
            });
        }else{
            setState({
                ...state,
                entry: "",
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div  className="cell-entry">
            { state.entry != null &&
            <p>{state.entry}</p>
            }
        </div>
    )
}

export default PreviewDateCell
