import React, {useEffect, useRef, useState} from 'react'
import {Dropdown} from 'antd';


function StatusCell({table, row, column, setRowValue}) {

    const initialState = {
        loading: false,
        error: null,
        metadata : column.type.metadata,
        form : JSON.parse(JSON.stringify(column.type.metadata)),
        entry: null,
    }

    const [state, setState] = useState(initialState)
    const [visible, setVisible] = useState(false)

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    useEffect(() => {
        computeEntry()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table, row, column, state.metadata, row.entries]);

    const computeEntry = () =>{

        var c = row.entries.find(function(obj){
            return obj.column === column.id
        })

        if(c !== undefined){
            var entry = column.type.metadata.find(function(obj){
                return obj.id === c.value
            })
        }

        setState({
            ...state,
            metadata : column?.type.metadata ?? c.type.metadata,
            entry: entry !== undefined ? entry : "",
        })
    }

    const setStatus = val => {
        setRowValue({
            row   : row,
            column: column,
            value : val,
        })
        computeEntry();
        setVisible(false);
    }

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setVisible(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const menu = (
        <div className={"status-dropdown"} ref={wrapperRef}>
            <div className="status-options">
                { column && column.type && state.metadata.map((status, index) =>
                    <div className="status"
                         style={{backgroundColor: status.color }}
                         key={status.id}
                         onClick={() => setStatus(status.id)} >
                        {status.name}
                    </div>
                )}
            </div>
        </div>
    );

    const changeVisibility = (e) => {
        setVisible(!visible)
    };

    return (
        <div className="cell-entry status-cell">
            {state.entry != null &&
            <Dropdown overlay={menu}
                      trigger={["click"]}
                      arrow={true}
                      placement="bottomCenter"
                      visible={visible}
                      animation={false}
                      onClick={(e)=>changeVisibility(e)}
            >
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()} href="/#">
                    <button
                        id="dropdown-basic"
                        className="status-toggle"
                        style={{backgroundColor: state.entry.color}}>
                        {state.entry.name}
                    </button>
                </a>
            </Dropdown>
            }
        </div>
    )
}

export default StatusCell
