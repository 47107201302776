import React, {useEffect, useState} from 'react'
import CopyEditor from "./CopyEditor";
import CopyView from "./CopyView";

function CopyComponent({setPageAndBody}) {

    const [editMode, setEditMode] = useState(false)
    const [body, setBody] = useState("")

    useEffect(() => {
        setEditMode(true);
    }, []);

    const done = (body) => {
        setBody(body);
        setPageAndBody(body);
    }

    return (
        <div className={`copy-type`}>
            {/*<h3>Rich text</h3>*/}
            <div className={"copy-component"}>
                {editMode ?
                    <CopyEditor
                        // table={table}
                        setEditMode={setEditMode}
                        body={body}
                        done={done}
                    />
                    :
                    <CopyView
                        // table={table}
                        body={body}
                        setEditMode={setEditMode}
                    />
                }
            </div>
        </div>
    )
}

export default CopyComponent