import React, {useContext} from 'react'
import DeleteButton from "./DeleteButton";
import {AiFillEdit} from "react-icons/ai";
import {Tooltip} from "antd";
import {BoardsContext} from "../../state/boardsStore";
import {FaPlus} from "react-icons/all";
import {OPEN_CREATE_BOARD_MODAL} from "../../state/boards/BoardTypes";
import {formatDate} from "../../service/DateService"

const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function TemplateRow({ template, refresh, visibility, edit, setViewTemplate }) {

    const [boardsState, dispatchBoards] = useContext(BoardsContext);

    return (
        <tr className="template-row">
            <td className="template-name-row" onClick={()=>setViewTemplate(template)}>
                {template.name}
            </td>
            {boardsState.width > 768 &&
                <React.Fragment>
                    <td>
                        {capitalize(template.type)}
                    </td>
                    <td>
                        {template.visibility === "public" && "Kaiseki.io"}
                        {template.visibility === "account" && "Account"}
                        {template.visibility === "private" && "Me"}
                    </td>
                </React.Fragment>
            }
            {visibility !== 'public' &&
                <td>
                    {formatDate(template.createdAt)}
                </td>
            }
            <td>
                <div className={"d-flex " + visibility}>
                    { template.type === "board" &&
                        <Tooltip placement="bottom" mouseEnterDelay={0.6} title={"Create from template"}>
                            <FaPlus className="template-plus-cta" onClick={() =>
                                dispatchBoards({type: OPEN_CREATE_BOARD_MODAL, payload: {board: null, template:template}})
                            }   />
                        </Tooltip>
                    }
                    {visibility !== 'public' &&
                        <React.Fragment>
                            <Tooltip placement="bottom" mouseEnterDelay={0.6} title={"Edit template"}>
                                <AiFillEdit className={"edit-icon"} onClick={() => {
                                    edit(template);
                                }} />
                            </Tooltip>
                            <DeleteButton template={template} refresh={refresh}/>
                        </React.Fragment>
                    }
                </div>
            </td>
        </tr>
    )
}

export default TemplateRow
