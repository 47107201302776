import React, {useEffect, useState, useContext} from 'react'
import UserIcon from './UserIcon';
import { UsersContext } from '../../state/usersStore';

function UserListItem(userId) {

    const [usersState] = useContext(UsersContext);
    const [user, setUser] = useState(null)

    useEffect(() => {

        setUser(
            usersState.users.find(user => {
                return userId.user === user.ID
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    return (
        <div className="user-item">
            { user &&
                <div>
                    <UserIcon user={user} disable={true} />
                    <p>{user.name} {user.surname}</p>
                </div>
            }
        </div>
    )
}

export default UserListItem
