import React from 'react'

function TeamIcon({ board, color = null, disable }) {

    return (
        <div>
            <span className="d-inline-block board-thumb">
                <p className="user-initials" style={{ backgroundColor: color || "green" }}>
                    {board.name.substring(0, 2)}
                </p>
            </span>
        </div>
    )
}

export default TeamIcon
