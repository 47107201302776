import React, {useContext, useEffect, useState} from 'react'
import {Alert, Button, Modal, Radio} from 'antd';
import {useForm} from "react-hook-form";
import clientAPI from "../../service/ApiService"
import {API_TABLE, API_URL} from '../../constants';
import {generatePath} from 'react-router';
import {BoardContext} from '../../state/boardStore'
import {CREATE_TABLE} from "../../state/boards/BoardTypes";
import {Form} from 'react-bootstrap';
import {toast} from "react-toastify";
import {GENERIC_ERR_MSG} from "../../constants/constants";
import TableSearchTemplate from "./Template/TableSearchTemplate";

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
};

function CreateTableModal({open, board}) {

    const initialState = {
        visible: false,
        error: null,
        loading: false,
        radioTemplate: "basic",
        template: null,
    }

    useEffect(() => {
        if(open === 0 || !open) return;
        setState({
            ...state,
            visible: true,
            radioTemplate: "basic",
            template: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const dispatchBoard = useContext(BoardContext)[1];
    const [state, setState] = useState(initialState)
    const { handleSubmit, register, errors } = useForm();

    const onSubmit = (values, e) => {

        setState({
            ...state,
            error: null,
            loading: true,
        })

        const path = API_URL + generatePath(API_TABLE, {
            board: board,
        });

        let payload = {...values,
            "type":"standard",
            "fill" : state.radioTemplate === "basic"
        }
        if(state.template){
            payload = {...payload, template: state.template.id}
        }

        clientAPI.post(path, payload)
        .then(response => {
            setState({
                ...state,
                error:null,
                loading: false,
            })
            // e.target.reset()
            setState({
                ...state,
                visible: false,
            });
            dispatchBoard({type: CREATE_TABLE, payload: {table: response.data.payload} });
            // dispatchBoard({type: UPDATE_BOARD, payload:response.data.payload});
        })
        .catch(error => {
            setState({
                ...state,
                loading: false
            })
            toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
        })
    };

    const handleCancel = e => {
        setState({
            ...state,
            visible: false,
            files: [],
        });
    };

    const handleOk = e => {
        setState({
            ...state,
            visible: false,
            files: [],
        });
    };

    const radioChange = e => {
        setState({
            ...state,
            radioTemplate: e.target.value,
            template: null,
        });
    };

    const selectTemplate = (template) => {
        setState({
            ...state,
            template: template
        })
    };

    return (
        <div>
            {state.visible &&
            <Modal
                title="Create table"
                visible={state.visible}
                okText="Create"
                className="create-table-modal"
                onOk={() => handleOk()}
                onCancel={() => handleCancel()}
                wrapClassName="vertical-center-modal"
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{form: 'create-table-form', key: 'submit', htmlType: 'submit'}}
                footer={[
                    <Button loading={state.loading}
                            type={"primary"}
                            form="myForm"
                            key="submit"
                            htmlType="submit"
                    >
                        Create
                    </Button>
                ]}
            >

                { state.error && <Alert variant={"danger"} message={state.error} />}
                <Form
                    onSubmit={handleSubmit(onSubmit)}
                    id={"myForm"}
                >
                    <Form.Group controlId="formCreateTable">
                        <Form.Label>Table Name</Form.Label>
                        <input
                            name="name"
                            className="form-control"
                            placeholder= "Name"
                            autoComplete= "off"
                            autoFocus={true}
                            ref={(e) => {
                                register(e, { required: true })
                            }}
                        />
                        <p className="alert-danger">{errors.name && errors.name.message}</p>
                    </Form.Group>

                    <Form.Group controlId="formTemplate">
                        <Form.Label style={{"display":"block"}}>Template</Form.Label>
                        <Radio.Group onChange={radioChange} value={state.radioTemplate} className={"paddingleft20"}>
                            <Radio style={radioStyle} value={"basic"}>
                                Basic data
                            </Radio>
                            <Radio style={radioStyle} value={"empty"}>
                                Empty table
                            </Radio>
                            <Radio style={radioStyle} value={"template"}>
                                {!state.template &&
                                    <React.Fragment>Select from template</React.Fragment>
                                }
                                {state.template &&
                                    <React.Fragment>Selected template:&nbsp;&nbsp;<strong>{state.template.name}</strong></React.Fragment>
                                }
                            </Radio>
                        </Radio.Group>
                    </Form.Group>

                    { state.radioTemplate === "template" && !state.template &&
                        <TableSearchTemplate ttype={"table"} selectTemplate={selectTemplate} />
                    }

                </Form>
            </Modal>
            }

        </div>
    );
}

export default CreateTableModal