import axios from 'axios';
import Cookies from 'universal-cookie';

const clientAPI = axios.create()
const cookies = new Cookies();

clientAPI.interceptors.request.use(function (config) {
    let token    = cookies.get('token');    
    config.headers.Authorization =  token;
    return config;
});

export default clientAPI