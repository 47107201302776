import React, {useEffect, useState} from 'react';
import { Modal, Alert, Input, Select as NSelect} from 'antd';
import { Form } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import clientAPI from "../../service/ApiService"
import {API_SEARCH} from "../../constants";
import Select from 'react-select';
import makeAnimated from "react-select/animated/dist/react-select.esm";

function SearchModal({search, open, searchText="", currentTeam=null, refreshSearch, teams, categoriesSelect, currentCategoriesOnSearch= null}){

    const initialState = {
        error: null,
        loading: false,
        success: false,
        visible: false,
        teamsOptions: [],
    }

    const [state, setState] = useState(initialState)
    const animatedComponents = makeAnimated();
    const handleCancel = e => {
        setState({
            ...state,
            visible: false,
        });
        reset();
    };

    useEffect(() => {
        if(open === 0) return;

        setState({
            ...state,
            visible: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        if(!teams || teams.length <= 0) return;

        setState({
            ...state,
            teamsOptions: teams ? teams?.map((team) => { return {value: team.id, label: team.name}}) : null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teams]);


    const { handleSubmit, control, errors, triggerValidation, reset } = useForm();
    const onSubmit = values => {

        setState({
            ...state,
            error: null,
            loading: true,
            success: false
        })

        values = {
            ...values,
            categories: values?.categories?.map((category) => category.value)
        }

        if(!search){ // new
            clientAPI.post(API_SEARCH, values)
                .then(response => {
                    setState({
                        ...state,
                        error:null,
                        loading: false,
                        success: false,
                        visible: false
                    })
                    refreshSearch();
                })
                .catch(error => {
                    setState({
                        ...state,
                        error: error?.response?.data?.statusCode?.msg ?? 'Error connecting with the server, check your connection or contact support',
                        loading: false,
                        success: false
                    })
                })
        }else{
            clientAPI.put(API_SEARCH + "/" + search.id, values)
                .then(response => {
                    setState({
                        ...state,
                        error:null,
                        loading: false,
                        success: false,
                        visible: false
                    })
                    refreshSearch();
                })
                .catch(error => {
                    console.log(error)
                    setState({
                        ...state,
                        error: error?.response?.data?.statusCode?.msg ?? 'Error connecting with the server, check your connection or contact support',
                        loading: false,
                        success: false
                    })
                })
        }

    };

    const handleOk = e => {
        triggerValidation()
            .then((result) => {
                if (result) {
                    handleSubmit(onSubmit)();
                }
            })
    };

    const customStyles = {
        control: (styles, state) => {
            return {
                ...styles,
                boxShadow: state.isFocused ? "0 0 0 2px rgba(24, 144, 255, 0.2)" : 0,
                borderColor: state.isFocused ? "#40a9ff" : "#CED4DA",
                "&:hover": {
                    borderColor: state.isFocused ? "#40a9ff" : "#CED4DA"
                }
            }}
    };

    return (
        <div>
            {state.visible &&
            <Modal
                title="Save search"
                visible={state.visible}
                okText="Save"
                className="save-search-modal"
                onOk={() => handleOk()}
                onCancel={() => handleCancel()}
                okButtonProps={{form: 'search-editor-form', key: 'submit', htmlType: 'submit'}}
            >
                {state.error && <Alert message={state.error} type="error" closable className="notification"/>}
                {state.success && <Alert message="Changes saved." closable className="notification" type="success"/>}

                <Form onSubmit={handleSubmit(onSubmit)}>

                    <Form.Group controlId="formBasicName">
                        <Form.Label>Name</Form.Label>
                        <Controller
                            as={Input}
                            name='name'
                            control={control}
                            placeholder="Name"
                            defaultValue={search?.name}
                            rules={{
                                required: true,
                            }}
                        />

                        {errors.name && (
                            <span className="alert-danger">Invalid name</span>
                        )}
                    </Form.Group>

                    <Form.Group controlId="formBasicName">
                        <Form.Label>Text to search (optional)</Form.Label>
                        <Controller
                            as={Input}
                            name='search'
                            control={control}
                            placeholder="Text to search"
                            defaultValue={search?.text ?? searchText}
                        />
                    </Form.Group>

                    { state.teamsOptions?.length > 0 &&
                    <Form.Group controlId="formBasicName" className={"select-team"}>
                        <Form.Label>Team (optional)</Form.Label>
                        <Controller
                            as={NSelect}
                            name='team'
                            control={control}
                            placeholder="Select team"
                            options={state.teamsOptions}
                            defaultValue={search?.team?.id ?? (currentTeam === "-1" ? null : currentTeam)}
                        />
                    </Form.Group>
                    }

                    { categoriesSelect?.length > 0 &&
                    <Form.Group controlId="formBasicName" className={"select-categories"}>
                        <Form.Label>Categories (optional)</Form.Label>
                        <Controller
                            name="categories"
                            as={Select}
                            isMulti
                            components={animatedComponents}
                            isLoading={false}
                            options={categoriesSelect}
                            control={control}
                            closeMenuOnSelect={false}
                            defaultOptions={true}
                            styles={customStyles}
                            className="cat-multi-select"
                            classNamePrefix="cat-select"
                            defaultValue={search?.categories?.map(category=>{return {value:category, label:category}}) ??
                            currentCategoriesOnSearch?.map(category=>{return {value:category, label:category}})}
                        />
                    </Form.Group>
                    }
                </Form>
            </Modal>
            }

        </div>
    );
};

export default SearchModal;
