import React, {useContext, useEffect, useState} from 'react'
import {Button, Drawer} from 'antd';
import {SpinnerLoading} from '../Style/styles.css'
import {BoardContext} from '../../state/boardStore'
import {CLOSE_MESSAGES_DRAWER} from '../../state/boards/BoardTypes';
import {API_GET_MESSAGES, API_URL} from '../../constants';
import {generatePath} from 'react-router';
import {toast} from 'react-toastify';
import clientAPI from "../../service/ApiService"
import UserItem from '../Common/UserItem';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Alert} from "react-bootstrap";
import {GENERIC_ERR_MSG} from "../../constants/constants";
import {formatDate} from "../../service/DateService";


function MessageDrawer() {

    const initialState = {
        loading: true,
        error: null,
        row: null,
        messages: [],
        currentMessage: "",
        editor: null,
    }
    const [boardState, dispatchBoard] = useContext(BoardContext);
    const [state, setState] = useState(initialState)
    const [ckeditor, setCKEditor] = useState(null)
    const [loadingCreate, setLoadingCreate] = useState(false)
    const [visible, setVisible] = useState(boardState.drawerVisible);

    useEffect(() => {
        setVisible(boardState.drawerVisible)
        setState({
            ...state,
            row: boardState.row,
            loading: true,
            loadingCreate: false,
            messages: [],
            currentMessage: "",
            editor: null,
            error: null,
        })
        
        setLoadingCreate(false)

        if(boardState.row != null){
            requestMessages();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boardState]);

    const onClose = () => {
        dispatchBoard({type: CLOSE_MESSAGES_DRAWER});
    };

    const requestMessages = () => {
        setState({
            ...state,
            messages: [],
        })

        let path= API_URL + generatePath(API_GET_MESSAGES + "?order=DESC", {
            board: boardState.board.id,
            table: boardState.table.id,
            row: boardState.row.id
        })

        clientAPI.get(path)
        .then(response => {
            setState({
                ...state,
                loading: false,
                messages: response.data.payload.messages ? response.data.payload.messages : [],
                error: null,
            })
        })
        .catch(error => {
            if(error && error.response && error.response.data.statusCode.msg){
                setState({
                    ...state,
                    error: error.response.data.statusCode.msg,
                    loading: false,
                })
            }else{
                toast.error('Error connecting with the server, check your connection or contact support');
            }
        })
    }

    const createMessage = () => {
        setLoadingCreate(true)

        let path= API_URL + generatePath(API_GET_MESSAGES, {
            board: boardState.board.id,
            table: boardState.table.id,
            row: boardState.row.id
        })

        clientAPI.post(path, { body : state.currentMessage})
        .then(response => {
            setState({
                ...state,
                messages: response.data.payload ? [response.data.payload].concat(state.messages) : state.messages,
                error: null,
            })
            setLoadingCreate(false)
            ckeditor.setData("")
        })
        .catch(error => {
            setState({
                ...state,
                loading: false,
                error: error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG
            })
            setLoadingCreate(false)
        })
    }

    return (
        <div>
            <Drawer
                title={boardState.row ? boardState.row.name : "Messages"}
                placement="right"
                closable={true}
                onClose={onClose}
                visible={visible}
                width="500px"
                className={"message-drawer"}
            >
                {state.error && <Alert variant={"danger"}>{state.error}</Alert>}
                <div className="new-message-container">
                    <CKEditor
                        editor={ ClassicEditor }
                        className="message-editor"
                        config={ {
                            toolbar: [ 'bold', 'italic', 'link', 'bulletedList', 'numberedList' ],
                        } }
                        onInit={ editor2 => {
                            setCKEditor(editor2)
                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            setState({
                                ...state,
                                currentMessage: data
                            })
                        } }
                    />
                    <Button
                        type="primary"
                        loading={loadingCreate}
                        onClick={()=>createMessage()}
                    >Save</Button>
                </div>

                { state.messages && state.messages.map((message, index) =>
                    <div className="message-card" key={index}>
                        <div className="message-card-header">
                                <UserItem key={message.user} user={message.user} />
                            <p className="card-date">{ formatDate(message.createdAt) }</p>
                        </div>
                        <div className="message-card-content">
                            <div dangerouslySetInnerHTML={{ __html: message.body }} />
                        </div>
                    </div>
                )}
                { !state.messages && !state.loading  &&
                    <p>There are no messages for this row yet.</p>
                }
                
                {state.loading && <SpinnerLoading className="messages-drawer-spinner" animation="border" role="status" />}
            </Drawer>
        </div>
    )
}

export default MessageDrawer
