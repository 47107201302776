import React, {useContext, useEffect, useState} from 'react'
import {Container, Row} from 'react-bootstrap'
import {Route, Switch, useRouteMatch,} from "react-router-dom";
import CreateBoardPage from '../BoardsPage/CreateBoardPage';
import BoardPage from './BoardPage';
import {SpinnerLoading} from '../Style/styles.css'
import clientAPI from "../../service/ApiService";
import {API_ALL_USERS} from "../../constants";
import {GET_ALL_USERS} from "../../state/users/UsersTypes";
import {toast} from "react-toastify";
import {GENERIC_ERR_MSG} from "../../constants/constants";
import {UsersContext} from "../../state/usersStore";

function BoardsPage() {

    let {path} = useRouteMatch();

    const initialState = {
        error: null,
        loading: false,
        currentBoard: null,
        users: [],
        connected: true,
    }
    const [state, setState] = useState(initialState)
    const dispatchUsers = useContext(UsersContext)[1];

    useEffect(() => {
        getAllUsers()
        return function cleanup() {

        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAllUsers = () => {
        clientAPI.get(API_ALL_USERS)
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false,
                })
                dispatchUsers({type: GET_ALL_USERS, payload: response.data.payload});
            })
            .catch(error => {
                setState({
                    ...state,
                    loading: false,
                })
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    return (
        <div className={"boards-page-top"}>
            <div className={"main-container page-inner page-boards-padding"}>
                <Container fluid>
                    <Row>
                        <Switch>
                            <Route exact path={`${path}`}>
                                <CreateBoardPage />
                            </Route>

                            <Route path="/boards/:id">
                                <BoardPage />
                            </Route>
                            {/*{boardsState.boards && boardsState.boards.length > 0 &&*/}
                            {/*    <Route component={BoardNotFoundPage} />*/}
                            {/*}*/}
                        </Switch>
                    </Row>
                </Container>

                {!state.connected &&
                <div className="reconnecting-toast">
                    <SpinnerLoading animation="border" role="status" className="spinner-animation" />
                    {/*<p>Connecting</p>*/}
                </div>
                }
            </div>
        </div>
    )
}

export default BoardsPage