import React from 'react'
import UserIcon from '../../../Common/UserIcon';

function UserListItem(props) {

    return (
        <div className="user-list-item" onClick={() => { props.addUser ? props.addUser(props.user) : console.log("user selected") }} >
            <div className="user-list-item-inner">
                <UserIcon user={props.user} disable={true} />
                <p>{props.user.name} {props.user.surname}</p>
            </div>
        </div>
    )
}

export default UserListItem
