import React, {useContext, useEffect, useState} from 'react';
import {BoardContext} from "../../../state/boardStore";
import {API_ROW_ID, API_TABLE_ID, API_URL} from "../../../constants";
import clientAPI from "../../../service/ApiService";
import {CHANGE_ROW_NAME, DELETED_SOME_ROWS, SET_SUB_ROWS} from "../../../state/boards/BoardTypes";
import {toast} from "react-toastify";
import {generatePath} from 'react-router';
import { IoMdClose } from "react-icons/io";
import {GENERIC_ERR_MSG} from "../../../constants/constants";
import AddRow from "../../Boards/AddRow";
import EdiText from "react-editext";
import StatusCell from "../../Boards/Cells/StatusCell";

function RowModalSubtasks({boardId, table, row}) {

    const [boardState, dispatchBoard] = useContext(BoardContext);
    const [statusColumn, setStatusColumn] = useState(false)

    useEffect(() => {

        // eslint-disable-next-line array-callback-return
        setStatusColumn(table?.columns.find(column => {
            if (column.id === table.kanbanStatusColumn) {
                return true;
            }
        }));

        if(row.rows?.length>0)
            return

        getRows();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getRows = () => {

        let path = API_URL + generatePath(API_ROW_ID, {
            board: boardId,
            table: table.id,
            row:   row.id,
        });

        return clientAPI.get(path)
            .then(response => {
                let rows = response.data.payload;
                dispatchBoard({type: SET_SUB_ROWS, payload: {tableId: table.id ,rows: rows, parentRowId: row.id}});
            })
            .catch(error => {
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    const editSubRowName = (val,subrow) => {
        let path = API_URL + generatePath(API_ROW_ID, {
            board: boardId,
            table: table.id,
            row: subrow.id
        })

        clientAPI.put(path, {"name": val})
            .then(response => {
                dispatchBoard({
                    type: CHANGE_ROW_NAME,
                    payload: {tableId: table.id, rowId: subrow.id, parentRowId:row.id, name: val}
                });
            })
            .catch(error => {
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    const deleteRow = (subrow) => {

        let payload = {"rows": [subrow.id]};
        let path = API_URL + generatePath(API_TABLE_ID, {
            board: boardId,
            table: table.id,
        });

        return clientAPI.delete(path + "/rows", { data: payload })
            .then(response => {
                dispatchBoard({type: DELETED_SOME_ROWS, payload: {tableId:table.id, rows: [subrow.id]}});
            })
            .catch(error => {
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    return (
        <div className={"subtasks-list"}>
            <React.Fragment>
                {row?.rows?.map((subrow, index) => (
                    <div key={index} className={"subtask-entry"}>
                        <div className={"left"}>
                            {statusColumn &&
                                <StatusCell boardId={boardId} table={table} row={subrow} column={statusColumn} hideName={true} />
                            }
                            <EdiText
                                submitOnEnter
                                cancelOnEscape
                                submitOnUnfocus
                                hideIcons={true}
                                editOnViewClick={true}
                                showButtonsOnHover={false}
                                editButtonClassName="hide"
                                saveButtonClassName="hide"
                                cancelButtonClassName="hide"
                                viewContainerClassName="row-name"
                                inputProps={{
                                    style: {
                                        backgroundColor: '#FAFAFA',
                                    },
                                }}
                                type="text" value={subrow.name} onSave={(val)=>editSubRowName(val, subrow)} className="edit-row-name"/>
                        </div>
                        <IoMdClose onClick={() => deleteRow(subrow)} />
                    </div>
                ))}
            </React.Fragment>
            {table &&
            <div className="table-footer">
                <AddRow boardId={boardState.board.id} table={table} parentRowId={row.id} />
            </div>
            }
        </div>
    );
}

export default RowModalSubtasks;