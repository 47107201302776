import React, {useState, useContext, useEffect} from 'react'
import clientAPI from "../../../service/ApiService"
import { API_URL, API_CELL } from '../../../constants';
import { generatePath } from 'react-router';
import { BoardContext } from '../../../state/boardStore'
import { toast } from 'react-toastify';
import {DELETE_ENTRY, SET_SINGLE_VALUE} from "../../../state/boards/BoardTypes";
import {GENERIC_ERR_MSG} from "../../../constants/constants";
import { DatePicker } from 'antd';
import { dateFormat } from "../../../service/DateService"
import moment from 'moment';

function DateTimeCell(props) {

    const initialState = {
        loading: false,
        error: null,
        table: props.table,
        boardId: props.boardId,
        path: API_URL + generatePath(API_CELL, {
            board: props.boardId,
            table: props.table.id,
            row: props.row.id,
            column: props.column.id,
        }),
        date: null,
        dateString: null,
    }

    const dispatchBoard = useContext(BoardContext)[1];
    const [state, setState] = useState(initialState)

    useEffect(() => {
        var entryAux = props.row.entries.find(function(obj){
            return obj.column === props.column.id
        })
        setState({
            ...state,
            date: entryAux !== undefined ? new Date(entryAux.value): "",
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const updateValue = date => {
        let isoDateTime = null;
        if(date !== null){
            let d = new Date(date)
            d.setHours(0);
            isoDateTime = new Date(d.getTime() - (d.getTimezoneOffset() * 60000)).toISOString();

            setState({
                ...state,
                date: d,
            })
        }else{
            setState({
                ...state,
                date: null,
                dateString: null,
            })
            clientAPI.delete(state.path)
                .then(response => {
                    dispatchBoard({
                        type: DELETE_ENTRY,
                        payload: {tableId: props.table.id, rowId: props.row.id, parentRowId: props.row.parent, columnId: props.column.id}
                    });
                })
                .catch(error => {
                    setState({
                        ...state,
                        loading: false,
                        date: null,
                    })
                    toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
                })
            return;
        }

        clientAPI.post(state.path, {"value": isoDateTime})
        .then(response => {
            setState({
                ...state,
                loading: false,
                date: new Date(date),
            })
            dispatchBoard({
                type: SET_SINGLE_VALUE,
                payload: {tableId: props.table.id, rowId: props.row.id, parentRowId: props.row.parent, columnId: props.column.id, value: isoDateTime}
            });
        })
        .catch(error => {
            setState({
                ...state,
                loading: false,
                date: null,
            })
            toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
        })
    }

    return (    
        <div className="cell-entry datetime-cell">
            { state.date != null &&
                <DatePicker
                    // showTime={{ format: 'HH:mm' }}
                    value= {state.date ? moment(state.date, dateFormat) : null}
                    format={dateFormat}
                    onChange={date => updateValue(date)}
                />
            }
        </div>
    )
}

export default DateTimeCell
