import React, {useEffect, useState} from 'react'
import {Alert} from 'react-bootstrap';
import {Box, Container, SpinnerLoading} from 'components/Style/styles.css'
import axios from 'axios'
import {API_CONFIRM} from '../../../constants/index';
import {toast} from 'react-toastify';

function ConfirmPage(props) {

    const initialState = {
        error: null,
        loading: false,
        token: props.match.params.token,
        success: false,
    }

    const [state, setState] = useState(initialState)
    useEffect(() => {
        if (state.token && state.token.length > 0) {
            enableAccount()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const enableAccount = () => {

        setState({
            ...state,
            error: null,
            loading: true,
        })

        axios.post(API_CONFIRM, { "token": state.token })
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false,
                    success: true
                })
                // dispatch({ type: USER_GET_ACCOUNT, payload: response.data.payload });
                // history.push("/");
            })
            .catch(error => {
                if (error && error.response && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false,
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    };

    return (
        <Container>
            <Box>
                <h3>Confirm Account</h3>
                {state.error && <Alert variant={"danger"}>{state.error}</Alert>}
                {state.loading && <SpinnerLoading animation="border" role="status"></SpinnerLoading>}
                {state.success && <p>Your account have been successfully enabled, you can now <a href="/landing#login">login</a></p>}
            </Box>
        </Container>
    )
}

export default ConfirmPage
