import React, {useContext, useEffect, useState} from 'react'
import clientAPI from "../../service/ApiService"
import {API_BOARD, API_TABLE_ID, API_URL} from '../../constants';
import {generatePath} from 'react-router';
import {toast} from 'react-toastify';
import {Alert, Input, Modal} from 'antd';
import {BoardContext} from "../../state/boardStore";
import {SpinnerLoading} from "../Style/styles.css";
import UsersPagination from "../Users/UsersPagination";
import BoardIcon from "../BoardsPage/BoardIcon";
import {Link} from "react-router-dom";
import {DELETE_TABLE} from "../../state/boards/BoardTypes";
import {GENERIC_ERR_MSG} from "../../constants/constants";


function TransferTableModal({boardId, table, counter}) {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        visible: false,
        page: 1,
        boards: [],
        sizePerPage: 10,
        totalSize: 0,
    }
    const [state, setState] = useState(initialState)

    const dispatchBoard = useContext(BoardContext)[1];

    useEffect(() => {
        if(!state.visible)
            return;
        getBoards()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.page, state.sizePerPage, state.visible]);

    useEffect(() => {
        if (counter > 0) {
            showModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter]);

    const showModal = () => {
        setState({
            ...initialState,
            visible: true,
        });
    };

    const handleCancel = e => {
        setState({
            ...state,
            visible: false,
        });
    };

    const transferTable = (newBoardId) => {

        setState({
            ...state,
            error: null,
            loading: true,
            success: false,
        })

        let path = API_URL + generatePath(API_TABLE_ID, {
            board: boardId,
            table: table.id,
        });

        clientAPI.post(path + "/transfer/" + newBoardId)
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false,
                    success: false,
                    visible: false,
                })
                handleCancel()
                dispatchBoard({type: DELETE_TABLE, payload: {tableId: table.id}});
            })
            .catch(error => {
                setState({
                    ...state,
                    error: error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG,
                    loading: false,
                })
            })
    };

    const getBoards = (search=null) => {
        setState({
            ...state,
            error: null,
            loading: true,
        })

        clientAPI.get(API_BOARD + "?page=" + state.page + "&limit=" + state.sizePerPage + (search ? "&search=" + encodeURIComponent(search) : "" ))
            .then(response => {
                let boards = response.data.payload.boards;

                if(!boards || boards.length <= 0 ){
                    setState({...state, boards:[], totalSize: 0, page: state.page > 1 ? state.page-1 : state.page})
                    return false
                }

                boards = boards.filter(o => o.id !== boardId)

                setState({
                    ...state,
                    error: null,
                    loading: false,
                    boards: boards,
                    totalSize: response.data.payload.pagination.total,
                })

            })
            .catch(error => {

                if (error && error.response && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false,
                        data: []
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }

            })
    }

    const goToPage = ((page) => {
        setState({
            ...state,
            page: page
        })
    });

    const { Search } = Input;
    const [timer, setTimer] = useState(null)


    const setSearch = (event) => {
        var searchText = event.target.value;

        if (timer != null) {
            clearTimeout(timer);
        }

        setTimer(
            setTimeout(() => {
                getBoards(searchText)
            }, 300)
        )
    }

    return (
        <React.Fragment>

            <Modal
                title={"Transfer table to which board"}
                visible={state.visible}
                okText={"Duplicate"}
                className="create-board-modal"
                onCancel={() => handleCancel()}
                footer={null}
                okButtonProps={{form: 'category-editor-form', key: 'submit', htmlType: 'submit'}}
            >
                {state.error && <Alert message={state.error} type="error" closable className="notification"/>}

                <div className="search-actions">
                    <Search placeholder="Search board by name"  onChange={(e) => setSearch(e)}/>
                </div>

                {state.loading && <SpinnerLoading animation="border" role="status" />}
                {state.boards &&
                <div>
                    <table className="table select-board-table">
                        <thead>
                        <tr>
                            <th scope="col" style={{ color:"black" }}>Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.boards.map((board, index) =>
                            <tr className="board-row" key={index} onClick={ () => transferTable(board.id)}>
                                <td className="board-name-row">
                                    <BoardIcon board={board} color={board.color} />
                                    <div className="vertical-div">
                                        <div className="d-flex">
                                            <div className="name"><Link to={"/boards/" + board.id}>{board.name}</Link></div>
                                        </div>
                                        <div className="visibility">{board.visibility}</div>
                                    </div>

                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    <UsersPagination
                        sizePerPage={state.sizePerPage}
                        totalSize={state.totalSize}
                        page={state.page}
                        goToPage={goToPage}
                    />
                </div>
                }

            </Modal>

        </React.Fragment>
    )
}

export default TransferTableModal
