import React, { useState, useContext, useEffect } from 'react'
import { BoardsContext } from '../../../state/boardsStore'
import CreateBoardModal from "./CreateBoardModal";

function CreateBoard() {

    const [visible, setVisible] = useState(false)
    const [boardsState] = useContext(BoardsContext);

    useEffect(() => {
        if(boardsState.createBoardsModal > 0){
            setVisible(true);
        }
    }, [boardsState.createBoardsModal]);

    return (
        <div>
            {visible &&
                <CreateBoardModal visible={visible} setVisible={setVisible} />
            }
        </div>
    )
}

export default CreateBoard
