import React from 'react';
import styled from '@emotion/styled';
import {colors} from '@atlaskit/theme';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import QuoteItem from './quote-item';
import {grid} from './constants';

export const getBackgroundColor = (
    isDraggingOver,
    isDraggingFrom,
) => {
    if (isDraggingOver) {
        return "rgba(76, 154, 255, 0.6)";
    }
    if (isDraggingFrom) {
        return colors.T50;
    }
    return colors.N30;
};

const Wrapper = styled.div`
  background-color: ${(props) =>
    getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};
  display: flex;
  flex-direction: column;
  opacity: ${({isDropDisabled}) => (isDropDisabled ? 0.5 : 'inherit')};
  padding: ${grid}px;
  border: ${grid}px;
  padding-bottom: 0;
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
  width: 250px !important;
  padding-top: 6px;
  max-height: 650px;
  overflow-y: auto;
`;

const scrollContainerHeight = 250;

const DropZone = styled.div`
  /* stop the list collapsing when empty */
  min-height: ${scrollContainerHeight}px;

  /*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
  */
  padding-bottom: ${grid}px;
`;

const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${scrollContainerHeight}px;
`;

/* stylelint-disable block-no-empty */
const Container = styled.div``;
/* stylelint-enable */

const InnerQuoteList = React.memo(function InnerQuoteList(
    props,
) {

    return props.quotes.map((quote, index) => (

        <Draggable key={quote.id} draggableId={quote.id} index={index} openRowModal={props.openRowModal}>
            {(
                dragProvided,
                dragSnapshot,
            ) => (
                <QuoteItem
                    key={quote.id}
                    quote={quote}
                    isDragging={dragSnapshot.isDragging}
                    isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
                    provided={dragProvided}
                    openRowModal={props.openRowModal}
                />
            )}
        </Draggable>
    ));
});

function InnerList(props) {
    const {quotes, dropProvided, openRowModal} = props;

    return (
        <Container>
            <DropZone ref={dropProvided.innerRef}>
                <InnerQuoteList quotes={quotes} openRowModal={openRowModal}/>
                {dropProvided.placeholder}
            </DropZone>
        </Container>
    );
}

export default function QuoteList(props) {
    const {
        ignoreContainerClipping,
        internalScroll,
        scrollContainerStyle,
        isDropDisabled,
        isCombineEnabled,
        listType,
        style,
        title,
        useClone,
        columnId,
        quotes,
        openRowModal
    } = props;


    return (
        <Droppable
            droppableId={columnId}
            type={listType}
            ignoreContainerClipping={ignoreContainerClipping}
            isDropDisabled={isDropDisabled}
            isCombineEnabled={isCombineEnabled}
            renderClone={
                useClone
                    ? (provided, snapshot, descriptor) => (
                        <QuoteItem
                            quote={quotes[descriptor.source.index]}
                            provided={provided}
                            isDragging={snapshot.isDragging}
                            openRowModal={openRowModal}
                            isClone
                        />
                    )
                    : null
            }
        >
            {(
                dropProvided,
                dropSnapshot,
            ) => (
                <Wrapper
                    style={style}
                    isDraggingOver={dropSnapshot.isDraggingOver}
                    isDropDisabled={isDropDisabled}
                    isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
                    {...dropProvided.droppableProps}
                >
                    {internalScroll ? (
                        <ScrollContainer style={scrollContainerStyle}>
                            <InnerList
                                quotes={quotes}
                                title={title}
                                dropProvided={dropProvided}
                                openRowModal={openRowModal}
                            />
                        </ScrollContainer>
                    ) : (
                        <InnerList
                            quotes={quotes}
                            title={title}
                            dropProvided={dropProvided}
                            openRowModal={openRowModal}
                        />
                    )}
                </Wrapper>
            )}
        </Droppable>
    );
}
