import React from 'react';

const BoardNotFoundPage = () => {
    return (
        <div className={"not-found-page"}>
            <div className="centered">
                <h1>Board not found or you don't have access.</h1>
                <p>The board that you are looking for doesn't exist or <strong>you don't have enough permissions to access it.</strong><br/>
                Please ensure that the url is correct and try again or request for access.</p>
            </div>
        </div>
    );
};

export default BoardNotFoundPage;
