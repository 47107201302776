import React, {useContext, useEffect, useState} from "react";
import {BoardContext} from "../../../state/boardStore";
import {API_GET_MESSAGES, API_URL} from "../../../constants";
import { generatePath } from 'react-router';
import clientAPI from "../../../service/ApiService";
import {toast} from "react-toastify";
import UserItem from '../../Common/UserItem';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button } from 'antd';
import {SpinnerLoading} from "../../Style/styles.css";
import {formatDate} from "../../../service/DateService";

function Messages({table, row}) {

    const initialState = {
        loading: true,
        error: null,
        row: null,
        messages: [],
        currentMessage: "",
        editor: null,
    }
    const [boardState] = useContext(BoardContext);
    const [state, setState] = useState(initialState)
    const [ckeditor, setCKEditor] = useState(null)
    const [loadingCreate, setLoadingCreate] = useState(false)


    useEffect(() => {

        setState({
            ...state,
            loading: true,
            messages: [],
            currentMessage: "",
            editor: null,
        })

        setLoadingCreate(false)
        requestMessages();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [row]);

    const requestMessages = () => {
        setState({
            ...state,
            messages: [],
        })

        let path= API_URL + generatePath(API_GET_MESSAGES + "?order=DESC", {
            board: boardState.board.id,
            table: table.id,
            row: row.id
        })

        clientAPI.get(path)
            .then(response => {
                setState({
                    ...state,
                    loading: false,
                    messages: response.data.payload.messages ? response.data.payload.messages : []
                })
            })
            .catch(error => {
                if(error && error.response && error.response.data.statusCode.msg){
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false,
                    })
                }else{
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    }

    const createMessage = () => {
        setLoadingCreate(true)

        let path= API_URL + generatePath(API_GET_MESSAGES, {
            board: boardState.board.id,
            table: table.id,
            row:   row.id
        })

        clientAPI.post(path, { body : state.currentMessage})
            .then(response => {
                setState({
                    ...state,
                    messages: response.data.payload ? [response.data.payload].concat(state.messages) : state.messages,
                })
                setLoadingCreate(false)
                ckeditor.setData("")
            })
            .catch(error => {
                console.log(error)
                if(error && error.response && error.response.data.statusCode.msg){
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                    })
                    setLoadingCreate(false)
                }else{
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    }

    return (
        <div className={"messages-container"}>
            <div className="new-message-container">
                <CKEditor
                    editor={ ClassicEditor }
                    className="message-editor"
                    config={ {
                        toolbar: [ 'bold', 'italic', 'link', 'bulletedList', 'numberedList' ],
                    } }
                    onInit={ editor2 => {
                        setCKEditor(editor2)
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setState({
                            ...state,
                            currentMessage: data
                        })
                    } }
                />
                <Button
                    type="primary"
                    loading={loadingCreate}
                    onClick={()=>createMessage()}
                >Save</Button>
            </div>

            { state.messages && state.messages.map((message, index) =>
                <div className="message-card" key={index}>
                    <div className="message-card-header">
                        <UserItem key={message.user} user={message.user} />
                        <p className="card-date">{ formatDate(message.createdAt) }</p>
                    </div>
                    <div className="message-card-content">
                        <div dangerouslySetInnerHTML={{ __html: message.body }} />
                    </div>
                </div>
            )}
            { !state.messages && !state.loading  &&
            <p>There are no messages for this row yet.</p>
            }

            {state.loading && <SpinnerLoading className="messages-drawer-spinner" animation="border" role="status" />}
        </div>
    )

}

export default Messages