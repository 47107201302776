import React, {useContext, useEffect, useState} from 'react'
import {Alert, Button, Form} from 'react-bootstrap';
import {AnonymousContainer, Box, Container, SpinnerLoading} from './ShareLinkPage.css'
import {useForm} from "react-hook-form";
import axios from 'axios'
import {SHARE_LINK} from '../../../constants/index';
import {useHistory} from 'react-router-dom';
import {Context} from "../../../state/store";
import {ANONYMOUS_USER_LOGIN} from "../../../state/user/UserTypes";
import BoardPage from "../../Boards/BoardPage";
import UsersStore from "../../../state/usersStore";

function AcceptInvitePage(props) {

    const initialState = {
        error: null,
        loading: true,
        firstLoad: false,
        success: false,
        needPassword: false,
    }

    useEffect(() => {
        requestSharedLinkInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const history = useHistory();
    const dispatch = useContext(Context)[1];
    const [state, setState] = useState(initialState)
    const { handleSubmit, register, errors } = useForm();

    const requestSharedLinkInfo = () => {

        axios.post(SHARE_LINK + "/validate", {"boardId": props.match.params.board, "token": props.match.params.token} )
            .then(response => {
                setState({
                    ...state,
                    error:null,
                    firstLoad: true,
                    loading: false,
                    success: false,
                    needPassword: !response.data.payload.public,
                })
                if(response.data.payload.public){
                    login();
                }
            })
            .catch(error => {

                setState({
                    ...state,
                    error: error?.response?.data?.statusCode?.msg ?? 'Error connecting with the server, check your connection or contact support',
                    loading: false,
                    success: false
                })

                if(error?.response?.data?.statusCode?.code === -11){
                    history.replace("/landing");
                }

            })
    }

    const login = (values) => {

        setState({
            ...state,
            loading: true,
        })

        const payload = {
            "boardId": props.match.params.board,
            "token": props.match.params.token,
            "password" : values?.password,
        }

        axios.post(SHARE_LINK + "/login", payload)
            .then(response => {
                setState({
                    ...state,
                    error:null,
                    firstLoad: true,
                    loading: false,
                    success: true,
                    needPassword: !response.data.payload.public,
                })

                dispatch({ type: ANONYMOUS_USER_LOGIN, payload: response.data.payload });

            })
            .catch(error => {
                setState({
                    ...state,
                    error: "Invalid password",
                    loading: false,
                    success: false
                })
            })
    }

    return (
        <React.Fragment>
            {state.success ?

                <AnonymousContainer>
                    <UsersStore>
                        <BoardPage board={props.match.params.board}/>
                    </UsersStore>
                </AnonymousContainer>
                :
                <Container>
                    <Box>
                        {state.loading &&
                        <React.Fragment><SpinnerLoading animation="border" role="status"/> Loading</React.Fragment>}
                        {state.error && <Alert variant={"danger"}>{state.error}</Alert>}
                        {state.firstLoad && state.needPassword && !state.success &&
                        <Form onSubmit={handleSubmit(login)}>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Board Password</Form.Label>
                                <input
                                    name="password"
                                    className="form-control"
                                    placeholder="Password"
                                    type="password"
                                    ref={register({
                                        required: "You must specify a password",
                                        minLength: {
                                            value: 3,
                                            message: "Password must have at least 3 characters"
                                        }
                                    })}
                                />
                                <p className="alert-danger">{errors.password && errors.password.message}</p>
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form>
                        }
                    </Box>
                </Container>
            }

        </React.Fragment>
    )
}

export default AcceptInvitePage
