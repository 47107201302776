import React from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';

import './App.scss';
import Store from './state/store';
import BoardsStore from './state/boardsStore';
import BoardStore from './state/boardStore';
import ConnectedUsersStore from './state/connectedUsersStore';
import {ToastContainer} from 'react-toastify';
import AppRouter from './AppRouter.js'
import UsersStore from "./state/usersStore";

function App(props) {

    return (
        <div className="App">
            <Store>
                <BoardsStore>
                    <BoardStore>
                        <UsersStore>
                            <ConnectedUsersStore>
                                <AppRouter/>
                            </ConnectedUsersStore>
                        </UsersStore>
                    </BoardStore>
                </BoardsStore>
            </Store>
            <ToastContainer />
        </div>
    );
}

export default App;
