import React, { useState, useEffect } from 'react'
import { FaPlusCircle, FaRegUserCircle } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import UserIcon from "../../../../../Common/UserIcon";

function PeopleButton({users}) {

    const initialState = {
        numPlus: 0,
        usersToDisplay: []
    }
    const [state, setState] = useState(initialState)
    const [isShown, setIsShown] = useState(false);
    const [hoverDelete, setHoverDelete] = useState(false);

    useEffect(() => {

        if(users?.length > 3){
            setState({
                ...state,
                numPlus: users.length - 2,
                usersToDisplay : users.slice(0,2)
            })
        }else{
            setState({
                ...state,
                usersToDisplay : users
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users]);

    const removeAll = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }
    
    return (
        <div className="people-button" onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
            { isShown && !hoverDelete && 
                <FaPlusCircle className="add-people-cta" />
            }
            { state.usersToDisplay && state.usersToDisplay.map((user, index) =>
                <UserIcon key={index} user={user} disable={false} placement={"top"} />
            )}
            { users && users.length > 3 &&
                <div className="user-initials plus-button">+{state.numPlus}</div>
            }
            { isShown && state.usersToDisplay?.length > 0 &&
                <div className="remove-people-cta" onClick={(e) => removeAll(e)} onMouseEnter={() => setHoverDelete(true)} onMouseLeave={() => setHoverDelete(false)}>
                    <IoMdCloseCircle />
                </div>
            }
            { state.usersToDisplay?.length === 0 &&
                <FaRegUserCircle className="no-user-icon" />
            }
        </div>
    )
}

export default PeopleButton
