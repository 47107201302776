import React, {useRef, useEffect, useState} from "react";
import {Badge} from "antd";
import {FaRegBell} from "react-icons/fa/index";
import {IoMdClose} from "react-icons/io/index";
import {API_GET_NOTIFICATIONS} from "../../constants/index"
import clientAPI from "../../service/ApiService"
import {toast} from 'react-toastify';
import {useHistory} from "react-router-dom";
import {
    UserAddedToTeam, UserInvitedToBoard, UserRemovedFromTeam, WelcomeNotification
} from "../../constants/constants";
import {SpinnerLoading} from "../Style/styles.css";
import {formatDate} from "../../service/DateService";

const NotificationsModal = ({updateNotifications}) => {

    const [state, setState] = useState({
        error: false,
        loading: false,
        end: false,
        showNotifications: false,
        clickedButton: false,
        unread: 0,
    })
    const limit = 10
    const [notifications, setNotifications] = useState([]);
    const [page, setPage] = useState(1);
    const history = useHistory();

    useEffect(() => {
        getNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        setState({
            ...state,
            initialRequest: true,
        })
        setPage(1);
        setNotifications([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateNotifications]);

    useEffect(() => {
        if(state.initialRequest)
            getNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.initialRequest]);

    useEffect(() => {
        setState({
            ...state,
            initialRequest: true,
        })
        setPage(1);
        setNotifications([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.showNotifications]);

    function useOutsideAlerter(ref, button) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {

                    if (button.current.contains(event.target)) {
                        setState({...state, showNotifications: false, clickedButton: true})
                    } else {
                        setState({...state, showNotifications: false, clickedButton: false})
                    }
                    clearNotifications();
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    const buttonRef = useRef(null);
    useOutsideAlerter(wrapperRef, buttonRef);

    const openNotifications = () => {
        if (state.clickedButton) {
            setState({...state, clickedButton: false})
            return;
        }
        setState({...state, showNotifications: true})
    }

    // useEffect(() => {
    //     getNotifications();
    // }, []);

    const getNotifications = () => {

        if (state.loading || state.end) {
            return false;
        }

        setState({
            ...state,
            error: null,
            loading: true,
        })

        clientAPI.get(API_GET_NOTIFICATIONS + "?page=" + page + "&limit=" + limit)
            .then(response => {

                let objs = response.data.payload.notifications;
                if (!objs || objs.length === 0) {
                    setState({
                        ...state,
                        loading: false,
                        end: true,
                        initialRequest: false,
                        unread : response.data.payload.unread,
                    })
                } else {
                    setState({
                        ...state,
                        loading: false,
                        initialRequest: false,
                        unread : response.data.payload.unread,
                    })
                    setNotifications(objs ? notifications.concat(objs) : [])
                }

            })
            .catch(error => {
                if (error && error.response && error.response.data && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false,
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    }

    const clearNotifications = () => {
        clientAPI.post(API_GET_NOTIFICATIONS + "/clear")
            .then(response => {
                notifications.map(function (notification) {
                    notification.read = true
                    return notification;
                })
            })
            .catch(error => {
                if (error && error.response && error.response.data && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false,
                    })
                } else {
                    toast.error('Error connecting with the server 2, check your connection or contact support');
                }
            })
    }

    const handleScroll = (e) => {

        if(state.loadingPage || state.end){
            return false;
        }

        const target = e.target;
        let difference = target.scrollHeight - target.scrollTop;
        if( difference === target.clientHeight){
            setPage(page + 1);
        }
    }

    const notificationClick = (notification) => {
        switch (notification.Type) {
            case WelcomeNotification:
                return;
            case UserAddedToTeam:
                setState({...state, showNotifications: false, clickedButton: false})
                history.push("/teams");
                return;
            case UserRemovedFromTeam:
                setState({...state, showNotifications: false, clickedButton: false})
                history.push("/teams");
                return;
            case UserInvitedToBoard:
                if(!notification.board) return;
                setState({...state, showNotifications: false, clickedButton: false})
                history.push("/boards/" + notification.board.id);
                return;
            default:
                return null;
        }
    }

    const hasLink = (notification) => {
        switch (notification.Type) {
            case WelcomeNotification:
                return false;
            case UserAddedToTeam:
                return true;
            case UserRemovedFromTeam:
                return true;
            case UserInvitedToBoard:
                return true;
            default:
                return null;
        }
    }

    return (
        <React.Fragment>
            <div
                ref={buttonRef}
                className={"notifications-button"}
                onClick={() => openNotifications()}
            >
                <Badge count={state.unread}>
                    <FaRegBell/>
                </Badge>
            </div>
            {state.showNotifications &&
            <div ref={wrapperRef} className={"notifications-modal"}>
                <div className="header">Notifications</div>
                <IoMdClose className={"close-button"}
                           onClick={() => setState({...state, showNotifications: false, clickedButton: false})}/>
                <div className="separator"/>

                <div className="notifications-list" onScroll={(e) => handleScroll(e)}>
                    {notifications && notifications.map((notification, index) =>
                        <div className="notification-card" style={{cursor:hasLink(notification) ? "pointer" : "initial"}} key={index} onClick={() => notificationClick(notification)}>
                            <div className="date">
                                {formatDate(notification.createdAt)}
                            </div>
                            { notification.hasOwnProperty('read') && notification.read === false &&
                                <div className="new">
                                    <p>NEW</p>
                                </div>
                            }
                            <div className="message-card-content">
                                {(() => {
                                    switch (notification.Type) {
                                        case WelcomeNotification:
                                            return <p>Welcome to Kaiseki.io</p>;
                                        case UserAddedToTeam:
                                            return <p><span className={"username"}>{notification?.createdByUser?.name} {notification?.createdByUser?.surname}</span> added you to the team <span className={"team-name"}>{notification?.team?.name ?? notification?.team?.teamName }</span></p>;
                                        case UserRemovedFromTeam:
                                            return <p>You have been removed from the team <span className={"team-name"}>{notification?.team?.name}</span> by <span className={"username"}>{notification?.createdByUser?.name} {notification?.createdByUser?.surname}</span></p>;
                                        case UserInvitedToBoard:
                                            if(!notification.board){
                                                return <p><span className={"username"}>{notification?.createdByUser?.name} {notification?.createdByUser?.surname}</span> added you to a board that is already deleted</p>;
                                            }
                                            return <p><span className={"username"}>{notification?.createdByUser?.name} {notification?.createdByUser?.surname}</span> added you to the board <span className={"team-name"}>{notification?.board?.name ?? notification?.board?.boardName}</span></p>;
                                        default:
                                            return null;
                                    }
                                })()}
                            </div>
                        </div>
                    )}
                    { state.loading && notifications.length > 0 &&
                        <div className={"loading-card"}>
                            <SpinnerLoading animation="border" role="status"></SpinnerLoading>
                        </div>
                    }
                </div>

            </div>
            }
        </React.Fragment>
    );
};

export default NotificationsModal;
