import React, {useEffect, useState} from 'react'
import CopyEditor from "./CopyEditor";
import CopyView from "./CopyView";
import {Draggable} from "react-beautiful-dnd";
import {GiHamburgerMenu} from "react-icons/gi";
import QuillCopyEditor from "./QuillCopyEditor";
import QuillCopyView from "./QuillCopyView";

function CopyComponent({table, index, isDragging}) {

    const [editMode, setEditMode] = useState(false)

    useEffect(() => {
        if(table.editMode){
            setEditMode(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`copy-type ${isDragging ? "dragging-table" : ""}`}>
            <Draggable draggableId={table.id}
                       index={index}
                       >
                {provided => (
                    <div className={"table-wrapper"}
                         ref={provided.innerRef}
                         {...provided.draggableProps}
                    >
                        { isDragging &&
                            <h3>{table.name}</h3>
                        }
                        <div {...provided.dragHandleProps}>
                            <GiHamburgerMenu className="table-drag-handle"/>
                        </div>
                        <div className={"copy-component"}>
                            {editMode ?
                                // <CopyEditor
                                //     table={table}
                                //     setEditMode={setEditMode}
                                // />
                                <QuillCopyEditor
                                    table={table}
                                    setEditMode={setEditMode}
                                />
                                :
                                // <CopyView
                                //     table={table}
                                //     setEditMode={setEditMode}
                                // />
                                <QuillCopyView
                                    table={table}
                                    setEditMode={setEditMode}
                                />
                            }
                        </div>
                        {provided.placeholder}
                    </div>
                )
                }
            </Draggable>
        </div>
    )
}

export default CopyComponent