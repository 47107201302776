import React, {useContext, useEffect, useState} from 'react';
import Fade from 'react-reveal/Fade';
import {Spin} from "antd";
import clientAPI from "../../../service/ApiService";
import {API_BOARD} from "../../../constants";
import {useHistory} from "react-router-dom";
import {Context} from "../../../state/store";
import {CREATE_BOARD} from "../../../state/boards/BoardTypes";
import {BoardsContext} from "../../../state/boardsStore";
import {LAUNCH_ONBOARD_DONE} from "../../../state/user/UserTypes";

function EndPage({boardName, table, copy, rows}){

    const [loading, setLoading] = useState(true)
    const [boardId, setBoardId] = useState(null)
    const [userState, dispatchUser] = useContext(Context);
    const history = useHistory();
    const dispatch = useContext(BoardsContext)[1];

    useEffect(()=>{
        createBoard()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const createBoard = async () => {

        try {
            let response = await clientAPI.post(API_BOARD, {
                name:boardName,
                visibility: "private",
                users: [userState.user.ID],
                categories:[],
                teams: [],
                color:"#ABB8C3",
            });
            let board = response.data.payload;
            setBoardId(board.id);
            dispatch({ type: CREATE_BOARD, payload: board });

            response = await clientAPI.post(API_BOARD + "/" + board.id + "/table",
                {"type":"standard", "name": table.name, "fill": true});
            let tableResponse = response.data.payload;

            // We cannot use a promise.all as we want to preserve the order
            let createdRows = [];
            for(const row of rows) {
                createdRows.push(await clientAPI.post(API_BOARD + "/" + board.id + "/table/" + tableResponse.id + "/row",
                    {name: row.name}));
            }

            let columns = [];
            tableResponse.columns.map(column => {
                if(column.type.type === "status") {
                    columns = column.type.metadata;
                }
                return column;
            })

            let promises = [];
            let index = 0;

            rows.map(row => {
                row.entries.map(entry => {
                    if(entry.column === "2"){ // status
                        promises.push(clientAPI.post(API_BOARD + "/" + board.id +
                            "/table/" + tableResponse.id +
                            "/column/" + tableResponse.columns[1].id +
                            "/row/" + createdRows[index].data.payload.id +
                            "/entry",
                            {value: columns[parseInt(entry.value)-1].id }))
                    }else if(entry.column === "3"){ //Date
                        console.log(columns)
                        console.log(entry)
                        promises.push(clientAPI.post(API_BOARD + "/" + board.id +
                            "/table/" + tableResponse.id +
                            "/column/" + tableResponse.columns[2].id +
                            "/row/" + createdRows[index].data.payload.id +
                            "/entry",
                            {value: entry.value }))
                    }
                    return entry;
                })
                index++;
                return row;
            })

            await Promise.all(promises);
            // We create the copy table
            response = await clientAPI.post(API_BOARD + "/" + board.id + "/table",
                {"type":"copy", "name": "Rich text information"});
            tableResponse = response.data.payload;

            await clientAPI.post(API_BOARD + "/" + board.id + "/table/" + tableResponse.id + "/copy",
                {"body":copy});

            // We create the assets table
            await clientAPI.post(API_BOARD + "/" + board.id + "/table",
                {"type":"assets", "name": "Assets"});

            setLoading(false);
        } catch (e) {
            console.error(e);
            history.push("/");
        }
    }

    const goToBoard = () => {
        dispatchUser({ type: LAUNCH_ONBOARD_DONE})
        history.push("/boards/" + boardId);
    }

    return (
        <div className={"intro"}>
            <div className="triangle-topleft" />
            <Fade bottom>
                <div className="wrapper">
                    <h1>You get it from here</h1>
                    <p>Now you mastered the basics from Kaiseki.io, but this is just a start,
                        don't be afraid to start exploring all the options that kaiseki.io provides.</p>
                        <br/>
                        <br/>
                    { loading ?
                        <React.Fragment>
                            <p>We are creating the first board for you, give us a second.</p>
                            <Spin style={{marginTop:"10px"}} />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <p>Your first board has been created, feel free to play with it.</p>
                            <button className={"next"} onClick={() => goToBoard()}>Done</button>
                        </React.Fragment>
                    }

                </div>
            </Fade>
        </div>
    );
};

export default EndPage;