import React, {useContext} from 'react'
import {SpinnerLoading} from "../../Style/styles.css";
import AssetIcon from "../../AssetsPage/AssetIcon";
import {humanFileSize} from "../../AssetsPage/Utils";
import DeleteButton from "../../AssetsPage/DeleteButton";
import DownloadButton from "./DownloadButton";
import {BoardsContext} from "../../../state/boardsStore";
import {formatDate} from "../../../service/DateService";

function AssetRow({ assetFile, refresh, showAssetDetail }) {

    const [boardsState] = useContext(BoardsContext);

    return (
        <tr className="asset-row">
            <td className="asset-name-row" onClick={() => showAssetDetail(assetFile?.asset)}>

                <AssetIcon asset={assetFile?.asset}/>

                {!assetFile?.asset && <p>{assetFile.name}</p> }
                {assetFile?.asset &&
                    <div className="name">
                        {assetFile?.asset?.name && assetFile?.asset?.name.length > 0 ? assetFile?.asset?.name : assetFile?.asset?.originalFilename}
                    </div>
                }
            </td>
            {boardsState?.width > 768 &&
                <React.Fragment>
                    <td className={"align"}>
                        {assetFile?.asset &&
                        (() => {
                            switch (assetFile?.asset?.filetype) {
                                case "image":
                                    return "Image";
                                case "video":
                                    return "Video";
                                case "audio":
                                    return "Audio";
                                case "pdf":
                                    return "PDF Document";
                                case "archive":
                                    return "Compressed file";
                                case "document":
                                    return "Document";
                                default:
                                    return "-"
                            }
                        })()
                        }

                        {(assetFile?.status === "uploading" || assetFile?.status == null) &&
                            <SpinnerLoading animation="border" role="status" className="spinner-animation"/>
                        }
                        {assetFile?.status && assetFile?.status === "error" && <div className={"error"}>Error</div>}
                    </td>

                    <td className="align">
                        {assetFile?.asset &&
                            humanFileSize(assetFile.asset.fileSizeInBytes, true)
                        }
                    </td>
                    <td className="align">
                        {assetFile?.asset &&
                            formatDate(assetFile.asset.createdAt)
                        }
                    </td>
                </React.Fragment>
            }
            <td className="align d-flex asset-actions">
                <DownloadButton asset={assetFile.asset} />
                <DeleteButton asset={assetFile.asset} refresh={refresh} />
            </td>
        </tr>
    )
}

export default AssetRow
