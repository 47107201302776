import React, { useState, useEffect, useContext } from 'react'
import clientAPI from "../../../../service/ApiService"
import UserCard from './UserCard'
import { SpinnerLoading } from '../../../Style/styles.css'
import { toast } from 'react-toastify';
import { API_USERS } from '../../../../constants';
import UserListItem from './UserListItem';
import {BoardContext} from "../../../../state/boardStore";
import {SET_SINGLE_VALUE} from "../../../../state/boards/BoardTypes";
import {GENERIC_ERR_MSG} from "../../../../constants/constants";

function PeopleDropdown(props) {

    const [users, setUsersState] = useState(props.users) // Users selected
    const [searchTerm, setSearchTerm] = useState('')
    const [timer, setTimer] = useState(null)

    const initialState = {
        loading: false,
        error: null,
        usersResult: null  // Users that come from the search
    }
    const dispatchBoard = useContext(BoardContext)[1];
    const [state, setState] = useState(initialState)

    useEffect(() => {
        if (props.visible){
            search('');
            return;
        }
        setUsersState(props.users);
        setState({
            ...state,
            usersResult: []
        })
        setSearchTerm('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.visible]);


    useEffect(() => {
        setUsersState(props.users)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.users]);

    const removeUser = (user) => {
        setUsersState(
            users.filter(o => o.ID !== user.ID)
        )

        setState({
            ...state,
            usersResult: [user].concat(state.usersResult)
        })
    }

    const setName = (event) => {
        var searchText = event.target.value;
        setSearchTerm(searchText)

        if (timer != null) {
            clearTimeout(timer);
        }

        setTimer(
            setTimeout(() => {
                search(searchText)
            }, 300)
        )
    }

    const search = (searchString) => {

        setState({
            ...state,
            loading: true,
        })

        clientAPI.get(API_USERS + "?page=1&limit=20&search=" + searchString)
            .then(response => {

                var results = response.data.payload.users;
                if(!results || results.length === 0){
                    setState({
                        ...state,
                        error: null,
                        loading: false,
                        usersResult: []
                    })
                    return
                }
                var filtered = results.filter( function(o){
                    var findResult = users.find( function(obj){
                        return obj.ID === o.ID
                    })
                    return typeof findResult === 'undefined'
                })

                setState({
                    ...state,
                    error: null,
                    loading: false,
                    usersResult: filtered
                })
            })
            .catch(error => {
                console.log(error)
                if (error && error.response && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false,
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    }

    const addUser = user => {
        setUsersState(users.concat(user))
        setState({
            ...state,
            usersResult: state.usersResult.filter( o => o.ID !== user.ID) 
        })
    }

    const updateValue = () => {

        let userIDS = users.map(a => a.ID);

        clientAPI.post(props.path, {"value": userIDS})
        .then(response => {
            props.closeDropdown(false)
            props.updateCell(userIDS)

            dispatchBoard({
                type: SET_SINGLE_VALUE,
                payload: {tableId: props.table.id, rowId: props.row.id, parentRowId: props.row.parent, columnId: props.column.id, value: userIDS}
            });

        })
        .catch(error => {
            toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
        })
    }

    return (
        <div>
            <div className="current-users">
                {users && users.map((user, index) =>
                    <UserCard user={user} key={index} removeUser={removeUser} />
                )}
            </div>
            <div className="user-search-field">
                <input type="text" placeholder="Enter name" value={searchTerm} onChange={(e) => setName(e)} />
                {state.loading && <SpinnerLoading className="user-field-spinner" animation="border" role="status" />}
            </div>
            <div className="list-results">
                {state.usersResult && state.usersResult.map((user, index) =>
                    <UserListItem key={user.ID} user={user} addUser={addUser} />
                )}
                {state.usersResult && state.usersResult.length === 0 &&
                    <p>No results</p>
                }
            </div>
            <div className="save-people-actions">
                <button onClick={() => updateValue()}>Save</button>
            </div>
        </div>
    )
}

export default PeopleDropdown
