import React, {useEffect, useState} from 'react';
import clientAPI from "../../service/ApiService";
import {API_SEARCH} from "../../constants";
import {toast} from "react-toastify";
import { IoMdClose } from "react-icons/io";
import { MdEdit } from "react-icons/md";

function SearchGrid({openSearchModal, refresh, doSearch}) {

    const initialState = {
        error: null,
        loading: false,
        refresh: 0,
    }
    const [state, setState] = useState(initialState)
    const [searches, setSearches] = useState([])

    useEffect(() => {
        getSearches()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    const getSearches = () => {
        setState({
            ...state,
            error: null,
            loading: true,
        })

        clientAPI.get(API_SEARCH)
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false,
                })

                let s = response.data.payload;
                if(s?.length > 0){
                    setSearches(s);
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: true,
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    }

    const deleteSearch = (search) => {
        setState({
            ...state,
            error: null,
            loading: true,
        })

        clientAPI.delete(API_SEARCH + "/" + search.id)
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false,
                })
                setSearches(searches.filter((s) => s.id !== search.id));
            })
            .catch(error => {
                if (error && error.response && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: true,
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    }

    const searchClicked = (event, search) =>{
        if(event.target === event.currentTarget){
            doSearch(search);
        }
    }

    return (
        <div className={"search-grid"}>
            {searches.map((search, index) =>
                <div className={"search-item"} key={index} onClick={(e) => searchClicked(e, search)}>
                    { search.name && search.name.length > 0 &&
                    <h4>{search?.name}</h4>
                    }
                    {search.text && search.text.length > 0 &&
                    <p>{search?.text}</p>
                    }
                    {search.team?.name &&
                    <p>{search?.team?.name}</p>
                    }
                    {search.categories && search.categories.length > 0 &&
                    <span className={"categories"}>
                        {search.categories?.map((category, index) =>
                            <span className={"category"} key={index}>
                                <p className={"label"}>{category}</p>
                            </span>
                        )}
                    </span>
                    }
                    <div className="search-group">
                        <div className="edit-search-cta" onClick={(e) =>
                            openSearchModal(search)}>
                            <MdEdit />
                        </div>
                        <div className="remove-search-cta" onClick={(e) =>
                            {if (window.confirm('Are you sure you wish to delete this search?')) deleteSearch(search)} }
                        >
                            <IoMdClose />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SearchGrid;
