import React, { useState, useContext, useCallback } from 'react'
import { FaRegUserCircle } from "react-icons/fa";
import { Button, Modal } from 'react-bootstrap';
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider';
import getCroppedImg from './CropImage'
import clientAPI from "../../service/ApiService"
import { API_USER_PICTURE } from '../../constants';
import { toast } from 'react-toastify';
import { SpinnerLoading } from '../Style/styles.css'
import {Context} from '../../state/store'
import { USER_GET_PROFILE } from '../../state/user/UserTypes'

function UploadPicture(props) {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        showModal: false,
    }

    const dispatch = useContext(Context)[1];
    const [state, setState] = useState(initialState);
    const [image, setImage] = useState({ preview: props.user.image, raw: null });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const handleChange = e => {
        if (e.target.files && e.target.files.length > 0) {

            const reader = new FileReader();
            reader.addEventListener("load", () => setImage({ ...image, raw: reader.result }));
            reader.readAsDataURL(e.target.files[0]);

            setState({
                ...state,
                showModal: true
            })
        }
    };

    function DataURIToBlob(dataURI) {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
    }

    const handleClose = e => {
        setState({
            ...state,
            showModal: false
        })
    }

    const saveCrop = async e => {

        try {
            const croppedImage = await getCroppedImg(
                image.raw,
                croppedAreaPixels
            )
            // setCroppedImage(croppedImage)

            setState({
                ...state,
                loading: true,
                showModal: true
            })

            const formData = new FormData();
            formData.append("image", DataURIToBlob(croppedImage));
            clientAPI.post(API_USER_PICTURE, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
            .then(response => {
                setState({
                    ...state,
                    loading: false,
                    showModal: false
                })
                setImage({ ...image, preview: response.data.payload.image })
                dispatch({ type: USER_GET_PROFILE, payload: response.data.payload });
            })
            .catch(error => {
                console.log(error)
                if (error && error.response && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false,
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })

        } catch (e) {
            console.error(e)
        }
    }

    // Croppie
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

return (
    <div className="upload-profile">
        <label htmlFor="upload-button">
            {image.preview ?
                <img src={image.preview} className="profile-image" alt="User profile"/>
                :
                <div>
                    <FaRegUserCircle></FaRegUserCircle>
                    <h5 className="text-center">Upload your photo</h5>
                </div>
            }
        </label>
        <input
            type="file"
            id="upload-button"
            style={{ display: "none" }}
            onChange={handleChange}
        />
        <br />
        {/* <button onClick={handleUpload}>Upload</button> */}

        <Modal
            className="crop-profile-image-modal"
            show={state.showModal}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Crop image
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {image &&
                        <div className="">
                            <div className="crop-container">
                                {image.raw &&
                                    <Cropper
                                        image={image.raw}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={1}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={setZoom}
                                    />
                                }
                            </div>
                        </div>
                    }
                </div>
            </Modal.Body>
            <div className="controls">
                <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => setZoom(zoom)}
                />
            </div>
            <Modal.Footer>
                {state.loading && <SpinnerLoading animation="border" role="status"></SpinnerLoading>}
                <Button variant="primary" type="submit" onClick={() => saveCrop()}>
                    Save
                    </Button>
            </Modal.Footer>
        </Modal>

    </div>
)
}

export default UploadPicture
