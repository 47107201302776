import React, {useEffect, useState} from 'react'
import clientAPI from "../../../service/ApiService";
import {API_BOARD} from "../../../constants";
import {toast} from "react-toastify";
import {GENERIC_ERR_MSG} from "../../../constants/constants";
import {Spin} from "antd";

function AutomationSelectTable({boardId, selectedTable}) {

    const initialState = {
        loading: false,
        error: null,
        board: null,
    }
    const [state, setState] = useState(initialState)

    useEffect(() => {
        getBoard();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBoard = () => {
        setState({
            ...state,
            loading: true,
        })
        clientAPI.get(API_BOARD + "/" + boardId)
            .then(response => {
                setState({
                    ...state,
                    loading: false,
                    board: response.data.payload,
                })
            })
            .catch(error => {
                setState({
                    ...state,
                    loading: false,
                })
                toast.error(error?.response?.data?.statusCode?.msg ?? GENERIC_ERR_MSG);
            })
    }

    return (
        <div className={"select-table"}>
            {state.loading && <Spin/>}
            <div>
                <table className="table select-table">
                    <thead>
                    <tr>
                        <th scope="col" style={{color: "black"}}>Name</th>
                    </tr>
                    </thead>
                    <tbody>
                    {state.board?.tables?.map((table, index) =>
                        <tr className="board-row" key={index} onClick={() => selectedTable(table)}>
                            <td className="board-name-row">
                                <div className="vertical-div">
                                    <div className="d-flex">
                                        <div className="name">{table.name}</div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AutomationSelectTable
