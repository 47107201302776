import React, {useState, useEffect, useContext} from 'react'
import UserIcon from '../Common/UserIcon';
import TeamIcon from '../Common/TeamIcon';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {OPEN_CREATE_BOARD_MODAL} from "../../state/boards/BoardTypes";
import {BoardsContext} from "../../state/boardsStore";

function UsersAndTeams({board}) {

    const initialState = {
        numPlus: 0,
        usersAndTeamsToDisplay: [],
        showOverlay: false
    }
    const [state, setState] = useState(initialState)
    const dispatchBoards = useContext(BoardsContext)[1];

    useEffect(() => {
        if (!board.users && !board.teams) return
        let usersAndTeams = board.users.concat(board.teams)
        if (usersAndTeams.length > 5) {
            setState({
                ...state,
                numPlus: usersAndTeams - 2,
                usersAndTeamsToDisplay: usersAndTeams.slice(0, 5)
            })
        } else {
            setState({
                ...state,
                usersAndTeamsToDisplay: usersAndTeams
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [board]);

    const colors = [
        "#43a047",
        "#ffb300",
        "#1e88e5",
        "#e53935",
        "#fb8c00",
        "#757575",
        "#5e35b1",
        "#546e7a"
    ]

    const tooltip = (props) => (
        <Tooltip id="user-teams-tooltip" {...props}>
            <div className="user-teams-inner">
                <p><strong style={{textTransform: 'capitalize'}}>{board.visibility} board</strong></p>
                <div className="separator" />
                {board && board.users && board.users.length > 0 &&
                <div>
                    <p><strong>Members</strong></p>
                    <ul>
                        {board.users.map((user, index) =>
                            <li key={index}>{user.name} {user.surname}</li>
                        )}
                    </ul>
                </div>
                }
                {board && board.teams && board.teams.length > 0 &&
                <div>
                    <div className="separator" />
                    <div>
                        <p><strong>Teams</strong></p>
                        <ul>
                            {board.teams.map((team, index) =>
                                <li key={index}>{team.name}</li>
                            )}
                        </ul>
                    </div>
                </div>
                }
            </div>
        </Tooltip>
    );

    return (
        <OverlayTrigger placement={'bottom'} overlay={tooltip} showOverlay={false}>
            <div className="users-and-teams" onClick={() =>
                dispatchBoards({type: OPEN_CREATE_BOARD_MODAL, payload: {modalMode: "edit", board: board}})
            }  >
                {/* <p>Users and teams</p> */}

                <div className="list" style={{width: (33 + ((state.usersAndTeamsToDisplay.length - 1) * 16))}}>
                    {state.usersAndTeamsToDisplay && state.usersAndTeamsToDisplay.map((userTeam, index) =>
                        <div key={index} className="user-team-entry" style={{left: index * 15, zIndex: 5 - index}}>
                            {userTeam.email ?
                                <UserIcon user={userTeam} color={colors[index]} disable={true}/>
                                :
                                <TeamIcon team={userTeam} color={colors[index]}/>
                            }

                        </div>
                    )}
                </div>

            </div>
        </OverlayTrigger>
    )
}

export default UsersAndTeams
