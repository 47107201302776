import React, { useState, useEffect } from 'react'
import UserIcon from '../Common/UserIcon';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function UsersIcons({ users }) {

    const initialState = {
        numPlus: 0,
        usersToDisplay: [],
        showOverlay: false
    }
    const [state, setState] = useState(initialState)

    useEffect(() => {
        if (!users) return
        if (users.length > 5) {
            setState({
                ...state,
                numPlus: users - 2,
                usersToDisplay: users.slice(0, 5)
            })
        } else {
            setState({
                ...state,
                usersToDisplay: users
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users]);

    const colors = [
        "#43a047",
        "#ffb300",
        "#1e88e5",
        "#e53935",
        "#fb8c00",
        "#757575",
        "#5e35b1",
        "#546e7a"
    ]

    const tooltip = (props) => (
        <Tooltip id="user-teams-tooltip" {...props}>
            <div className="user-teams-inner">
                {users && users.length > 0 &&
                    <div>
                        <ul>
                            {users.map((user, index) =>
                                <li key={index}>{user.name} {user.surname}</li>
                            )}
                        </ul>
                    </div>
                }
            </div>
        </Tooltip>
    );

    return (
        <OverlayTrigger placement={'bottom'} overlay={tooltip} showOverlay={false}>
            <div className="users-and-teams" style={{ width: (33 + ((state.usersToDisplay.length-1) * 16)) }}>
                {/* <p>Users and teams</p> */}

                <div className="list" style={{ width: (33 + ((state.usersToDisplay.length-1) * 16)) }} >
                    {state.usersToDisplay && state.usersToDisplay.map((user, index) =>
                        <div key={index} className="user-team-entry" style={{ left: index * 15, zIndex: 5 - index }}>
                            <UserIcon user={user} color={colors[index]} />
                        </div>
                    )}
                </div>

            </div>
        </OverlayTrigger>
    )
}

export default UsersIcons
