import React from 'react'
import AssetsTable from "./AssetsTable";

function AssetsPage() {

    return (
        <div className="main-container assets-page page-inner">
            <h3>Files</h3>

            <AssetsTable />
        </div>
    )
}

export default AssetsPage
