import React, {useCallback, useEffect, useState} from 'react';
import {Alert, Modal} from 'antd';
import {useDropzone} from "react-dropzone";
import clientAPI from "../../service/ApiService";
import {API_ASSETS} from "../../constants";
import {toast} from "react-toastify";
import UploadingAssetRow from "./UploadingAssetRow";

function AssetUploadModal({open, refresh}){

    const initialState = {
        visible: false,
        filesToUpload: [],
        files: [],
        filesAdded: false,
        error: null,
    }
    const [state, setState] = useState(initialState)

    useEffect(() => {
        if(open === 0) return;
        setState({
            ...state,
            visible: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleCancel = e => {
        setState({
            ...state,
            visible: false,
            files: [],
        });
    };

    const handleOk = e => {
        setState({
            ...state,
            visible: false,
            files: [],
        });
    };

    const onDrop = useCallback(acceptedFiles => {
        setState({
            ...state,
            visible: true,
            filesToUpload: state.filesToUpload.concat(acceptedFiles),
            files: state.files.concat(acceptedFiles),
            filesAdded: true,
        })
    }, [setState, state])

    useEffect(() => {
        if(state.filesAdded === false) return;

        let promises = [];
        state.filesToUpload.forEach((file) => {
            promises.push(uploadFile(file));
        });

        setState({...state, filesToUpload: [], filesAdded: false})

        Promise.all(promises)
            .then((results) => {
                setState({...state, filesToUpload: [], filesAdded: false})
                refresh();
            })
            .catch((e) => {
                toast.error('Error uploading assets');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.filesAdded]);

    const uploadFile = (file) => {

        const formData = new FormData();
        formData.append("asset", file);

        return clientAPI.post(API_ASSETS, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                setState({...state,
                    filesToUpload: [],
                    filesAdded: false,
                    files: state.files.map(a => {
                        if(file.name === a.name){
                            a.status = "done";
                            a.assetId = response.data.payload.id
                        }
                        return a;
                    })})

            })
            .catch(error => {
                if (error && error.response && error.response.data.statusCode.msg) {
                    if(error.response.data.statusCode.code === -9001){
                        setState({...state,
                            filesAdded: false,
                            files: state.files.map(a => {
                                if(file.name === a.name){
                                    a.status = "error";
                                }
                                return a;
                            }),
                            error: "Disk exceed quota, please remove some files or contact support at info@kaiseki.io"})
                    }else{
                        setState({...state, error: error.response.data.statusCode.msg})
                    }
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    return (
        <div>
            {state.visible &&
            <Modal
                title="Upload asset"
                visible={state.visible}
                okText="Done"
                className="asset-upload-modal"
                onOk={() => handleOk()}
                onCancel={() => handleCancel()}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{form: 'search-editor-form', key: 'submit', htmlType: 'submit'}}
            >
                <div {...getRootProps()} className={"upload-dropzone"}>
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>Drop the files here ...</p> :
                            <p>Drag 'n' drop some files here, or click to select files</p>
                    }
                </div>
                {state.error && <Alert message={state.error} type="error" closable className="notification"/>}
                { state.files && state.files.length > 0 &&
                    <React.Fragment>
                        <table className="table asset-table">
                            <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Status</th>
                                {/*<th scope="col">Size</th>*/}
                                {/*<th scope="col">Type</th>*/}
                            </tr>
                            </thead>
                            <tbody>
                            {state.files.map((file, index) =>
                                <UploadingAssetRow
                                    key={index}
                                    file={file}
                                    refresh={refresh}
                                />
                            )}
                            </tbody>
                        </table>
                        <p className={"tip"}>Click on the name of the uploaded asset to edit the file name</p>
                    </React.Fragment>
                }

            </Modal>
            }

        </div>
    );
};

export default AssetUploadModal;
