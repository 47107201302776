import React from 'react';
import Fade from 'react-reveal/Fade';

function Intro({next}){

    return (
        <div className={"intro"}>
            <div className="triangle-topleft"></div>
            <Fade bottom>
                <div className="wrapper">
                    <h1>Thanks and welcome!</h1>
                    <p>We are going to go with you through the basics of kaiseki.io, to help you understand how it works.</p>
                    <button className={"next"} onClick={() => next()}>Next</button>
                </div>
            </Fade>
        </div>
    );
};

export default Intro;