import React, {useContext, useEffect, useState} from 'react'
import {FaRegUserCircle} from "react-icons/fa";
import UserIcon from "../../Common/UserIcon";
import clientAPI from "../../../service/ApiService";
import {API_URL} from "../../../constants";
import {ADD_USER} from "../../../state/users/UsersTypes";
import {UsersContext} from "../../../state/usersStore";

function PeoplePeopleCell({row, column}) {

    const initialState = {
        entry: null,
        usersToDisplay: [],
    }

    const [state, setState] = useState(initialState)
    const [usersState, dispatchUsers] = useContext(UsersContext);

    useEffect(() => {
        computeEntry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        computeEntry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersState]);

    const computeEntry = () =>{

        var rowEntries = row.entries.find(function(obj){
            return obj.column === column.id
        })

        if(rowEntries === undefined){
            return;
        }

        var users = [];
        if(rowEntries){
            users = rowEntries.value.map((userId) => {
                const user = usersState.users.find(o => o.ID === userId)
                if(user === undefined){
                    clientAPI.get(API_URL + "/user/" + userId)
                        .then(response => {
                            const user = response.data.payload;
                            dispatchUsers({
                                type: ADD_USER,
                                payload: {user: user}
                            });
                        })
                        .catch(error => {
                            console.log("Error getting user");
                        })
                }
                return user
            })
        }

        setState({
            ...state,
            entry: (rowEntries != null) ? rowEntries.value : "",
            users: users,
        })
    }

    return (
        <div className="cell-entry people-cell">
            { state.entry === null &&
                <FaRegUserCircle className="no-user-icon" />
            }

            { state.users?.map((user, index) =>
                <UserIcon key={index} user={user} />
            )}
        </div>
    )
}

export default PeoplePeopleCell
