import React, {useEffect, useState} from 'react';
import {Alert, Button, Input, Modal, Radio} from 'antd';
import {Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {API_TEMPLATE} from "../../constants";
import clientAPI from "../../service/ApiService";
import {toast} from "react-toastify";

function EditModal({open, template, refresh}){

    const initialState = {
        visible: false,
        error: null,
        editing: false,
        refresh: 0,
        visibility: null,
    }
    const [state, setState] = useState(initialState)

    useEffect(() => {
        if(open === 0) return;
        setState({
            ...state,
            visible: true,
            editing: false,
            visibility: template?.visibility
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleOk = e => {
        triggerValidation()
            .then((result) => {
                if (result) {
                    handleSubmit(onSubmit)();
                }
            })
    };

    const handleCancel = e => {
        // console.log(e);
        setState({
            ...state,
            visible: false,
        });
        reset();
    };

    const visibilityChange = e => {
        setState({
            ...state,
            visibility: e.target.value,
        });
    };

    const {handleSubmit, control, errors, triggerValidation, reset} = useForm();
    const onSubmit = (values, e) => {
        setState({
            ...state,
            error: null,
            loading: true,
            success: false,
        })

        values = {
            ...values,
            visibility: state.visibility,
        }

        clientAPI.put(API_TEMPLATE + "/" + template.id, values)
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false,
                    success: true,
                    visible: false,
                })
                reset()
                refresh();
            })
            .catch(error => {
                console.log(error)
                if(error && error.response && error.response.data.statusCode.msg){
                    setState({
                        ...state,
                        error:  error.response.data.statusCode.msg,
                        loading: false,
                    })
                }else{
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    };

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    return (
        <div>
            {state.visible &&
            <Modal
                title={"Edit template"}
                visible={state.visible}
                okText={"Done"}
                className="duplicate-tempalte-modal"
                onOk={() => handleOk()}
                onCancel={() => handleCancel()}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={state.loading} htmlType={"submit"}  onClick={handleOk}>
                        Submit
                    </Button>,
                ]}
            >
                {state.error && <Alert message={state.error} type="error" closable className="notification"/>}
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group controlId="formBasicName">
                        <Form.Label>Name</Form.Label>
                        <Controller
                            as={Input}
                            name='name'
                            control={control}
                            placeholder="Name"
                            autoComplete="off"
                            defaultValue={template.name}
                            rules={{
                                required: true,
                            }}
                        />

                        {errors.name && (
                            <span className="alert-danger">Invalid name</span>
                        )}
                    </Form.Group>

                    <Form.Group controlId="formBasicName">
                        <Form.Label style={{"display":"block"}}>Available to</Form.Label>
                        <Radio.Group onChange={visibilityChange} value={state.visibility}>
                            <Radio style={radioStyle} value={"account"}>
                                Everybody in this account
                            </Radio>
                            <Radio style={radioStyle} value={"private"}>
                                Only me
                            </Radio>
                        </Radio.Group>
                    </Form.Group>

                </Form>
            </Modal>
            }

        </div>
    );
};

export default EditModal;
