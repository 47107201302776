import React from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

function TeamIcon({team, color=null, disable}) {

    return (
        <div>
            { !disable ?
                <OverlayTrigger placement={'bottom'}  overlay={<Tooltip id="tooltip-disabled">{team.name}</Tooltip>} show={false}>
                    <span className="d-inline-block user-thumb">
                        <p className="user-initials" style={{ backgroundColor: color || "green" }}>
                            {team.name.substring(0, 2)}
                        </p>
                    </span>
                </OverlayTrigger>
                    :
                <span className="d-inline-block user-thumb">
                    <p className="user-initials" style={{ backgroundColor: color || "green" }}>
                        {team.name.substring(0, 2)}
                    </p>
                </span>
            }

        </div>
    )
}

export default TeamIcon
