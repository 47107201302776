import React from 'react'
import {Dropdown, Menu} from 'antd';
import {FiPlusCircle} from "react-icons/fi";
import {AiOutlineCalculator, AiOutlineUnorderedList} from "react-icons/ai";
import {GoTextSize} from "react-icons/go";
import {MdDateRange, MdPersonOutline} from "react-icons/md";

function AddColumn({newColumn, page}) {

    const addColumn = val => {
        let payload = {};
        if(val === "status"){
            payload = {
                "name" : "Status",
                "type" : "status",
                "metadata" : [
                    {
                        name: "Done",
                        color: "#43a047",
                        position: 1,
                    },
                    {
                        name: "Working on it",
                        color: "#ffb300",
                        position: 2,
                    },
                    {
                        name: "Pending",
                        color: "#1e88e5",
                        position: 3,
                    },
                    {
                        name: "Stuck",
                        color: "#e53935",
                        position: 4,
                    },
                    {
                        name: "",
                        color: "#fb8c00",
                        position: 5,
                    },
                    {
                        name: "",
                        color: "#757575",
                        position: 6,
                    },
                    {
                        name: "",
                        color: "#5e35b1",
                        position: 7,
                    },
                    {
                        name: "",
                        color: "#546e7a",
                        position: 8,
                    }
                ]
            }
        }else if(val === "text"){
            payload = {
                "name" : "Text",
                "type" : "text"
            }
        }else if(val === "datetime"){
            payload = {
                "id"   : "3",
                "name" : "date",
                "type" : {type: "datetime"}
            }
            if(page === 5){
                newColumn(payload);
            }
        }else if(val === "number"){
            payload = {
                "name" : "Number",
                "type" : "number"
            }
        }else if(val === "people"){
            payload = {
                "name" : "Owner",
                "type" : "people"
            }
        }

    }

    const menu = (
        <Menu className={"add-row-container"} inlineIndent={0} >
            <Menu.Item onClick={() => addColumn("status")}><AiOutlineUnorderedList/>Status</Menu.Item>
            <Menu.Item onClick={() => addColumn("text")}><GoTextSize/>Text</Menu.Item>
            <Menu.Item onClick={() => addColumn("datetime")}><MdDateRange/>Date</Menu.Item>
            <Menu.Item onClick={() => addColumn("number")}><AiOutlineCalculator/>Number</Menu.Item>
            <Menu.Item onClick={() => addColumn("people")}><MdPersonOutline/>People</Menu.Item>
        </Menu>

    );

    return (
        <div className="add-row-container" id={"add-row-container"}>
            <Dropdown overlay={menu} trigger={['click']}>
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()} href="/#">
                    <button className="add-column-action"><FiPlusCircle/></button>
                </a>
            </Dropdown>
        </div>
    )
}

export default AddColumn
