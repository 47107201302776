import {
    ADD_COLUMN,
    ADD_COLUMN_AND_ROWS,
    ADD_ROW,
    ADD_ROWS,
    ADD_SUB_ROW,
    CHANGE_ACTION_WIDTH,
    CHANGE_COLUMN_NAME,
    CHANGE_COLUMN_WIDTH,
    CHANGE_ROW_NAME,
    CHANGED_STATUS_NAME_METADATA,
    CLEANUP,
    CLOSE_LOGS_DRAWER,
    CLOSE_MESSAGES_DRAWER,
    CREATE_TABLE,
    DELETE_COLUMN,
    DELETE_ENTRY,
    DELETE_ROW,
    DELETE_TABLE,
    DELETED_SOME_ROWS,
    DESELECT_ALL_ROWS,
    DESELECT_ROW,
    DUPLICATED_SOME_ROWS,
    OPEN_LOGS_DRAWER,
    OPEN_MESSAGES_DRAWER, RICH_TEXT_SET_BODY, RICH_TEXT_SET_CONTENT,
    SELECT_ROW,
    SET_ROWS,
    SET_SINGLE_VALUE,
    SET_SUB_ROWS,
    SET_TABLES,
    SET_VIEW,
    SORT_COLUMN,
    STAR_BOARD,
    TABLE_CHANGE_NAME,
    TABLE_COMPLETE,
    UNSTAR_BOARD,
    UPDATE_ASSETS,
    UPDATE_BOARD,
    UPDATE_BOARD_DESC,
    UPDATE_BOARD_NAME,
    UPDATE_KANBAN_ROW_ORDER,
    UPDATE_KANBAN_STATUS_ORDER,
    UPDATE_ROW_POSITION,
    UPDATE_VIEW_CONFIG
} from './BoardTypes'

export const boardInitialState = {
    board: null,
    row: null,
    table: null,
    drawerVisible: false,
    logsDrawerVisible: false,
    selectedTable: null,
    selectedRows: [],
    reloadBoard: 1,
    dragging: false,
}

const BoardReducer = (state = boardInitialState, action) => {

    switch (action.type) {
        case CLEANUP:
            return boardInitialState;

        case UPDATE_BOARD:

            // We should maintain the current board
            let board = action.payload ?? {};
            if(state.board?.id === board?.id && board.tables && state?.board?.tables){ // In case that we got some rows on the old board we maintain those
                board.tables = board?.tables.map(table => {
                    let ta = state.board?.tables.find(t => t.id === table.id);
                    table.rows = ta?.rows ?? [];
                    return table;
                })
            }

            if(state.board?.id === board?.id && state.board?.userpreference)
                board.userpreference = state.board?.userpreference;

            return {
                ...state,
                board: board
            };

        case OPEN_MESSAGES_DRAWER:
            return {
                ...state,
                row: action.payload.row,
                table: action.payload.table,
                drawerVisible: true
            };
        case CLOSE_MESSAGES_DRAWER:
            return {
                ...state,
                row: null,
                drawerVisible: false
            };
        case OPEN_LOGS_DRAWER:
            return {
                ...state,
                logsDrawerVisible: true
            };
        case CLOSE_LOGS_DRAWER:
            return {
                ...state,
                logsDrawerVisible: false
            };
        case SELECT_ROW:

            let rows = [];
            if (state.selectedTable === action.payload.table) {
                rows = state.selectedRows.concat(action.payload.row);
            } else {
                rows = [action.payload.row];
            }

            return {
                ...state,
                selectedTable: action.payload.table,
                selectedRows: rows,
            };
        case DESELECT_ROW:

            let remainingRows = state.selectedRows.filter(o => o !== action.payload.row);
            let table = remainingRows.length === 0 ? null : state.selectedTable;

            return {
                ...state,
                selectedTable: table,
                selectedRows: remainingRows,
            };

        case DESELECT_ALL_ROWS:
            return {
                ...state,
                selectedTable: null,
                selectedRows: [],
            };
        case DELETED_SOME_ROWS:

            return {
                ...state,
                selectedTable: null,
                selectedRows: [],
                board: {
                    ...state.board, tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            // eslint-disable-next-line array-callback-return
                            table.rows = table.rows.filter(row => {

                                // child rows
                                if(row.rows?.length > 0){
                                    // eslint-disable-next-line array-callback-return
                                    row.rows = row.rows.filter(subrow => {
                                        if (!action.payload.rows.includes(subrow.id)) {
                                            return row;
                                        }
                                    })
                                }

                                // no parent, normal row
                                if (!action.payload.rows.includes(row.id)) {
                                    return row;
                                }
                            })
                        }
                        return table;
                    })
                }
                // reloadBoard: state.reloadBoard + 1
            };

        case ADD_COLUMN_AND_ROWS:

            let t = state.board.tables.map(table => {
                if (table.id === action.payload.tableId) {
                    table.columns = table?.columns?.concat(action.payload.column)
                    table.rows = table?.rows?.map(row => {
                        let r = action.payload.rows?.find((r) => r.id === row.id)
                        if (r) {
                            return r;
                        }
                        return row;
                    })
                }
                return table;
            });

            return {
                ...state,
                selectedTable: null,
                selectedRows: [],
                board: {
                    ...state.board,
                    tables: t
                }
            };


        case DUPLICATED_SOME_ROWS:

            const subrows = action.payload.rows.filter(row => {
                return row.parent
            })

            const mainRows = action.payload.rows.filter(row => {
                return row.parent == null
            })

            return {
                ...state,
                selectedTable: null,
                selectedRows: [],
                board: {
                    ...state.board, tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId && table.complete) {

                            // child rows
                            table.rows = table?.rows?.map(row => {
                                if(row.rows?.length > 0){
                                    subrows.map(subrow => {
                                        if (subrow.parent === row.id) {
                                            row.rows = row.rows.concat(subrow);
                                        }
                                        return subrow
                                    })
                                }
                                return row
                            })
                            table.rows = table.rows.concat(mainRows);
                        }
                        return table;
                    })
                }
                // reloadBoard: state.reloadBoard + 1
            };
        case STAR_BOARD:

            if (!state.board)
                return state;

            return {
                ...state,
                board: {...state.board, userpreference: {...state.board.userpreference ?? {}, favorite: true}}
            };
        case UNSTAR_BOARD:

            if (!state.board)
                return state;

            return {
                ...state,
                board: {...state.board, userpreference: {...state.board.userpreference ?? {}, favorite: false}}
            };
        case UPDATE_BOARD_NAME:
            return {
                ...state,
                board: {...state.board, name: action.payload}
            };
        case UPDATE_BOARD_DESC:
            return {
                ...state,
                board: {...state.board, description: action.payload}
            };

        case CREATE_TABLE:
            return {
                ...state,
                board: {...state.board, tables: state.board.tables.concat(action.payload.table)}
            };

        case TABLE_CHANGE_NAME:
            return {
                ...state,
                board: {
                    ...state.board, tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.name = action.payload.name
                        }
                        return table;
                    })
                }
            };
        case DELETE_TABLE:
            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board.tables.filter(table => table.id !== action.payload.tableId)
                }
            };
        case SET_TABLES:
            return {
                ...state,
                dragging: false,
                board: {
                    ...state.board,
                    tables: action.payload.tables
                }
            };
        case TABLE_COMPLETE:
            return {
                ...state,
                board: {
                    ...state.board, tables: state.board?.tables?.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.complete = true;
                        }
                        return table;
                    })
                }
            };
        case SORT_COLUMN:

            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.columns = action.payload.columns
                        }
                        return table;
                    })
                }
            };
        case DELETE_COLUMN:
            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.columns = table.columns.filter(column => {
                                return column.id !== action.payload.columnId
                            })
                        }
                        return table;
                    })
                }
            };
        case ADD_COLUMN:
            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.columns = table.columns.concat(action.payload.column)
                        }
                        return table;
                    })
                }
            };
        case CHANGE_COLUMN_NAME:

            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.columns = table.columns.map(column => {
                                if (column.id === action.payload.columnId) {
                                    column.name = action.payload.name
                                }
                                return column;
                            })
                        }
                        return table;
                    })
                }
            };

        case CHANGE_COLUMN_WIDTH:

            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.columns = table.columns.map(column => {
                                if (column.id === action.payload.columnId) {
                                    column.width = action.payload.width
                                }
                                return column;
                            })
                        }
                        return table;
                    })
                }
            };

        case CHANGE_ROW_NAME:

            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.rows = table.rows.map(row => {

                                // Subrow
                                if (action.payload.parentRowId && row.id === action.payload.parentRowId){
                                    row.rows = row.rows.map(subrow => {
                                        if(subrow.id === action.payload.rowId){
                                            subrow.name = action.payload.name;
                                        }
                                        return subrow;
                                    })
                                }else if (!action.payload.parentRowId && row.id === action.payload.rowId) { // Normal row
                                    if (row.id === action.payload.rowId) {
                                        row.name = action.payload.name;
                                    }
                                }
                                return row;
                            })
                        }
                        return table;
                    })
                }
            };

        case UPDATE_ROW_POSITION:

            let tables = state.board.tables.map(table => {
                if (table.id === action.payload.tableId) {
                    table.rows = table.rows.map(row => {
                        if (row.id === action.payload.row.id) {
                            row.position = action.payload.row.position;
                        }
                        return row;
                    })

                    table.rows = table.rows.sort(function(a,b){return a.position>b.position ? 1 : -1})

                }
                return table;
            })

            return {
                ...state,
                board: {
                    ...state.board,
                    tables: tables
                }
            };

        case ADD_ROW:

            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.rows = table.rows ? table.rows.concat(action.payload.row) : [action.payload.row]
                        }
                        return table;
                    })
                }
            };

        case ADD_SUB_ROW:

            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.rows.map(row => {
                                if (row.id === action.payload.rowParentId) {
                                    row.rows = row.rows ? row.rows.concat(action.payload.row) : [action.payload.row]
                                }
                                return row;
                            })
                        }
                        return table;
                    })
                }
            };

        case ADD_ROWS:

            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board?.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.rows = table.rows ? table.rows.concat(action.payload.rows) : action.payload.rows
                        }
                        return table;
                    })
                }
            };

        case DELETE_ROW:
            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.rows = table.rows.filter(row => {
                                return row.id !== action.payload.rowId
                            })
                        }
                        return table;
                    })
                }
            };

        case SET_ROWS:

            if(!state?.board?.tables)
                return state;

            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.rows = action.payload.rows
                        }
                        return table;
                    })
                }
            };

        case SET_SUB_ROWS:

            if(!state?.board?.tables)
                return state;

            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.rows = table.rows.map(row => {
                                if(row.id === action.payload.parentRowId){
                                    row.rows = action.payload.rows
                                }
                                return row;
                            })
                        }
                        return table;
                    })
                }
            };

        case CHANGE_ACTION_WIDTH:

            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.actionWidth = action.payload.width;
                        }
                        return table;
                    })
                }
            };

        case SET_SINGLE_VALUE:

            // payload: {tableId: props.table.id, rowId: props.row.id, columnId: props.column.id, value: val}

            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.rows = table.rows.map(row => {

                                // Subrow
                                if (action.payload.parentRowId && row.id === action.payload.parentRowId){
                                    row.rows = row.rows.map(subrow => {
                                        if(subrow.id === action.payload.rowId){
                                            let found = false;
                                            subrow.entries = subrow.entries.map(entry => {
                                                if (entry.column === action.payload.columnId) {
                                                    entry.value = action.payload.value;
                                                    found = true;
                                                }
                                                return entry;
                                            })

                                            if (!found) {
                                                subrow.entries = subrow.entries.concat({
                                                    column: action.payload.columnId,
                                                    value: action.payload.value
                                                });
                                            }
                                        }
                                        return subrow;
                                    })

                                }else if (!action.payload.parentRowId && row.id === action.payload.rowId) { // Normal row
                                    let found = false;
                                    row.entries = row.entries.map(entry => {
                                        if (entry.column === action.payload.columnId) {
                                            entry.value = action.payload.value;
                                            found = true;
                                        }
                                        return entry;
                                    })

                                    if (!found) {
                                        row.entries = row.entries.concat({
                                            column: action.payload.columnId,
                                            value: action.payload.value
                                        });
                                    }
                                }
                                return row;
                            })
                        }
                        return table;
                    })
                }
            };

        case DELETE_ENTRY:

            // payload: {tableId: props.table.id, rowId: props.row.id, columnId: props.column.id, value: val}

            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.rows = table.rows.map(row => {

                                // Subrow
                                if (action.payload.parentRowId && row.id === action.payload.parentRowId){
                                    row.rows = row.rows.map(subrow => {
                                        if(subrow.id === action.payload.rowId){
                                            subrow.entries = subrow.entries.filter(entry => {
                                                if (entry.column === action.payload.columnId) {
                                                    return false;
                                                }
                                                return entry;
                                            })

                                        }
                                        return subrow;
                                    })

                                }else if (!action.payload.parentRowId && row.id === action.payload.rowId) { // Normal row
                                    row.entries = row.entries.filter(entry => {
                                        if (entry.column === action.payload.columnId) {
                                            return false;
                                        }
                                        return entry;
                                    })
                                }
                                return row;
                            })
                        }
                        return table;
                    })
                }
            };

        case SET_VIEW:

            if (!state.board)
                return state;

            var newView = state?.board?.userpreference?.tableViews;
            if (newView !== undefined) {
                newView[action.payload.tableId] = action.payload.view
            } else {
                newView = {};
                newView[action.payload.tableId] = action.payload.view
            }

            return {
                ...state,
                board: {
                    ...state.board,
                    userpreference: {
                        ...state.board.userpreference ?? {},
                        tableViews: newView
                    }
                }
            };

        case UPDATE_VIEW_CONFIG:

            if (!state.board)
                return state;

            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.calendarStartDate = action.payload.calendarStartDate;
                            table.calendarEndDate = action.payload.calendarEndDate;
                            table.kanbanStatusColumn = action.payload.kanbanStatusColumn;
                        }
                        return table;
                    })
                }
            };

        case UPDATE_KANBAN_STATUS_ORDER:

            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.columns = table.columns.map(column => {
                                if (column.id === action.payload.columnId) {
                                    // eslint-disable-next-line array-callback-return
                                    action.payload.status.map((position, i) => {
                                        column.type.metadata = column.type.metadata.map(status => {
                                            if (status.id === position.id) {
                                                status.position = i + 1;
                                            }
                                            return status;
                                        })
                                    })
                                }
                                return column;
                            })
                        }
                        return table;
                    })
                }
            };

        case UPDATE_KANBAN_ROW_ORDER:

            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.rows.map(row => {
                                row.kanbanPosition = action.payload.positions[row.id];
                                return row;
                            })
                        }
                        return table;
                    })
                }
            };

        case CHANGED_STATUS_NAME_METADATA:

            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.columns = table.columns.map(column => {
                                if (column.id === action.payload.columnId) {
                                    column.type.metadata = action.payload.metadata;
                                }
                                return column;
                            })
                        }
                        return table;
                    })
                }
            };

        case UPDATE_ASSETS:
            return {
                ...state,
                board: {
                    ...state.board,
                    tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.refresh = table.refresh ? table.refresh+1 : 1
                        }
                        return table;
                    })
                }
            };

        case RICH_TEXT_SET_BODY:
            return {
                ...state,
                board: {
                    ...state.board, tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.body = action.payload.body
                        }
                        return table;
                    })
                }
            };

        case RICH_TEXT_SET_CONTENT:
            return {
                ...state,
                board: {
                    ...state.board, tables: state.board.tables.map(table => {
                        if (table.id === action.payload.tableId) {
                            table.quillContent = action.payload.quillContent
                        }
                        return table;
                    })
                }
            };

        default:
            return state;
    }
};

export default BoardReducer;