import React, {useContext, useEffect, useState} from 'react'
import {Form} from 'react-bootstrap';
import clientAPI from "../../../service/ApiService"
import {Controller, useForm} from "react-hook-form";
import {API_BOARD} from '../../../constants';
import {CREATE_BOARD, EDIT_BOARD, UPDATE_BOARD} from '../../../state/boards/BoardTypes';
import {BoardsContext} from '../../../state/boardsStore'
import {BoardContext} from '../../../state/boardStore'
import {useHistory} from "react-router-dom";
import {Alert, Button, Input, Modal, Radio, Switch} from 'antd';
import ButtonColorPicker from '../../Common/ButtonColorPicker';
import SelectUsersField from '../../Common/SelectUsersField';
import SelectTeamsField from '../../Common/SelectTeamsField';
import SelectCategoriesField from "../../Common/SelectCategoriesField";
import TableSearchTemplate from "../Template/TableSearchTemplate";

function CreateBoardModal({visible, setVisible}) {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        mode: null,
        color: null,
        users: [],
        teams: [],
        categories: null,
    }

    const handleOk = e => {
        triggerValidation()
            .then((result) => {
                if (result) {
                    handleSubmit(onSubmit)();
                }
            })
    };

    const handleCancel = e => {
        setVisible(false);
        reset();
    };

    const [state, setState] = useState(initialState)
    const [visibilityValue, setVisibilityValue] = useState(null)
    const [boardsState, dispatch] = useContext(BoardsContext);
    const dispatchBoard = useContext(BoardContext)[1];
    const history = useHistory();

    useEffect(() => {
        const color = boardsState.board === null ? ["#FF6900", "#FCB900", "#7BDCB5", "#00D084", "#8ED1FC", "#0693E3", "#ABB8C3", "#EB144C", "#F78DA7", "#9900EF"][Math.floor(Math.random() * 10)] : boardsState.board.color;

        setState({
            ...initialState,
            template: boardsState.template ?? null,
            templateChecked: boardsState.template !== null,
            board: boardsState.board,
            mode: boardsState.board === null ? 'new' : 'edit',
            color: color,
            categories: boardsState.board?.categories && boardsState.board?.categories.length > 0 ? boardsState.board?.categories : null
        });
        setVisibilityValue(boardsState.board === null ? "public" : boardsState.board?.visibility)

        return function cleanup(){
            // console.log("cleanup")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const { handleSubmit, control, errors, triggerValidation, reset } = useForm();
    const onSubmit = (values, e) => {
        setState({
            ...state,
            error: null,
            loading: true,
            success: false,
        })

        values = {
            ...values,
            visibility: visibilityValue,
            color: state.color,
            teams: values.teams && values.teams.length > 0 ? values.teams.map(function (team) { return team.value }) : [],
            users: values.users && values.users.length > 0 ? values.users.map(function (user) { return user.value }) : [],
            categories: values.categories && values.categories.length > 0 ? values.categories.map(function (category) { return category.value }) : [],
        }

        if(state.template){
            values = {...values, template: state.template.id}
        }

        if (state.mode === 'new'){
            clientAPI.post(API_BOARD, values)
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false,
                    success: true,
                })
                e.target.reset()
                dispatch({ type: CREATE_BOARD, payload: response.data.payload });

                history.push("/boards/" + response.data.payload.id);
                handleCancel()
            })
            .catch(error => {
                console.log(error)
                setState({
                    ...state,
                    error: error?.response?.data?.statusCode?.msg ?? 'Error connecting with the server, check your connection or contact support',
                    loading: false,
                    success: false
                })
            })
        }else{
            clientAPI.put(API_BOARD + "/" + state.board.id, values)
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false,
                    success: true,
                })
                setVisible(false);
                e.target.reset()
                // console.log(response.data.payload);
                dispatch({ type: EDIT_BOARD, payload: response.data.payload });
                dispatchBoard({type: UPDATE_BOARD, payload:response.data.payload});
            })
            .catch(error => {
                console.log(error)
                setState({
                    ...state,
                    error: error?.response?.data?.statusCode?.msg ?? 'Error connecting with the server, check your connection or contact support',
                    loading: false,
                    success: false
                })
            })
        }
    };

    const visibilityChange = (e) => {
        setVisibilityValue(e.target.value);
    };

    const selectTemplate = template => {
        setState({ ...state, template: template })
    }

    const toggleUseTemplate = checked => {
        setState({...state, templateChecked: checked})
    }

    return (
        <div>
            { state.mode &&
            <Modal
                title={state.mode==="new" ? "Create board" : "Edit board"}
                visible={visible}
                okText={state.mode==="new" ? "Create" : "Edit"}
                className="create-board-modal"
                onOk={() => handleOk()}
                onCancel={() => handleCancel()}
                footer={null}
                okButtonProps={{ form: 'category-editor-form', key: 'submit', htmlType: 'submit' }}
            >
                {state.error && <Alert message={state.error} type="error" closable className="notification" />}
                {state.success && <Alert message={state.mode==="new" ? "Board created" : "Board edited" } closable className="notification" type="success" />}

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group controlId="formBasicName">
                        <Form.Label>Board name</Form.Label>
                        <Controller
                            as={Input}
                            name='name'
                            control={control}
                            placeholder="Name"
                            autoComplete="off"
                            defaultValue={state.mode === "new" ? "" : state.board?.name}
                            rules={{
                                required: true,
                            }}
                        />

                        {errors.name && (
                            <span className="alert-danger">Invalid name</span>
                        )}
                    </Form.Group>

                    <SelectCategoriesField
                        title={"Categories"}
                        defaultCategories={state.mode==="new" ? [] : state.board?.categories}
                        control={control}
                        errors={errors}
                    />

                    <Form.Group controlId="formBasicName" className="vertical-radio">
                        <Form.Label>Visibility</Form.Label>
                        {/* <Controller
                            as={
                                // <Radio.Group
                                // value={"private"}
                                // ref={register}
                                // options={[{ label: 'Public', value: 'public' },{ label: 'Private', value: 'private' },]}  
                                // /> */}

                        <Radio.Group onChange={visibilityChange} value={visibilityValue} name='visibility'>
                            <Radio value={"public"} checked={visibilityValue === 'public'}>Public</Radio>
                            <Radio value={"private"} checked={visibilityValue === 'private'} >Private</Radio>
                        </Radio.Group>

                        {errors.visibility && (
                            <span className="alert-danger">Pick visibility</span>
                        )}
                    </Form.Group>

                    {state.color &&
                        <Form.Group controlId="formBasicName" className="">
                            <ButtonColorPicker color={state.color} setColor={(color) => setState({ ...state, color: color })} />
                        </Form.Group>
                    }


                    {visibilityValue === 'private' &&
                        <div>
                            <SelectUsersField title={"Add users to the board"} defaultUsers={state.mode==="new" ? [] : state.board.users} control={control} errors={errors} />
                            <SelectTeamsField title={"Add teams to the board"} defaultTeams={state.mode==="new" ? [] : state.board.teams} control={control} errors={errors} />
                        </div>
                    }

                    { state.mode === "new" &&
                        <Form.Group controlId="formBasicName" className="">
                            {!state.template &&
                                <label>Use template</label>
                            }
                            {state.template &&
                                <label>Selected template:&nbsp;&nbsp;<strong>{state.template.name}</strong></label>
                            }
                            <Switch checked={state.templateChecked} onChange={toggleUseTemplate} className={"template-switch"} />
                            { state.templateChecked &&
                                <TableSearchTemplate ttype={"board"} selectTemplate={selectTemplate} />
                            }
                        </Form.Group>
                    }

                    <div className="actions">
                        <Button htmlType="button" className="cancel-button" onClick={() => setVisible(false)}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="primary" htmlType="submit" className="board-form-button" loading={state.loading}>
                            {state.mode==="new" ? "Create" : "Edit"}
                        </Button>
                    </div>
                </Form>
            </Modal>
            }
        </div>
    )
}

export default CreateBoardModal
