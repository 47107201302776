import React, {useState, useEffect} from 'react'
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import clientAPI from "../../service/ApiService"
import { Controller } from "react-hook-form";
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import {API_URL} from "../../constants";

function SelectCategoriesField({ title, control, errors, defaultCategories }) {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        loadingCategories: false,
        categories: [],
    }

    useEffect(() => {
        loadCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [state, setState] = useState(initialState)
    const animatedComponents = makeAnimated();

    const loadCategories = () => {

            setState({
                ...state,
                error: null,
                loadingCategories: true,
            })

            clientAPI.get(API_URL + "/categories/all")
                .then(response => {

                    let categories = response.data.payload;
                    var options = [];
                    if(categories && categories.length > 0){
                        categories.map(function (category) {
                            options.push({ value: category, label: category });
                            return category;
                        })
                    }

                    let values = defaultCategories && defaultCategories.length > 0 ? defaultCategories.map(function(category){ return {value: category, label: category}} ) : [];
                    // console.log(defaultCategories) // les daqui
                    // console.log(categories)        // totes
                    // console.log(values)            // la que toca ben formada

                    control.setValue('categories', values, { shouldValidate: false })

                    setState({
                        ...state,
                        error: null,
                        loadingCategories: false,
                        categories: options,
                    })

                })
                .catch(error => {
                    setState({
                        ...state,
                        loadingCategories: false,
                    })
                    if (error && error.response && error.response.data.statusCode.msg) {
                        console.log(error);
                    } else {
                        toast.error('Error connecting with the server, check your connection or contact support');
                    }
                })

        };

    return (
        <div>
            <Form.Group controlId="formBasicCategories">
                <Form.Label>{title}</Form.Label>

                <Controller
                    name="categories"
                    as={CreatableSelect}
                    isMulti
                    components={animatedComponents}
                    options={state.categories}
                    isLoading={state.loadingCategories}
                    control={control}
                    closeMenuOnSelect={false}
                    defaultOptions={true}
                    className="categories-multi-select"
                    classNamePrefix="categories-select"
                    rules={{ required: false }}
                    placeholder={"Type to create a new category or select an existing one"}
                />

                {errors.categories && (
                    <span className="alert-danger">Invalid categories</span>
                )}
            </Form.Group>
        </div>
    )
}

export default SelectCategoriesField