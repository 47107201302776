import React from 'react'
import EdiText from "react-editext";
import clientAPI from "../../service/ApiService";
import {API_ASSETS} from "../../constants";
import {toast} from "react-toastify";
import {SpinnerLoading} from "../Style/styles.css";

function UploadingAssetRow({ file, refresh }) {

    const setMetadata = (value) => {
        const payload = {"name": value, "description": ""}
        clientAPI.post(API_ASSETS + "/metadata/" + file.assetId, payload)
            .then(response => {
                refresh()
            })
            .catch(error => {
                if(error && error.response && error.response.data.statusCode.msg){
                }else{
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    }

    return (
        <tr className="asset-row">
            <td className="asset-name-row">

                {file.status && file.assetId && file.status === "done" ?
                    <EdiText
                        submitOnEnter
                        cancelOnEscape
                        submitOnUnfocus
                        hideIcons={true}
                        editOnViewClick={true}
                        showButtonsOnHover={false}
                        editButtonClassName="hide"
                        saveButtonClassName="hide"
                        cancelButtonClassName="hide"
                        viewContainerClassName="asset-name"
                        inputProps={{
                            style: {
                                backgroundColor: '#FAFAFA',
                                width: "100%"
                            },
                        }}
                        type="text" value={file.name}
                        onSave={setMetadata}
                        className="edit-entry"/>
                    : file?.name
                }

                {/*<AssetIcon asset={asset} />*/}
                {/*<div className="name">{asset.name && asset.name.length > 0 ? asset.name : asset.originalFilename }</div>*/}
            </td>
            <td className={"status-cell"}>
                { (file.status === "uploading" || file.status == null) &&
                <SpinnerLoading animation="border" role="status" className="spinner-animation" /> }
                { file.status && file.status === "error" && <div className={"error"}>Error</div>}
                { file.status && file.status === "done" && <div className={"done"}>Done</div>}
            </td>
            {/*<td className="">*/}
            {/*    {humanFileSize(file.size)}*/}
            {/*</td>*/}
            {/*<td className="">*/}
            {/*    {file.type}*/}
            {/*    /!*{formatDate(asset.createdAt)}*!/*/}
            {/*</td>*/}
        </tr>
    )
}

export default UploadingAssetRow
