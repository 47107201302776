import React, {useContext, useEffect, useState} from 'react'
import {Form} from 'react-bootstrap';
import clientAPI from "../../service/ApiService"
import {Controller, useForm} from "react-hook-form";
import {API_BOARD} from '../../constants';
import {CREATE_BOARD} from '../../state/boards/BoardTypes';
import {BoardsContext} from '../../state/boardsStore'
import {toast} from 'react-toastify';
import {Alert, Button, Input, Modal} from 'antd';

function DuplicateBoard({board, boardDuplicated, counter}) {

    const initialState = {
        error: null,
        loading: false,
        success: false,
        visible: false,
    }

    useEffect(() => {
        setState({...state, board: board})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [board]);

    useEffect(() => {
        if (counter > 0) {
            showModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter]);

    const showModal = () => {
        setState({
            ...initialState,
            visible: true,
        });
        reset();
    };

    const handleOk = e => {
        triggerValidation()
            .then((result) => {
                if (result) {
                    handleSubmit(onSubmit)();
                }
            })
    };

    const handleCancel = e => {
        // console.log(e);
        setState({
            ...state,
            visible: false,
        });
        reset();
    };


    const [state, setState] = useState(initialState)
    const dispatch = useContext(BoardsContext)[1];

    const {handleSubmit, control, errors, triggerValidation, reset} = useForm();
    const onSubmit = (values, e) => {
        setState({
            ...state,
            error: null,
            loading: true,
            success: false,
        })

        clientAPI.post(API_BOARD + "/" + board.id + "/duplicate", values)
            .then(response => {
                setState({
                    ...state,
                    error: null,
                    loading: false,
                    success: false,
                    visible: false,
                })
                e.target.reset()
                dispatch({type: CREATE_BOARD, payload: response.data.payload});

                boardDuplicated(response.data.payload)
            })
            .catch(error => {
                if (error && error.response && error.response.data.statusCode.msg) {
                    setState({
                        ...state,
                        error: error.response.data.statusCode.msg,
                        loading: false,
                    })
                } else {
                    toast.error('Error connecting with the server, check your connection or contact support');
                }
            })
    };

    return (
        <React.Fragment>

            <Modal
                title={"Duplicate board"}
                visible={state.visible}
                okText={"Duplicate"}
                className="create-board-modal"
                onOk={() => handleOk()}
                onCancel={() => handleCancel()}
                footer={null}
                okButtonProps={{form: 'category-editor-form', key: 'submit', htmlType: 'submit'}}
            >
                {state.error && <Alert message={state.error} type="error" closable className="notification"/>}
                {/*{state.success &&*/}
                {/*<Alert message={"Board duplicated"} closable className="notification" type="success"/>}*/}

                <Form onSubmit={handleSubmit(onSubmit)}>
                    { board &&
                        <Form.Group controlId="formBasicName">
                            <Form.Label>Board name</Form.Label>
                            <Controller
                                as={Input}
                                name='name'
                                control={control}
                                placeholder="Name"
                                autoComplete="off"
                                defaultValue={"Copy of " + board.name}
                                rules={{
                                    required: true,
                                }}
                            />

                            {errors.name && (
                                <span className="alert-danger">Invalid name</span>
                            )}
                        </Form.Group>
                    }

                    <div className="actions">
                        <Button htmlType="button" className="cancel-button"
                                onClick={() => setState({...state, visible: false})}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="primary" htmlType="submit" className="board-form-button"
                                loading={state.loading}>
                            Duplicate
                        </Button>
                    </div>
                </Form>
            </Modal>

        </React.Fragment>
    )
}

export default DuplicateBoard
