import React, {useEffect, useState} from 'react'
import UserCard from './UserCard'
import UserListItem from './UserListItem';

function PeopleDropdown({users, allUsers, visible, updateCell, setVisible}) {

    const [filteredUsers, setFilteredUsers] = useState([]) // Users on the list
    const [selectedUsers, setSelectedUsers] = useState([]) // Users selected

    useEffect(() => {

        if(!users)return;

        let arrayFiltered = [];
        let arraySelected = [];

        allUsers.map(user => {
            let exist = users.find(addedUser => {
                return user.ID === addedUser.ID;
            })
            if(!exist){
                arrayFiltered.push(user);
            }else{
                arraySelected.push(user);
            }
            return user;
        })

        setFilteredUsers(arrayFiltered);
        setSelectedUsers(arraySelected);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, visible]);


    const removeUser = (user) => {
        setSelectedUsers(selectedUsers.filter(u => u.ID !== user.ID));
        setFilteredUsers(filteredUsers.concat(user))
    }

    const addUser = user => {
        setSelectedUsers(selectedUsers.concat(user));
        setFilteredUsers(filteredUsers.filter(u => u.ID !== user.ID))
    }

    const updateValue = () => {
        let userIDS = selectedUsers.map(a => a.ID);
        updateCell(userIDS);
        setVisible(false);
    }

    return (
        <div>
            <div className="current-users">
                {selectedUsers && selectedUsers.map((user, index) =>
                    <UserCard user={user} key={index} removeUser={removeUser} />
                )}
            </div>
            <div className="list-results">
                {filteredUsers.map((user, index) =>
                    <UserListItem key={user.ID} user={user} addUser={addUser} />
                )}
            </div>
            <div className="save-people-actions">
                <button onClick={() => updateValue()}>Save</button>
            </div>
        </div>
    )
}

export default PeopleDropdown
