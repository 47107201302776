import React from 'react'
import AssetTable from "./AssetTable";

function AssetView({table}) {

    return (
        <div className={"asset-view"}>
            <h3>Assets</h3>
            <AssetTable
                open={true}
                table={table}
                showUpload={true}
            />
        </div>
    )
}

export default AssetView